<template>
  <div id="map-find">
    <transition name="modal">
        <div class="modal-mask">

          <div class="modal-wrapper">
            <div class="modal-container"
            role="dialog"
            aria-labelledby="modalTitle"
            aria-describedby="modalDescription">
            <mapbox
              access-token="pk.eyJ1IjoiaG9ydGVuc2VncmVlbiIsImEiOiJja3N1Nm9iNGEwNjUyMm9xY2RlMDlwYWsxIn0.AMGi9L_80RYWk4sB5JYU5w"
              :map-options="{
                style: 'mapbox://styles/mapbox/streets-v10',
                zoom: 15,
                center : this.markers,
              }"
               @map-init="displayMarkers"
            />
          </div>
        </div>
        <div class="closing">
            <button class="close-btn-modal" @click="$emit('close')"></button>
          </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import { loadScript } from 'vue-plugin-load-script';
  import Mapbox from 'mapbox-gl-vue';
  loadScript('https://api.mapbox.com/mapbox-gl-js/v0.34.0/mapbox-gl.js');
  export default{
    name: 'map-find',
    props: ['markers'],
    components: { Mapbox },
    methods: {
      displayMarkers(map) {
        const marker = new mapboxgl.Marker()
        .setLngLat(this.markers)
        .addTo(map);
      },
    },
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/map'>
</style>