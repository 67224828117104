<template>
  <div id="card-resa-pending">
    <div class="col-lg-5 col-xs-8">
      <h3 class="merriweather-reg-26">Mes</h3>
      <h2 class="merriweather-reg-40 mb-5">Réservations en cours</h2>
    </div>
    <div class="reserved-lodgings mt-5" v-for='pending in this.pendings'>
      <div class="col-lg-10 col-xs-12 offset-lg-2">
        <p class="raleway-reg-p13">Numéro de réservation <strong>N°{{pending.num}}</strong></p>
        <div class="card-product">
          <img :src="formatSource(pending)" :alt="pending.lodging.name">
          <div class="card-product-infos">
            <div class="basic-info">
              <p class="raleway-bold-p text-center">{{pending.checkin}} au {{pending.check_out}}</p>
              <p class="raleway-bold-p text-center">{{pending.duration}} nuits</p>
              <!-- <p class="raleway-bold-p text-center">_ personnes</p> -->
            </div>
            <div class="row row-des">
              <div class="col-7">
                <p class="raleway-bold-p">{{pending.lodging.name}}</p>
                <p class="raleway-bold-p">{{pending.lodging.address}}</p>

                <!-- <p class="raleway-reg-p13"><br>dîner et massage offerts 1 chambre</p> -->
              </div>
              <div class="col-4 ml-3">
                <p class="raleway-bold-p">TOTAL {{pending.total}}€</p>
                <p class="raleway-reg-p13">Dû en arrivant à l’établissement</p>
              </div>
            </div>
          </div>
        </div>
        <p></p>
      </div>
    </div>
  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';


  export default{
    name: 'card-resa-pending',
    data() {
      return {
        reservationsList: [],
        origin: window.location.origin,
        pendings: null,
      }
    },
    methods: {
      formatSource(pending) {
        if (pending.lodging.photo_banner) {
          return pending.lodging.photo_banner
        } else {
          return 'https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=';
        }
      },
      async fetchPendingResa() {
        try {
          const response = await fetcher.get("/reservations.json")
          this.reservationsList = response.data
          console.log(this.reservationsList)
          await this.dateNow('-')
          await this.pendingReservations()
        } catch(e) {
          console.error(e)
        }
      },
      dateNow(splinter){
        const set = new Date();
        let getDate = set.getDate().toString();
        if (getDate.length == 1){ //example if 1 change to 01
         getDate = "0"+getDate;
        }
        let getMonth = (set.getMonth()+1).toString();
        if (getMonth.length == 1){
         getMonth = "0"+getMonth;
        }
        let getYear = set.getFullYear().toString();
        const dateNow = getYear +splinter+ getMonth +splinter+ getDate ; //today
        return dateNow;
      },
      pendingReservations() {
        this.pendings = this.reservationsList.filter((reservation) => {
          // console.log(this.dateNow('-'))
          return reservation.checkin >= this.dateNow('-') && reservation.check_out >= this.dateNow('-')
        })
        return this.pendings
      }
    },
  mounted() {
    this.fetchPendingResa()
    }
  }
</script>
