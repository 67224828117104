<template>
  <div id="lodging-show">
    <div id="headDesti" class="mt-lg-5 mt-md-5 pt-lg-5 pt-md-5">
    <div class="text-center">
      <h1 class="merriweather-reg-40">Hortense for Workation</h1>
    </div>
    <div id="banner-lodging" :style="cssProps">
      <div class="banner-show-container">
        <div class="col-md-5 col-sm-2 offset-0 catch-hub-show">
          <p class="merriweather-italic24">
            Vous souhaitez faire vivre à vos collaborateurs une expérience workation unique ? Découvrez une expérience workation unique pour vos collaborateurs avec Hortense.
          </p>
        </div>
      </div>
    </div>
      <div class="container">
        <div class="desti-header text-center">
          <div class="des">
            <p class="raleway-reg-p15" style="text-align: center;">
              Nous proposons à vos collaborateurs un séjour alliant bien-être et productivité. Faites confiance à notre expertise pour organiser ces moments privilégiés. Nous mettrons tout en œuvre pour offrir à vos équipes des instants de détente propices à leur épanouissement professionnel. Faites vivre à vos équipes une expérience workation inoubliable grâce à notre accompagnement sur mesure.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt-lg-2 mt-md-2 pt-lg-2 pt-md-2">
      <div class="row pt-5 pb-5 pr-2">
        <div class="col-md-7 col-lg-7 col-sm-12 align-self-center">
          <h6 class="pb-2" style="font: normal normal normal 26px/33px Merriweather;">Connaissez-vous le concept du workation ?</h6>
          <p class="mab raleway-reg-p15 pr-5" style="line-height: 20px !important;">Le workation est un concept en vogue qui combine travail et vacances. Il permet aux professionnels de travailler depuis des lieux de villégiature, alliant ainsi productivité et découverte. Ce phénomène a gagné en popularité depuis la pandémie, offrant aux travailleurs la flexibilité de travailler à distance. <br></br> L’objectif du workation est de favoriser le bien-être au travail, devenu un critère primordial pour les employés, dépassant même la rémunération. Il s’agit de trouver un équilibre entre les exigences professionnelles et les moments de détente, permettant aux individus de profiter de destinations attractives tout en maintenant leur productivité. <br></br> Le workation va au-delà d’une simple tendance, il représente une nouvelle vision de l’entreprise et du travail, qui reconnaît l’importance d’un environnement de travail épanouissant, tant sur le plan professionnel que personnel. C’est une approche qui valorise l’équilibre entre vie professionnelle et vie personnelle, en offrant aux collaborateurs des opportunités de travail flexibles et enrichissantes.</p>
        </div>
        <div class="col-md-5 col-lg-5 p-3 col-sm-12">
          <img class="img-fluid" src="../images/workation-three.png" alt="hello">
        </div>
      </div>
    </div>

    <div class="container horizontal-scrollable mt-2 pt-2" v-if="isPhone">
      <h3 style="font: normal normal normal 26px/33px Merriweather;">Les avantages du workation</h3>
      <div class="container-fluid">
        <div class="scrolling-wrapper row flex-row flex-nowrap mt-4 pb-1 pt-2">
          <div class="col-md-5  col-lg-5 col-12 mr-5">
            <p style="text-align: center; font: normal normal bold 16px/15px Raleway;">POUR LES ENTREPRISES</p>
            <ul class="experiences pt-5 border" style="list-style-type: none" >
              <li>
                <div>
                  <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> AUGMENTER LA MOTIVATION DE VOS COLLABORATEURS</p>
                </div>
              </li>
              <li>
                <div>
                  <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> DÉVELOPPER L’ENGAGEMENT DES SALARIÉS</p>
                </div>
              </li>
              <li>
                <div>
                  <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;">AMÉLIORER LA MARQUE EMPLOYEUR </p>
                </div>
              </li>
              <li>
                <div>
                  <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> LUTTER CONTRE L’ABSENTÉISME</p>
                </div>
              </li>
              <li>
                <div>
                  <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> RENFORCER LA STRATÉGIE RSE</p>
                </div>
              </li>
            </ul>
          </div>
          <div class="col-md-5  col-lg-5 col-12 ml-5" >
            <p style="text-align: center; font: normal normal bold 16px/15px Raleway;">POUR LES SALARIÉS</p>
            <ul class="experiences pt-5 border pr-3" style="list-style-type: none">
              <li>
                <div>
                  <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> FAIRE LE PLEIN D’ÉNERGIE ET D’INSPIRATION</p>
                </div>
              </li>
              <li>
                <div>
                  <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;">STIMULER LA PRODUCTIVITÉ ET LA CRÉATIVITÉ </p>
                </div>
              </li>
              <li>
                <div>
                  <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> PERMETTRE UNE MEILLEURE FLEXIBILITÉ</p>
                </div>
              </li>
              <li>
                <div>
                  <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> AMÉLIORER LA CONFIANCE SALARIÉ-EMPLOYEUR</p>
                </div>
              </li>
              <li>
                <div>
                  <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> RÉDUIRE LE STRESS AU TRAVAIL ET ENTRETENIR LE BIEN-ÊTRE MENTAL</p>
                </div>
              </li>
              <li>
                <div>
                  <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> MENER DES PROJETS DE GROUPE À BIEN TOUT EN CHANGEANT D’AIR</p>
                </div>
              </li>
              <li>
                <div>
                  <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;">PROFITER D’UN SENTIMENT DE VACANCES PENDANT LES TEMPS LIBRES </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!isPhone" class="container horizontal-scrollable mt-2 pt-2">
      <h3 style="font: normal normal normal 26px/33px Merriweather;">Les avantages du workation</h3>
      <div class="row p-5">
        <div class="col-md-5  col-lg-5 col-12 mr-5">
          <p style="text-align: center; font: normal normal bold 16px/15px Raleway;">POUR LES ENTREPRISES</p>
          <ul class="experiences pt-5 border" style="list-style-type: none" >
            <li>
              <div>
                <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> AUGMENTER LA MOTIVATION DE VOS COLLABORATEURS</p>
              </div>
            </li>
            <li>
              <div>
                <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> DÉVELOPPER L’ENGAGEMENT DES SALARIÉS</p>
              </div>
            </li>
            <li>
              <div>
                <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;">AMÉLIORER LA MARQUE EMPLOYEUR </p>
              </div>
            </li>
            <li>
              <div>
                <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> LUTTER CONTRE L’ABSENTÉISME</p>
              </div>
            </li>
            <li>
              <div>
                <p  style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> RENFORCER LA STRATÉGIE RSE</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-md-5  col-lg-5 col-12 ml-5" >
          <p style="text-align: center; font: normal normal bold 16px/15px Raleway;">POUR LES SALARIÉS</p>
          <ul class="experiences pt-5 border pr-3" style="list-style-type: none">
            <li>
              <div>
                <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> FAIRE LE PLEIN D’ÉNERGIE ET D’INSPIRATION</p>
              </div>
            </li>
            <li>
              <div>
                <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;">STIMULER LA PRODUCTIVITÉ ET LA CRÉATIVITÉ </p>
              </div>
            </li>
            <li>
              <div>
                <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> PERMETTRE UNE MEILLEURE FLEXIBILITÉ</p>
              </div>
            </li>
            <li>
              <div>
                <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> AMÉLIORER LA CONFIANCE SALARIÉ-EMPLOYEUR</p>
              </div>
            </li>
            <li>
              <div>
                <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> RÉDUIRE LE STRESS AU TRAVAIL ET ENTRETENIR LE BIEN-ÊTRE MENTAL</p>
              </div>
            </li>
            <li>
              <div>
                <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;"> MENER DES PROJETS DE GROUPE À BIEN TOUT EN CHANGEANT D’AIR</p>
              </div>
            </li>
            <li>
              <div>
                <p style="line-height: 2.0 !important; font: normal normal normal 16px/15px Raleway;">PROFITER D’UN SENTIMENT DE VACANCES PENDANT LES TEMPS LIBRES </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="!isPhone" id="headDesti" style="margin-top: 0px !important;"
    >
      <div id="banner-lodging" :style="cssPropstwo">
        <div class="banner-show-container">
          <p style="color: transparent">Nous proposons à vos collaborateurs un séjour alliant bien-être et productivité. Faites confiance à notre expertise pour organiser ces moments privilégiés. Nous mettrons tout en œuvre pour offrir à vos équipes des instants de détente propices à leu</p>
        </div>
      </div>
    </div>
    <div v-if="isPhone" id="headDesti" style="margin-top: 0px !important;">
      <div id="banner-lodging" :style="cssPropsfour">
        <div class="banner-show-container">
          <p style="color: transparent">Nous proposons à vos collaborateurs un séjour alliant bien-être et productivité. Faites confiance à notre expertise pour organiser ces moments privilégiés. Nous mettrons tout en œuvre pour offrir à vos équipes des instants de détente propices à leu</p>
        </div>
      </div>
    </div>

    <div id="tipss" class="container">
      <div v-if="!isPhone" class="titles">
        <p style="font: normal normal 600 25px/30px Raleway; text-align: center;">Qu’est ce qu’une expérience Workation avec Hortense ?</p>
      </div>
      <div v-if="isPhone" class="titles">
        <p style="font: normal normal 600 16px/19px Raleway; text-align: center;">Qu’est ce qu’une expérience Workation avec Hortense ?</p>
      </div>
      <div class="list-tips justify-content-center">
        <div class="justify-content-center">
          <div class="col-md-8 col-lg-8 col-sm-12  tip-item justify-content-center">
            <div class="number-tip">
              <h1 class="raleway-reg-h1"></h1>
            </div>
            <div class="tip-content">
              <p class="mt-2 mb-2" style="font: normal normal bold 15px/18px Raleway;">DES LIEUX D’EXCEPTION</p>
              <p style="font: normal normal normal 15px/18px Raleway;">Avec Hortense, vous pouvez profiter de séjours workation dans des lieux exceptionnels. Que ce soit au bord de la mer, à la campagne, à la montagne ou en plein cœur de la ville, Hortense sélectionne des hébergements éco-responsables qui répondent aux besoins de vos salariés pour rester productifs pendant leur séjour workation.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="list-tips justify-content-center">
        <div class="justify-content-center">
          <div class="col-md-8 col-lg-8 col-sm-12  tip-item justify-content-center">
            <div class="number-tip">
              <h1 class="raleway-reg-h1"></h1>
            </div>
            <div class="tip-content">
              <p class="mt-2 mb-2" style="font: normal normal bold 15px/18px Raleway;">DES HÉBERGEMENTS ÉCO-RESPONSABLES</p>
              <p style="font: normal normal normal 15px/18px Raleway;">Hortense accorde une grande importance à l’éco-responsabilité. Nous nous efforçons de sélectionner des hébergements respectueux de l’environnement, afin que vos séjours Workation contribuent également à la préservation de la nature.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="list-tips justify-content-center">
        <div class="justify-content-center">
          <div class="col-md-8 col-lg-8 col-sm-12  tip-item justify-content-center">
            <div class="number-tip">
              <h1 class="raleway-reg-h1"></h1>
            </div>
            <div class="tip-content">
              <p class="mt-2 mb-2" style="font: normal normal bold 15px/18px Raleway;">UN ESPACE DE TRAVAIL ADAPTÉ</p>
              <p style="font: normal normal normal 15px/18px Raleway;">Nous veillons à ce que les hébergements choisis offrent un environnement propice au travail, avec des installations adaptées telles que des espaces de travail équipés, une connexion internet fiable et tous les équipements nécessaires. Ainsi, vos collaborateurs pourront concilier travail et détente dans un cadre inspirant et confortable.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="list-tips justify-content-center">
        <div class="justify-content-center">
          <div class="col-md-8 col-lg-8 col-sm-12  tip-item justify-content-center">
            <div class="number-tip">
              <h1 class="raleway-reg-h1"></h1>
            </div>
            <div class="tip-content">
              <p class="mt-2 mb-2" style="font: normal normal bold 15px/18px Raleway;">DES ACTIVITÉS INOUBLIABLES</p>
              <p style="font: normal normal normal 15px/18px Raleway;">Nous mettons en avant l’équilibre entre travail et détente en proposant une variété d’activités pour permettre à vos collaborateurs de se détendre et se ressourcer en dehors de leurs horaires de travail, afin qu’ils puissent profiter pleinement de leur séjour workation.<br></br> Nous organisons des activités spécialement conçues pour favoriser la détente et le bien-être, telles que des séances de yoga, des massages, des randonnées ou des activités sportives. Ces moments de détente permettront à vos salariés de se ressourcer et de retrouver une énergie positive.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5">
        <p style="font: normal normal normal 15px/18px Raleway; text-align: center;">Notre objectif est de créer une expérience workation complète, où le travail, la détente et les moments de partage se combinent harmonieusement pour offrir à vos collaborateurs un séjour unique et enrichissant.</p>
      </div>
      <div class="mt-3">
        <p  style="font: normal normal 600 16px/19px Raleway; text-align: center;">Vous souhaitez faire vivre à vos collaborateurs une expérience de travail unique ?</p>
      </div>
      <div class="mt-5">
        <p @click="goToContact()" style="font: normal normal bold 13px/15px Raleway; text-align: center;">CONTACTEZ-NOUS ICI VIA NOTRE FORMULAIRE DE CONTACT OU À L’ADRESSE SUIVANTE :</p>
        <p style="text-align: center;text-decoration: underline;font: normal normal normal 16px/19px Raleway;"> servicecorporate@hortense.green</p>
      </div>
      <div v-if="!isPhone" class="container mt-lg-2 mt-md-2 pt-lg-2 pt-md-2">
        <div class="row pt-5 pb-5 pr-2">
          <div class="col-md-7 col-lg-7 col-sm-12 align-self-center">
            <p class="pb-2" style="font: normal normal normal 26px/33px Merriweather;">Après votre séjour Workation vous souhaitez planifier un séminaire d’entreprise dans un lieu respectueux de l’environnement ?</p>
            <p class="mab raleway-reg-p15 pr-5" style="line-height: 20px !important;">Hortense vous accompagne dans l’organisation de vos séminaires éco-responsables avec Hortense for business.</p>
            <div class="mt-5 ml-5 pl-5">
              <button type="submit" @click="goToBusiness()" class="hortense-btn-primary">DÉCOUVREZ NOTRE OFFRE</button>
            </div>
          </div>
          <div class="col-md-5 col-lg-5 p-3 col-sm-12">
            <img class="img-fluid" src="../images/workation-five.png" alt="hello">
          </div>

        </div>
      </div>
      <div v-if="isPhone" class="container mt-lg-2 mt-md-2 pt-lg-2 pt-md-2">
        <div class="row pt-5 pb-5 pr-2">
          <div class="col-md-5 col-lg-5 p-3 col-sm-12">
            <img class="img-fluid" src="../images/workation-five.png" alt="hello">
          </div>
          <div class="col-md-7 col-lg-7 col-sm-12 align-self-center">
            <p class="pb-2" style="font: normal normal normal 16px/20px Merriweather;">Après votre séjour Workation vous souhaitez planifier un séminaire d’entreprise dans un lieu respectueux de l’environnement ?</p>
            <p class="mab raleway-reg-p15 pr-5" style="line-height: 20px !important;">Hortense vous accompagne dans l’organisation de vos séminaires éco-responsables avec Hortense for business.</p>
            <div class="mt-5">
              <button type="submit" @click="goToBusiness()" class="hortense-btn-primary">DÉCOUVREZ NOTRE OFFRE</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ScrollUp from "../components/btns/ScrollUp.vue";
import MiniList from "./yogas/MiniList.vue";

export default {
  name: "Workation",
  components: {MiniList, ScrollUp},
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require('../images/workation-two.png')})`
      },
      cssPropstwo: {
        backgroundImage: `url(${require('../images/workation-one.png')})`
      },
      cssPropsthree: {
        backgroundImage: `url(${require('../images/icotype.svg')})`
      },
      cssPropsfour: {
        backgroundImage: `url(${require('../images/workation-six.png')})`
      }
    }
  },
  methods:{
    goToContact(){
      window.location.href = "/pages/contact";
    },
    goToBusiness(){
      window.location.href = "/hortense-for-business";
    },
  },
computed: {
  isPhone() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return true;
    }else{
      return false;
    }
  }
}
};
</script>
<style scoped lang="scss" scope="app/assets/stylesheets/components/lodging-show">
.scrolling-wrapper{
  overflow-x: auto;
}
#tipss {
  z-index: 1;
  width: 90%;
  margin: auto;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .list-tips {
    margin-top: 1rem;
    margin-left: 25rem;
    .row-tips{
      margin-bottom: 5rem;
    }

    .tip-item{
      display: flex;

      .number-tip{
        padding-right: 2rem;
        background-image: url('../images/Ohortense.svg');
        background-repeat: no-repeat;
        background-position: 13% 2%;
        background-size: 30px;
        .num3{
          padding-left: 1.4rem;
          padding-top: 0.6rem;
        }
        .num4{
          padding-left: 1.3rem;
          padding-top: 0.6rem;
        }
        .num5{
          padding-left: 1.3rem;
          padding-top: 0.7rem;
        }
        .num6{
          padding-left: 1.3rem;
          padding-top: 0.9rem;
        }
        .num7{
          padding-left: 1.3rem;
          padding-top: 0.8rem;
        }
        h1{
          padding-left: 1.4rem;
          padding-top: 0.8rem;
        }
      }
    }
  }
}

ul.experiences li {
  position:relative; /* so that pseudoelements are positioned relatively to their "li"s*/
  /* use padding-bottom instead of margin-bottom.*/
  margin-bottom: 0; /* This overrides previously specified margin-bottom */
  padding-bottom: 0.5em;
}

ul.experiences li:after {
  /* bullets */
  content: url('../images/bullet.png');
  position: absolute;
  left: -23px; /*adjust manually*/
  top: 0px;
}

ul.experiences li:before {
  /* lines */
  content:"";
  position: absolute;
  left: -16px; /* adjust manually */
  border-left: 1px solid #3b6152;
  height: 100%;
  width: 1px;
}

ul.experiences li:first-child:before {
  /* first li's line */
  top: 6px; /* moves the line down so that it disappears under the bullet. Adjust manually */
}

ul.experiences li:last-child:before {
  /* last li's line */
  height: 6px; /* shorten the line so it goes only up to the bullet. Is equal to first-child:before's top */
}
.mab{
  margin-bottom: 0 !important;
  font: normal normal normal 15px/35px Raleway;
  line-height: 30px;
}
.mab2{
  font: normal normal 600 24px/42px Raleway;
  letter-spacing: 0px;
  line-height: 10px;
}
.mab3 {
  text-align: left;
  font: normal normal normal 15px/18px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
#headDesti{
  margin-top: 13rem !important;
  .des {
    p {
      text-align: center;
      font: normal normal normal 15px/18px Raleway;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
  }
  .desti-header {
    padding: 0rem 3rem;
  }
  .row-desti-map {
    justify-content: space-around;
  }
  .bar {
    align-items: center;
  }
  .col-mapcontent {
    margin-top: 8rem;
    .btn-desti {
      width: 226px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .info-loca {
    display: flex;
    align-items: flex-start;

    img {
      width: 12px;
      margin-right: 10px;
    }
  }

  .list-desti-btns {
    margin-bottom: 5rem;
    ul {
      display: flex;
      justify-content: center;
    }

    li {
      list-style: none;
      padding: 15px 30px;
      margin-right: 1rem;
    }
  }
}



.padding_bottom {
  padding-bottom: 10vw;
}

.link-hortense {
  cursor: pointer;
}
.capacity {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-left: auto;
}
.center{
  background-position: center;
  background-size: cover;
}

@media only screen and (max-device-width: 480px) {
  .lodging-header-desktop {
    display: none !important;
  }
}
@media only screen and (min-device-width: 481px) {
  .container-lodging {
    //margin-top: 15vh !important;
  }
  .lodging-header-container {
    display: none;
  }
}
.lodging-made-trip {
  text-align: center;
  border-color: #cddfd3;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: 20px 20px;
  border-bottom-right-radius: 20px 20px;
  border-bottom-left-radius: 20px 20px;
  height: fit-content;
  width: 200px;
  margin-top: 250px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: absolute;
  //left: 71%;
  max-width: 400px;
  width: 100%;

  @media (max-width: 480px) {
    left: 4%;
    margin-top: 1em;
    position: relative;
    max-width: 370px;
    width: 92%;
  }
  .image-container {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .image-container img {
    flex: 1;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
  }

  .head {
    display: flex;

    img {
      width: 32px;
      height: 32px;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .modal-arrow {
      .arrow {
        width: 14px;
        height: 14px;
      }
    }
  }
}
.lodging-show-raleway-reg-p26-b{
  font-family: $Merriweather;
  font-weight: 400;
  font-size: 26px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #tipss {

    .list-tips {
      margin-top: 1rem;
      margin-left: 0px !important;
      margin-right: 0px !important;
      padding: 0px !important;
    }
  }
  #headDestiii {
    .desti-header {
      padding: 0rem 2rem;
    }
    .row-desti-map {
      justify-content: space-around;
    }
    .bar {
      align-items: inherit;
    }
    .col-mapcontent {
      margin-top: 8rem;
      .btn-desti {
        width: 226px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .info-loca {
      display: flex;
      align-items: flex-start;

      img {
        width: 12px;
        margin-right: 10px;
      }
    }

    .list-desti-btns {
      margin-bottom: 5rem;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
      }

      li {
        list-style: none;
        padding: 1px 7px;
        margin-right: 0;
        margin-bottom: 11px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
}
</style>
