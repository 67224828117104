<template id="w-phone">
  <div class="card-exclusive">
    <div class="exclusive-phone-container">
        <div class="exclusive-phone-card">
            <img v-if="exclusive.photo_key" :src="exclusive.photo_key" :alt="exclusive.key_word">
            <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" :alt="exclusive.key_word">
        </div>
        <div class="exclusive-phone-card">
            <h3 class="category-exclusive merriweather-italic mt-4">{{ exclusive.key_word }}</h3>
            <p class="subtitle-card raleway-bold-p">{{ exclusive.title }}</p>
            <p class="description-card raleway-reg-p13">{{ exclusive.subtitle }}</p>
            <a :href="'/offres-exclusives/' + exclusive.slug" class="link-hortense">Découvrir l'offre</a>
        </div>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'CardExPhone',
     props: ['exclusive'],
    data() {
      return{
      }
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/w-phone'>

</style>
<style lang="scss" scoped>

 .exclusive-phone-container {
        gap: 1.5rem;
        margin-top: 2rem;
        width: 100%;
        //height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .exclusive-phone-card{
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 35vw;
            //height: 70vw;
            //height: 100%;
            //img{
            //  height: 182px;
            //}
        }
    }
</style>