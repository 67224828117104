<template>
  <div id="everGreen">
    <div class="evergreen-program">
      <div class="col-lg-5 offset-lg-3 col-xs-9 offset-xs-1">
        <h3 class="merriweather-reg-40 mb-4">Le programme Evergreen, en bref</h3>
        <p class="raleway-reg-p15">Hortense identifie et finance des projets écologiques destinés à réduire l’empreinte environnementale de ses hébergements partenaires. <br><br>Hortense accompagne ses partenaires dans leur démarche environnementale via une aide opérationnelle et financière.</p>
      </div>
    </div>
    <div class="container-index phone">
      <div class="evergreen-finance">
        <div class="col-lg-6 col-xs-11 offset-lg-4 offset-xs-1 mb-5">
          <h3 class="raleway-reg-p24">Qui finance le programme Evergreen ?</h3>
        </div>
        <div class="row row-finance-green">
          <div class="col-lg-6 offset-lg-4 col-xs-6 offset-xs-2 finance-1">
            <div class="num-item">
              <p class="raleway-reg-p24">1</p>
            </div>
            <div class="num-content">
              <h3 class="raleway-bold-p">Hortense Une plateforme de réservation engagée</h3>
            </div>
          </div>
          <div class="col-lg-6 col-xs-10 offset-lg-5 offset-xs-2 pourcent">
            <h2 class="merriweather-italic67">1%</h2>
            <p class="raleway-reg-p15">du tarif de la réservation d’une nuitée sur la plateforme est reversé pour financer le programme Evergreen.</p>
          </div>
          <div class="col-lg-6 col-xs-9 offset-lg-5 offset-xs-2">
            <p class="raleway-reg-p15">Côté voyageurs, le tarif par nuit ne change pas !</p>
             <p class="raleway-reg-p15 mb-5">Le programme Evergreen est financé par Hortense via les commissions qu’elle reçoit des hébergements partenaires.</p>
          </div>
          <div class="col-lg-6 offset-lg-4 col-xs-6 offset-xs-2 finance-1 mb-5">
            <div class="num-item">
              <p class="raleway-reg-p24">2</p>
            </div>
            <div class="num-content">
              <h3 class="raleway-bold-p">Les voyageurs Soucieux de soutenir le tourisme durable (:</h3>
            </div>
          </div>
          <div class="col-lg-6 offset-lg-4 col-xs-6 offset-xs-2 mb-5 mt-4">
            <h3 class="raleway-reg-p24">Le programme Evergreen répond à différentes formes du tourisme durable</h3>
          </div>
          <div class="col-lg-6 col-xs-7 offset-xs-3 offset-lg-4 mb-4">
            <p class="raleway-bold-p">Tourisme équitable</p>
            <p class="raleway-reg-p15">Une rémunération plus juste des hébergements : 1% du tarif de la nuitée est reversé aux hébergements porteurs de projets écologiques.</p>
          </div>
          <div class="col-lg-6 col-xs-7 offset-xs-3 offset-lg-4 mb-4">
            <p class="raleway-bold-p">Tourisme solidaire </p>
            <p class="raleway-reg-p15">Un lien de solidarité fort : Les voyageurs et Hortense participent main dans la main au financement de projets écologiques.</p>
          </div>
          <div class="col-lg-6 col-xs-7 offset-xs-3 offset-lg-4 mb-4">
            <p class="raleway-bold-p">Tourisme participatif</p>
            <p class="raleway-reg-p15">Une participation active des voyageurs : grâce à leur contribution financière, ils concourent à la réduction de l’impact environnemental des hébergements et au développement de la vie locale.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="evergreen-banner">

    </div>
    <div class="container-index phone">
      <div class="row row-projects">
        <div class="col-lg-6 offset-lg-4 col-xs-6 offset-xs-2">
          <p class="raleway-reg-p24">La sélection des projets</p>

          <p class="raleway-reg-p15">Les critères de sélection se fondent sur le principe du développement durable.</p>
        </div>
        <div class="col-lg-6 offset-lg-4 col-xs-6 offset-xs-2 mb-4">
          <p class="raleway-bold-p">Une dimension environnementale</p>
          <p class="raleway-reg-p15">  Des projets écologiques destinés à réduire l’empreinte environnementale des hébergements.</p>
        </div>
        <div class="col-lg-6 offset-lg-4 col-xs-6 offset-xs-2 mb-4">
          <p class="raleway-bold-p">Une dimension sociale </p>
          <p class="raleway-reg-p15">Des projets écologiques qui ont recours à des artisans et autres corps de métier locaux pour soutenir l’économie locale.</p>
        </div>
        <div class="col-lg-6 offset-lg-4 col-xs-6 offset-xs-2 mb-5">
          <p class="raleway-bold-p"> Une dimension économique</p>
          <p class="raleway-reg-p15"> Des projets écologiques qui garantissent une activité économique viable sur le long terme et une rémunération équitable des acteurs locaux.</p>
          <p class="raleway-reg-p15">En plus de nos conseils avisés, les projets sélectionnés bénéficieront d’un soutien financier et de la mise en relation avec de potentiels partenaires identifiés par Hortense.</p>
        </div>
        <div class="col-lg-6 offset-lg-4 col-xs-6 offset-xs-2 mt-5 mb-5" v-if="this.projectMount.length != 0">
         <p class="raleway-reg-p24">Les projets</p>
        </div>
      </div>
      <div class="row row-project" v-if="this.projectMount">
        <template v-for="project in this.projectMount">
          <CardProjects :project="project"></CardProjects>
        </template>
      </div>
     <!--  <h3 class="raleway-reg-p24 text-center mb-5">Quelques hébergements de notre sélection</h3>
      <SelectionHost class="mt-5"></SelectionHost> -->
    </div>
  </div>
</template>


<script>

  import fetcher from 'packs/shared/api.js';
  import SelectionHost from 'components/cards/SelectionHost.vue'
  import CardProjects from 'components/cards/CardProjects.vue';

  export default {
    name: 'everGreen',
    components:{ SelectionHost, CardProjects },
    data() {
      return {
        projectMount: [],
      }
    },
    methods: {
      async fetchEvergreen(){
        try{
          const response = await fetcher.get('/programme-evergreen-demarche-environnementale/')
          this.projectMount = response.data
        }catch(e){
          console.error(e)
        }
      },
    },
    mounted(){
      this.fetchEvergreen();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/ever-green'>

</style>
