<template>
  <div id="w-phone">
    <div class="phone-container">
      <div class="weekend-header">
        <h2 class="merriweather-reg-26">Nos</h2>
        <h2 class="merriweather-reg-40 mb-5">Week-ends</h2>
        <p class="raleway-reg-p15">{{ $t("Weekend.content") }}</p>
      </div>
        <slither-slider :options="{loop: true, autoplay: true, numberOfSlides: 1,dots: false, controls: false}">
          <template v-for="weekend in this.weekendsMounted">
            <!-- <CardweekendPhone :weekend="weekend"></CardweekendPhone> -->
            <div id="cardWeekendphone">
              <div class="card-weekend">
                <img v-if="weekend.photo_key" :src="weekend.photo_key" :alt="weekend.key_word">
                <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" :alt="weekend.key_word">
                <h3 class="category-weekend merriweather-italic mt-4">{{ weekend.key_word }}</h3>
                <p class="subtitle-card raleway-bold-p">{{ weekend.title }}</p>
                <p class="description-card raleway-reg-p13">{{ weekend.subtitle }}</p>
                <a :href="'/weekends/week-end-' + weekend.slug" class="link-hortense">Voir les établissements</a>
              </div>
            </div>
          </template>
        </slither-slider>
      <a class="hortense-btn-primary raleway-bold-p mt-2" href="/weekends">{{ $t("Weekend.viewbtn") }}</a>
    </div>
  </div>
</template>

<script>

  import CardweekendPhone from 'components/cards-phone/CardweekendPhone.vue';
  import fetcher from 'packs/shared/api.js';

  export default{
    name: 'w-phone',
    components: { CardweekendPhone},
    props:['weekends'],
    data() {
      return {
        weekendsMounted: this.weekends,
      }
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/w-phone'>

</style>
