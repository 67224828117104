<template>
  <div id="slider-stars">
    <vue-slider
    class="raleway-reg-p15"
    v-model="value"
    v-bind="options"
    />
  </div>
  </template>

  <script>

  export default {
    name: 'slider-stars',
    data() {
      return {
        value: [0, 3],
        options: {
          min: 0,
          max: 5,
          dotSize: 0,
          processStyle: {
            background: "black",
          },
          tooltip: 'always',
          tooltipPlacement: 'top',
          tooltipFormatter: value => `${value}★`,
          tooltipStyle:{
            background: 'transparent',
            border: 'transparent',
            color: 'black',
          },
        },
      }
    },
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/lodging-filter'>


</style>
