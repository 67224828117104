<template>  
      <div class="card-content-ex">
        <h1 class="mt-4 mb-4">{{ exclusive.title }}</h1>
        <h2 class="mb-2">{{ exclusive.subtitle_exclusive }}</h2>
        <p class="mb-2">{{ exclusive.description_exclusive_page }}</p>
        <hr/>
        <h3>Comment ça marche ?</h3>
        <p class="p-reg-p13 mb-2">{{ exclusive.how_it_works }}</p>
      </div>
</template>

<script>

  export default {
    name: 'cardLeftExclusive',
    props: ['exclusive'],
    data() {
    },
  }
</script>

<style lang="scss" scoped>
    .card-content-ex{
      padding-top: 1.5rem;
      padding-bottom: 2rem;
      h1{
        font-family: $Merriweather;
        font-weight: 700;
        font-size: 40px;
        color: #000000;
      }
      .p-reg-p13{
        font-family: $Raleway;
        font-weight: 400;
        font-size: 13px;
      }
      h2{
        font-family: $Merriweather;
        font-weight: 400;
        font-size: 26px;
        font-style: italic;
        text-transform: none;
      }
      h3{
        font-family: $Raleway;
        font-size: 24px;
        color: #000000;
        font-weight: 700;
        font-style: normal;
        text-transform: uppercase;
      }
      p{
        font-family: $Raleway;
        font-weight: 400;
        font-size: 15px;
        color: #000000;
      }
      a{
        text-decoration: underline;
        font-family: $Raleway;
        font-weight: 500;
        font-size: 15px;
        color: #000000;
      }
    }
</style>
