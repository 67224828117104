<template>
  <div class="container">
    <div class="row border pt-5 pb-5 pr-mg-5 pr-lg-5 pl-sm-5">
      <div class="col-md-6 col-lg-6 p-2 col-sm-12 m-lg-0 m-md-0 mx-4" @click="goToYoga()">
        <img class="img-fluid " :src="yoga.photo_banner" alt="hello">
      </div>
      <div class="col-md-6 col-lg-6 col-sm-12" style="text-align: center;">
        <div class="p-5 mt-5">
          <div @click="goToYoga()">
          <h6 class="pb-2"  v-if='yoga.yoga_place' style="font: normal normal normal 26px/33px Merriweather;">{{yoga.yoga_place.title}}</h6>
<!--          <p class="mab"  style="font:  normal normal normal 24px/42px Raleway;">{{yoga.accommodation}}</p>-->
          <!--          <p class="mab" style="font:  normal normal normal 24px/42px Raleway;" v-if='yoga.yoga_place'>{{yoga.yoga_place.title}}</p>-->
          <p class="mab" style="font:  normal normal normal 15px/18px Raleway;">{{yoga.location_detail}}</p>
          <div class="pb-3">
            <img src="../../images/yoga_icon.png" alt="">
          </div>
          <!--          <p class="mab2 " style="font: normal normal 600 24px/42px Raleway;">{{ yoga.number_of_days }} jours / {{ yoga.number_of_nights }} nuit</p>-->
          <p class="mab2 " style="font: normal normal 600 18px/32px Raleway;">A partir de {{ yoga.lowest_rate }}€</p>
          <p class="mab3 pt-3 pl-3 raleway-reg-p15">{{yoga.description}}</p>
          <p class="mab2 pt-5" style="text-decoration: underline; font: normal normal normal 13px/18px Raleway;">En savoir plus</p>
            </div>
          <div v-if="!hasSingleSchedule()" id="headDestiiii">
            <div class="list-desti-btns mt-5">
              <ul style="display: flex; justify-content: space-around; flex-wrap: wrap; gap: 20px;" class="bar">
                <li v-if="!isPhone" v-for="(schedule, i) in yoga_schedules" :key="schedule.id" class="menu-item raleway-reg-p16" style="list-style: none; padding: 5px; width: calc(50% - 10px);">
                  <button style="display: inline-block; white-space: nowrap;" class="dateBUtton raleway-bold-p" @click="setDestinations(schedule.id)">{{schedule.yoga_date}}</button>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="hasSingleSchedule()">
            <div class="list-desti-btns mt-5">
              <ul  style="display: contents; justify-content: center;" class="bar">
                <li v-if="!isPhone" v-for="(schedule, i) in yoga_schedules" :key="schedule.id" class="menu-item raleway-reg-p16" style="list-style: none; padding: 5px;">
                  <button style="display: inline-block; white-space: nowrap;" class="dateBUtton raleway-bold-p" @click="setDestinations(schedule.id)">{{schedule.yoga_date}}</button>
                </li>
              </ul>
            </div>
          </div>
          <div style="margin-top: 0 !important; color: #0a0a0a !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
            <p style="color: #0a0a0a !important;" v-for="(schedule, i) in this.yoga_schedules" :key="schedule.id" class="raleway-reg-p16 text-center">
              <button class="dateBUtton raleway-bold-p" style="color: #0a0a0a !important;" @click="setDestinations(schedule.id)">{{schedule.yoga_date}}</button>
            </p>
          </div>
        </div>
      </div>
    </div>
    <br></br>
  </div>
</template>

<script>
import myImage from '../../images/yoga_thumb.png';
export default {
  props: ['yoga'],
  data(){
    return{
      myImage:myImage,
      yoga_schedules: this.yoga.online_yoga_schedules
    }
  },
  name: 'YogaCardAlter',
  methods: {
    goToYoga(){
      window.open("/experiences-bien-etre/"+this.yoga.yoga_place.slug, "_blank");
      // window.location.href = "/yogacation/"+this.yoga.slug
    },
    setDestinations(id){
      window.open("/experiences-bien-etre/"+this.yoga.yoga_place.slug+"?date="+id, "_blank");
    },
    hasSingleSchedule() {
      return this.yoga.online_yoga_schedules.length == 1;
    }
  },
computed: {
  isPhone() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
      return true;
    }else{
      return false;
    }
  }
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.single-date {
  text-align: center;
  display: block !important;
  width: 100%;
}
.mab{
  margin-bottom: 0 !important;
  font: normal normal normal 15px/35px Raleway;
  line-height: 30px;
}
.mab2{
  font: normal normal 600 24px/42px Raleway;
  letter-spacing: 0px;
  line-height: 10px;
}
.mab3 {
  text-align: left;
  font: normal normal normal 15px/18px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

</style>