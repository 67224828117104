<template> 
       <div id="cardRightColl">
          <img v-if="exclusive.photo_key" :src="exclusive.photo_key" style="height:20vw;width:38vw" :alt="exclusive.name">
          <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" style="height:20vw;width:38vw" :alt="exclusive.name">
          <div id="description" data-aos="fade-down" data-aos-duration="1000">
            <h5>Découvrez notre offre exclusive</h5>
            <ul>
                <li v-if="exclusive.exclusive_offer_1">{{ exclusive.exclusive_offer_1 }}</li>
                <li v-if="exclusive.exclusive_offer_2">{{ exclusive.exclusive_offer_2 }}</li>
                <li v-if="exclusive.exclusive_offer_3">{{ exclusive.exclusive_offer_3 }}</li>
                <li v-if="exclusive.exclusive_offer_4">{{ exclusive.exclusive_offer_4 }}</li>
                <li v-if="exclusive.exclusive_offer_5">{{ exclusive.exclusive_offer_5 }}</li>
            </ul>
            <a :href="'/offres-exclusives/' + exclusive.slug" class="hortense-btn-primary btn-fid mb-4" @click="scrollToElement('form-user-info')">RÉSERVEZ</a>
          </div>
      </div>
</template>

<script>

  export default {
    name: 'cardRightExclusive',
    props: ['exclusive'],
    data() {
    },
  }
</script>

<style lang="scss" scoped>
  #description{
      display: block;
      position: inherit;
      width: 70%;
      margin-top: -15%;
      margin-left: 9%;
      padding-top: 7%;
      padding: 5%;
      background-color: #fff;
      .hortense-btn-primary {
        //width: 340px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      ul{
          list-style: none;
          list-style-image: url('../../images/Icotype_puce.svg');
          font-family: $Raleway;
          font-weight: 400;
          font-size: 13px;
      }
      h5{
        font-family: $Merriweather;
        font-weight: 400;
        font-style: italic;
        font-size: 20px;
      }
  }
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
     #description{
        display: block;
        position: inherit;
        width: 90%;
        margin-top: -15%;
        margin-left: 9%;
        padding-top: 7%;
        padding: 5%;
        background-color: #fff;
          .hortense-btn-primary {
            width: 80%;
            height: 55px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
      }
    }
</style>
