<template>
  <div id="modal-login-partner">
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-content">
              <div class="closing">
                <button class="close-btn-modal" @click="$emit('closeAdmin')"></button>
              </div>
              <div class="modal-content-form">
                <div class="header-modal">
                  <h2 class="merriweather-reg-26">Vous êtes un admin ?</h2>
                  <p class="raleway-reg-p15">Connectez-vous pour accéder à votre espace administrateur: </p>
                </div>
                <div class="form-signup raleway-reg-p15">
                  <p style="color: red;" v-if='errorMessage'>invalid email or password</p>
                  <div class="form-item">
                    <label for="name">Email</label>
                    <input v-model="userInfo.email" type="text">
                  </div>
                  <div class="form-item">
                    <label for="name">Mot de passe</label>
                    <input v-model="userInfo.password" type="password">
                  </div>
                  <button @click="UserBasicInfo" class="hortense-btn-primary">Me connecter</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';

  export default{
    name: 'ModalLoginAdmin',
    data() {
      return {
        userInfo: {
          email: '',
          password: '',
          role: 'admin'
        },
        errorMessage: false,
      }
    },
    methods: {
      async UserBasicInfo(){
        try {
          const response = await fetcher.post('/users/sign_in', { user: this.userInfo })
          this.$emit('close', response.data);
          this.errorMessage = false
          location.reload();
        } catch (e) {
          this.errorMessage = true
        }
      },
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/modal-signup'>

</style>
