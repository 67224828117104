<template>
	<div id="multiselectmeals" class="mt2">
		<div class="dropdown dropdown-filter" @click="showDropdown">
			<div class="overselect"></div>
			<div class="c-form-input">
				<h2 class="raleway-semi-p13">Repas</h2>
				<img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="show" />
				<img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
			</div>
		</div>
		<div class="multiselect" v-if="show">
		    <input type="text" v-model="selected">
			<!--<ul>
				<div class="row row-selectoptions mt-3">
					<div class="col-8">
						<li>
							<input type="checkbox" id="Petit déjeuner inclus" v-model="breakfast_include" />
							<label class="raleway-reg-p13 ml-2" for="Petit déjeuner inclus">Petit déjeuner inclus</label>
						</li>
						<li>
							<input type="checkbox" id="Demi-pension" v-model="half_pension" />
							<label class="raleway-reg-p13 ml-2" for="Demi-pension">Demi-pension</label>
						</li>
						<li>
							<input type="checkbox" id="Pension complète" v-model="full_pension" />
							<label class="raleway-reg-p13 ml-2" for="Pension complète">Pension complète</label>
						</li>
						<li>
							<input type="checkbox" id="Tout inclus" v-model="all_inclusive" />
							<label class="raleway-reg-p13 ml-2" for="Tout inclus">Tout inclus</label>
						</li>
					</div>
				</div>
			</ul>-->
		</div>
	</div>
</template>

<script>
	export default {
		name: 'multiselectmeals',
		props: ['options'],
		data() {
			return {
				show: false,
				breakfast_include: false,
				half_pension: false,
				full_pension: false,
				all_inclusive: false,
			};
		},
		methods: {
			showDropdown() {
				this.show = !this.show;
			},
		},
		watch: {
			breakfast_include(val) {
				this.$emit('checked', val, 'breakfast_include');
			},
			half_pension(val) {
				this.$emit('checked', val, 'half_pension');
			},
			full_pension(val) {
				this.$emit('checked', val, 'full_pension');
			},
			all_inclusive(val) {
				this.$emit('checked', val, 'all_inclusive');
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
