<template>
  <div id="rate-grid">
    <div class="rate-criteria">
      <div class="rate-line">
        <div class="rate-obj">
          <p class="raleway-bold-p">Accueil</p>
        </div>
       <div class="rate-numb">
          <div class="numb" v-if="this.resa.ratings.length > 0">
            <p>{{ this.resa.ratings[0].accueil }}</p>
          </div>
          <div class="numb" v-else>
            <v-select v-model="accueil" :options="options"></v-select>
          </div>
        </div>
      </div>
      <div class="rate-line">
        <div class="rate-obj">
          <p class="raleway-bold-p">confort</p>
        </div>
        <div class="rate-numb">
          <div class="numb" v-if="this.resa.ratings.length > 0">
            <p>{{ this.resa.ratings[0].comfort }}</p>
          </div>
          <!-- real design // pblm with radio btn  -->
         <!--  <div class="numb" v-for="option in options ">
            <input type="radio" :value="option" v-model="comfort" class="btn-check" :id='option' name="comfort" >
            <label class="btn raleway-bold-p" :for="option"><p class="raleway-bold-p">{{option}}</p>
            </label>
          </div> -->
          <div class="numb" v-else>
            <v-select v-model="comfort" :options="options"></v-select>
          </div>
        </div>
      </div>
      <div class="rate-line">
        <div class="rate-obj">
          <p class="raleway-bold-p">Propreté</p>
        </div>
        <div class="rate-numb">
          <div class="numb" v-if="this.resa.ratings.length > 0">
            <p>{{ this.resa.ratings[0].hygiene }}</p>
          </div>
          <!-- <div class="numb" v-for="option in options ">
            <input type="radio" :value="option" v-model="hygiene" class="btn-check" :id='option' check autocomplete="off">
            <label class="btn raleway-bold-p" :for="option"><p class="raleway-bold-p">{{option}}</p>
            </label>
          </div> -->
          <div class="numb" v-else>
            <v-select v-model="hygiene" :options="options"></v-select>
          </div>
        </div>
      </div>
      <div class="rate-line">
        <div class="rate-obj">
          <p class="raleway-bold-p">Repas</p>
        </div>
        <div class="rate-numb">
          <div class="numb" v-if="this.resa.ratings.length > 0">
            <p>{{ this.resa.ratings[0].meal }}</p>
          </div>
          <!-- <div class="numb" v-for="n  in 10 " :key="n">
            <input type="radio" v-model="meal" class="btn-check" id="meal" check autocomplete="off">
            <label class="btn raleway-bold-p" :for="n"><p class="raleway-bold-p">{{n}}</p>
            </label>
          </div> -->
          <div class="numb" v-else>
            <v-select v-model="meal" :options="options"></v-select>
          </div>
        </div>
      </div>
      <div class="rate-line">
        <div class="rate-obj">
          <p class="raleway-bold-p">Situation géographique</p>
        </div>
        <div class="rate-numb">
          <div class="numb" v-if="this.resa.ratings.length > 0">
            <p>{{ this.resa.ratings[0].location }}</p>
          </div>
          <!-- <div class="numb" v-for="n  in 10 " :key="n">
            <input type="radio" v-model="location" class="btn-check" id="location" check autocomplete="off">
            <label class="btn raleway-bold-p" :for="n"><p class="raleway-bold-p">{{n}}</p>
            </label>
          </div> -->
          <div class="numb" v-else>
            <v-select v-model="location" :options="options"></v-select>
          </div>
        </div>
      </div>
      <div class="rate-line">
        <div class="rate-obj">
          <p class="raleway-bold-p">Rapport qualité / prix</p>
        </div>
        <div class="rate-numb">
          <div class="numb" v-if="this.resa.ratings.length > 0">
            <p>{{ this.resa.ratings[0].price_quality_ratio }}</p>
          </div>
          <!-- <div class="numb" v-for="n  in 10 " :key="n">
            <input type="radio" v-model="price_quality_ratio" class="btn-check"  check autocomplete="off">
            <label class="btn raleway-bold-p" :for="n"><p class="raleway-bold-p">{{n}}</p>
            </label>
          </div> -->
          <div class="numb" v-else>
            <v-select v-model="price_quality_ratio" :options="options"></v-select>
          </div>
        </div>
      </div>
      <div class="rate-line">
        <div class="rate-obj">
          <p class="raleway-bold-p">Engagement environnemental</p>
        </div>
        <div class="rate-numb">
          <div class="numb" v-if="this.resa.ratings.length > 0">
            <p>{{ this.resa.ratings[0].environmental_commitment }}</p>
          </div>
          <!-- <div class="numb" v-for="n  in 10 " :key="n">
            <input type="radio" v-model="environmental_commitment" class="btn-check"  check autocomplete="off">
            <label class="btn raleway-bold-p" :for="n"><p class="raleway-bold-p">{{n}}</p>
            </label>
          </div> -->
          <div class="numb" v-else>
            <v-select v-model="environmental_commitment" :options="options"></v-select>
          </div>
        </div>
      </div>
    </div>
    <div class="comment-input">
      <p class="raleway-reg-p13">Commentaire</p>
      <p v-if="this.resa.ratings.length > 0" class="raleway-reg-p15">{{ this.resa.ratings[0].review }}</p>
      <div v-else>
        <textarea name="body" id="body" v-model="body" cols="65" rows="6"></textarea>
        <button @click="postRate" class="hortense-btn-primary">Valider</button>
        <!--<button @click="postComment" class="hortense-btn-primary">Valider</button>-->
      </div>
    </div>
    <Flash :text='this.text' :status='this.status' @destroy='toggleStatus(status)'></Flash>
  </div>
</template>

<script>
  import Flash from 'alert/Flash.vue';
  import fetcher from 'packs/shared/api.js';

  export default{
    name: 'RateGrid',
    props: [ 'resa', 'user', 'reservation' ],
    components: { Flash },
    data() {
      return {
        status: null,
        text: null,
        userRate: null,
        options: [1,2,3,4,5,6,7,8,9,10],
        comfort: "",
        accueil: "",
        hygiene: "",
        meal: "",
        location: "",
        price_quality_ratio: "",
        environmental_commitment: "",
        body: '',
      }
    },
    methods: {
      async postRate() {
        try {
          const response = await fetcher.post('/reservations/'+ this.reservation +'/ratings/', {
                                                            accueil: this.accueil,
                                                            comfort : this.comfort,
                                                            hygiene: this.hygiene,
                                                            meal: this.meal,
                                                            location: this.location,
                                                            price_quality_ratio: this.price_quality_ratio,
                                                            environmental_commitment: this.environmental_commitment,
                                                            review: this.body });
          this.toggleStatus('success');
          this.text = "Note enregistrée";
        } catch(e) {
          console.error(e);
          this.toggleStatus('alert');
          this.text = "Une erreur s'est produite veuillez réessayer";
        }
      },
      async postComment() {
        try {
          const response = await fetcher.post('/reservations/'+ this.reservation +'/comments', {body: this.body});
          this.$emit('close', response.data);
        } catch(e) {
          console.error(e);
          this.toggleStatus('alert');
          this.text = "Une erreur s'est produite veuillez réessayer";
        }
      },
      toggleStatus(value="success") {
        this.status = this.status === value ? null : value
      },
    },
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/my-resa'>

</style>
