<template>
  <div id="reservation-confirmation">
    <div class="line"></div>
    <div class="container-profil">
      <div class="row row-reserved">
        <div class="col-lg-6">
          <img src="../../images/Ohortense.svg" class="circle" alt="">
        </div>
        <div class="col-lg-5">
          <p class="raleway-reg-p24 mb-4">Réservation confirmée</p>
          <p class="raleway-reg-p15 mb-5">Merci de votre confiance ! <br>Vous retrouverez votre réservation et les détails de votre séjour dans votre espace personnel. <br> Nous vous tiendrons informé-e par e-mail des prochaines informations utiles concernant votre séjour.</p>
          <div class="card-what-do-you-think">
            <img class="" src="../../images/avis.svg" alt="avis">
            <div class="content">
              <div class="raleway-semi-p15 mb-2">
                Que pensez-vous d’Hortense ?
              </div>
              <p class="raleway-reg-p15">
                Vous recevrez prochainement un e-mail de <br> notre partenaire certifié « Avis Vérifiés »,<br> grâce auquel vous pourrez laisser un avis sur <br> votre expérience Hortense.
              </p>
            </div>
          </div>
          <p class="raleway-reg-p15 mb-4"><strong>Que faire lors de votre séjour ?</strong> Découvrez toutes nos inspirations et idées d’activités sur notre journal.</p>
          <a href="https://www.blog.hortense.green/" class="hortense-btn-primary">Lire le journal d’Hortense</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fetcher from "../../packs/shared/api";

export default {
  name: "reservation-confirmation",

};
</script>

<style lang="scss" scoped>
  #reservation-confirmation{
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .line{
      padding-top: 30vh;
      width: 100%;
      border: 1px solid black;
    }
    .row-reserved{
      margin-top: 5rem;
      .circle{
        width: 488px;
        height: 444px;
      }
    }
    .card-what-do-you-think{
      display: flex;
      align-items: flex-start;
      border: 1px solid #BFBFBF;
      padding: 1rem ;
      margin-bottom: 3rem;
      img{
        margin-right: 1rem;
      }
    }
  }
</style>
