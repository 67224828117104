<template>
  <div id="t-phone">
    <div class="phone-container">
      <div class="titles">
        <h2 class="title-first merriweather-reg-24">{{ $t("Tips.title") }}</h2>
        <h2 class="title-second merriweather-reg-24">{{ $t("Tips.subtitle") }}</h2>
      </div>
      <slither-slider :options="{ numberOfSlides: 1,dots: false, controls: true, autoplay: true}">
        <div class=" tip-item">
          <div class="number-tip">
            <h1 class="raleway-reg-h1">1</h1>
          </div>
          <div class="tip-content">
            <h3 class="raleway-bold-h3">{{ $t("Tips.advice.first") }}</h3>
            <p class="raleway-reg-p16">{{ $t("Tips.content.first") }}</p>
          </div>
        </div>
          <div class=" tip-item">
            <div class="number-tip">
              <h1 class="raleway-reg-h1">2</h1>
            </div>
            <div class="tip-content">
              <h3 class="raleway-bold-h3">{{ $t("Tips.advice.second") }}</h3>
              <p class="raleway-reg-p16">{{ $t("Tips.content.second") }}</p>
            </div>
          </div>
          <div class="tip-item">
            <div class="number-tip">
              <h1 class="raleway-reg-h1 ">3</h1>
            </div>
            <div class="tip-content">
             <h3 class="raleway-bold-h3">{{ $t("Tips.advice.third") }}</h3>
              <p class="raleway-reg-p16">{{ $t("Tips.content.third") }}</p>
            </div>
          </div>
          <div class="tip-item">
            <div class="number-tip">
              <h1 class="raleway-reg-h1 ">4</h1>
            </div>
            <div class="tip-content">
             <h3 class="raleway-bold-h3">{{ $t("Tips.advice.fourth") }}</h3>
              <p class="raleway-reg-p16">{{ $t("Tips.content.fourth") }}</p>
            </div>
          </div>
          <div class="tip-item">
            <div class="number-tip">
              <h1 class="raleway-reg-h1 ">5</h1>
            </div>
            <div class="tip-content">
             <h3 class="raleway-bold-h3">{{ $t("Tips.advice.fifth") }}</h3>
              <p class="raleway-reg-p16">{{ $t("Tips.content.fifth") }}</p>
            </div>
          </div>
          <div class="tip-item">
            <div class="number-tip">
              <h1 class="raleway-reg-h1 ">6</h1>
            </div>
            <div class="tip-content">
             <h3 class="raleway-bold-h3">{{ $t("Tips.advice.sixth") }}</h3>
              <p class="raleway-reg-p16">{{ $t("Tips.content.sixth") }}</p>
            </div>
          </div>
          <div class="tip-item">
            <div class="number-tip">
              <h1 class="raleway-reg-h1 ">7</h1>
            </div>
            <div class="tip-content">
             <h3 class="raleway-bold-h3">{{ $t("Tips.advice.seventh") }}</h3>
              <p class="raleway-reg-p16">{{ $t("Tips.content.seventh") }}</p>
            </div>
          </div>
          <div class="tip-item">
            <div class="number-tip">
              <h1 class="raleway-reg-h1 ">8</h1>
            </div>
            <div class="tip-content">
             <h3 class="raleway-bold-h3">{{ $t("Tips.advice.eighth") }}</h3>
              <p class="raleway-reg-p16">{{ $t("Tips.content.eighth") }}</p>
            </div>
          </div>
        </slither-slider>
    </div>
  </div>
</template>

<script>

// import { Splide, SplideSlide } from '@splidejs/vue-splide';


  export default {
    name: 't-phone',
    // components:{ Splide, SplideSlide },
    data() {
      return {
        options: {
          arrows: 0,
          rewind : true,
          width  : '100%',
          height: 304,
          padding: {
            right: '7rem',
          },
          perMove:1,
          gap    : '1rem',
          classes:{
            pagination: 'splide__pagination  pagi-phone', // container
            page : 'splide__pagination__page dot-phone', // each button
          }
        },
      };
    },
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/tips-phone'>

</style>

