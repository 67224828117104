<template>
  <div id="headDesti">
    <div class="desti-header text-center ">
      <h1 v-if="!is_urban" class="merriweather-reg-40">Nos Clubs</h1>
      <h1 v-if="is_urban" class="merriweather-reg-40">Urban Retreat</h1>
    </div>
    <div id="banner-lodging" :style="cssProps">
      <div class="banner-show-container">
        <div class="col-md-6 col-sm-2 offset-0 catch-hub-show">
          <p class="merriweather-italic24" v-if="!is_urban">
            Offrez-vous une parenthèse de détente absolue avec nos séjours tout inclus, où la déconnexion est le maître-mot. Nos Clubs sont de véritables havres de paix pour se retrouver en famille, en couple ou entre amis  dans des lieux intimes, authentiques et chaleureux.
          </p>
          <p class="merriweather-italic24" v-if="is_urban">
            Profitez d'une journée dédiée au bien-être grâce à notre expérience Urban Retreat, un espace où harmonie et détente fusionnent parfaitement. Laissez-vous transporter dans un refuge tranquille, une oasis de déconnexion, pour une expérience sans pareil.
          </p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="desti-header text-center">
        <div class="des">
          <p v-if="is_urban" class="raleway-reg-p15">Découvrez le summum du bien-être avec nos expériences Urban Retreat, niché dans des hôtels de charme parisiens.<br></br>
            Ces journées bien-être vous convient à une relaxation intense, loin de l'agitation urbaine, dans un cadre enchanteur. Certaines expériénces Urban Retreat offrent un accès exclusif au spa, promettant un apogée de détente.<br></br>
            Nos séances de yoga et de pilates, ouvertes à tous niveaux, visent à perfectionner votre pratique dans une atmosphère apaisante.<br></br>
            Un repas végétarien, soigneusement élaboré, vous sera servi, éveillant vos sens tout en nourrissant votre être.<br></br>
            L'expérience se poursuit avec un choix d'ateliers bien-être : initiez-vous à l'Ayurveda, découvrez les bienfaits de la naturopathie, donnez de l'éclat à votre visage avec le yoga du visage, fabriquez vos cosmétiques naturels ou évadez-vous lors d'un voyage sonore.<br></br>
            Chaque Urban Retreat est une véritable bulle de quiétude, une occasion unique de vous consacrer une journée pour régénérer corps et esprit, et vivre un interlude revitalisant dans l'écrin de Paris.<br></br>
            Chaque expérience est unique. Réservez dès à présent votre prochaine escapade Urban Retreat.<br></br>
          </p>
          <p v-if="!is_urban" class="raleway-reg-p15">Dans les Clubs Hortense, vivez une expérience hors du commun, conçue pour vous permettre de vous détendre et de renouer avec l'essentiel. Profitez de services complets comprenant un hébergement d'exception respectueux de l'environnement, des repas exquis et surtout sains, ainsi que des activités soigneusement pensées pour vous et votre famille. Laissez le stress et les tracas derrière vous et savourez le bonheur de moments spontanés.<br></br>
            Que vous recherchiez une escapade romantique, un moment de détente en solo ou en famille, ou une exploration entre amis, les Clubs Hortense vous offrent une sélection de lieux uniques pour un voyage tout inclus qui répond à vos désirs.<br></br>
            Profitez de moments conviviaux autour des repas et des activités, l'occasion parfaite pour faire de belles rencontres.<br></br>
            Hortense vous invite à vivre une expérience de voyage éco-responsable où chaque aspect, de l'hébergement aux activités en passant par les repas, est soigneusement élaboré pour minimiser son impact sur l'environnement et pour soutenir les communautés locales.<br></br>
            Déconnectez-vous de la vie quotidienne et plongez dans une expérience inoubliable. Si vous avez besoin de vous détendre et de vous reposer, nous nous occupons avec plaisir de vos enfants. Chaque club Hortense propose un Club enfant dès l'âge de 3 ans.<br></br>
            Réservez dès maintenant un séjour exceptionnel dans un cadre préservé !<br></br>
          </p>
        </div>
        <h1 v-if="!is_urban" class="merriweather-reg-40" style="margin-top: 5rem;">Nos Clubs</h1>
        <h1 v-if="is_urban" class="merriweather-reg-40" style="margin-top: 5rem;">Nos expériences Urban Retreat</h1>
      </div>

<!--      <div class="list-desti-btns mt-5" >-->
<!--        <ul class="bar" v-if="yoga_cities && yoga_cities[0] != 1" >-->
<!--          <li v-for='city in this.yoga_cities' class="raleway-bold-p desti-btn" @click="selectDestination(city.id)">-->
<!--            <button class="raleway-bold-p">{{ city["name"] }}</button>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>


export default {
  name: 'headYoga',
  props: {
    is_urban: {
      type: Boolean,
      required: true
    },
    yoga_cities: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      cssProps: {
        backgroundImage: `url(${require('../../images/club.jpeg')})`
      }
    }
  },
  methods: {
    selectDestination(destination){
      this.$emit("destinationSelector", destination);
      $(".desti-btn").css("background-color", "white")
      event.currentTarget.style.backgroundColor = '#cddfd3'
    }
  },
  mounted() {
    var currentURL = window.location.href;

    if (currentURL.indexOf("/urban-retreat") !== -1) {
      this.is_urban=true;
    }
  }
}
</script>
<style lang="scss">
#destinations {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .search-desti {
    margin-right: 1rem;
  }

  .container-index {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 30vh;
    min-height: 100vh;
    overflow: hidden;
  }
}

#headDesti {
  .desti-header {
    .des {
      margin-top: 2rem;
      p {
        text-align: left;
        font: normal normal normal 15px/18px Raleway;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }

  }

  .row-desti-map {
    justify-content: space-around;
  }

  .bar {
    align-items: center;
  }

  .col-mapcontent {
    margin-top: 8rem;

    .btn-desti {
      width: 226px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .info-loca {
    display: flex;
    align-items: flex-start;

    img {
      width: 12px;
      margin-right: 10px;
    }
  }

  .list-desti-btns {
    margin-bottom: 5rem;

    ul {
      display: flex;
      justify-content: center;
    }

    li {
      border: 1px solid black;
      border-radius: 26px;
      list-style: none;
      padding: 12px 2px !important;
      margin-right: 1rem;

      &:hover {
        transition: all-ease 1s;
        font-weight: 600;
        background-color: $light-green;
        border: 1px solid $light-kaki;
        cursor: pointer;

        button {
          background-color: transparent;
          border: 0;
          color: $light-kaki;
        }
      }

      button {
        background-color: transparent;
        border: 0;
        color: black;
        transition: all-ease 1s;
        text-decoration: none;
      }
    }
  }

  #banner-lodging {
    width: 100%;
    height: 100%;
    background-image: url("https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=");
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    margin-top: 2rem;

    .banner-show-container {
      width: 100%;
      height: 444px;
      margin: 0px !important;
      padding: 0px !important;

      .catch-hub-show {
        color: white;
        margin-top: 9rem;
      }
    }

    .btn-galerie {
      width: 100%;
      margin-left: 5rem;
      cursor: pointer;

      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.4;
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }

    .btn-galerie-phone {
      display: none;
    }
  }

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {

    #headDesti {
      .desti-header {
        padding: 0rem 2rem;
      }

      .row-desti-map {
        justify-content: space-around;
      }

      .bar {
        align-items: inherit;
      }

      .col-mapcontent {
        margin-top: 8rem;

        .btn-desti {
          width: 226px;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .info-loca {
        display: flex;
        align-items: flex-start;

        img {
          width: 12px;
          margin-right: 10px;
        }
      }

      .list-desti-btns {
        margin-bottom: 5rem;

        ul {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding-left: 0;
        }

        li {
          border: 1px solid black;
          border-radius: 26px;
          list-style: none;
          padding: 1px 7px;
          margin-right: 0;
          margin-bottom: 11px;
          justify-content: center;
          align-items: center;
          display: flex;

          &:hover {
            transition: all-ease 1s;
            font-weight: 600;
            background-color: $light-green;
            border: 1px solid $light-kaki;

            button {
              background-color: transparent;
              border: 0;
              color: $light-kaki;
            }
          }

          button {
            background-color: transparent;
            border: 0;
            color: black;
            transition: all-ease 1s;
            text-decoration: none;
          }
        }
      }
    }

    #banner-lodging {
      margin-top: 16vh;
      display: block;

      .btn-galerie-phone {
        display: block;
        //width: 100%;
        margin-left: 1rem;

        .galerie-icon {
          background-color: rgba(0, 0, 0, 0.4);
          // opacity: 0.4;
          border-radius: 60px;
          padding: 1rem 2rem;
          color: white;
          width: 152px;
          height: 48px;
        }
      }

      .btn-galerie {
        display: none;
      }

      .banner-show-container {
        width: 100%;
        height: 554px;
        display: flex;
        align-items: flex-end;

        .catch-hub-show {
          margin-left: 1rem;

          p {
            font-size: 26px;
          }
        }
      }
    }
  }
}
</style>
