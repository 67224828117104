<template>
  <div id="modal-gallery" v-if="this.images">
    <div class="modal-container">
      <div class="close-btn-photo">
        <h1 @click="$emit('close')">X</h1>
      </div>
      <div class="header">
        <div class="lodging-name">
        </div>
          <h1 class="raleway-reg-p24">{{this.lodging.name}}</h1>
        <div class="standing">
          <img src="../../images/Etoiles.svg" alt="Etoile remplie" v-for='i in parseInt(this.lodging.standing)' :key='i'>
        </div>
      </div>
      <div class="lodging-info-gallery">
        <p class="raleway-reg-p15 mb-2">{{ this.lodging.country.name }}, {{ this.lodging.city.name }}</p>
      </div>
      <a
        @click="$emit('close')"
        class="no-deco mb-5 fixed-button"
        style="align-items: ;"
        >
          < Retour 
      </a>
      <div class="images">
        <div class="box-images text-center">
          <img class="image-modal-gallery" v-for="(image, i) in this.images" :src="image.url" :key="i" @click="index = i">
        </div>
      </div>
      <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
    </div>
  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';
  import VueGallerySlideshow from 'vue-gallery-slideshow';

  export default{
    name: 'modal-gallery',
    props: {
      lodging: Object,
      photoKeys: Array,
    },
    components: { VueGallerySlideshow },
    data() {
      console.log('this.photoKeys', this.photoKeys)
      return {
        lodgingMount: this.lodging,
        images: [],
        index: null,
        isModalOpen: true,
        showButton: false,
      }
    },
    methods: {
      defineImageLodging() {
        const outsides = this.lodgingMount.outside_urls
        const commons = this.lodgingMount.common_part_urls
        const photoPage = this.photoKeys
        const imgCon = outsides.concat(commons)
        this.images = photoPage
      },
    },
    mounted() {
      this.defineImageLodging();
      window.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll);
    },
  }
</script>
<style scoped>
.fixed-button {
  position: fixed;
  left: 20px;
  top:8vh;
  z-index: 1000;
  background-color: white;
  padding: 8px;
}
</style>

