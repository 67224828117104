<template>
  <div id="collectionHome">
    <div class="card-container" v-if="this.collectionsMounted">
      <div class="row row-hubs">
        <div class="col-4">
          <div class="collection-header">
            <h2 class="merriweather-reg-26"> Nos </h2>
            <h2 class="merriweather-reg-40 mb-5">Collections</h2>
            <p class="raleway-reg-p16">Découvrez en exclusivité nos collections : une sélection thématique d’hôtels de charme et d’hébergements exceptionnels que vous ne trouverez nulle part ailleurs.</p>
          </div>
            <a class="hortense-btn-primary raleway-bold-p mt-5" :href="'/collections'">Voir toutes les collections</a>
        </div>
        <div class="col-8 pt-2">
          <div class="row row-weekend">
            <div v-for='collection,index in this.collectionsMounted.slice(0,this.value)' :key="index" class="col-6 col-weekend">
              <CardCollection :collection="collection" v-if='index === 0' data-aos="fade-up" data-aos-duration="900"></CardCollection>
              <CardCollection :collection="collection" v-else-if='seeMore(index)' data-aos="fade-up" data-aos-duration="900"></CardCollection>
            </div>
          </div>
          <div class="pagination-hub d-flex mt-3">
            <a @click='SeeMoreCollection'  class="link-hortense"> voir plus <img src="../../images/flechebas-simple.svg" alt="fleche bas"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AOS from 'aos';
  import CardCollection from 'components/cards/CardCollection.vue';
  export default {
    name: 'CollectionHome',
    components: { CardCollection },
    props: {
      collections: Array,
    },
    data() {
      return {
        collectionsMounted: this.collections,
        value: 4,
      }
    },
    methods: {
      seeMore(index) {
        return index < this.value;
      },
      SeeMoreCollection(e) {
        e.preventDefault();
        this.value += 3;
      },
      SeeLessCollection(e) {
        e.preventDefault();
        this.value -= 3;
      },
    },
    mounted() {
      AOS.init();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/card-collection'>

.col-weekend{
  padding-left: 40px;
}

</style>
