<template>
  <div id="modal-gallery" v-if="this.images">
    <div class="modal-container">
      <div class="close-btn-photo">
        <h1 @click="$emit('close')">X</h1>
      </div>
      <div class="header">
        <div class="lodging-name">
        </div>
        <h1 class="raleway-reg-p24">{{this.lodging.title}}</h1>
      </div>
      <div class="images">
        <div class="box-images text-center">
          <img class="image-modal-gallery" v-for="(image, i) in this.images" :src="image" :key="i" @click="index = i">
        </div>
      </div>
      <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
    </div>
  </div>
</template>

<script>
import fetcher from 'packs/shared/api.js';
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default{
  name: 'modal-yoga',
  props: {
    lodging: Object,
    photoKeys: Array,
  },
  components: { VueGallerySlideshow },
  data() {
    return {
      images: [],
      index: null,
    }
  },
  methods: {
    defineImageLodging() {
      this.images = this.photoKeys
    },
  },
  mounted() {
    this.defineImageLodging();
  },
}
</script>

