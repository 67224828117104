<template>
  <div id="inbox-user">
    <BannerProfile :user="this.user"></BannerProfile>
    <div class="container-profil">
      <Menuprofil :user="this.user"></Menuprofil>
      <div class="row">
        <div class="col-lg-4 col-xs-8">
          <h3 class="merriweather-reg-40 mb-5">Mes échanges</h3>
          <button class="hortense-btn-snd" @click="createRoom">Contacter hortense</button>
        </div>
        <div class="col-lg-8">
          <div class="cards">
            <cardMessage></cardMessage>
            <!-- <div class="line"> -->
              <a href="/chat_rooms/list?q=client" class="card-inbox no-deco">
                <div>
                  <h3 class="raleway-bold-p mb-0">Messages logement {{ this.clients }}</h3>
                </div>
              </a>
              <a href="/chat_rooms/list?q=hortense" class="card-inbox no-deco">
                <div>
                  <h3 class="raleway-bold-p mb-0">Messages hortense {{ this.hortenses }}</h3>
                </div>
              </a>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import fetcher from "packs/shared/api";
  import cardMessage from 'pages/inbox/cardMessage.vue';
  import BannerProfile from 'components/shared/BannerProfile.vue';
  import Menuprofil from 'components/shared/Menuprofil.vue';
  export default{
    name: 'inbox-user',
    components: { BannerProfile, Menuprofil, cardMessage},
    props: {
      user: Object,
      reservations: Number,
      cancellations: Number,
      clients: Number,
      hortenses: Number,
    },
    methods: {
      async createRoom(){
        const res = await fetcher.post('/chat_rooms', { chat_room: {
                                                                            object: 'hortense',
                                                                            subject: '',
                                                                            receiver_id: '',
                                                                          }
                                                              })
        Turbolinks.visit('/chat_rooms/'+res.data.id)
      },
    }
  }

</script>

<style lang="scss" scoped>
#inbox-user{
    display: flex;
    flex-direction: column;
    align-items: center;
  .cards{
    margin-top: 5rem;

    .line{
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 5rem;
    }

    a.card-inbox {
      width: 27%;
      height: 118px;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 4rem;
      border: 1px solid black;
      margin-bottom: 2rem;
      transition: opacity 2s cubic-bezier(.165, .84, .44, 1);

      &:hover{
        box-shadow: 0 8px 17px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
        transform: scale3d(1.006, 1.006, 1);
        border: 0;
      }
    }
  }
}

</style>
