<template>
  <div id="cgv">
    <div class="container-index">
      <h1 class="merriweather-reg-40 text-center mb-5">Conditions Générales de Vente</h1>
      <pre class="raleway-reg-p15">
<h2>PREAMBULE</h2>
Les présentes Conditions Générales de Vente (ci-après « les CGV) constituent, conformément à l'article L.441-6 du Code du Commerce, le socle unique de la relation commerciale entre le Vendeur et les utilisateurs passant commande sur le Site (ci-après les « Clients »).

Les présentes conditions régissent la vente des prestations touristiques commercialisées par la société HORTENSE au travers de son site internet www.hortense.green.

Toute inscription à l’un des séjours proposés sur ce site ou tout achat de prestation y figurant implique l’acceptation sans réserve par l’acheteur des présentes conditions générales suivantes, qui prévalent sur tout autre document.

<h2>Offre en ligne</h2>
L’information donnée sur le site relativement aux différentes prestations proposées, aux transports, aux séjours, aux conditions de paiement et conditions d’annulation, constitue l’offre préalable au sens de l’article L.211-8 du code du Tourisme.

Par l'intermédiaire du Site, HORTENSE fournit au Client un catalogue en ligne présentant et décrivant avec sincérité les Prestations.
Les Prestations sont certes présentées et décrites avec la plus grande exactitude possible mais à titre indicatif. Dès lors, en cas d'erreurs ou omissions dans la présentation et la description de chaque Prestation opérée par le Vendeur, la responsabilité de ce dernier ne pourra être engagée de ce fait.
Les Prestations sont proposées dans la limite des places disponibles pour chacune d’entre elles.

La société HORTENSE se réserve la possibilité de modifier certains éléments constituant l’information préalable.

Toute modification des prestations proposées sur le site qui pourrait survenir avant l’inscription du client sera portée à sa connaissance par écrit, avant la conclusion du contrat, qui seul, en conséquence, fera foi des prestations effectivement convenues entre les parties.

Donnée Personnelle : toute information se rapportant à une personne physique identifiée ou identifiable qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale ou qui y sont rattachées et/ou rattachables.

Activités sportives et cures : HORTENSE attire l’attention de l’Utilisateur sur la nécessité de s’enquérir, avant la commande de la Prestation, de l’aptitude de tous les bénéficiaires à en bénéficier en prenant toutes les précautions que leur état de santé impose, de sorte que la responsabilité de la société HORTENSE saurait être engagée en cas d'incident ou d'accident.

<h2>Réservations</h2>
Les ventes de prestations touristiques ne sont pas soumises à l’application du droit de rétractation prévu aux articles L 121-21 et suivants du code de la consommation en matière de vente à distance.

Par conséquence, toute réservation d’un séjour ou d’une autre prestation touristique réalisée via le site www.hortense.green est définitive pour le client. L’annulation ne sera possible que dans les conditions d’annulation mentionnées dans l’article 5 ci-après.

La remise des documents de voyage et toute information relative au séjour réservé s’effectuant par courrier électronique, le client devra communiquer, lors de son inscription, une adresse électronique valable et consulter régulièrement sa boîte mail.

Le client doit informer HORTENSE, par écrit et préalablement à toute réservation, de toute particularité le concernant et susceptible d’affecter le bon déroulement du séjour (personnes à mobilité réduite avec ou sans fauteuil roulant, régime alimentaire spécifique, présence d’un animal, etc.).

L’inscription du client au séjour ne sera effective et n’engagera la société HORTENSE qu’après la confirmation par celle-ci de la disponibilité des produits réservés.

Cette confirmation sera adressée au client par courrier électronique.

Attention : les prestations proposées sont soumises à un nombre minimum de participants.

Si le nombre minimum de participants est atteint au moment où l’utilisateur valide sa commande, l’inscription est définitive.

Le client recevra au préalable un mail de confirmation de commande, et à la suite un mail confirmant le départ du séjour, et recevra toutes les informations nécessaires à l’organisation de ce dernier.

En cas de non atteinte du nombre minimum de participants, comme en cas d’indisponibilité de tout ou partie des prestations réservées, le client en sera immédiatement informé et sa demande de réservation sera considérée comme caduque.

La somme payée par le client lui sera alors intégralement remboursée sans qu’il puisse prétendre à une quelconque indemnité.

Si le nombre de participants est inférieur au nombre minimum requis, HORTENSE pourra alors proposer au client de maintenir le voyage en contrepartie d’une majoration "petit groupe" du prix initial.

Le surcoût éventuel de cette formule "petit groupe" sera communiqué à l’Utilisateur qui pourra l’accepter ou le refuser.

En cas d’acceptation, ce surcoût fera l’objet d’une facturation complémentaire. Ce supplément sera remboursé si d'autres participants venaient à s'inscrire sur le voyage avant la date de départ et que le nombre initial de voyageurs minimum requis était atteint.

En cas de refus du surcoût proposé, la réservation de l’Utilisateur sera annulée sans frais et le montant versé lui sera alors intégralement remboursé.

Il est important de souligner que la confirmation d'une réservation pour une chambre twin est conditionnée à la formation d'un binôme pour cette chambre.

Si aucun binôme n'est constitué, Hortense proposera au client de poursuivre son séjour en réglant le solde correspondant à une chambre single.

Le coût supplémentaire de cette option sera communiqué à l'utilisateur, qui aura alors le choix de l'accepter ou de le refuser.

En cas de refus du surcoût proposé, la réservation de l’Utilisateur sera annulée sans frais et le montant versé lui sera alors intégralement remboursé.

<h2>Nos Tarifs</h2>
<h3>Tarif</h3>
Les tarifs applicables et les taxes afférentes à la vente des Prestations sont précisés dans le catalogue en ligne.

Dans le tarif affiché pour leur montant connu au jour de la consultation, qui pourra être différent de celui pris en compte lors de la réservation.

Toute modification des taxes (TVA, taxes aériennes, etc.) sera également répercutée sur le prix des prestations au jour de la réservation ou rétroactivement selon les dispositions légales.

Sauf mention contraire du descriptif, le tarif affiché pour un voyage est forfaitaire et ne comprend jamais : le transport, la taxe touristique, frais de visa,les extras, les taxis, les pourboires et frais de porteur,les assurances.

HORTENSE se réserve le droit de modifier ses tarifs à tout moment en les publiant en ligne. Seuls s'appliqueront les tarifs en vigueur indiqués au moment de la réservation du Client, sous réserve de disponibilité des Prestations à cette date.

Il s'agit d'une réservation avec obligation de paiement, ce qui signifie que la réservation implique un règlement du Client.

<h3>Révision du tarif</h3>
Conformément aux dispositions de l'article L.211-12 du Code du Tourisme, le tarif à payer figurant au contrat est révisable tant à la hausse qu’à la baisse, pour tenir compte des variations du coût des transports, lié notamment au coût du carburant, aux redevances et taxes afférentes aux prestations réservées et du taux de change appliqué au voyage ou au séjour considéré.

Toutefois, aucune modification du tarif  ne pourra être appliquée au cours des 30 jours qui précèdent la date de départ prévue, pour les clients déjà inscrits. En cas de révision du prix, le client en sera avisé par courriel.

<h2>Modalités de paiement</h2>
<h3>Paiement</h3>
Le règlement de la prestation touristique achetée se fait par carte bancaire ou via la solution Klarna (paiement en 3 fois) et sur le site internet www.hortense.green.

La totalité du montant du séjour est exigée lors de votre inscription lors d'un règlement par carte bancaire (Mastercard et Visa).

<h3>Défaut de paiement</h3>
Le non-paiement du prix du voyage à l'échéance autorisera HORTENSE à considérer le voyage comme annulé du fait du client au jour du départ et en conséquence à facturer à ce dernier l’intégralité des frais d’annulation normalement dus à cette date, soit 100% des frais.

<h2>Modification ou annulation par le client</h2>
<h3>Modification/Annulation</h3>
Toute demande du client visant à la modification ou l’annulation de sa réservation devra être adressée par mail à la société HORTENSE à l’adresse suivante : customercare@hortense.green.
Toute modification est soumise à l’accord de la société HORTENSE et aux disponibilités existantes.
En cas d’impossibilité d’apporter la modification souhaitée, le contrat initial restera alors applicable.

Toute modification de réservation non validée par HORTENSE (changement de chambre, du type de réservation, ou de toute activité ou élément non compris dans le tarif au moment de la réservation) ne pourra entraîner un remboursement, même partiel, sans l'accord préalable de HORTENSE, et ce avant, pendant et après le séjour.

Toute activité prévue dans le programme et non consommée (pour des raisons personnelles ou médicales) ne pourront faire l'objet de geste commercial spécifique.
La date retenue pour définir le délai de modification ou d'annulation entraînant les frais ci-dessous sera le jour ouvrable suivant la réception de la demande de modification ou d'annulation.

En cas d'annulation, les assurances ne sont jamais remboursables et sont à ajouter au montant des frais d'annulation.

<h3>Frais en cas de modification</h3>
Toute modification entraîne un minimum de 30 € de frais par dossier, outre les frais inhérents à la modification elle-même qui seront facturés par le fournisseur concerné et dont le client sera informé préalablement.

Ces mêmes conditions s’appliquent dans le cas de changement de nom ou d’erreurs typographiques des noms communiqués lors de la réservation.

Dans la plupart des cas, les modifications sur place ne sont pas acceptées.
Toutefois, en cas de modification acceptée, le supplément éventuel, à charge du client, est à régler sur place.

Les prestations non consommées ne peuvent donner lieu à aucun remboursement.

<h3>Frais en cas d’annulation</h3>
Le client peut à tout moment résilier le contrat avant le voyage.
La raison de l’annulation doit être impérativement donnée à HORTENSE.
Il est recommandé au client de notifier cette annulation par écrit.

Si le client résilie le contrat de voyage/d’hébergement ou s’il ne participe pas au voyage, HORTENSE pourra exiger un dédommagement pour les démarches entreprises et pour les frais engagés.

Nous prélevons 30€ de frais de dossier pour toutes les annulations. Il s’agit d’un dédommagement pour les démarches entreprises et pour les frais engagés.

En cas de paiement via la solution Klarna ou Paypal : des frais d'annulation supplémentaires sont facturés à hauteur de 5% du montant total de la transaction.

Les frais de dossier et les frais bancaires s’appliquent pour toutes les annulations (y compris celles antérieures à 60 jours).

Les conditions d’annulation sont les suivantes :
    • Annulation gratuite jusqu’à 60 jours avant la date de la retraite (hors frais de dossier et frais bancaires)
    • Entre 59 jours et 32 jours avant la retraite, nous effectuerons un remboursement sous forme d'avoir qui pourra être utilisé pour une autre expérience Yogacation (hors frais de dossier et frais bancaires)
    • Les frais d’annulation sont de 100% du montant total du séjour  (en cas d’annulation hors délai ou de non présentation).

<h3>Cas particulier COVID-19</h3>
Dans un contexte COVID-19, nous invitons nos clients à être le plus responsable possible. Si des symptômes apparaissent avant le départ, nous vous prions de vous faire tester et de nous informer de la situation.

Dans le cas d'un test positif chez le participant avant le séjour, il conviendra d'une annulation tardive.

N'étant pas un cas de force majeure (article 1218 du code civil), le participant ne pourra prétendre à un remboursement ou quelconque dédommagement.

Nous invitons nos clients à se rapprocher de leur assurance voyage pour connaître leurs modalités de remboursement en cas de maladie COVID-19.

<h3>Interruption du voyage ou du séjour par le client</h3>
Tout voyage ou séjour interrompu ou abrégé ou toute prestation non consommée quelle que soit la cause, hors le cas de la force majeure tel que défini à l’article 8 ci-après ou le fait de HORTENSE ou d’un de ses prestataires, ne donnera lieu à aucun remboursement, même en cas de rapatriement.

Si HORTENSE doit annuler un départ pour quelque motif que ce soit, les personnes déjà inscrites seront intégralement remboursées, sans pouvoir prétendre à aucune indemnité.
Si un nombre insuffisant de participants est inscrit sur un départ, HORTENSE peut annuler le voyage au plus tard 21 jours avant le départ.

En cas de modification de l’un des éléments du contrat par HORTENSE, seuls la destination, le tarif et les dates peuvent être considérés, de convention expresse entre les parties, comme étant des éléments essentiels du contrat.
Dès lors, sans pour autant que cette modification puisse être considérée comme touchant un élément essentiel du contrat pouvant justifier une indemnisation ou encore l’annulation du client, un hébergement ou un restaurant pourra être remplacé par un établissement de même catégorie ou de catégorie supérieure et l’itinéraire d’un circuit pourra être modifié pour assurer des prestations de qualité au moins équivalentes aux prestations initialement convenues.

En cas d’annulation, (y compris pour raisons sanitaires), HORTENSE remboursera au client toutes les sommes versées par celui-ci lors de sa réservation à l’exclusion de toutes autres.
Tous les frais engagés préalablement par le client, tels que frais de visas, frais de transport, de pré et post acheminement (achats de titres de transport aérien ferroviaire, maritime ou autres), frais de vaccination, etc., ne pourront donner lieu à un quelconque remboursement ou à indemnisation.

<h2>Formalités</h2>
Il est de la seule responsabilité du client de vérifier que les différents documents nécessaires à son voyage, à la consommation d’une prestation ou à un embarquement (carte nationale d’identité ou passeport, permis de conduire, etc.,) sont en cours de validité.

La pièce d’identité utilisée doit être au même nom que le billet de transport.

Il est de même pour tout document lié à la situation sanitaire (Test négatif, attestation spécifique).

Le non-respect par le voyageur des obligations administratives et/ou sanitaires de franchissement des frontières qui aurait pour conséquence notamment un refus d’embarquement ou une interruption du séjour ou du voyage, ne pourra justifier un quelconque remboursement ou dédommagement.

Toute réservation engage le client à fournir un pass sanitaire valide 48h avant le départ du séjour.

<h2>Cas particulier des expériences Urban Retreat</h2>
Le Client reconnaît qu’en réservant une expérience Urban Retreat, il a préalablement pris connaissance des présentes CGV et les a acceptées, sans réserve aucune. Le Client est informé que HORTENSE peut être amené à faire évoluer les termes des présentes CGV.
Dans cette hypothèse, les CGV applicables seront celles en vigueur au moment de la commande et/ou de la réservation par le Client. L’acceptation des présentes CGV emporte également acceptation du règlement intérieur de l’hôtel partenaire lors de l’expérience Urban Retreat.
Toute demande du client visant à la modification ou l’annulation de sa réservation devra être adressée par mail à la société HORTENSE à l’adresse suivante : customercare@hortense.green.

Toute modification est soumise à l’accord de la société HORTENSE et aux disponibilités existantes.
En cas d’impossibilité d’apporter la modification souhaitée, le contrat initial restera alors applicable.

Toute modification de réservation non validée par HORTENSE (changement de date, ou de toute activité ou élément non compris dans le tarif au moment de la réservation) ne pourra entraîner un remboursement, même partiel, sans l'accord préalable de HORTENSE, et ce avant, pendant et après le séjour.

Toute activité prévue dans le programme et non consommée (pour des raisons personnelles ou médicales) ne pourront faire l'objet de geste commercial spécifique.

La date retenue pour définir le délai de modification ou d'annulation entraînant les frais ci-dessous sera le jour ouvrable suivant la réception de la demande de modification ou d'annulation.

En cas d'annulation, les assurances ne sont jamais remboursables et sont à ajouter au montant des frais d'annulation.

Les conditions d’annulation sont les suivantes :
• Annulation gratuite jusqu’à 21 jours avant la date de la journée
• Les frais d’annulation sont de 100% du montant total de la journée (en cas d’annulation hors délai ou de non présentation).

En cas d'annulation de l'événement par Hortense, le client recevra un remboursement sous forme d'avoir valable pendant un an, pouvant être utilisé pour une expérience Urban Retreat ou une Yogacation.

<h2>Assurances</h2>
Afin de vous protéger et d'assurer votre remboursement en cas d'annulation, nous vous recommandons vivement de souscrire à une assurance, pour tous vos séjours. Vous pouvez regarder les assurances proposées par votre carte bancaire, ou bien tout autre assureur. Sachez que suite à votre réservation, nous vous proposerons la souscription à une assurance,  <a href="https://www.chapkadirect.fr/index.php?action=produit&id=824&app=hortensegreen"> l'Assurance Chapka</a>, qui vous couvre également en cas de maladie COVID.

Vous avez un délai de 24h maximum après votre achat pour souscrire à une assurance voyage, dépassé ce délai, vous ne pourrez plus souscrire à une assurance voyage.

<h2>Réclamations</h2>
Toute réclamation concernant les conditions d’exécution de la Prestation doit être faite :
-      sans délai, sur place et par écrit, auprès des personnes accréditées par le Vendeur,
-       et directement auprès de ce dernier à l'adresse suivante: customercare@hortense.green pour traitement immédiat.
Après avoir interrogé HORTENSE et à défaut de réponse satisfaisante dans un délai de soixante (60) jours, le Client pourra saisir le médiateur du Tourisme et du Voyage à l’adresse suivante : Médiation Tourisme et Voyage (MTV) BP 80303 75823 Paris Cedex 17 (www.mtv.travel).

<h2>Juridiction compétente</h2>
La société HORTENSE est installée en France d'une manière stable et durable pour exercer effectivement son activité, quel que soit le lieu d’exécution des Prestations.
Aussi, les CGV sont soumises à l'application du droit français.
En cas de litige ou de réclamation, le Client s'adressera en priorité à HORTENSE pour tenter de parvenir à une solution amiable.
A défaut d'accord amiable, le litige sera porté devant les tribunaux français conformément aux règles de compétence en vigueur.

</pre>
    </div>
  </div>
</template>
<script>
export default {
  name: 'cgv',
}
</script>
<style lang="scss" scope='app/assets/stylesheets/components/static-pages'>
</style>