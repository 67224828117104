<template>
  <div id="banner-profil">
    <div class="banner-dashboard">
      <div class="banner-header">
        <h2 class="merriweather-reg-30 mb-4 mt-3">Bonjour {{this.user.first_name}}</h2>
        <p class="raleway-reg-p15 text-center h-content">Bienvenue dans votre espace personnel. Vous y retrouverez toutes vos informations personnelles ainsi que vos réservations passées et à venir. Il vous manque <strong>{{ this.userNextLevel }}</strong> points pour arriver au statut <strong>{{ this.userNextStatus }}</strong>.</p>
      </div>
      <div class="point-statut">
        <div class="points">
          <p class="raleway-reg-p24 mb-0">{{ this.userMount.loyalty_points }}</p>
          <p class="raleway-reg-p15 text-center"><strong>POINTS</strong></p>
        </div>
        <div class="points-select" v-if='this.userStatut === "Green Trotter"'>
          <img src="../../images/badge-guest.png" class="guest-badge" alt="">
          <p class="raleway-bold-p10 mb-0 text-center">Green Trotter</p>
          <p class="raleway-reg-p13 mb-0">Statut</p>
        </div>
        <div class="points-select" v-if='this.userStatut === "Green Trotter Plus"'>
          <img src="../../images/badge-member.png" class="member-badge" alt="">
          <p class="raleway-bold-p10 mb-0 text-center">Green Trotter Plus</p>
          <p class="raleway-reg-p13 mb-0">Statut</p>
        </div>
        <div class="points-select" v-if='this.userStatut === "Green Trotter Premium"'>
          <img src="../../images/badge-nomad@2x.png" class="nomad-badge" alt="">
          <p class="raleway-bold-p10 mb-0 text-center">Green Trotter Premium</p>
          <p class="raleway-reg-p13 mb-0">Statut</p>
        </div>
        <div class="points-select" v-if='this.userStatut === "Green Trotter Infinite"'>
          <img src="../../images/badge-trotter@2x.png" class="trotter-badge" alt="">
          <p class="raleway-bold-p10 mb-0 text-center">Green Trotter Infinite</p>
          <p class="raleway-reg-p13 mb-0">Statut</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';

  export default{
    name: 'BannerProfile',
    props: ['user'],
    data() {
      return {
        userPoints: 0,
        userMount: '',
        userStatut: '',
        userNextLevel: '',
        userNextStatus: '',
      }
    },
    methods: {
      async setUserLevel(){
        const res = await fetcher.get('/dashboard/'+this.user.slug)
        this.userMount = res.data
        console.log('this.userMount');
        console.log(this.userMount);
        this.userPoints = this.userMount.privilege.total_point
        this.userStatut = this.userMount.privilege.status
        this.userNextLevel = this.userMount.privilege.next_level
        this.userNextStatus = this.userMount.privilege.next_status
      },
    },
    mounted() {
      this.setUserLevel();
    }
  }
</script>
