const navbar = {
  "en": {
    "Hortense": {
      "whois": "Who's Hortense ?",
      "exclusive": "Nos Offres exclusives",
      "greenhost": "Green Host Label",
      "greennote": "Green Note",
      "evergreen": "Evergreen Program",
      "loyalty" : "Loyalty program",
      "faq" : "Q&A",
      "business" : "Hortense for business",
      "ethic" : "Codes of ethics",
      "gift_cards": "Gift Card",
      "privacy" : "By subscribing, I agree to Hortense sending me information and offers by email. I can unsubscribe at any time. I have read the Hortense Privacy Policy.",
    },
    "collections": "Our Collections",
    "destinations": "Our Destinations",
    "experiences": "Our Experiences",
    "newspaper": "Our Newspaper",
    "partner" : "Partner space",
    "host" : "Become a host",
    "contact" : "Contact",
    "contactinfo" : "00 00 00 00 00 Monday to Friday, 8 a.m. to 7 p.m.",
    "join" : "Join us",
    "message" : "By message",
    "write" : "Write us",
    "suscribe": "I subscribe",
    "legals" : "Legal notice",
    "policy" : "Privacy policy",
    "payment" : "Secure payment",
    "cgv" : "GTC",
    "Tips": {
      "title": "Hortense's advice",
      "subtitle": "to travel responsibly",
      "advice":{
        "first":"Favor a local destination" ,
        "second":"Choose a sustainable mode of transportation",
        "third": "Opt for eco-responsible accommodation",
        "fourth":"Bringing the local economy to life! ",
        "fifth": "Adopt a zero waste routine,",
        "sixth": "Calculate the carbon footprint of the stay",
        "seventh": "Immerse yourself in the surrounding culture",
        "eighth": "Preserve local natural resources",
      },
      "content":{
        "first":"There is so much to discover without going to the end of the world!" ,
        "second":"If the plane is essential, direct flights are preferred!" ,
        "third":"Charm, comfort, well-being and sustainability.",
        "fourth":"Promote small producers and sustainable activities",
        "fifth": "Even on vacation",
        "sixth": "By supporting concrete sus<Propositions>tainable development projects.",
        "seventh": "By favoring exchanges with the local population.",
        "eighth": "And favor environmentally friendly destinations.",
      },
    },
    "LodgingCard": {
      "title": "Dive into our selection of exceptional hotels and accommodations, less than 2 hours from your home. Between excellence and eco-responsible criteria, find the ideal accommodation for your local stay.",
      "view" : "View all the lodgings",
    },
    "our": "Our",
    "lodgings": "Lodgings",
    "hotel": "Hotel",
    "guestrooms": "Guest rooms",
    "aparthotel" : "Apart Hotel",
    "ecolodge" : "Ecolodges",
    "chalet" : "Chalets",
    "villa" : "Villas",
    "unusual" : "Unusual places",
    "viewmore" : "View more",
    "Banner": {
      "header": "Give meaning to your trip",
      "subtitle": "Exceptional places at the service of sustainable tourism",
      "whereto": "Where are you leaving?",
    },
    "search" : "Search",
    "Weekend":{
      "content": "",
      "viewbtn": "gift card",
    },
    "gift_cards":{
      "content": "A few days away from it all, to enjoy with friends, family or two. Discover our weekend ideas to offer you a break of relaxation and well-being in an exceptional setting.",
      "viewbtn": "See all gift card ideas",
    },
    "Experience":{
      "header": "For which",
      "title": "Experiences ?",
      "content": "Can't imagine your stay without a dose of discovery? Here is our selection of local experiences, to do with friends, family or two. Nature, sport, culture, gastronomy… There is an adventure for each of you.",
      "viewbtn": "SEE ALL experience IDEAS"
    },
    "Destination":{
      "content": "France, Caribbean, Polynesia, Indian Ocean, Morocco ... Let yourself be guided by our selection and find the destination that will capsize your heart.",
      "text": "Hortense offers ecotourism stays throughout France and in many regions of the world. Caribbean, Africa, Polynesia, mainland France: your heart swings? Let yourself be guided by our selection and find the perfect destination for your trip.",
      "viewbtn": "SEE ALL the Destinations",
    },
    "Collection":{
      "content": "Discover our collections exclusively: a thematic selection of charming hotels and exceptional accommodation that you will not find anywhere else.",
      "viewbtn": "SEE ALL the collections"
    },
    "Evergreen":{
      "program": "The EverGreen Program",
      "content": "Be actors in the development of sustainable tourism! Support ecological projects selected by Hortense by offsetting the CO2 emissions of your reservation with a financial contribution. Thanks to the loyalty program, Hortense rewards your financial support by giving you free nights!",
      "viewbtn": "Discover the program"
    },
    "Label":{
      "title": "The Green Host label",
      "content": "Green Host is the trust label developed by Hortense and awarded to accommodations committed to a responsible approach. To obtain the label and be part of the Hortense selection, accommodation must comply with 10 environmental criteria broken down into 150 compliance indicators transcribed in the Green Note.",
      "viewbtn": "Find out more"
    },

    "Green": {
      "title": "The Green Note",
      "content":"The Green Note is the rating that assesses the eco-responsible commitment of the hotels and accommodation offered by Hortense. From 1 to 10, it reviews the 10 essential criteria to integrate our selection.",
    },
    "Fid":{
      "head": "Hortensia",
      "title": "The loyalty program",
      "content": "Discover the Hortensia loyalty program. Cumulate advantages, exclusive offers and gifts by booking your stays with Hortense.",
      "viewbtn": "I'M REGISTERING",
      "link":"Learn more about Hortensia",
    },
    "Testimony":{
      "title": "Our partners testify",
    },
    "Reassurance":{
      "one": "Exceptional and committed accommodation",
      "two": "Accommodation selected according to 150 indicators",
      "three": "A loyalty program that covers you with gifts",
      "four": "Local and unique experiences",
      "five": "Evergreen: a reduced CO² footprint for every traveler",
      "six": "Secure and instant booking",
    },
    "Booking": {
      "month": "Bookings of the month",
      "day": "Bookings of the day",
      "week": "Bookings of the week",
      "view": "View bookings",
      "number": "Number of bookings",
      "nightcount": "Number of nights",
    },
    "arrivals": "Arrivals",
    "departures": "Departures",

    "Cancellations": {
      "cancellations": "Cancellations",
      "percentage": "Percentage of cancellations (%)",
      "number": "Cancellations (number)",
    },

    "Turnover": {
      "header": "Turnover for the current month",
      "turnover": "Turnover",
      "commissions": "Commissions",
    },
    "Profile": {
      "header":{
        "first" : "This section allows you to manage the access rights to your back office.",
        "second" : "Only the people present in this list will be authorized to access and make modifications.",
      },
      "signdate" : "This contract has been sign on",
      "online": "Online establishment on",
    },
    "User": {
      "first_name": "First name",
      "last_name" : "Last name",
      "fonction" : "Function",
      "authorization": "Authorization date",
      "removelist" : "Remove",
      "add" : "Add a user",
    },
    "Contact": {
      "add": "Add a contact"
    },
    "Client":{
      "relation": "Customer relationship",
    },
    "Message":{
      "view": "View messages",
      "unread" : "Unread messages",
    },
    "Comment":{
      "view": "View comments",
      "unread" : "Unread comments",
    },
    "Bank":{
      "imprint": "Bank imprint",
      "tocheck" : "To check",
      "view": "View bank imprint",
    },

  },
  "fr": {
    "Hortense": {
      "whois": "Qui est Hortense ?",
      "exclusive": "Nos Offres exclusives",
      "greenhost": "Le Label Green Host",
      "greennote": "La Green Note",
      "evergreen": "Le Programme Evergreen",
      "loyalty" : "Programme de fidélité",
      "faq" : "FAQ",
      "business" : "Hortense for business",
      "ethic" : "Notre charte éthique",
      "gift_cards": "Carte cadeau",
      "privacy" : "En m’abonnant, j’accepte qu’Hortense m’envoie des informations et des offres par e-mail. Je peux me désabonner à tout moment. J’ai lu la Politique de confidentialité Hortense.",
      "made_trip": "Voyage sur mesure",
      "for_wedding": "Hortense for wedding",
    },
    "collections": "Nos Collections",
    "clubs": "Nos Clubs",
    "yogacation": "Nos Expériences bien-être",
    "destinations": "Nos Destinations",
    // "experiences": "Nos Expériences",
    "newspaper": "Notre Journal",
    "exclusive": "Nos Offres exclusives",
    "partner" : "Mon espace partenaire",
    "selection": "Notre Sélection",
    "host" : "Devenir hôte",
    "contact" : "Contact",
    "contactinfo" : "00 00 00 00 00 Du lundi au vendredi, de 8h à 19h",
    "join" : "Rejoignez-nous",
    "message" : "Par message",
    "write" : "Nous écrire",
    "suscribe": "Je m'abonne",
    "legals" : "Mentions légales",
    "policy" : "Politique de confidentialité",
    "payment" : "Paiement sécurisé",
    "cgv" : "CGV",
    "Tips": {
      "title": "Les conseils d’Hortense",
      "subtitle": "pour voyager responsable",
      "advice":{
        "first": "Privilégier une destination locale",
        "second": "Choisir un mode de transport durable",
        "third":"Opter pour un hébergement éco-responsable",
        "fourth":"Faire vivre l’économie locale !",
        "fifth": "Adopter une routine zéro déchets",
        "sixth": "Calculer l’empreinte carbone du séjour",
        "seventh": "S’imprégner de la culture ambiante",
        "eighth": "Préserver les ressources naturelles locales",
      },
      "content":{
        "first": "Il y a tant à découvrir sans aller au bout du monde !",
        "second": "Si l’avion est indispensable, les vols directs sont à privilégier !",
        "third": "Charme, confort, bien-être et durabilité.",
        "fourth":"Favoriser les petits producteurs et les activités durables.",
        "fifth": " Même en vacances",
        "sixth": "En soutenant des projets concrets de développement durable. ",
        "seventh": "En privilégiant les échanges avec la population locale.",
        "eighth": "Et privilégier des destinations respectueuses de l’environnement. ",
      },
    },
    "Propositions":{
      "title1": "Pourquoi voyager avec",
      "title2": "Hortense ?",
      "subtitle": "Hortense a déniché pour vous les plus beaux hébergements éco-responsables. Des lieux authentiques et pleins de charme soigneusement sélectionnés pour leur engagement environnemental. Une Green Note minimale de 5/10 est nécessaire pour intégrer notre collection. Séjournez dans un hébergement sélectionné par Hortense c’est la garantie de séjourner dans un lieu d’exception respectueux de l’environnement.",
      "content":{
        "first": "Des hébergements de charme éco-responsables",
        "second": "DES SERVICES EXCLUSIFS PENDANT LE SÉJOUR",
        "third": "Des séjours bien-être",
        "fourth": "DONS AU PROFIT DE L’ENVIRONNEMENT",
        "fifth": "DES VOYAGES ÉCO-RESPONSABLES SUR MESURE",
        "sixith": "Des journées bien-être",
        "seven": "Des séjours tout inclus",
      },
      "anchor":{
        "first": "Découvrez notre sélection",
        "second": "Rejoignez le club Hortensia",
        "third": "Découvrez nos expériences Yogacation",
        "fourth": "Découvrez le programme Evergreen",
        "fifth": "Faites créer votre voyage",
        "seven": "Découvrez nos clubs",

      }
    },
    "LodgingCard": {
      "title": "Découvrez notre sélection unique d'hébergements exceptionnels alliant excellence et respect de l'environnement. Trouvez le lieu idéal pour votre prochain séjour inoubliable.",
      "view" : "Voir tous les hébergements",
    },
    "our": "Nos",
    "lodgings": "Hébergements",
    "hotel": "Hôtel",
    "guestrooms": "Chambres d'hôtes",
    "aparthotel" : "Appart’hôtels",
    "ecolodge" : "Écogîtes",
    "chalet" : "Chalets",
    "villa" : "Villas",
    "unusual" : "Lieux insolites",
    "viewmore" : "Voir plus",
    "Banner": {
      "header": "Donnez du sens à votre voyage",
      "subtitle": "Des lieux exceptionnels au service du tourisme durable",
       "whereto": "Où partez-vous?",
    },
    "search": "Rechercher",
    "Weekend":{
      "content": "Quelques jours loin de tout, pour profiter entre amis, en famille ou à deux. Découvrez nos idées de week-ends pour vous offrir une parenthèse de détente et de bien-être dans un cadre exceptionnel.",
      "viewbtn": "VOIR TOUTES LES IDÉES DE WEEK-ENDS"
    },
    "Exclusive":{
      "content": "Voyager différemment grâce à Hortense. Hortense vous offre la possibilité de faire de votre voyage plus qu'un simple souvenir, une expérience dont vous parlerez grâce à nos offres exclusives réservées aux membres du programme de fidélité Hortensia.",
      "viewbtn": "VOIR TOUTES NOS OFFRES"
    },
    "gift_cards":{
      "content": "Quelques jours loin de tout, pour profiter entre amis, en famille ou à deux. Découvrez nos idées de week-ends pour vous offrir une parenthèse de détente et de bien-être dans un cadre exceptionnel.",
      "viewbtn": "OFFRIR UNE CARTE CADEAU"
    },
    "Experience":{
      "header": "Pour quelles",
      "title": "Expériences ?",
      "content": "Vous n’imaginez pas votre séjour sans une dose de découverte ? Voici notre sélection d’expériences locales, à faire entre amis, en famille ou à deux. Nature, sport, culture, gastronomie… Il y a une aventure pour chacun d’entre vous.",
      "viewbtn": "VOIR TOUTES LES IDÉES D'expériences"
    },
    "Destination":{
      "content": "France, Caraïbes, Polynésie, Océan Indien, Maroc… Laissez-vous guider par notre sélection et trouvez la destination qui fera chavirer votre cœur.",
      "text":"Hortense propose des séjours d’écotourisme à travers toute la France et dans de nombreuses régions du monde. Caraïbes, Afrique, Polynésie, France métropolitaine : votre cœur balance ? Laissez-vous guider par notre sélection et trouvez la destination parfaite pour votre voyage. ",
      "viewbtn": "VOIR TOUTES LES destinations ",
    },

    "Collection":{
      "content": "Découvrez en exclusivité nos collections : une sélection thématique d’hôtels de charme et d’hébergements exceptionnels que vous ne trouverez nulle part ailleurs.",
      "viewbtn": "VOIR TOUTES LES collections ",
    },
    "Evergreen":{
      "program": "Le Programme EverGreen",
      "content": "Soyez acteurs du développement du tourisme durable ! Soutenez des projets écologiques sélectionnés par Hortense en contrebalançant l’émission de CO2 de votre réservation grâce à une contrepartie financière. Grâce au programme de fidélité, Hortense récompense votre soutien financier en vous faisant bénéficier de nuits offertes !",
      "viewbtn": "Découvrir le programme"
    },
     "Label":{
      "title":"Le label Green Host",
      "content": "Green Host, c’est le label de confiance développé par Hortense et attribué aux hébergements engagés dans une démarche responsable. Pour obtenir le label et faire partie de la sélection Hortense, les hébergements doivent respecter 10 critères environnementaux déclinés en 150 indicateurs de conformité retranscrits dans la Green Note.",
      "viewbtn": "En savoir plus"
    },
    "Green": {
      "title": "La Green Note",
      "content":"La Green Note, c’est la note qui évalue l’engagement éco-responsable des hôtels et hébergements proposés par Hortense. De 1 à 10, elle passe en revue les 10 critères indispensables pour intégrer notre sélection.",
    },
    "Fid":{
      "head": "Hortensia",
      "title": "Le programme de fidélité",
      "content": "Découvrez le programme de fidélité Hortensia. Cumulez des avantages, offres exclusives et cadeaux en réservant vos séjours avec Hortense.",
      "viewbtn": "JE M'INSCRIS",
      "link":"En savoir plus sur Hortensia",
    },
    "Testimony":{
      "title": "Nos partenaires témoignent",
    },
    "Reassurance":{
      "one": "Des hébergements exceptionnels et engagés",
      "two": "Des hébergements sélectionnés selon 150 indicateurs",
      "three": "Un programme de fidélité qui vous couvre de cadeaux",
      "four": "Des expériences locales et uniques",
      "five": "Evergreen : une empreinte CO² réduite pour chaque voyageur",
      "six": "Réservation sécurisée et instantanée",
    },
    "Booking": {
      "month": "Réservations du mois",
      "day": "Réservations du jour",
      "week": "Réservations de la semaine",
      "view": "Voir les réservations",
      "number": "Nombre de réservations",
      "nightcount": "Nombre de nuitées",
    },
    "arrivals": "Arrivées",
    "departures": "Départs",
    "Cancellations": {
      "cancellations": "Annulations",
      "percentage": "Pourcentage d'annulations (%)",
      "number": "Annulations (nombre)",
    },

    "Turnover": {
      "header": "Chiffre d’affaire du mois en cours",
      "turnover": "CA",
      "commissions": "Commissions",
    },
     "Profile" :{
      "header":{
        "first" : "Cette section vous permet de gérer les droits d’accès à votre back office.",
        "second" : "Seules les personnes présentes dans cette liste seront habilitées à accéder et faire des modifications.",
      },
      "signdate" : "Signature du contrat le",
      "online" : "Mise en ligne de l’établissement le",
    },
    "User" :{
      "first_name": "Prénom",
      "last_name" : "Nom",
      "fonction" : "Fonction",
      "authorization": "Date d'habilitation",
      "removelist" : "Retirer de la liste",
      "add" : "Ajouter un utilisateur",
    },
    "Contact": {
      "add": "Ajouter un contact"
    },
    "Client":{
      "relation": "Relation client",
    },
    "Message":{
      "view": "Voir les messages",
      "unread" : "Messages non lus",
    },
    "Comment":{
      "view": "Voir les commentaires",
      "unread" : "Commentaires non lus",
    },
    "Bank":{
      "imprint": "Empreinte bancaire",
      "tocheck" : "À vérifier",
      "view": "Voir les empreinte bancaire",
    },
  }
}

export default navbar
