<template>
  <div id="gift-card-phone">
    <div class="phone-container">      
      <div class="gift_cards-header">
        <h2 class="merriweather-reg-26">Notre</h2>
        <h2 class="merriweather-reg-40 mb-5">Carte cadeau</h2>
        <p class="raleway-reg-p16">Hortense propose la sélection la plus exclusive d’hébergements éco-responsables. Séjourner dans un hôtel sélectionné par Hortense c’est la garantie de séjourner dans un hébergement d’exception engagé dans une démarche de développement durable. <br/> Surprenez vos proches en leur offrant une nuit ou plus dans un lieu d’exception engagé dans une démarche environnementale.</p>
      </div>      
        <div id="gift-home-container">
          <div class="text-center gift-card-overview">
            <h2 class="gift-card-overview-title">Plaisir d'offrir</h2>
            <div class="card-number fs-8">Carte n°000XX</div>
            <div class="gift-card-overview-body line-form form-group my-3">
              <div class="row w-100">
                <div class="col-md-10 fs-10">
                  <span class="no-bold">Montant :</span>
                  <input class="border-input w-50 text-right" type="text" value="xx"/>
                  <span class="no-bold">€</span>
                </div> 
              </div>
            </div>
            <div class="gift-card-overview-footer fs-8">
              <p class="p-container">Hortense.green <br/> Code d'activation : <span class="gift-card-overview-activation-code">xxxxxx</span> valable jusqu'au <span>XX/XX/XXXX</span></p>
            </div>
          </div>
        </div>
        <div class="picture-gift">
          <img id="o-gift-card" src="../images/icotype.svg" alt="" data-aos="zoom-out" data-aos-duration="1000">
        </div>
        <img class="img-fluid" data-aos="fade-right" data-aos-duration="1000" id="gift-home" src="../images/giftcard/home-presentation.jpg" alt="">
        
      <div class="w-100"><a class="hortense-btn-primary w-100 raleway-bold-p mt-5" href="/carte-cadeau">{{ $t("gift_cards.viewbtn") }}</a>
      </div>
      
                    

    </div>
  </div>
</template>

<script>
  import AOS from 'aos';

  export default {
    name: 'gift-card-phone',
    props: {
      
    },
    data() {
      return {
      }
    },
    methods: {
      
    },
    mounted() {
      AOS.init();
    }
  }
</script>


<style lang="scss">
  #gift-card-phone{
    .hide{
      display: none !important;
    }

    .picture-gift{
      display: block;      
      width: 100px;
      height: 300px;
      z-index: 100;
      right: 1rem;
      margin-top: 8%;
      overflow: hidden;
      position: absolute;
    }

    .no-bold{
      font-style: normal !important;
      font-weight: initial !important;
    }

    #o-gift-card{      
      width: 20rem !important; 
      position: absolute;
    }

    .fs-10{
      font-size: 10px;
    }

    .fs-8{
      font-size: 8px !important;
    }

    margin-top: 5rem;
    left: 0;

    .border-input{
      border: none;
      border-bottom: 1px solid #ccc;
    }

    .card-number{
      float: right;
      transform: rotate(-90deg);
      padding: 0 !important;
      margin: 0 !important;
      position: absolute;
      font-size: 6px;
      right: -15px;
    }

    #gift-home-container{
      position: absolute;
      width: 75%;
      z-index: 2111;
      background: white;
      margin: 15% 8%;
    }

    #gift-home{
      margin-top: 1rem;
      position: relative;
      width: 100%;
      height: 100%;
    }

    .gift-card-overview{
      border: 5px #3B6152 solid;
      padding-top: 3px;

      .gift-card-overview-title{
        padding: 10px;
        font-family: Abuget;
        font-size: 30px;
      }

      .gift-card-overview-body{
        display: block;
        margin-top: 5px;
        font-weight: bolder;
        color: #3B6152;

        .gift-card-overview-price{
          font-weight: bolder;
          border-bottom: solid 1px #3B6152;
          color: #000;
          inline-size: 200px;
        }
      }

      .gift-card-overview-footer{
        margin: 0;
        padding: 0;
        bottom: 0;
      }

      .p-container{
        font-family: $Raleway;
        margin: 0 !important;
        padding: 0 !important;
      }
    }
  }
</style>
