<template>
  <div id="cardProduct" v-if="this.lodging">
    <div class="hub-img-zoom" @click="goToLodging()" :style="{backgroundImage: 'url('+ formatSource() +')'}" v-if='this.lodging.photo_banner'>
      <div class="sticker-hub d-flex mt-4 pl-2">
        <img :src="greenNotePicto((this.lodging.score).toFixed())" :alt="(this.lodging.score).toFixed()" v-if="this.lodging.score">
        <div class="sticker-info d-flex" v-if="this.lodging.score">
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2"> <b>{{ (this.lodging.score).toFixed() }}</b>/10</p>
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2">Green note</p>
        </div>
      </div>
      <!-- <img class="hub-img" :src="formatSource()" :alt="this.lodging.name" v-if='this.photo_keys'> -->
    </div>
    <div class="card-hub-info">
      <div class="hub-header d-flex">
        <h1 class="raleway-bold-h3">{{this.lodging.name}}</h1>
        <div class="like-icon">
          <img src="../../images/wishlistOn.svg" alt="Coeur plein" v-if='isIncludeInWishlist()' @click='toggleLike()'>
          <img src="../../images/wishlist.svg" alt="Coeur vide" v-else @click='toggleLike()'>
        </div>
      </div>
      <Mapfind v-if='showMap' :markers='this.markers' @close='showMap = false'></Mapfind>
      <div class="standing">
        <img src="../../images/Etoiles.svg" alt="Etoile remplie" v-for='i in parseInt(this.lodging.standing)' :key='i'>
      </div>
      <p class="description-hub raleway-reg-p13" v-if='this.lodging'>{{formatStyle(this.lodging.country.name)}}, région {{this.lodging.region.name}}</p>
      <div class="location-hub d-flex">
        <img src="../../images/Localisation.svg" alt="Localisation">
        <!-- <p class="raleway-reg-p13 pl-1 pr-2">3,4km du centre</p> -->
        <a class="link-hortense ml-2" @click="displayMap">Voir sur la carte</a>
      </div>
      <div class="catch-hub" v-if='this.lodging.lodging_description.catch_sentence'>
        <p class="merriweather-italic">{{ this.lodging.lodging_description.catch_sentence }}</p>
      </div>
      <div class="catch-hub" v-else>
        <p class="merriweather-italic">Un havre aussi éco-responsable que luxueux</p>
      </div>
      <div class="reviews-btn">
        <div class="hub-reviews d-flex">
          <img class="mt-2 mr-2" src="../../images/avis.svg" alt="avis">
          <div class="review-info d-flex">
            <div class="score-hub d-flex">
              <p class="raleway-reg-p13 mb-0 pl-1 pr-2"><b>{{this.average}}</b>/5</p>
              <a :href="'/reservation-hotel/'+ this.lodging.slug +'/avis'" class="link-hortense"> {{this.ratedLodgings.length}} avis</a>
            </div>
            <p class="raleway-reg-p13">Note des voyageurs</p>
          </div>
        </div>
        <a :href="'/reservation-hotel/'+ this.lodging.slug" class="xs-btn-primary text-center">Voir les disponibilités</a>
      </div>
    </div>
  </div>
</template>

<script>
import fetcher from 'packs/shared/api.js';
import Mapfind from 'components/Mapfind.vue';

export default{
    name: 'CardProduct',
    props: {
      lodging: Object,
      user: Object,
      wishlist: Object
    },
    components: { Mapfind },
    data() {
      return {
        photo_keys: this.lodging.photos_keys,
        uid: this.user.id,
        lid: this.lodging.id,
        showMap: false,
        markers: [],
        ratedLodgings: [],
        averageList:[],
        average: 0,
        wishlistLodgings: null,
      }
    },
    methods: {
      greenNotePicto(score){
         if (score === `${score}`){
          return require(`../../images/greennote ${score}.svg`)
        } else {
          return require("../../images/blanc.png")
        }
      },
      goToLodging(){
        window.location.href = "/reservation-hotel/"+this.lodging.slug
      },
      markerMap() {
        return [this.lodging.longitude, this.lodging.latitude];
      },
      displayMap() {
        this.showMap = !this.showMap;
        this.markers = this.markerMap();
       },
      formatSource() {
        if (this.lodging.photo_banner) {
          return this.lodging.photo_banner
        } else {
          return "https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0="
        }
      },
      async toggleLike() {
        try {
          if (Object.entries(this.user).length !== 0) {
            const response = await fetcher.patch('/wishlists/'+ this.user.id, { lodging_id: this.lodging.id });
            this.wishlistLodgings = response.data.lodgings;
          } else {
            this.$root.$emit('openModal');
            window.scrollTo({
              top: 0,
              behaviour: 'smooth'
            });
          }
        } catch(e) {
          console.error(e)
        }
      },
      ratedLodging(){
        this.ratedLodgings = this.lodging.reservations.filter(el => el.rated === true)
          return this.ratedLodgings
      },
      averageRate(){
        this.ratedLodgings.forEach((el) => {
          this.averageList.push(el.rate_average)
        })
        return this.averageList
      },
      formatStyle(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      averageLodging(){
        if(this.averageList.length !== 0){
          this.average = (this.averageList.reduce((a, b) => a + b, 0) / this.averageList.length).toFixed(1);
          return this.average
        } else {
          return this.average = '-'
        }
      },
      isIncludeInWishlist() {
        if (this.wishlistLodgings) {
          for (const lodging of this.wishlistLodgings) {
            if (lodging.id === this.lodging.id) {
              return true
            }
          }
        }
        return false
      },
    },
    mounted(){
      this.ratedLodging();
      this.averageRate();
      this.averageLodging();
    },
    computed: {
      defineWishlistLodgings() {
        return this.wishlistLodgings = this.wishlist.lodgings
      }
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/card-product'>

</style>

