<template>
  <div id="destinations">
    <div>
      <headYoga :is_urban="this.is_urban" @destinationSelector="selectDestination" :yoga_cities="this.yoga_cities"></headYoga>
      <div class="filters-phone" v-if="isPhone">
        <p class="raleway-reg-p15 mb-0" @click="toggleFilters" v-if="showFilters">Masquer les filtres</p>
        <p class="raleway-reg-p15 mb-0" @click="toggleFilters" v-else>Appliquer les filtres</p>
      </div>
      <div class="row no-gutters">
        <div class="col-lg-3" v-if="!isPhone || showFilters" style="margin-top: 30px">
          <div class="filters">
            <h5>APPLIQUER LES FILTRES</h5>
            <div class="filter-group">
              <h6 @click="toggleFilter('destinationsVisible')">
                Destination
                <img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="destinationsVisible" />
                <img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
              </h6>
              <ul v-if="destinationsVisible">
                <li v-for="city in yoga_cities" :key="city.id">
                  <input type="checkbox" :id="city.id" :value="city.name" v-model="selectedDestinations" @change="applyFilters">
                  <label class="raleway-reg-p13 ml-2" :for="city.id">{{ city.name }}</label>
                </li>
              </ul>
            </div>
            <div class="filter-group">
              <h6 @click="toggleFilter('activitiesVisible')">
                Activités
                <img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="activitiesVisible" />
                <img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
              </h6>
              <ul v-if="activitiesVisible">
                <li v-for="activity in activities" :key="activity">
                  <input type="checkbox" :id="activity" :value="activity" v-model="selectedActivities" @change="applyFilters">
                  <label class="raleway-reg-p13 ml-2" :for="activity">{{ activity }}</label>
                </li>
              </ul>
            </div>
            <div class="filter-group">
              <h6 @click="toggleFilter('periodsVisible')">
                Période
                <img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="periodsVisible" />
                <img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
              </h6>
              <ul v-if="periodsVisible">
                <li v-for="period in periods" :key="period">
                  <input type="checkbox" :id="period" :value="period" v-model="selectedPeriods" @change="applyFilters">
                  <label class="raleway-reg-p13 ml-2" :for="period">{{ period }}</label>
                </li>
              </ul>
            </div>
            <div class="filter-group">
              <h6 @click="toggleFilter('durationVisible')">
                Durée
                <img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="durationVisible" />
                <img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
              </h6>
              <ul v-if="durationVisible">
                <li v-for="dur in duration" :key="dur">
                  <input type="checkbox" :id="dur" :value="dur" v-model="selectedDurations" @change="applyFilters">
                  <label class="raleway-reg-p13 ml-2" :for="dur">{{ dur }}</label>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-9">
          <div class="container" style="margin-top: 30px">
            <div class="row mb-5">
              <div class="col-md-6 col-lg-6 col-sm-12 mb-4 d-flex" v-for="yoga in paginatedYogas" :key="yoga.id">
                <div class="card yoga-card flex-fill" @click="goToYoga(yoga)">
                  <img :src="yoga.photo_banner" class="card-img-top" alt="Yoga Image">
                  <div class="card-body d-flex flex-column">
                    <h5 class="card-title text-center" style="font: normal normal normal 26px/33px Merriweather;">{{ yoga.yoga_place.title }}</h5>
                    <p class="card-text text-center" style="font: normal normal normal 15px/18px Raleway;">{{ yoga.location_detail }}</p>
                    <div class="list-desti-btns mt-3 flex-grow-1 d-flex flex-column justify-content-center">
                      <ul style="display: flex; justify-content: center; flex-wrap: wrap; gap: 10px; flex-direction: column;" class="bar">
                        <li v-for="(schedule, i) in yoga.online_yoga_schedules" :key="schedule.id" class="menu-item raleway-reg-p16" style="list-style: none; padding: 5px; text-align: center;">
                          <span class="date-box" @click.stop="setDestinations(yoga, schedule.id)">
                            <strong>{{ schedule.yoga_date }} - A partir de {{ schedule.lowest_rate }}€</strong>
                          </span>
                        </li>
                      </ul>
                    </div>
                    <p class="mab2 pt-1 text-center" style="text-decoration: underline; font: normal normal normal 13px/18px Raleway;">En savoir plus</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="pageCount > 1" class="pagination-controls">
              <button v-for="page in pageCount" :key="page" :class="['pagination-button', { active: page === currentPage }]" @click="currentPage = page">
                {{ page }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadYoga from "./HeadYoga.vue";

export default {
  name: "Yogas",
  components: { HeadYoga },
  props: {
    yoga_cities: {
      type: Array,
      required: true
    },
    yogas: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 8,
      is_urban: false,
      selectedDestination: "France",
      destinations: null,
      isLoading: true,
      selectedDestinations: [],
      selectedActivities: [],
      selectedPeriods: [],
      selectedDurations: [],
      destinationsVisible: true,
      activitiesVisible: true,
      periodsVisible: true,
      durationVisible: true,
      showFilters: false,
      activities: ["Yoga", "Pilates", "Fitness", "Danse"],
      duration: ["One Day Retreat", "Week-end", "Long Week-end", "Long séjour"],
      periods: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
    };
  },
  computed: {
    isPhone() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent);
    },
    pageCount() {
      return Math.ceil(this.filteredYogas.length / this.itemsPerPage);
    },
    paginatedYogas() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredYogas.slice(start, end);
    },
    filteredYogas() {
      return this.yogas.filter(yoga => {
        const matchesDestination = this.selectedDestinations.length ? this.selectedDestinations.some(city => yoga.city.includes(city)) : true;
        const matchesActivities = this.selectedActivities.length ? this.selectedActivities.some(activity => yoga.activities.includes(activity)) : true;
        const matchesDurations = this.selectedDurations.length ?  this.selectedDurations.some(duration => yoga.duration.includes(duration)) : true;
        const matchesPeriods = this.selectedPeriods.length ? this.selectedPeriods.some(schedule => yoga.periods.includes(schedule)) : true;
        return matchesDestination && matchesActivities && matchesDurations && matchesPeriods;
      });
    }
  },
  methods: {
    goToYoga(yoga) {
      window.open("/experiences-bien-etre/" + yoga.yoga_place.slug, "_blank");
    },
    setDestinations(yoga, id) {
      window.open("/experiences-bien-etre/" + yoga.yoga_place.slug + "?date=" + id, "_blank");
    },
    applyFilters() {
      this.currentPage = 1; // Reset to the first page after applying filters
    },
    toggleFilter(filter) {
      this[filter] = !this[filter];
    },
    toggleFilters() {
      this.showFilters = !this.showFilters;
    },
    async hashEmail(email) {
      if(email && email !== '') {
        const encoder = new TextEncoder();
        const data = encoder.encode(email);
        const hash = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hash));
        return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      } else {
        return ""
      }
    },
  },
  async mounted() {
    this.yogas = this.yogas.filter(yoga => yoga.is_online === true);
    var currentURL = window.location.href;

    if (currentURL.indexOf("/urban-retreat") !== -1) {
      this.is_urban = true;
    } else {
      this.is_urban = false;
    }
    const hashedEmail = await this.hashEmail(this.user?.email || '');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page: {
        page_type: 'product_list',
        page_category: 'nos experiences bien être',
      },
      user: {
        email: this.user ? hashedEmail : "",
      },
    });
    window.dataLayer.push({
      event: 'view_item_list',
      item_list_name: 'nos experiences bien être',
      item_list_id: 'nos experiences bien être'
    });
  }
};
</script>

<style scoped>
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row.no-gutters > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.card.yoga-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}

.card.yoga-card:hover {
  transform: scale(1.05);
}

.card.yoga-card .card-img-top {
  height: 356px;
  object-fit: cover;
}

.card.yoga-card .card-body {
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.card.yoga-card .card-title {
  font: normal normal normal 26px/33px Merriweather;
}

.card.yoga-card .card-text {
  font: normal normal normal 15px/18px Raleway;
  margin-bottom: 10px;
}

.card.yoga-card .card-price {
  font: normal normal 600 18px/32px Raleway;
}

.date-box {
  display: block;
  white-space: nowrap;
  font: normal normal 600 14px/20px Raleway;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date-box:hover {
  background-color: #000;
  color: #fff;
}

.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.pagination-button {
  width: 60px;
  height: 60px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 3px;
  opacity: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pagination-button.active {
  opacity: 1;
  font-weight: bold;
}

.filters {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
}

.filters-phone {
  display: block;
  position: fixed;
  bottom: 0;
  z-index: 5;
  color: white;
  background-color: rgba(0,0,0,0.8);
  padding: 4px 5px;
  width: 100%;
  text-align: center;
}

.filter-group {
  margin-bottom: 20px;
}

.filter-group h6 {
  font-weight: bold;
  margin-bottom: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-group ul {
  list-style: none;
  padding: 0;
}

.filter-group ul li {
  margin-bottom: 10px;
}

.filter-group ul li input[type="checkbox"] {
  margin-right: 5px;
}
</style>
