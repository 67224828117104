<template>
  <div id="my-privilege">
    <BannerProfile :user="this.user"></BannerProfile>
    <div class="container-profil">
      <Menuprofil :user="this.user"></Menuprofil>
      <div class="row row-info-privilege">
        <div class="col-lg-4 col-xs-9 col-header">
          <h2 class="merriweather-reg-26">Mon</h2>
          <h1 class="merriweather-reg-40">Programme de Fidélité Hortensia</h1>
          <p class="raleway-reg-p15 mt-2">Profitez d’avantages exclusifs et de privilèges à chaque nouveau statut de fidélité atteint : surclassement, accès au spa, repas gastronomique, remises…</p>
          <a href="/programme-fidélité-hortensia/" class="hortense-btn-primary mt-2">En savoir + sur le programme</a>
        </div>

        <div class="col-lg-7 col-xs-12 col-cards">
          <CardStatut :user="this.user"></CardStatut>
          <CardGreenNight :user="this.user"></CardGreenNight>
          <CardPromoCode :user="this.user"></CardPromoCode>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import BannerProfile from 'components/shared/BannerProfile.vue';
  import Menuprofil from 'components/shared/Menuprofil.vue';
  import CardStatut from 'pages/dashboard/CardStatut.vue';
  import CardGreenNight from 'pages/dashboard/CardGreenNight.vue';
  import CardAdvantage from 'pages/dashboard/CardAdvantage.vue';
  import CardPromoCode from 'pages/dashboard/CardPromoCode.vue';
  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'my-privilege',
    components: { BannerProfile, Menuprofil, CardStatut, CardGreenNight, CardAdvantage, CardPromoCode },
    props: ["user"],
    data() {
      return {
        wishList: {
          lodgings: []
        },
      }
    },
    mounted() {
      this.fetchWishlist();
    },
    methods: {
      async toggleLike(id) {
        try {
          const response = await fetcher.patch('/wishlists', { lodging_id: id });
          this.wishList = response.data;
        } catch(e) {
          console.error(e);
        }
      },
      async fetchWishlist() {
        try {
          const response = await fetcher.get('/wishlists.json')
          this.wishList = response.data;
        } catch(e) {
          console.error(e)
        }
      }
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>

</style>
