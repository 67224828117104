<template>
  <div id="reset">
    <div class="container-reset">
      <div class="reset-header">
        <h2 class="merriweather-reg-26">Réinitialiser votre mot de passe</h2>
      </div>
      <div class="modal-content-form">
        <div class="form-item">
          <label for="password">Nouveau mot de passe* <br/>12 caractères minimum</label>
          <input v-model="password" type="password">
        </div>
        <div class="form-item">
          <label for="password_confirmation">Confirmation du mot de passe*</label>
          <input v-model="passwordConfirmation" type="password">
        </div>
      </div>
      <p class="reset-alert">{{this.text}}</p>

      <button  @click="sendReset" class="hortense-btn-primary">Enregistrer le nouveau mot de passe</button>

    </div>
  </div>
</template>

<script>
import fetcher from '../../packs/shared/api.js';

export default {
  name: "reset-password",
  props:{
    token: String
  },
  data() {
    return {
      password: '',
      passwordConfirmation: '',
      text: " "
    }
  },
  methods: {
    async sendReset() {
      try {
        if(this.password.length < 12) {
          this.text = 'Le mot de passe doit contenir 12 caractères minimum '
          return
        }
        if(this.password !== this.passwordConfirmation){
          this.text = 'Le mot de passe saisi doit être identique'
          return
        }
        const response = await fetcher.post(`/reset-password`, {
          'password': this.password,
          'password_confirmation': this.passwordConfirmation,
          'token': this.token
        })
        if(response.status === 404){
          this.text = "Le token a expiré"
        }
        if(response.status === 200){
          window.location.href = "/"
        }
        console.log(response)
      } catch(e) {
        console.log(e)
      }
    }
  }
}
</script>

<style scoped>
  #reset {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }
  .container-reset {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 20vh;
  }
  .reset-header{
    text-align: center;
  }
  .modal-content-form {
    padding: 2rem 7rem;
    margin-right: auto;
    margin-left: auto;
  }
  .form-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  .hortense-btn-primary {
    margin: 2rem 7rem;
  }
  .reset-alert{
    text-align: center;
    color:red;
  }

  .form-item input{
    width: 350px;
    height: 60px;
    padding: 2px 23px;
  }
</style>