<template>
  <div id="greenNote">
    <div class="container-index phone">
       <h1 class="merriweather-reg-40 text-center">La Green Note</h1>
      <div class="row row-intro">
        <div class="col-lg-6 col-xs-8 col-sm-8 col-img">
           <img src="../../images/greenFull.svg" alt="">
        </div>
        <div class="col-lg-6 col-xs-10 col-sm-10">
          <h3 class="raleway-reg-p24 mb-5">L’hôtellerie responsable, un enjeu sectoriel</h3>
          <p class="merriweather-italic24 mt-2">“L’ensemble des acteurs du secteur de l’hôtellerie doivent agir pour générer un véritable impact positif. Nous souhaitons valoriser les hébergements respectueux de l’environnement et les accompagner dans la mise en place d’une démarche environnementale.”</p>
          <p class="raleway-reg-p15 mt-5">Aurore Sterling, Fondatrice d’Hortense</p>
        </div>
      </div>
      <div class="whatis">
        <div class="col-lg-5 col-xs-7 col-sm-7 offset-lg-1 p-0">
          <h3 class="raleway-reg-p24">La Green Note : qu’est-ce que c’est ?</h3>
        </div>
        <div class="row row-whatis">
          <div class="col-lg-5 col-xs-11 col-sm-11 offset-lg-1">
            <p class="raleway-reg-p15 mb-2">Un outil de scoring environnemental développé par <br>Hortense permettant de mesurer le niveau d’engagement <br>éco-responsable des hébergements.</p>
            <p class="raleway-reg-p15 mt-3">Un outil basé sur un questionnaire reprenant 150 <br>indicateurs d’éco-responsabilité répartis en 10 critères.</p>
          </div>
          <div class="col-lg-5 col-xs-11 col-sm-11">
            <p class="raleway-reg-p15 mb-2">Un outil développé pour permettre aux voyageurs d’avoir <br>une visibilité plus claire de la définition d’un hébergement <br>éco-responsable.</p>
            <p class="raleway-reg-p15 mt-3">Un outil qui attribue un score sur 10 sur chaque critère <br>puis une note globale allant de 1 à 10.</p>
          </div>
        </div>
      </div>
    </div>
  <div class="img-full"></div>
  <div class="container-inside phone">

    <div class="col-lg-5 col-xs-7 col-sm-7 offset-lg-2 mb-4">
      <h3 class="raleway-reg-p24 mb-5">Les 10 critères de la Green Note</h3>
      <p class="raleway-reg-p15">Les principaux objectifs par critère sont présentés ci-dessous :</p>
    </div>
    <div class="row row-standard">

        <div class="col-lg-6 col-xs-8 col-sm-8 standard-1">
          <!-- <img src="../../images/note-01.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">La politique et la gestion environnementale</h3>
            <ul>
              <li>Mettre en place une politique environnementale claire et écrite</li>
              <li>Identifier les zones de gaspillage </li>
              <li>Identifier les axes d’amélioration</li>
              <li>Définir des plans d’actions à court, moyen et long terme</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-5 col-xs-8 col-sm-8 standard-2">
          <!-- <img src="../../images/note-02.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">La responsabilité sociale</h3>
            <ul>
              <li>Instaurer un dialogue social</li>
              <li>Établir des solutions d’amélioration des conditions de travail</li>
              <li>Évaluer son empreinte carbone</li>
              <li>Mettre en place des mesures correctives</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-7 col-xs-8 col-sm-8 offset-lg-2 standard-3">
          <!-- <img src="../../images/note-03.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">La formation du personnel</h3>
            <ul>
              <li>Nommer un responsable de la gestion environnementale</li>
              <li>Fédérer les équipes autour du développement durable</li>
              <li>Former les salariés aux éco-gestes et aux bonnes pratiques de gestion durable </li>
              <li>Organiser des réunions sur les questions environnementales</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-xs-8 col-sm-8 offset-lg-1 standard-4">
          <!-- <img src="../../images/note-04.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">La gestion de l’énergie</h3>
            <ul>
              <li>Mettre en place un suivi de la consommation </li>
              <li>Mettre en place une isolation thermique efficace</li>
              <li>Diminuer la consommation d’énergie par nuitée</li>
              <li>Augmenter la part des énergies renouvelables</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-5 col-xs-8 col-sm-8 offset-lg-1 standard-5">
          <!-- <img src="../../images/note-05.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">La sensibilisation des clients</h3>
            <ul>
              <li>Information des clients sur la politique environnementale de l’hébergement </li>
              <li>Proposer des activités respectueuses de l’environnement</li>
              <li>Afficher les éco-gestes</li>
              <li>Informer les clients sur les modes de transport les moins polluants pour leurs déplacements</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-xs-8 col-sm-8 offset-lg-2 standard-6">
          <!-- <img src="../../images/note-06.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">La gestion des déchets</h3>
            <ul>
              <li>Tri et recyclage des déchets</li>
              <li>Réduction des volumes de déchets à la source</li>
              <li>Réutilisation des déchets produits et recherche d’utilisations alternatives</li>
              <li>Offrir les surplus alimentaires à des organismes de charité</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-4 col-xs-8 col-sm-8 offset-lg-2 standard-7">
          <!-- <img src="../../images/note-07.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">La gestion de l’eau</h3>
            <ul>
              <li>Mettre en place un suivi de la consommation</li>
              <li>Réduire la consommation d’eau par nuitée</li>
              <li>Collecter et traiter les eaux usées</li>
              <li>Réduire la pollution</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-5 col-xs-8 col-sm-8 offset-lg-6 standard-8">
          <!-- <img src="../../images/note-08.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">La politique d’achats responsables</h3>
            <ul>
              <li>Acheter des produits (alimentation, entretien, papeterie, mobilier, autres achats) en circuit court</li>
              <li>Privilégier les produits biologiques/durables et de saison</li>
              <li>Privilégier les emballages recyclables</li>
              <li>Éviter les emballages individuels</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-6 col-xs-8 col-sm-8 offset-lg-1 standard-9">
          <!-- <img src="../../images/note-09.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">L’environnement extérieur et intérieur</h3>
            <ul>
              <li>Aménager des espaces verts</li>
              <li>Entretenir les espaces verts</li>
              <li>Ne pas utiliser de produits chimiques polluants</li>
              <li>Limiter le tabagisme dans les espaces communs et dans les chambres</li>
            </ul>
          </div>
        </div>

        <div class="col-lg-5 col-xs-8 col-sm-8 offset-lg-7 standard-10">
          <!-- <img src="../../images/note-10.svg" alt=""> -->
          <div class="img-note"></div>
          <div class="standard-content">
            <h3 class="raleway-bold-p mb-4">L’éco-construction</h3>
            <ul>
              <li>Favoriser les constructions et les rénovations durables (label HQE)</li>
              <li>Améliorer l’intégration paysagère des hébergements </li>
              <li>Privilégier la plantation d'espèces végétales locales</li>
              <li>Mettre en place des systèmes de production d’énergie renouvelable</li>
            </ul>
          </div>
        </div>

      </div>
    </div>

    <div class="bg-img"></div>

    <div class="container-index phone">

      <div class="col-lg-8 col-xs-10 col-sm-10 offset-lg-1">
        <h2 class="raleway-reg-p24 mb-4">Le niveau d’engagement</h2>
        <p class="raleway-reg-p15">Les critères permettent d’attribuer une note et un niveau d’engagement environnemental associé. <br>Note minimale nécessaire pour intégrer notre sélection : 4/10</p>
      </div>

      <div class="row row-engagement-niv">
        <div class="col-lg-5 col-xs-10 col-sm-10 offset-lg-1">
          <h3 class="raleway-bold-p mb-3">Note de 4 à 5 : Convenable</h3>
          <p class="raleway-semi-p13 passable">Mention assez bien</p>
          <p class="raleway-reg-p15 mb-5">L’hébergement est sur la bonne voie. Il a commencé à mettre en place des actions qui permettent de limiter son impact sur l’environnement. Toutefois, il dispose d’une marge de progression forte.</p>

          <h3 class="raleway-bold-p mb-3">Note > 5 à 7 : Honorable</h3>
          <p class="raleway-semi-p13 abien">Mention bien</p>
          <p class="raleway-reg-p15 mb-5">L’hébergement a un niveau adapté d’engagement environnemental. Sa démarche a un impact sur l’environnement. Toutefois, des actions peuvent encore être mises en place pour réduire son empreinte environnementale.</p>
        </div>

        <div class="col-lg-5 col-xs-10 col-sm-10 niv2">
          <h3 class="raleway-bold-p mb-3 mt-5">Note > 7 à 8 : Remarquable</h3>
          <p class="raleway-semi-p13 bien">Mention très bien</p>
          <p class="raleway-reg-p15">Cet hébergement a un bon niveau d’engagement éco-responsable. La gestion durable de son établissement est au cœur de ses priorités et ses actions portent leurs fruits. Sa démarche a un réel impact sur l’environnement.</p>

          <h3 class="raleway-bold-p mb-3 mt-5">Note > 8 à 10 : Exemplaire</h3>
          <p class="raleway-semi-p13 tbien">Mention Excellent</p>
          <p class="raleway-reg-p15">Cet hébergement a un excellent niveau d’engagement éco-responsable et peut difficilement faire mieux. Son impact environnemental est faible.</p>
        </div>
      </div>

      <div class="row row-objectif">
        <div class="col-lg-5 col-xs-9 col-sm-10 object-content">
          <h3 class="raleway-reg-p24">La Green Note : son objectif</h3>
          <p class="raleway-reg-p15">Évolutive, la Green Note est revue chaque année en fonction des actions mises en place par les hébergements pour améliorer leur bilan carbone. Nous accompagnons les hébergements dans cette démarche grâce à notre outil de définition de plans d’actions et nos conseils avisés.</p>
          <p class="raleway-reg-p15">Grâce à ce système, nous souhaitons :</p>
          <ul>
            <li>Améliorer la performance environnementale et économique du secteur.</li>
            <li>Proposer des outils pragmatiques basés sur des fondamentaux techniques.</li>
            <li>Partager et diffuser les connaissances au plus grand nombre.</li>
          </ul>
          <p class="raleway-reg-p15">Elle permet également de déterminer le montant des commissions pour chaque hébergement. Nous proposons à nos partenaires un taux de commission équitable basé sur des critères objectifs sur lequel ils peuvent agir : plus leur bilan environnemental est positif, plus le taux de commission est bas !</p>
        </div>
        <div class="col-lg-5 col-xs-9 col-sm-10 offset-lg-2 col-img">
        </div>
      </div>


    <!--   <div class="selection-lodging">
        <h3 class="raleway-reg-p24 text-center mb-5">Quelques hébergements de notre sélection</h3>
        <div class="col-8 offset-2">
          <CardProduct class="mb-3"></CardProduct>
          <CardProduct></CardProduct>
        </div>
        <div class="col-lg-4 col-xs-5 offset-lg-4">
          <a href="/lodgings" class="hortense-btn-primary">Découvrir nos hébergements</a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
  import CardProduct from 'components/cards/CardProduct.vue';

  export default{
    name: 'greenNote',
    components: { CardProduct },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/green-note'>

</style>
