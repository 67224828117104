<template>
  <div id="scrollUp">
    <div class="scroll-up" @click="scrollToTop"> Remonter</div>
  </div>
</template>

<script>
  export default{
    name: 'ScrollUp',
    methods: {
      scrollToTop() {
          window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      }
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/card-collection'>

</style>
