<template>
  <div id="wishlist-card-exp">
    <div class="card-product-exp">
      <img v-if="this.experience.photo_key" :src="this.experience.photo_key" class="img-exp" :alt="this.experience.name">
        <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" class="img-exp" :alt="this.experience.name">
      <div class="row card-product-infos-exp">
        <div class="col-lg-6 col-xs-7 basic-infos">
          <p class="raleway-bold-p mb-0">{{this.experience.name}}</p>
          <p class="description-hub raleway-reg-p13 mb-0">{{ this.experience.description}}</p>
          <p class="raleway-bold mb-0">Où ?</p>
          <p class="raleway-reg-p15">{{ this.experience.address }}</p>
        </div>
        <div class="col-lg-6 col-xs-12 col-share">
          <div class="row row-share-exp">
            <Modalshare v-if="showModal" @close="showModal = false" :for="this.experience"></Modalshare>
            <div class="item col-lg-3 col-xs-5" @click="showModal = true">
              <img src="../../images/Share.svg" alt="">
              <p class="raleway-reg-p13">Partager</p>
            </div>
            <div class="item col-lg-3 col-xs-5">
              <img src="../../images/whishlist on.svg" alt="" @click='$emit("toggle")'>
              <p class="raleway-reg-p13">Favori</p>
            </div>
          </div>
          <div class="col-lg-12 col-xs-6 dispo-btn p-0">
            <a :href="'/experiences/'+ this.experience.slug" class="hortense-btn-primary">Voir les hébergements</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';
  import Modalshare from 'components/modals/Modalshare.vue';

  export default {
    name: 'wishlist-card-exp',
    components:{ Modalshare },
    props: ["experience"],
    data() {
      return {
        showModal: false,
        expMount: null,
      }
    },
    mounted() {
      // this.fetchExp();
    },
    methods: {
      async fetchExp() {
        try {
          const response = await fetcher.get('/experiences/'+ this.experience.slug)
          this.expMount = response.data;
        } catch(e) {
          console.error(e)
        }
      },
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>

</style>
