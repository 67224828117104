<template>
  <div id="cardEvergreen">
    <div class="card-ever">
      <img :src="project.photo_key" alt="">
      <div class="sticker-program d-flex mt-4 pl-2">
        <div class="sticker-program-info d-flex">
          <p class="raleway-bold-h3 mb-0 pl-1 pr-2">{{ project.title }}</p>
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2">{{ project.goal }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'CardEvergreen',
    props: [ 'project' ],
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/evergreen-home'>

</style>
