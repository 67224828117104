<template>
  <div id="greenHost">
    <div class="img-full"></div>
    <img src="../../images/Label.svg" class="greenHost-label" alt="">
    <div class="container-index phone">
      <div class="col-lg-7 col-xs-9 offset-xs-1 offset-lg-2">
        <h3 class="merriweather-reg-40 text-center mb-5">Green Host</h3>
        <p class="merriweather-italic24">Dans l’objectif de participer activement au <br>développement du tourisme durable, Hortense a créé <br>Green Host, une marque destinée à mettre en avant l’engagement environnemental des hébergements touristiques.</p>
      </div>
      <div class="row row-green-intro">
        <div class="col-lg-6 col-xs-9 col-content">
          <div class="intro-content">
            <p class="raleway-reg-p15 mb-5">Green Host met en avant les hébergements de charme qui répondent à des critères de respect de l’environnement.</p>
            <p class="raleway-bold-p">La marque Green Host garantit aux voyageurs :</p>
          </div>
          <div class="act-1 col-lg-9 col-xs-12">
            <h1 class="merriweather-italic67">1</h1>
            <p class="raleway-reg-p15">Des hébergements engagés dans une démarche éco-responsable.</p>
          </div>
          <div class="act-2 col-lg-10 offset-lg-2 col-xs-12">
            <h1 class="merriweather-italic67">2</h1>
            <p class="raleway-reg-p15">Des hébergements haut de gamme où bien être, confort et service sont les mots d’ordre.</p>
          </div>
        </div>
        <div class="col-lg-6 col-xs-12 col-img">
          <img src="../../images/Green Host - 2.jpeg" alt="green host">
        </div>
      </div>

      <div class="row row-card">
        <div class="col-lg-5 col-xs-8 card-obj">
          <p class="raleway-reg-p24 mb-5">Notre objectif</p>
          <p class="raleway-reg-p15">Rassembler et fédérer nos hébergements partenaires autour de valeurs communes et d’enjeux de développement durable. <br><br>Permettre aux voyageurs d’identifier les hébergements éco-responsables et d’avoir une visibilité sur le niveau d’engagement de chaque hébergement.</p>
        </div>
        <div class="col-lg-5 col-xs-8 card-amb">
          <p class="raleway-reg-p24 mb-5">Notre ambition</p>
          <p class="raleway-reg-p15">Créer une communauté d’hébergements engagés dans le tourisme durable. <br><br>Devenir le label de confiance du tourisme responsable et haut-de-gamme.</p>
        </div>
      </div>

      <div class="become">
        <p class="raleway-reg-p24 become-header">Comment devenir un hébergement « Green Host » ?</p>
        <p class="raleway-bold-p mb-5">Engagement et durabilité</p>
        <div class="row row-become">
          <div class="col-lg-4 col-sm-4 col-xs-8 first">
            <div class="becom-item">
              <img src="../../images/G-01.svg" alt="La politique environnementale">
              <p class="raleway-reg-p15">La politique environnementale</p>
            </div>
            <div class="becom-item">
              <img src="../../images/G-02.svg" alt="La responsabilité sociale">
              <p class="raleway-reg-p15">La responsabilité sociale</p>
            </div>
            <div class="becom-item">
              <img src="../../images/G-03.svg" alt="La formation du personnel" style="width: 59px;height: 49px;">
              <p class="raleway-reg-p15">La formation du personnel</p>
            </div>
            <div class="becom-item">
              <img src="../../images/G-04.svg" alt="La sensibilisation des clients">
              <p class="raleway-reg-p15">La sensibilisation des clients</p>
            </div>
            <div class="becom-item">
              <img src="../../images/G-08.svg" alt="La gestion de l’énergie" style="width: 64px;height: 62px;">
              <p class="raleway-reg-p15">La gestion de l’énergie</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-4 col-xs-8">
            <div class="becom-item">
              <img src="../../images/G-06.svg" alt="La gestion de l’eau">
              <p class="raleway-reg-p15">La gestion de l’eau</p>
            </div>
            <div class="becom-item">
              <img src="../../images/G-07.svg" alt="La gestion des déchets">
              <p class="raleway-reg-p15">La gestion des déchets</p>
            </div>
            <div class="becom-item">
              <img src="../../images/G-05.svg" alt="La politique d’achats responsables">
              <p class="raleway-reg-p15">La politique d’achats responsables</p>
            </div>
            <div class="becom-item">
              <img src="../../images/G-09.svg" alt="L’environnement extérieur et intérieur">
              <p class="raleway-reg-p15">L’environnement extérieur et intérieur</p>
            </div>
            <div class="becom-item">
              <img src="../../images/G-10.svg" alt="L'éco-construction">
              <p class="raleway-reg-p15">L'éco-construction</p>
            </div>
          </div>
          <div class="col-lg-4 col-xs-8">
            <p class="raleway-reg-p15">Ces critères sont déclinés en 150 indicateurs de conformité permettant d’attribuer une note environnementale pour chaque critère et une note globale : la Green Note. <br><br><br>La Green Note est un outil de scoring environnemental développé par Hortense. Ce score permet de qualifier le niveau d’engagement environnemental des hébergements et sert de référence dans la sélection des hébergements éligibles au label Green Host.</p>
            <div class="note-min">
              <div class="head">
                <img src="../../images/info-blue.svg" alt="">
                <p class="raleway-bold-p">4/10</p>
              </div>
              <p class="raleway-reg-p15">La note minimale pour intégrer la plateforme et exploiter l’usage du label Green Host.</p>
            </div>

          </div>
        </div>
        <div class="col-3">
          <a href="/green-note-engagement-environnemental/" class="hortense-btn-snd">En savoir plus</a>
        </div>
      </div>

      <div class="excellence">
        <div class="excellence-header">
          <p class="raleway-bold-p mb-5">Excellence et durabilité</p>
          <p class="raleway-reg-p15">Green Host est une distinction pour les hébergements de charme <br>engagés. Green Host met à l’honneur les hébergements qui :</p>
          <div class="row row-excellence">
            <div class="col-lg-3 col-xs-6 col-note">
              <div class="note-item">
                <h3 class="merriweather-italic30">1</h3>
                <p class="raleway-reg-p15">S’inscrivent dans une démarche destinée à limiter leur impact environnemental</p>
              </div>
              <div class="note-item">
                <h3 class="merriweather-italic30">2</h3>
                <p class="raleway-reg-p15">Cultivent l’art de vivre</p>
              </div>
              <div class="note-item">
                <h3 class="merriweather-italic30">3</h3>
                <p class="raleway-reg-p15">Proposent un cadre élégant et chaleureux</p>
              </div>
            </div>
            <div class="col-lg-3 col-xs-6 col-note">
              <div class="note-item">
                <h3 class="merriweather-italic30">4</h3>
                <p class="raleway-reg-p15">Disposent de chambres, suites ou lodges qui marient à la perfection élégance et modernité</p>
              </div>
              <div class="note-item">
                <h3 class="merriweather-italic30">5</h3>
                <p class="raleway-reg-p15">Sont situés dans des cadres d’exception</p>
              </div>
            </div>
             <div class="col-lg-5 col-xs-9 offset-lg-1 offset-sm-2">
              <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/green-host-nature.jpeg" class="green-host-nature" alt="green-host-nature">
            </div>
          </div>
        </div>
      </div>
      <SelectionHost></SelectionHost>
    </div>
  </div>
</template>


<script>

  import SelectionHost from 'components/cards/SelectionHost.vue'
  export default {
    name: 'greenHost',
    components:{ SelectionHost }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/green-host'>

</style>
