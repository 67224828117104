<template>
 <div id="lodging-show">
   <div
       id="banner-lodging"
       :style="{backgroundImage: 'url('+ yoga.photo_second_banner +')'}"
   >
     <ModalYoga
         :lodging="this.yoga"
         :photoKeys="this.yoga.photos_keys"
         v-if="modalGallery"
         @close="modalGallery = false"
     ></ModalYoga>
     <div class="btn-galerie mb-3">
       <div class="galerie-icon df-jsb-aic">
         <img src="../../images/Galerie icone.svg" alt="galerie" />
         <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
       </div>
     </div>
     <div class="banner-show-container">
       <div class="col-9 offset-3 catch-hub-show">
         <p class="merriweather-italic30">
           {{ this.yoga.hub_description }}
         </p>
       </div>
     </div>
     <div class="btn-galerie-phone mb-3">
       <div class="galerie-icon df-jsb-aic">
         <img src="../../images/Galerie icone.svg" alt="galerie" />
         <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
       </div>
     </div>
   </div>
  <div class="nav-lodging" style="height: 50px !important;">
    <div class="lodging-header">
      <div class="header">
        <h1 v-if="this.stay_name" class="lodging-show-raleway-reg-p26-b text-center">{{ this.stay_name }}</h1>
      </div>
    </div>
<!--    <div id="headDestiii">-->
<!--    <div class="list-desti-btns mt-5">-->
<!--      <ul class="bar">-->
<!--        <li  v-if="!isPhone" v-for="(schedule, i) in this.yoga_schedules" :key="schedule.id" class="menu-item raleway-reg-p16">-->
<!--          <button :id="'button_' + schedule.id" class="dateBUtton"  v-if="i==0" style="font: normal normal 18px/36px Raleway; font-weight: bold !important;" @click="fetchDestinations(schedule.id)">{{schedule.club_date}}</button>-->
<!--          <button :id="'button_' + schedule.id" class="dateBUtton" style="font: normal normal 18px/36px Raleway;" v-if="i!=0" @click="fetchDestinations(schedule.id)">{{schedule.club_date}}</button>-->
<!--        </li>-->
<!--        <li>-->
<!--          <a-->
<!--              @click="goToContact()"-->
<!--             class="hortense-btn-primary df-jsb-aic"-->
<!--          >CONTACTEZ NOUS</a-->
<!--          >-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
<!--  </div>-->
  </div>
<!--   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">-->
<!--     <p style="color: #0a0a0a !important;" v-for="(schedule, i) in this.yoga_schedules" :key="schedule.id" class="raleway-reg-p16 text-center">-->
<!--       <button :id="'button_' + schedule.id" v-if="i==0" style=" font: normal normal  18px/36px Raleway; font-weight: bold !important; color: #0a0a0a !important;" class="dateBUtton" @click="fetchDestinations(schedule.id)">{{schedule.club_date}}</button>-->
<!--       <button :id="'button_' + schedule.id" v-if="i!=0" style="font: normal normal  18px/36px Raleway; color: #0a0a0a !important;" class="dateBUtton" @click="fetchDestinations(schedule.id)">{{schedule.club_date}}</button>-->
<!--     </p>-->
<!--   </div>-->
   <div  class="nav-lodging" style="margin-top: 0 !important; background-color: #F7F7F7">
       <div style="text-align: center; background-color: #F7F7F7" >
<!--           <p class="mab"  style="font:  normal normal normal 24px/42px Raleway;">{{yoga.accommodation}}</p>-->
         <p class="mab2" style="font:  normal normal bold 24px/42px Raleway;" v-if='yoga.yoga_place'>{{yoga.yoga_place.title}}</p>
         <p class="mab" style="font: normal normal normal 18px/36px Raleway;">{{yoga.location_detail}}</p>
         <p v-if="this.yoga && !is_urban" class="mab " style="font: normal normal 600 18px/36px Raleway;">{{ this.yoga.number_of_days }} jours / {{ this.yoga.number_of_nights }} nuits</p>
         <p v-if="this.yoga && is_urban" class="mab " style="font: normal normal 600 18px/36px Raleway;">One Day Retreat</p>
         <p v-if="this.current_date" class="mab" style="font:  italic normal normal 18px/36px Raleway;">{{this.current_date}}</p>
           <div>
             <img style="height: 90px; width: 90px" src="../../images/icone-club-hortense.svg" alt="" />
           </div>
         <p v-if="this.yoga" class="mab" style="font: italic normal normal 18px/36px Raleway;">{{this.yoga.yoga_type}}</p>
<!--         <div v-if="this.yoga" style="display: flex; align-items: center; justify-content: center;">-->
<!--           <p  class="mab2 " style="font: normal normal normal 18px/36px Raleway;">Niveau : </p><p style="font: italic normal 600 18px/36px Raleway"> {{ this.yoga.level }}</p>-->
<!--         </div>-->
           <p v-if="this.yoga" class="mab2 " style="font:  normal normal bold 18px/36px Raleway;">À partir de {{ this.yoga.lowest_rate }} €</p>
       </div>
   </div>
   <YogaPlace :yoga_place="yoga_place" v-if="!isPhone" :yoga_services="yoga_services"></YogaPlace>
   <div v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3" style="margin-top: 2px !important;">
     <div
         class="accordion-header"
         @click.prevent="isExpand2 = !isExpand2"
         :class="this.isExpand2 ? 'active' : ''"
     >
       <p class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">LE LIEU</p>
     </div>
     <div class="accordion-body" :class="this.isExpand2 ? 'active' : ''">
       <YogaPlacePhone :yoga_place="yoga_place" v-if="isPhone" :yoga_services="yoga_services"></YogaPlacePhone>
     </div>
   </div>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <div
         class="accordion-header"
         @click.prevent="isExpand3 = !isExpand3"
         :class="this.isExpand3 ? 'active' : ''"
     >
       <p v-if="!is_urban" class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">Informations sur le séjour</p>
       <p v-if="is_urban" class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">L'expérience Urban Retreat</p>
     </div>
     <div class="accordion-body" :class="this.isExpand3 ? 'active' : ''">
       <yogaStayPhone :yoga_stay="yoga_stay"></yogaStayPhone>
     </div>
   </div>
   <YogaStay v-if="!isPhone" :yoga_stay="yoga_stay" :is_urban="is_urban"></YogaStay>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <div
         class="accordion-header"
         @click.prevent="isExpand4 = !isExpand4"
         :class="this.isExpand4 ? 'active' : ''"
     >
       <p v-if="!is_urban" class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">LE PROGRAMME DU SÉJOUR</p>
       <p v-if="is_urban" class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">Le programme de la journée</p>
     </div>
     <div class="accordion-body" :class="this.isExpand4 ? 'active' : ''">
       <yogaProgramPhone v-if=" this.yoga_schedule.yoga_programs" :yoga_program=" this.yoga_schedule.yoga_programs" :yoga_schedule="this.yoga_schedule"></yogaProgramPhone>
     </div>
   </div>
   <YogaProgram v-if="!isPhone && this.yoga_schedule.yoga_programs" :is_urban="is_urban" :yoga_program="this.yoga_schedule.yoga_programs" :yoga_schedule="this.yoga_schedule"></YogaProgram>

   <HotelRoom :club_rooms="this.yoga.club_rooms" :club="this.yoga"></HotelRoom>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <div
         class="accordion-header"
         @click.prevent="isExpand6 = !isExpand6"
         :class="this.isExpand6 ? 'active' : ''"
     >
       <p class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">LA LOCALISATION</p>
     </div>
     <div class="accordion-body" :class="this.isExpand6 ? 'active' : ''">
       <div class="pb-4" style="font: normal normal normal 15px/18px Raleway;" v-html="yoga.location_description"></div>
       <YogaMap :markers="this.markers"></YogaMap>
     </div>
   </div>
   <div v-if="!isPhone" class="container  justify-content-md-center mt-3">
     <h3 style="font: normal normal normal 26px/33px Merriweather;">La localisation</h3>
     <div class="pb-4" style="font: normal normal normal 15px/18px Raleway;" v-html="yoga.location_description"></div>
   </div>
   <YogaMap v-if="!isPhone" :markers="this.markers"></YogaMap>
   <ClubCat :club_activities="this.yoga.club_activities"></ClubCat>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <div
         class="accordion-header"
         @click.prevent="isExpand7 = !isExpand7"
         :class="this.isExpand7 ? 'active' : ''"
     >
       <p class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">Votre demande de réservation</p>
     </div>
     <div class="accordion-body" :class="this.isExpand7 ? 'active' : ''">
       <YogaPayment :yoga_schedules="this.yoga.club_schedules" :yoga_id="yoga.id" :yoga_rate="yoga_rate"></YogaPayment>
     </div>
   </div>
   <YogaPayment :yoga_schedules="this.yoga.club_schedules" :yoga_id="yoga.id" v-if="!isPhone" :yoga_rate="yoga_rate"></YogaPayment>
   <div class="container">
   <div class="row greennote-lodging mt-3" data-aos="fade-up" data-aos-duration="900">
     <div class="col-lg-7 col-xs-12">
       <p class="raleway-reg-p24">La Green Note</p>
       <div class="first d-flex">
         <img
             :src="greenNotePicto(this.yoga.score.toFixed())"
             :alt="this.yoga.score.toFixed()"
             v-if="this.yoga.score"
         />
         <div class="content">
           <div class="note d-flex" v-if="this.yoga.score">
             <p class="raleway-reg-p15 mb-0">{{ this.yoga.score.toFixed() }}/</p>
             <p class="raleway-reg-p13 mb-0 mr-2">10</p>
             <p class="raleway-bold-p16 mb-0">
               {{ this.yoga.green_note_mention }}
             </p>
           </div>
           <a :href="'/green-note-engagement-environnemental/'" class="link-hortense"
           >Comprendre la Green Note</a
           >
         </div>
       </div>
       <div class="row row-slidenote">
         <div class="col-lg-5 col-xs-8 col-md-8">
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Politique et gestion <br />environnementale</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.pol() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note1">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="pol"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#65B193' }"
               />
             </div>
           </div>
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Responsabilité sociale</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.soc() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note2">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="soc"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#386F5A' }"
               />
             </div>
           </div>
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Formation du personnel</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.per() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note3">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="per"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#65B193' }"
               />
             </div>
           </div>
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Sensibilisation des clients</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.cli() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note4">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="cli"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#4E8E75' }"
               />
             </div>
           </div>
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Politique d’achats responsables</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.ach() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note5">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="ach"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#E5E5B7' }"
               />
             </div>
           </div>
         </div>
         <div class="col-lg-5 col-xs-8 col-md-8 mt-3">
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Gestion de l’eau</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.eau() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note6">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="eau"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#386F5A' }"
               />
             </div>
           </div>
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Gestion des déchets</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.dec() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note7">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="dec"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#A9D997' }"
               />
             </div>
           </div>
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Gestion de l’énergie</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.ene() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note8">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="ene"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#7DCEAF' }"
               />
             </div>
           </div>
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Eco-construction</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.con() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note9">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="con"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#358867' }"
               />
             </div>
           </div>
           <div class="full-note">
             <div class="title-note d-flex">
               <p class="raleway-reg-p13">Environnement extérieur et intérieur</p>
               <div class="note d-flex">
                 <p class="raleway-reg-p15 mb-0">{{ this.env() }}/</p>
                 <p class="raleway-reg-p13 mb-0 mr-2">10</p>
               </div>
             </div>
             <div class="slide-note10">
               <vue-slider
                   class="raleway-reg-p15"
                   v-model="env"
                   v-bind="options"
                   ref="slider"
                   disabled
                   :processStyle="{ backgroundColor: '#8ED8A2' }"
               />
             </div>
           </div>
         </div>
       </div>
     </div>
     <div class="col-lg-5 col-xs-10 col-planet">
       <img src="../../images/illus green note.png" />
       <div class="promise" v-if="this.yoga.environmental_promise">
         <p class="raleway-reg-p24 mb-3">Leur promesse environnementale</p>
         <p class="raleway-reg-p15 mb-3">
           «
           {{ this.yoga.environmental_promise }} »<br />
         </p>
<!--         <a @click="showModalPromise = !showModalPromise" class="link-hortense"-->
<!--         >En savoir plus sur leur promesse environnementale</a-->
<!--         >-->
       </div>
     </div>
   </div>
   </div>
   <div
       id="experiences-lodging"
       class="experiences-lodging"
       data-aos="fade-up"
       data-aos-duration="900"
   >
     <div class="container-lodging container phone">
       <div class="row d-flex justify-content-around">
         <div class="col-lg-3 col-md-10">
           <div class="lodging-header-desktop">
             <p class="raleway-reg-p24">
               Expériences authentiques, à proximité de cet établissement
             </p>
             <p class="raleway-reg-p15"></p>
             <div class="show-cart-btn">
               <img
                   src="../../images/Localisation.svg"
                   alt="Localisation"
                   width="15"
                   height="15"
               />
               <a class="link-hortense" @click="displayMapfindtwo">Voir sur la carte</a>
             </div>
           </div>
           <div class="exp-filter">
             <ul class="filter-exp">
               <li>
                 <a class="exp-category active" href="#" @click.prevent="selectOption"
                 ><p class="raleway-reg-p16" data-value="culturelles">culturelles</p>
                   <img src="../../images/flechedroite.png" alt="flèche droite"
                   /></a>
               </li>
               <li>
                 <a class="exp-category" href="#" @click.prevent="selectOption"
                 ><p class="raleway-reg-p16" data-value="au naturel">au naturel</p>
                   <img src="../../images/flechedroite.png" alt="flèche droite"
                   /></a>
               </li>
               <li>
                 <a class="exp-category" href="#" @click.prevent="selectOption"
                 ><p class="raleway-reg-p16" data-value="au bord de l'eau">au bord de l'eau</p>
                   <img src="../../images/flechedroite.png" alt="flèche droite"
                   /></a>
               </li>
               <li>
                 <a class="exp-category" href="#" @click.prevent="selectOption"
                 ><p class="raleway-reg-p16" data-value="sportives">sportives</p>
                   <img src="../../images/flechedroite.png" alt="flèche droite"
                   /></a>
               </li>
               <li>
                 <a class="exp-category" href="#" @click.prevent="selectOption"
                 ><p class="raleway-reg-p16" data-value="immersives">immersives</p>
                   <img src="../../images/flechedroite.png" alt="flèche droite"
                   /></a>
               </li>
               <li>
                 <a class="exp-category" href="#" @click.prevent="selectOption"
                 ><p class="raleway-reg-p16" data-value="culinaires">culinaires</p>
                   <img src="../../images/flechedroite.png" alt="flèche droite"
                   /></a>
               </li>
               <li>
                 <a class="exp-category" href="#" @click.prevent="selectOption"
                 ><p class="raleway-reg-p16" data-value="diy">DIY</p>
                   <img src="../../images/flechedroite.png" alt="flèche droite"
                   /></a>
               </li>
               <li>
                 <a class="exp-category" href="#" @click.prevent="selectOption"
                 ><p class="raleway-reg-p16" data-value="oenologiques">oenologiques</p>
                   <img src="../../images/flechedroite.png" alt="flèche droite"
                   /></a>
               </li>
             </ul>
           </div>
         </div>
         <div class="col-lg-8 col-md-10 pt-2" v-if="experiencesFiltered.length > 0">
           <slither-slider
               ref="slither"
               :options="{ numberOfSlides: 1, dots: false, controls: true }"
           >
             <div v-for="experience in experiencesFiltered" :key="experience.id">
               <Cardexplodging
                   :experience="experience"
                   :user="isUserConnected()"
                   @connection="$emit('connection')"
               ></Cardexplodging>
               <!-- <div v-if='!selectedMarker'>
                 <Cardexplodging :experience="experience" :user="isUserConnected()" @connection="$emit('connection')"></Cardexplodging>
               </div>
               <div v-else>
                 <Cardexplodging :experience="experiencesFiltered[experienceselected]" :user="isUserConnected()" @connection="$emit('connection')"></Cardexplodging>
               </div> -->
             </div>
             <template slot="previous">
               <div class="previous">
                 <img src="../../images/fleche gauche.svg" alt="fleche gauche" />
               </div>
             </template>
             <template slot="next">
               <img src="../../images/fleche droite.svg" alt="fleche droite" />
             </template>
           </slither-slider>
         </div>
         <div class="col-8 pt-5" v-else>
           <p class="raleway-reg-p15">
             Hortense est à la recherche d'expériences à vous proposer près de cet hébergement
           </p>
         </div>
       </div>
     </div>
   </div>
 </div>

</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

import HeadYoga from "./HeadYoga.vue";
import ScrollUp from "../../components/btns/ScrollUp.vue";
import fetcher from "packs/shared/api.js";
import Searchbtn from "../../components/btns/Searchbtn.vue";
import Searchbanner from "../../components/btns/Searchbanner.vue";
import YogaCard from "./YogaCard.vue";
import YogaStay from "./YogaStay.vue";
import ModalGallery from "../../components/modals/ModalGallery.vue";
import Modalshare from "../../components/modals/Modalshare.vue";
import YogaProgram from "./YogaProgram.vue";
import yogaStay from "./YogaStay.vue";
import YogaPlace from "./YogaPlace.vue";
import YogaPayment from "./YogaPayment.vue";
import YogaMap from "../../components/YogaMap.vue";
import YogaPlacePhone from "./YogaPlacePhone.vue";
import yogaStayPhone from "./YogaStayPhone.vue";
import yogaProgramPhone from "./YogaProgramPhone.vue";
import ModalYoga from "../../components/modals/ModalYoga.vue";
import ClubCat from "./ClubCat.vue";
import HotelRoom from "./HotelRoom.vue";
import VueSlider from "vue-slider-component";
import AOS from "aos";
import Cardexplodging from "../../components/cards/Cardexplodging.vue";
export default {
  name: "Clubshow",
  components: {
    Cardexplodging,
    HotelRoom,
    YogaMap, VueSlider,
    Modalshare, ModalGallery, HeadYoga, ScrollUp, Searchbtn, Searchbanner, YogaCard, YogaStay, YogaProgram, yogaStay, YogaPlace, YogaPayment, YogaPlacePhone, yogaStayPhone, yogaProgramPhone, ModalYoga, ClubCat},
  props: {
    yoga: {
      type: Object,
      required: true
    },
    user: Object
  },
  data() {
    return {
      stay_name: this.yoga.stay_name,
      modalGallery: false,
      isExpand: false,
      isExpand2: true,
      showModalPromise: false,
      isExpand3: false,
      isExpand5: false,
      isExpand6: false,
      showBanner: false,
      isExpand4: false,
      experiencesFiltered: [],
      SelectedOption: "culturelles",
      options: {
        min: 0,
        max: 10,
        dotSize: 60,
        fixed: true,
        clickable: false,
        tooltip: "none",
      },
      isExpand7: true,
      selectedDestination: "France",
      destinations: null,
      isLoading: true,
      yoga_schedules: this.yoga.club_schedules,
      yoga_schedule: this.yoga,
      yoga_place: this.yoga.club_place,
      yoga_rate: this.yoga.club_schedules[0],
      yoga_stay: this.yoga,
      yoga_services: this.yoga.club_services,
      current_date: this.yoga.club_schedules[0].club_date,
      markers: [],
      markersTwo: [],
      fileNames: {
        "Caraïbes": "Caraibes",
        "Polynésie Française": "Polynesias",
        "Afrique": "Africas",
        "Océan Indien": "Indians",
        "France": "Francecard",
        "Europe": "Europe",
        "Americas": "Americas",
        "Asia": "Asia"
      },
      is_urban: this.yoga.club_city.includes('Urban Retreat')
    };
  },
  methods: {
    greenNotePicto(score) {
      if (score === `${score}`) {
        return require(`../../images/greennote ${score}.svg`);
      } else {
        return require("../../images/blanc.png");
      }
    },
    showModalGallery() {
      this.modalGallery = !this.modalGallery;
    },
    goToContact(){
      window.open("/pages/contact", "_blank");
    },

    formatDate(date){
      const set = new Date(date)
      const options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
      return set.toLocaleString('default', options)
    },
    isUserConnected() {
      return this.user ? this.user : {};
    },
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    async fetchExps(cat) {
      try {
        const response = await fetcher.get(
            `/categories/${encodeURI(cat)}/experiences/list?lodging_id=254`
        );
        this.experiencesFiltered = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    isSelectDestination(name) {
      return this.selectedDestination === name && this.destinations;
    },
    selectDestination(destination){
      this.$emit("destinationSelector", destination);
    },
    async fetchDestinations(id) {
      $(".dateBUtton").css("font-weight", "normal")
      event.currentTarget.style.fontWeight = "bold"
      try {
        const response = await fetcher.get(
            `/clubs_details?schedule=${id}`
        );
        this.yoga_schedule = response.data;
        this.$set(this.yoga_schedule, 'yoga_programs', response.data.programs);
        this.yoga_programs = response.data.programs;
        this.yoga_rate = response.data;
        this.current_date = response.data.club_date;
        this.yoga_stay = response.data;
        // this.stay_name = response.data.stay_name;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
    pol() {
      return this.yoga.average_score_for_POL;
    },
    soc() {
      return this.yoga.average_score_for_SOC;
    },
    per() {
      return this.yoga.average_score_for_PER;
    },
    cli() {
      return this.yoga.average_score_for_CLI;
    },
    ach() {
      return this.yoga.average_score_for_ACH;
    },
    eau() {
      return this.yoga.average_score_for_EAU;
    },
    dec() {
      return this.yoga.average_score_for_DEC;
    },
    ene() {
      return this.yoga.average_score_for_ENE;
    },
    con() {
      return this.yoga.average_score_for_CON;
    },
    env() {
      return this.yoga.average_score_for_ENV;
    },
    async fetchDestinationsTwo(id) {
      $(".dateBUtton").css("font-weight", "normal")

      // Set font weight to bold for the clicked button
      const clickedButton = document.getElementById('button_' + id);
      if (clickedButton) {
        clickedButton.style.fontWeight = 'bold';
      }
      try {
        const response = await fetcher.get(
            `/clubs_details?schedule=${id}`
        );
        this.yoga_schedule = response.data;
        this.$set(this.yoga_schedule, 'yoga_programs', response.data.programs);
        this.yoga_programs = response.data.programs;
        this.yoga_rate = response.data;
        this.current_date = response.data.club_date;
        this.yoga_stay = response.data;
        // this.stay_name = response.data.stay_name;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
    displayMapfindtwo() {
      this.markersTwo = JSON.parse(JSON.stringify(this.markerMaptwo()));
      this.showMapfind = !this.showMapfind;
      if (event.currentTarget.className === "link-hortense main-link") {
        setTimeout(function () {
          document.querySelectorAll(".markersTwo").forEach((marker) => {
            marker.style.display = "none";
            marker.style.pointerEvents = "none";
          });
          document.querySelectorAll(".markersTwo")[0].style.display = "block";
        }, 1);
      }
    },
    async selectOption(e) {
      const activeEl = document.querySelector(".active");
      activeEl.classList.remove("active");
      e.target.parentNode.classList.add("active");
      this.SelectedOption = e.target.dataset.value;
      if (this.SelectedOption) {
        console.log(this.SelectedOption);
        this.fetchExps(this.SelectedOption);
      } else {
        this.experiencesFiltered = [];
      }
    },
    markerMap() {
      const markers = [];
      markers.push([this.yoga.lat, this.yoga.long]);
      return markers;
    },
    markerMaptwo() {
      const markers = [];
      markers.push([this.yoga.lat, this.yoga.long]);
      this.experiencesFiltered.map((el) => {
        markers.push([el.longitude, el.latitude]);
      });
      return markers;
    },
    displayMapfind() {
      this.markers = JSON.parse(JSON.stringify(this.markerMap()));
    },
  },
  mounted() {
      AOS.init();
    const urlString = window.location.href;
    const url = new URL(urlString);
    const dateParam = url.searchParams.get('date');

    if (dateParam !== null) {
      this.fetchDestinationsTwo(dateParam)
    }
    else{
      this.yoga_schedule.yoga_programs = this.yoga.club_schedules[0].programs;
    }
    this.markerMap();
    this.displayMapfind();
    this.fetchExps("culturelles");
  },
  computed: {
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scope="app/assets/stylesheets/components/lodging-show">
#headDestiii {
  .desti-header {
    padding: 0rem 11rem;
  }
  .row-desti-map {
    justify-content: space-around;
  }
  .bar {
    align-items: center;
  }
  .col-mapcontent {
    margin-top: 8rem;
    .btn-desti {
      width: 226px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .info-loca {
    display: flex;
    align-items: flex-start;

    img {
      width: 12px;
      margin-right: 10px;
    }
  }

  .list-desti-btns {
    margin-bottom: 5rem;
    ul {
      display: flex;
      justify-content: center;
    }

    li {
      list-style: none;
      padding: 15px 30px;
      margin-right: 1rem;
    }
  }
}



.padding_bottom {
  padding-bottom: 10vw;
}

.link-hortense {
  cursor: pointer;
}
.capacity {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-left: auto;
}
.center{
  background-position: center;
  background-size: cover;
}

@media only screen and (max-device-width: 480px) {
  .lodging-header-desktop {
    display: none !important;
  }
}
@media only screen and (min-device-width: 481px) {
  .container-lodging {
    //margin-top: 10vh !important;
  }
  .lodging-header-container {
    display: none;
  }
}
.lodging-made-trip {
  text-align: center;
  border-color: #cddfd3;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: 20px 20px;
  border-bottom-right-radius: 20px 20px;
  border-bottom-left-radius: 20px 20px;
  height: fit-content;
  width: 200px;
  margin-top: 250px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: absolute;
  //left: 71%;
  max-width: 400px;
  width: 100%;

  @media (max-width: 480px) {
    left: 4%;
    margin-top: 1em;
    position: relative;
    max-width: 370px;
    width: 92%;
  }
  .image-container {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .image-container img {
    flex: 1;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
  }

  .head {
    display: flex;

    img {
      width: 32px;
      height: 32px;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .modal-arrow {
      .arrow {
        width: 14px;
        height: 14px;
      }
    }
  }
}
.lodging-show-raleway-reg-p26-b{
  font-family: $Merriweather;
  font-weight: 400;
  font-size: 26px;
}
.mab{
  margin-bottom: 0 !important;
  line-height: 20px;
}
.mab2{
  letter-spacing: 0px;
  line-height: 0px;
}
.mab3 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #headDestiii {
    .desti-header {
      padding: 0rem 2rem;
    }
    .row-desti-map {
      justify-content: space-around;
    }
    .bar {
      align-items: inherit;
    }
    .col-mapcontent {
      margin-top: 8rem;
      .btn-desti {
        width: 226px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .info-loca {
      display: flex;
      align-items: flex-start;

      img {
        width: 12px;
        margin-right: 10px;
      }
    }

    .list-desti-btns {
      margin-bottom: 5rem;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
      }

      li {
        list-style: none;
        padding: 1px 7px;
        margin-right: 0;
        margin-bottom: 11px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
}
</style>