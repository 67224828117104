<template>
  <div id="rea-phone">
    <div class="phone-container">
      <div class=" col-reas">
        <img src="../images/hébergements.svg" alt="Hébergement">
        <p class="raleway-reg-p13"> {{ $t("Reassurance.one") }} </p>
      </div>
      <div class=" col-reas">
        <img src="../images/Indicateurs.svg" alt="Indicateurs">
        <p class="raleway-reg-p13">{{ $t("Reassurance.two") }}</p>
      </div>
      <div class=" col-reas">
        <img src="../images/PGM.svg" alt="PGM">
        <p class="raleway-reg-p13">{{ $t("Reassurance.three") }}</p>
      </div>
      <div class=" col-reas">
        <img src="../images/experiences.svg" alt="experiences">
        <p class="raleway-reg-p13">{{ $t("Reassurance.four") }}</p>
      </div>
      <div class=" col-reas">
        <img src="../images/CO2.svg" alt="CO2">
        <p class="raleway-reg-p13">{{ $t("Reassurance.five") }}</p>
      </div>
      <div class=" col-reas">
        <img src="../images/securite.svg" alt="securite">
        <p class="raleway-reg-p13">{{ $t("Reassurance.six") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'rea-phone',
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/reassurance'>

</style>
