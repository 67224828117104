<template>
  <div id="giftcard">
    <div class="container-index row mt-5 ">
      <headGiftCard></headGiftCard>

      <div class="my-4"></div>
    
      <section id="section-sejour" class="mb-4 w-100" style="z-index: -99 !important;">
        <div id="sejour" class="row">
          <div id="picture-sejour-container" class="col-md-6">
            <img class="img-fluid" id="o-hortense-sejour" src="../../images/icotype.svg" alt="" data-aos="zoom-out" data-aos-duration="1000">
            <!--p>S&#201JOUR HAUT DE GAMME</p-->
            <img class="img-fluid" id="picture-sejour" src="../../images/giftcard/sejour.jpg" alt="" data-aos="fade-left" data-aos-duration="1000">
          </div>
          <div class="col-md-6 sejour-text" data-aos="fade-left" data-aos-duration="1000">
            <h5 class="raleway-bold-p fs-18">Des séjours responsables et solidaires dans des hébergements haut de gamme partout en France.</h5>
            <ul class="raleway-reg-p15 pl-3 fs-12">
              <li>E-carte cadeau (disponible immédiatement)</li>
              <li>Carte cadeau valable 2 ans</li>
              <li>Utilisable en une ou plusieurs fois</li> 
              <li>Applicable sur toutes les réservations</li>
            </ul>
            
            <h5 class="title raleway-bold-p fs-18 mt-3">A chaque occasion, sa carte cadeau !</h5>
            <p class="raleway-reg-p15 fs-12">Que ce soit pour un anniversaire, un mariage, la fête des mères ou une simple envie d'évasion, faites plaisir à vos proches en leur offrant une carte cadeau Hortense. 
              <br>
              Un cadeau à la fois unique et adapté à chacun !
            </p>
            <div class="btn btn-durable pl-0">
              <a class="hortense-btn-primary btn-fid mb-4" @click="scrollToElement('form-user-info')">Offrir un séjour durable</a>
            </div>
          </div>
        </div>
      </section>

      <section id="section-why-and-enterprise" class="mb-4 w-100">
        <div class="row">
          <div id="why-gift-card" class="col-md-7">
            <div id="picture-why-gift-card-container">
              <img class="img-fluid" id="o-hortense-pourquoi" src="../../images/icotype.svg" alt="" data-aos="zoom-out" data-aos-duration="1000">
              <img class="img-fluid" data-aos="fade-right" data-aos-duration="1000" id="picture-why-gift-card" src="../../images/giftcard/why-gift-card.jpg" alt="">
            </div>
            <div id="description-1" data-aos="fade-down" data-aos-duration="1000">
              <h5 class="title merriweather ph-title">Pourquoi offrir une carte cadeau Hortense ?</h5>
              <ul class="raleway-reg-p15 pl-3 pourquoi-li">
                <li>Des hébergements exceptionnels engagés</li>
                <li>Des hébergements sélectionnés selon 150 critères d'éco-responsabilité.</li>
                <li>Un programme de fidélité qui vous couvre de cadeaux</li>
                <li>Des expériences locales et uniques</li>
                <li>Une empreinte réduite pour chaque voyageur grâce au programme Evergreen</li>
              </ul>
              <div class="btn btn-durable border-deb">
                <a class="hortense-btn-primary btn-fid mb-4" @click="scrollToElement('form-user-info')">OFFRIR UNE CARTE CADEAU</a>
              </div>
            </div>
          </div>

          <div id="entreprise-or-ce text-center" class="col-md-5 ">
            <div id="picture-enterprise-ce-container" data-aos="fade-left" data-aos-duration="1000">
              <img class="img-fluid" id="o-hortense-entreprise-ce" src="../../images/icotype.svg" alt="" data-aos="zoom-out" data-aos-duration="1000">
              <img class="img-fluid" id="picture-enterprise-ce" src="../../images/giftcard/enterprise-ce.jpg" alt="">
            </div>
            <div id="description-2" data-aos="fade-down " data-aos-duration="1000">
              <h4 class="title merriweather text-center">Vous êtes une entreprise ou un CE ?</h4>
              <p class="text-center">Si vous souhaitez couvrir vos collaborateurs 
              </p><p class="text-center">ou vos partenaires de cadeaux, n'hésitez </p><p class="text-center">pas à prendre contact avec nous.</p>
              <div class="w-75 mt-4 mx-5 ph-btn-contact">
                <a href="/pages/contact/" class="hortense-btn-primary btn-fid btn-contact">NOUS CONTACTER</a>
              </div>            
            </div>
          </div>
        </div>
      </section>

      <section class="mb-4 w-100" id="section-experience"> 
        <div id="experience" class="row m-4">
          <div id="picture-experience-container" class="col-md-6">        
            <img class="img-fluid" id="o-hortense-experience" src="../../images/icotype.svg" alt="" data-aos="zoom-out" data-aos-duration="1000">
            <img class="img-fluid" data-aos="fade-right" data-aos-duration="1000" id="picture-experience" src="../../images/giftcard/experience.jpg" alt="">
          </div>

          <div class="col-md-6 description-experience" data-aos="fade-left" data-aos-duration="1000">     
            <h4 class="title merriweather">L'expérience Hortense</h4>
            <div class="phone-container-center">
              <p class="merriweather italic">Hortense a déniché pour vous des lieux exceptionnels au service du tourisme durable. Design soigné, architecture différenciée, ambiance conviviale... Chaque hébergement est unique, authentique et plein de charme.</p>

              <ul class="pl-3 lh-1 raleway-reg-p15">
                <li>Des <b>hébergements responsables</b> : des hôtes engagés qui mettent en place des actions concrètes pour minimiser leur impact environnemental.</li>
                <li>Des <b>expériences locales</b> : Gastronomie, culture, activités sportives. Hortense vous propose une sélection d'expériences à vivre à proximité de chaque hébergement partenaire afin de faire vivre l'économie locale.</li>
                <li><b>L'art du service</b> : Hortense vous offre des services exclusifs dont vous pouvez profiter dans les plus beaux hôtels éco-responsables.</li>
              </ul>
            </div>

            <div class="btn btn-durable border-deb">
              <a class="hortense-btn-primary btn-fid mb-3" @click="scrollToElement('form-user-info')">OFFREZ L'EXP&#201;RIENCE HORTENSE</a>
            </div>
            

            <div class="text-info-experience text-center raleway-reg-p15">
              <div class="row">
                <div class="col-lg-7 pt-2 px-0">
                  <p class="raleway-reg-p15 m-0">La carte cadeau Hortense est valable 2 ans.</p>
                  <p class="raleway-reg-p15 m-0">La carte peut être utilisée en une ou plusieurs fois.</p>
                </div>
                <div class="col-lg-5 amount-verif phone-add-margin-top">
                  <button class="hortense-btn-primary btn-fid">V&#201;RIFIER LE SOLDE</button>
                </div>
              </div>   
            </div>

            <img class="img-fluid" data-aos="fade-right" data-aos-duration="1000" id="discover-selection" src="../../images/giftcard/discover-selection.jpg" alt="">

            <div class="text-center">
              <h5 class="raleway-it-l15 text-center merriweather fs-23">Découvrez notre sélection des plus beaux hébergements éco-responsables</h5>
              <div class="btn btn-durable mt-5 border-deb">
                <a href="/reservation-hotel?search=" class="hortense-btn-primary btn-fid d-inline">D&#201;COUVRIR NOTRE S&#201;LECTION</a>
              </div>            
            </div>
          </div>
        </div>
      </section>

      <section id="gift-card-form-container" class="raleway-reg-p15 pr-5 mb-4 w-100"> 
        <div class="row row-one-info" data-aos="fade-left" data-aos-duration="1000">
            <img class="img-fluid" id="o-hortense-form" src="../../images/icotype.svg" alt="" data-aos="zoom-out" data-aos-duration="1000">
          <div id="gift-card-left" class="col-md-6 mt-5">
            <h4 class="title merriweather fs-30 gift-title-form">Une carte cadeau pour s'évader dans un lieu d'exception au service du tourisme durable</h4>


            <p class="merriweather italic fs-15">Offrez une escapade de rêve à vos proches. Une nuit ou plus dans un hôtel de charme engagé dans une démarche environnementale.</p>
            <div class="text-center gift-card-overview mt-5">
              <h2 class="gift-card-overview-title">Plaisir d'offrir</h2>
              <div class="card-number">Carte n°000XX</div>
              <div class="gift-card-overview-body line-form form-group">
                <div class="row">
                  <div class="col-sm-12 px-0">
                    <span>Montant :</span>
                    <input class="w-50 border-input text-right" type="text" :value="giftCardPrice"/>
                    <span>€</span>        
                  </div>
                </div>
                <p></p>
              </div>
              <div class="gift-card-overview-footer raleway-reg-p15">
                <p class="p-container">Hortense.green </p> 
                <p class="p-container">Code d'activation : <span class="gift-card-overview-activation-code">xxxxxx</span> Valide jusqu'au <span>XX/XX/XXXX</span></p>
              </div>
            </div>
          </div>

          <div id="gift-card-form" class="col-md-6 col-info-form raleway-reg-p15" data-aos="fade-right" data-aos-duration="1000">
              <div class="form-user-info p-5">
            <img class="img-fluid" id="o-hortense-carte-cadeau" src="../../images/CARTE_CADEAU.svg" alt="" data-aos="zoom-out" data-aos-duration="1000">
                <p class="raleway-bold-p">A l'attention de :</p>
                <div class="line-form form-group">                  
                  <input id="input-firstname-o" placeholder="Nom" class="form-control w-100 border-input" v-model="recieverInfo.first_name" type="text" />                  
                </div>
                <div class="line-form form-group">                  
                  <input id="input-lastname-o" placeholder="Prenom" class="form-control w-100 border-input" v-model="recieverInfo.last_name"  type="text" />
                </div>

                <div class="line-form form-group">                   
                  <input id="input-email-o" placeholder="Email" class="form-control w-100 border-input" v-model="recieverInfo.email"  type="text" />         
                </div>

                <p class="raleway-bold-p">De la part de :</p>
                <div class="line-form form-group">                  
                  <input id="input-firstname-s" placeholder="Nom" class="form-control w-100 border-input" v-model="senderInfo.first_name" type="text" />              
                </div>
                <div class="line-form form-group">                  
                  <input id="input-lastname-s" placeholder="Prenom" class="form-control w-100 border-input" v-model="senderInfo.last_name" type="text" />
                </div>

                <div class="line-form form-group">                  
                  <input id="input-email-s" placeholder="Email" class="form-control w-100 border-input" v-model="senderInfo.email" type="text" />
                </div>

                <div class="line-form form-group">
                  <p class="raleway-bold-p">Votre message :</p>
                  <textarea
                    class="w-100"
                    cols="45"
                    rows="6"
                    v-model = "messageText"
                  ></textarea>
                </div>              

                <div class="line-form form-group">
                  <label for="price" class="raleway-bold-p">Montant :</label>
                  <v-select id="price" class="select-item w-100-border-input" placeholder="" v-model="giftCardPrice" :options="priceList"></v-select>
                </div>              

                <p class="raleway-reg-p18"> Disponible à partir de 50€, saisir un multiple de 50.</p>

                <div class="line-form form-group">
                  <div class="term-and-condition">
                    <input class="form-control"
                      type="checkbox"
                      id="term"
                      value="term"
                      v-model="term"
                    />
                    <label for="term">J'accepte<a href="/conditions-generales-utilisation" class="no-deco"> les conditions générales d'utilisation</a></label>
                  </div>
                </div>

                <button class="hortense-btn-primary border-deb" @click="createGiftCard">
                  OFFREZ CETTE CARTE CADEAU
                </button>
              </div>
          </div>
        </div>
      </section>

      <!--Flash :text='this.text' :status='this.statusFlash' @destroy='toggleStatus(statusFlash)'></Flash-->

    </div>
  </div>

</template>

<script>
  import fetcher from "packs/shared/api.js";
  import headGiftCard from '../../components/giftcards/HeadGiftCard.vue';
  import ScrollUp from "components/btns/ScrollUp.vue";
  import Flash from 'alert/Flash.vue';
  import AOS from 'aos';

export default {
  name: 'giftcard',
  components: {
    headGiftCard,
    ScrollUp,
    Flash,
    AOS
  },
  props: {
    activationCode: String,
    validityDate: String,
    user: null
  },

  data() {
    return {
      senderInfo: {
        last_name: null,
        first_name: null,
        email: null,
        phone: null,
        city: null,
        country: null,
      },
      recieverInfo: {
        last_name: null,
        first_name: null,
        email: null,
      },
      text:null,
      statusFlash: null,
      messageText: null,
      giftCardPrice: 50,
      priceList: [],
      //validityDate: null,
      term: false,
      //giftCardActivationCode: null,
      giftCardCreated: null,
    };
  },

  methods: {
    async createGiftCard(){
      console.log(" validate button clicked")
      try {
        console.log("try")
        const response = await fetcher.post("/carte-cadeau/create", {
          //senderInfo: this.senderInfo,
          giftCard: {
            validityDate: this.validityDate,
            price: this.giftCardPrice,
            activationCode: this.activationCode,
            senderFirstName: this.senderInfo.first_name,
            senderLastName: this.senderInfo.last_name,
            senderEmail: this.senderInfo.email,
            recieverFirstName: this.recieverInfo.first_name,
            recieverLastName: this.recieverInfo.last_name,
            recieverEmail: this.recieverInfo.email
          },
          message: this.messageText
        });
        this.giftCardCreated = response.data;
        this.toggleStatus('success');
        ///:sender/:receiver/:gift_card'
        Turbolinks.visit("/carte-cadeau/paiement/"+ response.data.sender_user_json_response.id +"/"+ response.data.receiver_user_json_response.id +"/"+ response.data.gift_card_json_response.id);
      } catch (e) {
        console.log("Failed createGiftCard");
        console.error(e);

        this.text = "Une erreur s'est produite pendant la création de la carte, merci de réessayer plus tard.";
        /*this.text = "carte cadeau créee, un mail a été envoyé.";*/
        this.toggleStatus('alert');
      }
    },

    async fetchUser() {
      if (this.user){
        try {
          const res = await fetcher.get(`/dashboard/${this.user.id}`);
          this.senderInfo = res.data;
        } catch (e) {
          console.error(e);
        }
      }
    },

    toggleStatus(value="success") {
        this.statusFlash = this.statusFlash === value ? null : value
      },

    scrollToElement(div) {
      const el = document.getElementsByClassName(div)[0];

      if (el) {
        el.scrollIntoView();
      }
    },

  },
  computed: {
    paymentButtonCaption() {
      if (this.hasStatusError()) {
        //return "Erreur de validation, veuillez réessayer";
        return "Payement effectué";
      } else if (this.isProcessingPayment) {
        return "Paiement en cours...";
      } else {
        return `Valider l'achat de la carte cadeau et payer ${this.giftCardPrice} €`;
      }
    },
  },

  mounted(){
    AOS.init();
    this.fetchUser();
  },

  beforeMount(){
    for (var i = 0, val=50; i < 100; i++, val+=50) {
      this.priceList[i] = val;
    }
    console.log(this.priceList);
  }
  
  
};
</script>

<style lang="scss" scoped>
#sejour{
  .row{
    z-index: -99 !important;
  }
}

#o-hortense-sejour{
  display: flex;
  position: absolute;
  margin-top: -7%;
  margin-left: -7%;
  z-index: 100;
  width: 150px;
}
#o-hortense-pourquoi{
  display: flex;
  position: absolute;
  margin-top: -5%;
  z-index: 100;
  width: 150px;
}
#o-hortense-entreprise-ce{
  display: flex;
  position: absolute;
  margin-top: -10%;
  float:right;
  right: 0;
  margin-right: -10%;
  z-index: 100;
  width: 150px;
}

#o-hortense-experience{
  display: flex;
  position: absolute;
  margin-top: -7%;
  margin-left: -7%;
  z-index: 100;
  width: 150px;
}
#o-hortense-form{
  display: flex;
  position: absolute;
  margin-top: -16%;
  margin-left: -7%;
  z-index: 100;
  width: 300px;
}
#o-hortense-carte-cadeau{
  display: flex;
  position: absolute;
  float: right;
  right: 0;
  margin-top: -23%;
  margin-right: -7%;
  z-index: 100;
  width: 150px;
}

.hortense-btn-primary{
  max-height: 25px;
  font-size: 11px;
}

.col-info-form{
  margin-top: 10%;
}

.border-inspect{
  border: 3px solid red !important;
}

#giftcard{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 8%;

  #section-sejour {

    #sejour {
      z-index: -99;
      .row {
        z-index: -99 !important;
      }
    }
  }
  .phone-add-margin-top{
    padding-top: 2%;
  }

  .card-number{
    float: right;
    transform: rotate(-90deg);
    padding: 0;
    position: absolute;
    right: 0;
  }

  .fs-18{
    font-size: 18px;
  }
  .fs-23{
    font-size: 23px;
  }
  .fs-30{
    font-size: 30px;
  }
  .fs-15{
    font-size: 15px;
  }
  .lh-1{
    line-height: 1.2;
  }

  ul{
    li{
      list-style: none;
      list-style-image: url('../../images/Icotype_puce.svg');
    }
  }
  .raleway-bold-p{
    text-transform: none;
  }
  .border-input{
    border: none;
    border-bottom: 1px solid #ccc;
  }
  .merriweather{
    font-family: Merriweather;    
  }
  .italic{
    font-style: italic;
  }
  .container-index {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 25vh;
    min-height: 100vh;
    /*overflow: hidden;*/
    overflow: visible;
    .why-gift-card{
      display: block;
    }
    #description-1{
      display: block;
      position: inherit;
      width: 90%;
      margin-top: -15%;
      margin-left: 5%;
      padding-top: 7%;
      padding: 5%;
      background-color: #fff;
    }
    #picture-enterprise-ce{
      display: block;
      margin-top: 5%;
    }
    #description-2{
      display: block;
      position: inherit;
      width: 90%;
      margin-top: -20%;
      margin-left: 10%;
      padding: 5%;
      background-color: #fff;
    }
    #section-experience{
      margin-top: 5%;
      padding-top: 3%;
      border-top: solid 2px #aba;
    }
    .form-user-info {
      border: 2px solid grey;
      padding: 10px;
    }
  }
}
.gift-card-overview{
  /*border: 35px #CFE5DC solid;*/
  border: 15px #3B6152 solid;
  padding-top: 25px;
  padding-bottom: 10px;
  .gift-card-overview-title{
    font-family: Abuget;
    font-size: 80px;
  }

  .gift-card-overview-body{
    display: block;
    margin-top: 50px;
    margin-bottom: 50px ;
    font-weight: bolder;
    color: #3B6152;
    .gift-card-overview-price{
      font-weight: bolder;
      border-bottom: solid 2px #3B6152;
      color: #000;
      inline-size: 200px;
    }
  }

  .gift-card-overview-footer{
    line-height: 0.5px;
  }
}

.text-info-experience{
  background-color: #CFE5DC;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding: 20px;
}

.p-container{
  margin: 0 !important;
  padding: 0 !important;
  line-height: 1.2;
}

#description-2{
  p{
    font-family: $Raleway;
    margin: 0;
    padding: 0;
  }
}

#discover-selection{
  display: none;
}
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    .phone-add-margin-top{
      margin-top: auto 25%;
      width: 100% !important;

      .btn-fid{
        width: 60% !important;
        padding: 2% 0;
        margin: auto 20%;
      }
    }

    .gift-title-form{
      font-size: 14px !important;
      padding: 0 !important;
      margin-left: 0 !important;
      width: 100% !important;
    }

    #description-2{
      h4{
        width: 110% !important;
        margin: auto 20%;
        font-size: 20px;
        margin-bottom: 1rem;
      }
      p{
        margin: 1% 25%;
        padding-right: 10%;
        font-size: 13px !important;
        font-family: $Raleway;
      }
      .ph-btn-contact{
        padding-right: 15%;
      }

    }
    .pourquoi-li{
      width: 100% !important;
      text-align: center;
    }

    .offer-gift-btn{      
      width: 100% !important;
      margin: 0 !important;
    }
    .border-deb{
      margin-left: 1.7rem !important;
    }
    .ph-title{
      font-size: 15px !important;
      width: 110% !important;
      text-align: center !important;         
    }
    
    .container-index {
      display: block;
      width: 150% !important;
    }

    #section-experience{
      border: none !important;
      .description-experience{
        margin-top: 5%;
        .phone-container-center{          
          margin-left: 2rem;
          width: 80%;
        }        
      }
    }

    #discover-selection{
      display: block;
      margin-bottom: 10%;
    }
    #o-hortense-sejour{
      margin-top: -13%;
      margin-left: 0%;
      z-index: 1 !important;
    }
    #o-hortense-pourquoi{
      margin-top: -16%;
      margin-left: -1%;
    }
    #o-hortense-entreprise-ce{
      margin-top: -16%;
      margin-left: -4%;
      left: 0;
    }
    #o-hortense-experience{
      margin-top: -15%;
      float: right;
      right: 7%;
    }
    #o-hortense-form{
      margin-top: -22%;
      margin-left: -25%;
      width: 155px;
    }
    #o-hortense-carte-cadeau{
      display: none;
    }
    #gift-card-form-container{
      padding: 0 !important;

      #gift-card-left{
        margin-top: 10% !important;
        width: 100% !important;
        .card-number{
          float: right;
          transform: rotate(-90deg);
          padding: 0 !important;
          margin-right: 5px;
          font-size: 8px;
        }

        .gift-card-overview{
          border: 8px #3B6152 solid;
          padding-top: 3px;

          .gift-card-overview-title{
            padding: 10px;
            font-family: Abuget;
            font-size: 30px;
          }

          .gift-card-overview-body{
            display: block;
            margin-top: 5px;
            font-weight: bolder;
            color: #3B6152;

            .gift-card-overview-price{
              font-weight: bolder;
              border-bottom: solid 1px #3B6152;
              color: #000;
              inline-size: 200px;
            }
          }

          .p-container{
            font-size: 8px;
          }
        }
      }
    }
    .form-user-info{
      padding: 10% !important;
      margin-top: 15px !important;
      input, p, label{
        font-size: 10px !important;
      }
      .offer-gift{
        width: 100% !important;
      }
    }

    .text-info-experience{
      width: 100% !important;

    }

    .fs-23{
      text-align: center !important;
      font-size: 18px !important;
    }

    .fs-30{
      margin-left: 15px;
      text-align: center !important;
      font-size: 22px !important;
    }

    .fs-15{
      text-align: center !important;
      font-size: 12px !important;
    }
    #section-sejour{
      text-align: center;

      .sejour-text{
        margin-top: 20px;

        .fs-18{
          font-size: 14px;
        }

        .fs-12{
          font-size: 12px !important;
          margin: 0 !important;
        }
      }

      .btn-durable{
        margin-top: 20px;
      }
    }

    #description-1, #description-2{
      width: 100% !important;
      margin-top: 0 !important;
      margin-left: 0 !important;

      h5, h4{
        font-size: 15px;
        margin-left: -16px;
      }

      ul{
        margin-left: 0 !important;
        li{
          font-size: 12px;
        }
      }

  .add-btn{
    margin-top: 3rem;
    button {
      display: flex;
    }
    .fs-30{
      margin-left: 15px !important;
      text-align: center !important;
      font-size: 22px !important;
    }
    .fs-15{
      text-align: center !important;
      font-size: 12px !important;
    }
    #section-sejour{
      text-align: center;
      .sejour-text{
        margin-top: 20px;
        .fs-18{
          font-size: 14px;
        }
        .fs-12{
          font-size: 12px !important;
          margin: 0 !important;
        }
      }
      .btn-durable{
        margin-top: 20px;
      }
    }
    #description-1, #description-2{
      width: 100% !important;
      margin-top: 0 !important;
      margin-left: 0 !important;
      h5, h4{
        font-size: 15px;
        margin-left: -16px;
      }
      ul{
        margin-left: 0 !important;
        li{
          font-size: 12px;
        }
      }
      .btn-fid{
        margin-left: 15px;
      }
      p{
        width: 100%;
        font-size: 12px;
        margin-left: 15px !important;
      }
      .btn-contact{
        width: 100% !important;
      }
    }
    #experience{
      width: 100% !important;
      margin: 0 !important;
      h4{
        text-align: center !important;
      }
      p{
        font-size: 12px;
        text-align: center !important;
        margin-left: 15px !important;
      }
      ul{
        font-size: 12px;
        text-align: center !important;
      }
    }
  }

      .btn-fid{
        margin-left: 15px;
      }

      p{
        width: 100%;
        font-size: 12px;
        margin-left: 15px !important;
      }

      .btn-contact{
        width: 100% !important;
      }
    }

    #experience{
      width: 100% !important;
      margin: 0 !important;

      h4{
        text-align: center !important;
      }

      p{
        font-size: 12px;
        text-align: center !important;
        margin-left: 15px !important;
      }

      ul{
        font-size: 12px;
        text-align: center !important;
      }
    }




  }


  @media screen and (min-width:600px) and (max-width: 1200px){
    #o-hortense-sejour{
      display: flex;
      position: absolute;
      margin-top: -10%;
      margin-left: -10%;
      z-index: 100;
      width: 150px;
    }
    #o-hortense-pourquoi{
      display: flex;
      position: absolute;
      margin-top: -5%;
      z-index: 100;
      width: 150px;
    }
    #o-hortense-entreprise-ce{
      display: flex;
      position: absolute;
      margin-top: -15%;
      float:right;
      right: 0;
      margin-right: -15%;
      z-index: 100;
      width: 150px;
    }
    #o-hortense-experience{
      display: flex;
      position: absolute;
      margin-top: -10%;
      margin-left: -10%;
      z-index: 100;
      width: 150px;
    }
    #o-hortense-form{
      display: flex;
      position: absolute;
      margin-top: -65%;
      margin-left: -7%;
      z-index: 100;
      width: 300px;
    }
    #o-hortense-carte-cadeau{
      display: flex;
      position: absolute;
      float: right;
      right: 0;
      margin-top: -27%;
      margin-right: -10%;
      z-index: 100;
      width: 150px;
    }
  }


  @media screen and (min-width:1200px) and (max-width: 2048px){

     #o-hortense-sejour{
      display: flex;
      position: absolute;
      margin-top: -7%;
      margin-left: -7%;
      z-index: 100;
      width: 150px;
    }
    #o-hortense-pourquoi{
      display: flex;
      position: absolute;
      margin-top: -5%;
      z-index: 100;
      width: 150px;
    }
    #o-hortense-entreprise-ce{
      display: flex;
      position: absolute;
      margin-top: -8%;
      float:right;
      right: 0;
      margin-right: 11%;
      z-index: 100;
      width: 150px;
    }
    #o-hortense-experience{
      display: flex;
      position: absolute;
      margin-top: -7%;
      margin-left: -7%;
      z-index: 100;
      width: 150px;
    }
    #o-hortense-form{
      display: flex;
      position: absolute;
      margin-top: -14%;
      margin-left: -7%;
      z-index: 100;
      width: 300px;
    }
    #o-hortense-carte-cadeau{
      display: flex;
      position: absolute;
      float: right;
      right: 0;
      margin-top: -15%;
      margin-right: -7%;
      z-index: 100;
      width: 150px;
    }

  }

  @media screen and (width > 2048px) {
    #o-hortense-entreprise-ce{
      display: flex;
      position: absolute;
      margin-top: -8%;
      float:right;
      right: 0;
      margin-right: 28%;
      z-index: 100;
      width: 150px;
    }
    #o-hortense-form{
      display: flex;
      position: absolute;
      margin-top: -11%;
      margin-left: -5%;
      z-index: 100;
      width: 300px;
    }

    #o-hortense-carte-cadeau{
      display: flex;
      position: absolute;
      float: right;
      right: 0;
      margin-top: -11%;
      margin-right: -5%;
      z-index: 100;
      width: 150px;
    }
  }

</style>