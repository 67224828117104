<template>
	<div id="multiselectnote">
		<div class="dropdown dropdown-filter" @click="showDropdown">
			<div class="overselect"></div>
			<div class="c-form-input">
				<h2 class="raleway-semi-p13">Type d’établissement</h2>
				<img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="show" />
				<img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
			</div>
		</div>
		<div class="multiselect" v-if="show">
			<ul>
				<div class="row row-selectoptions mt-3">
					<div class="col-10">
						<li>
							<input type="checkbox" id="hotel" value="Hotel" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="hotel">Hôtels</label>
						</li>
						<li>
							<input type="checkbox" id="villa" value="Villa" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="villa">Maisons et Villas</label>
						</li>
						<li>
							<input type="checkbox" id="guesthouse" value="Guesthouse" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="guesthouse">Chambres d’Hôtes</label>
						</li>
						<li>
							<input type="checkbox" id="lodge" value="Lodge" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="lodge">Lodges et Lieux Insolites</label>
						</li>

						<li>
							<input type="checkbox" id="appartement" value="Appartement" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="appartement">Appartements</label>
						</li>
<!--						<li>-->
<!--							<input type="checkbox" id="guestroom" value="Guestroom" v-model="selected" />-->
<!--							<label class="raleway-reg-p13 ml-2" for="guestroom">Chambres d’hôtes</label>-->
<!--						</li>-->
<!--						<li>-->
<!--							<input type="checkbox" id="gite" value="Gite" v-model="selected" />-->
<!--							<label class="raleway-reg-p13 ml-2" for="gite">Gîtes</label>-->
<!--						</li>-->
						<li>
							<input type="checkbox" id="other" value="Other" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="other">Lieux insolites</label>
						</li>
					</div>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
	import fetcher from 'packs/shared/api.js';

	export default {
		name: 'multiselectnote',
		props: ['options'],
		data() {
			return {
				show: false,
				selected: [],
				// lodgingsMount: null,
				// typesMount: [],
			};
		},
		methods: {
			showDropdown() {
				this.show = !this.show;
			},
			// async fetchTypes() {
			//      try {
			//        const response = await fetcher.get('lodgings.json');
			//          this.lodgingsMount = response.data
			//          this.lodgingsMount.forEach((lodging) => {
			//             return this.typesMount.push(lodging.type)
			//          })
			//      } catch(e) {
			//        console.error(e);
			//      }
			//    },
		},
		//   beforeMount() {
		//   this.fetchTypes();
		// },
		watch: {
			selected(val) {
				this.$emit('checked', val, 'type');
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
