<template>
  <div class="container" style="margin-top: 30px">
    <div class="row mb-5">
      <div class="col-md-6 col-lg-6 col-sm-12 mb-4 d-flex" v-for="yoga in paginatedYogas" :key="yoga.id">
        <div class="card yoga-card flex-fill" @click="goToYoga(yoga)">
          <img :src="yoga.photo_banner" class="card-img-top" alt="Yoga Image">
          <div class="card-body d-flex flex-column">
            <h5 class="card-title text-center" style="font: normal normal normal 26px/33px Merriweather;">{{ yoga.yoga_place.title }}</h5>
            <p class="card-text text-center" style="font: normal normal normal 15px/18px Raleway;">{{ yoga.location_detail }}</p>
            <div class="list-desti-btns mt-3 flex-grow-1 d-flex flex-column justify-content-center">
              <ul style="display: flex; justify-content: center; flex-wrap: wrap; gap: 10px; flex-direction: column;" class="bar">
                <li v-for="(schedule, i) in yoga.yoga_schedules" :key="schedule.id" class="menu-item raleway-reg-p16" style="list-style: none; padding: 5px; text-align: center;">
                  <span class="date-box" @click.stop="setDestinations(yoga, schedule.id)">
                    <strong>{{ schedule.yoga_date }} - A partir de {{ schedule.lowest_rate }}€</strong>
                  </span>
                </li>
              </ul>
            </div>
            <p class="mab2 pt-1 text-center" style="text-decoration: underline; font: normal normal normal 13px/18px Raleway;">En savoir plus</p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="pageCount > 1" class="pagination-controls">
      <button v-for="page in pageCount" :key="page" :class="['pagination-button', { active: page === currentPage }]" @click="currentPage = page">
        {{ page }}
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YogaCard',
  props: ['yogas'],
  data() {
    return {
      currentPage: 1,
      itemsPerPage: 8
    };
  },
  computed: {
    pageCount() {
      return Math.ceil(this.yogas.length / this.itemsPerPage);
    },
    paginatedYogas() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.yogas.slice(start, end);
    }
  },
  methods: {
    goToYoga(yoga) {
      window.open("/experiences-bien-etre/" + yoga.yoga_place.slug, "_blank");
    },
    setDestinations(yoga, id) {
      window.open("/experiences-bien-etre/" + yoga.yoga_place.slug + "?date=" + id, "_blank");
    }
  }
}
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: auto;
}
.card.yoga-card {
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: transform 0.3s ease;
  cursor: pointer;
  display: flex;
  flex-direction: column;
}
.card.yoga-card:hover {
  transform: scale(1.05);
}
.card.yoga-card .card-img-top {
  height: 356px;
  object-fit: cover;
}
.card.yoga-card .card-body {
  padding: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.card.yoga-card .card-title {
  font: normal normal normal 26px/33px Merriweather;
}
.card.yoga-card .card-text {
  font: normal normal normal 15px/18px Raleway;
  margin-bottom: 10px;
}
.card.yoga-card .card-price {
  font: normal normal 600 18px/32px Raleway;
}
.date-box {
  display: block;
  white-space: nowrap;
  font: normal normal 600 14px/20px Raleway;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.date-box:hover {
  background-color: #000;
  color: #fff;
}
.pagination-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}
.pagination-button {
  width: 60px;
  height: 60px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #000000;
  border-radius: 3px;
  opacity: 0.2;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pagination-button.active {
  opacity: 1;
  font-weight: bold;
}
</style>