import axios from 'axios';

const fetcher = axios.create({
    baseURL: window.location.origin,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
});
fetcher.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

axios.interceptors.request.use(config => {
    config.paramsSerializer = params =>
      qs.stringify(params, {
        arrayFormat: 'brackets',
        encode: false
      });
  
    return config;
  });

export default fetcher;
