<!-- <template>
  <div id="inspirations">
    <div class="container-index">
      <div class="header-inspi text-center ">
        <h1 class="merriweather-reg-40">Inspirations</h1>
        <p class="raleway-reg-p15">L’envie de découvrir le monde à travers le prisme du tourisme durable et haut-de-gamme vous démange… Mais vous ne savez pas encore où, ni comment ? Hortense est là pour vous guider ! Dites-nous de quoi vous avez envie : ambiance de voyage, activités, style d’hébergement… Et nous vous proposerons plusieurs solutions pour faire de votre séjour un souvenir inoubliable.</p>
      </div>

      <div class="row row-desti">
        <div class="col-lg-4 col-xs-8 question">
          <h3 class="raleway-reg-p24">Où souhaitez-vous partir ?</h3>
          <p class="raleway-reg-p15">Vous pouvez sélectionner plusieurs destinations</p>
        </div>
        <div class="col-lg-8 col-xs-10 cards">
          <div class="row">
            <div class="col-lg-4 col-xs-5" v-for='destination in this.destinationsMounted'>
              <div class="card" v-if="destination.description.photo_key" :style='this.imageBackGroundDesti(destination)'>
                <input type="form-check-input" @click="selectDestination" class="btn-check" :id="destination.name" check autocomplete="off">
                <label class="btn raleway-bold-p destination" :for="destination.name">{{ destination.name }}
                  <img src="../../images/Tick.svg" class="img-tick" alt="tick">
                </label>
              </div>
              <div class="card" v-else>
                <input type="form-check-input" @click="selectDestination" class="btn-check" :id="destination.name" check autocomplete="off">
                <label class="btn raleway-bold-p destination" :for="destination.name">{{ destination.name }}
                  <img src="../../images/Tick.svg" alt="tick">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-amb">
        <div class="col-lg-4 col-xs-8 question">
          <h3 class="raleway-reg-p24">Quelle ambiance ?</h3>
          <p class="raleway-reg-p15">Vous pouvez sélectionner plusieurs ambiances.</p>
        </div>
        <div class="col-lg-8 col-xs-10 cards">
          <div class="row">
            <div class="col-lg-4 col-xs-5" v-for='ambiance in this.ambiancesMounted'>
              <div class="card">
                <input type="form-check-input" class="btn-check" @click="selectAmbiance" :id="ambiance.name" checked autocomplete="off">
                <label class="btn raleway-bold-p ambiance" :for="ambiance.name">{{ ambiance.name }}
                  <img src="../../images/Tick.svg" alt="tick">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-activity">
        <div class="col-lg-4 col-xs-8 question">
          <h3 class="raleway-reg-p24">Que souhaitez-vous faire ?</h3>
          <p class="raleway-reg-p15">Vous pouvez sélectionner plusieurs activités.</p>
        </div>
        <div class="col-lg-8 col-xs-10 cards">
          <div class="row">
            <div class="col-lg-4 col-xs-5" v-for='activity in this.activitiesMounted'>
              <div class="card">
                <input type="form-check-input" class="btn-check" @click="selectActivity" :id="activity.name" checked autocomplete="off">
                <label class="btn raleway-bold-p activity" :for="activity.name">{{ activity.name }}
                  <img src="../../images/Tick.svg" alt="tick">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-lodging">
        <div class="col-lg-4 col-xs-8 question">
          <h3 class="raleway-reg-p24">Quel style d’hébergement ?</h3>
          <p class="raleway-reg-p15">Vous pouvez sélectionner plusieurs styles.</p>
        </div>
        <div class="col-lg-8 col-xs-10 cards">
          <div class="row">
            <div class="col-lg-4 col-xs-5" v-for='style in this.stylesMounted'>
              <div class="card">
                <input type="form-check-input" class="btn-check" @click="selectStyle" :id="style.name" checked autocomplete="off">
                <label class="btn raleway-bold-p style" :for="style.name">{{ style.name }}
                  <img src="../../images/Tick.svg" alt="tick">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-envie">
        <div class="col-lg-4 col-xs-8 question">
          <h3 class="raleway-reg-p24">Quelles sont vos envies ?</h3>
          <p class="raleway-reg-p15">Vous pouvez sélectionner plusieurs envies.</p>
        </div>
        <div class="col-lg-8 col-xs-10 cards">
          <div class="row">
            <div class="col-lg-4 col-xs-5" v-for='desire in this.desiresMounted'>
              <div class="card">
                <input type="form-check-input" class="btn-check" @click="selectDesire" :id="desire.name" checked autocomplete="off">
                <label class="btn raleway-bold-p desire" :for="desire.name">{{ desire.name }}
                  <img src="../../images/Tick.svg" alt="tick">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-type">
        <div class="col-lg-4 col-xs-8 question">
          <h3 class="raleway-reg-p24">Quel type de voyage ?</h3>
          <p class="raleway-reg-p15">Vous pouvez sélectionner plusieurs types.</p>
        </div>
        <div class="col-lg-8 col-xs-10 cards">
          <div class="row">
            <div class="col-lg-4 col-xs-5" v-for='typeOfTrip in this.typeOfTripsMounted'>
              <div class="card">
                <input type="form-check-input" class="btn-check" @click="selectType" :id="typeOfTrip.name" checked autocomplete="off">
                <label class="btn raleway-bold-p type" :for="typeOfTrip.name">{{ typeOfTrip.name }}
                  <img src="../../images/Tick.svg" alt="tick">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="results">
      <div class="col-4">
        <button class="hortense-btn-primary" @click='inspireMe'>Inspirez-moi</button>
      </div>
    </div>
    <div class="lodging-filtered" v-if='this.filteredLodgings'>
      <template v-for='lodging, index in this.filteredLodgings'>
        <CardProductbig :key='index' :lodging="lodging" :data-marker='index' :data-lodging='lodging.id'></CardProductbig>
      </template>
    </div>
  </div>
</template>
 -->
<template>
  <div id="inspirations">
        <Searchbtn
        class="search-inspi"
      @show="showBanner = !showBanner"
      @top="redirectToTop"
    ></Searchbtn>
    <Searchbanner v-if="showBanner" @close="showBanner = false"></Searchbanner>
    <div class="container-index"  >
      <div class="header-inspi text-center">
        <h1 class="merriweather-reg-40">Inspirations</h1>
        <p class="raleway-reg-p15">
          L’envie de découvrir le monde à travers le prisme du tourisme durable
          et haut-de-gamme vous démange… Mais vous ne savez pas encore où, ni
          comment ? Hortense est là pour vous guider ! Dites-nous de quoi vous
          avez envie : ambiance de voyage, activités, style d’hébergement… Et
          nous vous proposerons plusieurs solutions pour faire de votre séjour
          un souvenir inoubliable.
        </p>
      </div>
      <div class="row row-desti">
        <div class="col-md-3 col-xs-7 question">
          <h3 class="raleway-reg-p24">Où souhaitez-vous partir ?</h3>
          <p class="raleway-reg-p15">
            Vous pouvez sélectionner plusieurs destinations
          </p>
        </div>
        <div class="col-md-9 col-xs-11 cards">
          <div class="row container-card-inspirations">
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/voyage+Grand+Est.jpg"
                  class="bg-card"
                  alt="france"
                />
                <input
                  type="form-check-input"
                  @click="selectDestination"
                  class="btn-check"
                  id="France"
                  check
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p destination" for="France"
                  >France
                  <!-- <p class="raleway-bold-p">France</p> -->
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/voyage+aux+Iles+Turks+et+Cai%CC%88ques.jpg"
                  class="bg-card"
                  alt="Caraïbes"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectDestination"
                  id="Caraïbes"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p destination" for="Caraïbes"
                  >Caraïbes
                  <!-- <p class="raleway-bold-p">France</p> -->
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/voyage+a%CC%80+Madagascar+.jpg"
                  class="bg-card"
                  alt="Océan Indien"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectDestination"
                  id="Océan Indien"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p destination" for="Océan Indien"
                  >Océan Indien
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/voyage+a%CC%80+Bora+Bora.jpg"
                  class="bg-card"
                  alt="Polynésie Française"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectDestination"
                  id="Polynésie Française"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p destination"
                  for="Polynésie Française"
                  >Polynésie Française
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/voyage+au+maroc.jpg"
                  class="bg-card"
                  alt="afrique"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectDestination"
                  id="Afrique"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p destination" for="Afrique"
                  >Afrique
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-amb">
        <div class="col-md-3 col-xs-7 question">
          <h3 class="raleway-reg-p24">Quelle ambiance ?</h3>
          <p class="raleway-reg-p15">
            Vous pouvez sélectionner plusieurs ambiances.
          </p>
        </div>
        <div class="col-md-9 col-xs-11 cards">
          <div class="row container-card-inspirations">
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Montagne++.jpg"
                  class="bg-card"
                  alt="montage"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="montage"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p ambiance" for="montage"
                  >Montagne
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/City+trip.jpg"
                  class="bg-card"
                  alt="city break"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="city break"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p ambiance" for="city break"
                  >City Break
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Plage++.jpg"
                  class="bg-card"
                  alt="plage"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="plage"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p ambiance" for="plage"
                  >Plage
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Campagne++.jpg"
                  class="bg-card"
                  alt="campagne"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="campagne"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p ambiance" for="campagne"
                  >Campagne
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Hors+du+commun+.jpg"
                  class="bg-card"
                  alt="hors du commun"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="hors du commun"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p ambiance" for="hors du commun"
                  >Hors du commun
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Nature+sauvage.jpg"
                  class="bg-card"
                  alt=""
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="en pleine nature"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p ambiance"
                  for="en pleine nature"
                  >En pleine nature
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Romantique+.jpg"
                  class="bg-card"
                  alt="romantique"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="romantique"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p ambiance" for="romantique"
                  >Romantique
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Aventure.jpg"
                  class="bg-card"
                  alt="Aventure"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="aventure"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p ambiance" for="aventure"
                  >Aventure
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Plein+air.jpg"
                  class="bg-card"
                  alt="plein air"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="plein air"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p ambiance" for="plein air"
                  >Plein air
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Escales+gourmandes.jpg"
                  class="bg-card"
                  alt="escales gourmandes"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="escales gourmandes"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p ambiance"
                  for="escales gourmandes"
                  >Escales gourmandes
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Aux+bords+de+l%E2%80%99eau+.jpg"
                  class="bg-card"
                  alt="au bord de l’eau"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="au bord de l’eau"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p ambiance"
                  for="au bord de l’eau"
                  >Au bord de l’eau
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Escapade+insolite.jpg"
                  class="bg-card"
                  alt="escapade insolite"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="escapade insolite"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p ambiance"
                  for="escapade insolite"
                  >Escapade insolite
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/DYNAMIQUE+ET+BRANCH%C3%89.png"
                  class="bg-card"
                  alt=""
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="dynamique et branché"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p ambiance"
                  for="dynamique et branché"
                  >Dynamique et branché
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/VIE+DE+CHATEAU.png"
                  class="bg-card"
                  alt=""
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectAmbiance"
                  id="vie de château"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p ambiance" for="vie de château"
                  >Vie de château
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-activity">
        <div class="col-md-3 col-xs-7 question">
          <h3 class="raleway-reg-p24">Que souhaitez-vous faire ?</h3>
          <p class="raleway-reg-p15">
            Vous pouvez sélectionner plusieurs activités.
          </p>
        </div>
        <div class="col-md-9 col-xs-11 cards">
          <div class="row container-card-inspirations">
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/BRONZER+ET+NAGER.png"
                  class="bg-card"
                  alt=""
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="bronzer et nager "
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p activity"
                  for="bronzer et nager "
                  >Bronzer et nager
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <!--  <div class="col-4">
              <div class="card-inspirations">
                <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Service+de+concierge+.jpg" class="bg-card" alt="service de conciergerie">
                <input type="form-check-input" class="btn-check" @click="selectActivity" id="service de conciergerie" checked autocomplete="off">
                <label class="btn raleway-bold-p activity" for="service de conciergerie">service de conciergerie
                  <img src="../../images/Tick.svg" class="img-tick" alt="tick">
                </label>
              </div>
            </div> -->
            <!-- <div class="col-4">
              <div class="card-inspirations">
                <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Cours+de+fitness+.jpg" class="bg-card" alt="cours de fitness">
                <input type="form-check-input" class="btn-check" @click="selectActivity" id="cours de fitness" checked autocomplete="off">
                <label class="btn raleway-bold-p activity" for="cours de fitness">Cours de fitness
                  <img src="../../images/Tick.svg" class="img-tick" alt="tick">
                </label>
              </div>
            </div> -->
            <!-- <div class="col-4">
              <div class="card-inspirations">
                <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Cours+de+fitness+.jpg" class="bg-card" alt="jacuzzi">
                <input type="form-check-input" class="btn-check" @click="selectActivity" id="jacuzzi" checked autocomplete="off">
                <label class="btn raleway-bold-p activity" for="jacuzzi">Jacuzzi
                  <img src="../../images/Tick.svg" class="img-tick" alt="tick">
                </label>
              </div>
            </div> -->
            <!-- <div class="col-4">
              <div class="card-inspirations">
                <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Piscine.jpg" class="bg-card" alt="piscine">
                <input type="form-check-input" class="btn-check" @click="selectActivity" id="piscine" checked autocomplete="off">
                <label class="btn raleway-bold-p activity" for="piscine">Piscine
                  <img src="../../images/Tick.svg" class="img-tick" alt="tick">
                </label>
              </div>
            </div> -->
            <!-- <div class="col-4">
              <div class="card-inspirations">
                <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Hammam.jpg" class="bg-card" alt="hammam">
                <input type="form-check-input" class="btn-check" @click="selectActivity" id="hammam" checked autocomplete="off">
                <label class="btn raleway-bold-p activity" for="hammam">Hammam
                  <img src="../../images/Tick.svg" class="img-tick" alt="tick">
                </label>
              </div>
            </div> -->
            <!--  <div class="col-4">
              <div class="card-inspirations">
                <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Centre+de+Fitness.jpg" class="bg-card" alt="centre de fitness">
                <input type="form-check-input" class="btn-check" @click="selectActivity" id="centre de fitness" checked autocomplete="off">
                <label class="btn raleway-bold-p activity" for="centre de fitness">Centre de fitness
                  <img src="../../images/Tick.svg" class="img-tick" alt="tick">
                </label>
              </div>
            </div> -->
            <!--  <div class="col-4">
              <div class="card-inspirations">
                <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Sauna.jpg" class="bg-card" alt="sauna">
                <input type="form-check-input" class="btn-check" @click="selectActivity" id="sauna" checked autocomplete="off">
                <label class="btn raleway-bold-p activity" for="sauna">Sauna
                  <img src="../../images/Tick.svg" class="img-tick" alt="tick">
                </label>
              </div>
            </div> -->
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/CASINO.png"
                  class="bg-card"
                  alt=""
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="casino"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p activity" for="casino"
                  >Casino
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/GOLF+3.png"
                  class="bg-card"
                  alt=""
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="golf"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p activity" for="golf"
                  >Golf
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/RESTAURANT+ETOIL%C3%89.png"
                  class="bg-card"
                  alt="étoilé"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="restaurant étoilé"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p activity"
                  for="restaurant étoilé"
                  >Restaurant étoilé
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Restaurant+gastronomique+.jpg"
                  class="bg-card"
                  alt="gastronomique"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="restaurant gastronomique"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p activity"
                  for="restaurant gastronomique"
                  >Restaurant gastronomique
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Sports+nautiques+.jpg"
                  class="bg-card"
                  alt="sports nautiques"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="sports nautiques"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p activity"
                  for="sports nautiques"
                  >Sports nautiques
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/SKI.png"
                  class="bg-card"
                  alt=""
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="ski"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p activity" for="ski"
                  >Ski
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Promenade+%C3%96+cheval+.jpg"
                  class="bg-card"
                  alt="cheval"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="promenade à cheval"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p activity"
                  for="promenade à cheval"
                  >Promenade à cheval
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Circuits+%C5%93nologiques.jpg"
                  class="bg-card"
                  alt="circuits œnologiques"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="circuits œnologiques"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p activity"
                  for="circuits œnologiques"
                  >Circuits œnologiques
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Balade+%C3%96+v%C3%87lo.jpg"
                  class="bg-card"
                  alt="balade à vélo"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="balade à vélo"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p activity" for="balade à vélo"
                  >Balade à vélo
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/VISITES+CULTURELLES.png"
                  class="bg-card"
                  alt=""
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="visites culturelles "
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p activity"
                  for="visites culturelles "
                  >Visites culturelles
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Randonn%C3%87e+.jpg"
                  class="bg-card"
                  alt="randonnée"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="randonnée"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p activity" for="randonnée"
                  >Randonnée
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Balade+p%C3%87destre.jpg"
                  class="bg-card"
                  alt="balade pédestre"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="balade pédestre"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p activity" for="balade pédestre"
                  >Balade pédestre
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Cours+de+yoga.jpg"
                  class="bg-card"
                  alt="cours de yoga"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="cours de yoga"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p activity" for="cours de yoga"
                  >Cours de yoga
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Cure+thermale+.jpg"
                  class="bg-card"
                  alt="cure thermale"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="cure thermale"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p activity" for="cure thermale"
                  >Cure thermale
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Cours+et+ateliers+th%C3%87matiques+.jpg"
                  class="bg-card"
                  alt="cours et ateliers thématiques"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectActivity"
                  id="cours et ateliers thématiques"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p activity"
                  for="cours et ateliers thématiques"
                  >Cours et ateliers thématiques
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-lodging">
        <div class="col-md-3 col-xs-7 question">
          <h3 class="raleway-reg-p24">Quel style d’hébergement ?</h3>
          <p class="raleway-reg-p15">
            Vous pouvez sélectionner plusieurs styles.
          </p>
        </div>
        <div class="col-md-9 col-xs-11 cards">
          <div class="row container-card-inspirations">
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+empire+.jpg"
                  class="bg-card"
                  alt="empire / renaissance"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="empire / renaissance"
                  checked
                  autocomplete="off"
                />
                <label
                  class="btn raleway-bold-p style"
                  for="empire / renaissance"
                  >Empire / Renaissance
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+antique+.jpg"
                  class="bg-card"
                  alt="antique"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="antique"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="antique"
                  >Antique
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel++Art+D%C3%87co.jpg"
                  class="bg-card"
                  alt="art déco"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="art déco"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="art déco"
                  >Art Déco
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Baroque+.jpg"
                  class="bg-card"
                  alt="Baroque"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="baroque"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="baroque"
                  >Baroque
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel++Design+Moderne+.jpg"
                  class="bg-card"
                  alt="design moderne"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="design moderne"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="design moderne"
                  >Design Moderne
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Contemporain.jpg"
                  class="bg-card"
                  alt="contemporain"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="contemporain"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="contemporain"
                  >Contemporain
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Rustique+.jpg"
                  class="bg-card"
                  alt="rustique"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="rustique"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="rustique"
                  >Rustique
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Orientale.jpg"
                  class="bg-card"
                  alt="orientale"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="orientale"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="orientale"
                  >Orientale
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+boh%C3%A4me.jpg"
                  class="bg-card"
                  alt="bohème"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="bohème"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="bohème"
                  >Bohème
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Shabby+Chic.jpg"
                  class="bg-card"
                  alt="shabby chic"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="shabby chic"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="shabby chic"
                  >Shabby chic
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Traditionnel.jpg"
                  class="bg-card"
                  alt="traditionnel"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="traditionnel"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="traditionnel"
                  >Traditionnel
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Scandinave.jpg"
                  class="bg-card"
                  alt="scandinave"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="scandinave"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="scandinave"
                  >Scandinave
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Industriel.jpg"
                  class="bg-card"
                  alt="industriel"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="industriel"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="industriel"
                  >Industriel
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Vintage.jpg"
                  class="bg-card"
                  alt="vintage"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="vintage"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="vintage"
                  >Vintage
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/H%C3%ACtel+Minimaliste.jpg"
                  class="bg-card"
                  alt="minimaliste"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectStyle"
                  id="minimaliste"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p style" for="minimaliste"
                  >Minimaliste
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-envie">
        <div class="col-md-3 col-xs-7 question">
          <h3 class="raleway-reg-p24">Quelles sont vos envies ?</h3>
          <p class="raleway-reg-p15">
            Vous pouvez sélectionner plusieurs envies.
          </p>
        </div>
        <div class="col-md-9 col-xs-11 cards">
          <div class="row container-card-inspirations">
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Me+d%C3%87tendre+.jpg"
                  class="bg-card"
                  alt="détendre"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectDesire"
                  id="me détendre"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p desire" for="me détendre"
                  >Me détendre
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Me+d%C3%87penser++.jpg"
                  class="bg-card"
                  alt="dépenser"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectDesire"
                  id="me dépenser"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p desire" for="me dépenser"
                  >Me dépenser
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Me+cultiver.jpg"
                  class="bg-card"
                  alt="me cultiver"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectDesire"
                  id="me cultiver"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p desire" for="me cultiver"
                  >Me cultiver
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Me+d%C3%87payser++.jpg"
                  class="bg-card"
                  alt="dépayser"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectDesire"
                  id="me dépayser"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p desire" for="me dépayser"
                  >Me dépayser
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Bien+manger.jpg"
                  class="bg-card"
                  alt="bien manger"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectDesire"
                  id="bien manger"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p desire" for="bien manger"
                  >Bien manger
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row row-type">
        <div class="col-md-3 col-xs-7 question">
          <h3 class="raleway-reg-p24">Quel type de voyage ?</h3>
          <p class="raleway-reg-p15">
            Vous pouvez sélectionner plusieurs types.
          </p>
        </div>
        <div class="col-md-9 col-xs-11 cards">
          <div class="row container-card-inspirations">
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Voyage+seul+.jpg"
                  class="bg-card"
                  alt="seul"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectType"
                  id="seul"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p type" for="seul"
                  >Seul
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Voyage+en+couple+.jpg"
                  class="bg-card"
                  alt="en couple"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectType"
                  id="en couple"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p type" for="en couple"
                  >En couple
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/voyage+en+famille+.jpg"
                  class="bg-card"
                  alt="en famille"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectType"
                  id="en famille"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p type" for="en famille"
                  >En famille
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/voyage+entre+amis+.jpg"
                  class="bg-card"
                  alt="entre amis"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectType"
                  id="entre amis"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p type" for="entre amis"
                  >Entre amis
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
            <div class="col-4">
              <div class="card-inspirations">
                <img
                  src="https://hortense-vue.s3.eu-west-3.amazonaws.com/Voyage+d'affaires+.jpg"
                  class="bg-card"
                  alt="voyage d’affaires"
                />
                <input
                  type="form-check-input"
                  class="btn-check"
                  @click="selectType"
                  id="voyage d’affaires"
                  checked
                  autocomplete="off"
                />
                <label class="btn raleway-bold-p type" for="voyage d’affaires"
                  >Voyage d’affaires
                  <img
                    src="../../images/Tick.svg"
                    class="img-tick"
                    alt="tick"
                  />
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="results">
      <div class="col-4">
        <p
          class="raleway-reg-p15 text-center mt-5"
          v-if="this.filteredLodgings"
        >
          Hortense a trouvé {{ this.filteredLodgings }} inspirations qui
          correspondent à vos critères
        </p>
        <a
          :href="'/inspirations-voyage-hortense?inspiration=' + this.parameters"
          class="hortense-btn-primary mb-5"
          >Inspirez-moi</a
        >
      </div>
    </div>
    <!-- <div class="lodging-filtered" v-if='this.filteredLodgings.length'>
      <div class="header text-center mb-5">
        <p class="merriweather-italic24">Laissez-vous guider par les suggestions d'Hortense</p>
        <p class="raleway-reg-p15">Authentique, intimiste, desgin, moderne... Découvrez notre séléction des plus beaux hébergements éco-responsables!</p>
      </div>
      <template v-for='lodging, index in this.filteredLodgings'>
        <CardProductbig :key='index' :lodging="lodging" :data-marker='index' :data-lodging='lodging.id' class="mb-5"></CardProductbig>
      </template>
    </div> -->
  </div>
</template>

<script>
import fetcher from "packs/shared/api.js";
import CardProductbig from "components/cards/CardProductbig.vue";
import Searchbtn from "components/btns/Searchbtn.vue";
import Searchbanner from "components/btns/Searchbanner.vue";

export default {
  name: "inspirations",
  components: { CardProductbig, Searchbtn, Searchbanner },
  data() {
    return {
      showBanner: false,
      filteredLodgings: null,
      selectedDestination: [null],
      selectedAmbiance: [null],
      selectedStyle: [null],
      selectedActivity: [null],
      selectedDesire: [null],
      selectedType: [null],
      destinationsMounted: null,
      ambiancesMounted: null,
      activitiesMounted: null,
      stylesMounted: null,
      desiresMounted: null,
      typeOfTripsMounted: null,
      parameters: {},
    };
  },
  methods: {
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    async inspireMe() {
      try {
        const params = {
          destination: this.selectedDestination,
          ambiance: this.selectedAmbiance,
          style: this.selectedStyle,
          activity: this.selectedActivity,
          desire: this.selectedDesire,
          type_of_trip: this.selectedType,
        };
        const res = await fetcher.get("/inspirations/count", {
          params: { inspiration: params },
        });
        this.filteredLodgings = res.data;
        this.parameters = encodeURIComponent(JSON.stringify(params));
      } catch (e) {
        console.error(e);
      }
    },
    selectDestination(e) {
      this.toggleInArray(e, "selectedDestination");
    },
    toggleInArray(e, name) {
      const id = this[name].indexOf(e.currentTarget.id);
      if (this[name].includes(e.currentTarget.id)) {
        this[name].splice(id, 1);
      } else {
        this[name].push(e.currentTarget.id);
      }
      e.currentTarget.nextElementSibling.classList.toggle("active");
      this.inspireMe();
    },
    selectAmbiance(e) {
      this.toggleInArray(e, "selectedAmbiance");
    },
    selectActivity(e) {
      this.toggleInArray(e, "selectedActivity");
    },
    selectStyle(e) {
      this.toggleInArray(e, "selectedStyle");
    },
    selectDesire(e) {
      this.toggleInArray(e, "selectedDesire");
    },
    selectType(e) {
      this.toggleInArray(e, "selectedType");
    },
    imageBackGroundDesti(data) {
      backgroundImage: `url(${data.description.photo_key})`;
    },
    imageBackGround(data) {
      backgroundImage: `url(${data.photo_key})`;
    },
    async fetchAllDestinations() {
      try {
        const response = await fetcher.get("/destinations.json");
        this.destinationsMounted = await response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchAllAmbiances() {
      try {
        const response = await fetcher.get("/ambiances.json");
        this.ambiancesMounted = await response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchAllActivities() {
      try {
        const response = await fetcher.get("/activities.json");
        this.activitiesMounted = await response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchAllStyles() {
      try {
        const response = await fetcher.get("/styles.json");
        this.stylesMounted = await response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchAllDesires() {
      try {
        const response = await fetcher.get("/desires.json");
        this.desiresMounted = await response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchAllTypeOfTrips() {
      try {
        const response = await fetcher.get("/type_of_trips.json");
        this.typeOfTripsMounted = await response.data;
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    // this.fetchAllDestinations();
    // this.fetchAllAmbiances();
    // this.fetchAllActivities();
    // this.fetchAllStyles();
    // this.fetchAllDesires();
    // this.fetchAllTypeOfTrips();
  },
};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/inspi"></style>
