<template>
  <div id="show-message-user" v-if='this.chatMount'>
    <div class="container-index">
      <a href="javascript:history.go(-1)" class="no-deco mb-5 mt-5"> < Retour </a>
      <p class="raleway-bold text-center mb-5">Objet : {{this.chatMount.subject}}</p>
      <div class="card-message" :class="{'sent': isSender(message), 'received': !isSender(message)}" v-for="message in this.chatMount.messages">
        <div class="card-review">
          <div class="review-autor">
            <h3 class="raleway-reg-p13 mb-4">{{formatSender(message)}} le {{ createdAt(message.created_at)}} :</h3>
          </div>
           <p class="merriweather-italic-13">"{{message.body}}"</p>
        </div>
      </div>
      <div class="object-of" v-if="!chatMount.subject">
        <input type="text" v-model="chatRoom.subject">
        <v-select :options='listObjects' v-model="chatRoom.object" v-if='!this.chatRoom.object'></v-select>
        <v-select :options='listUsers' v-model="newMessage.receiver_id"></v-select>
      </div>

      <div class="card-reply">
        <textarea name="body" id="body" cols="71" rows="8" v-model="newMessage.body"></textarea>
      </div>
      <button class="hortense-btn-snd" @click="sendMessage" v-if="!chatMount.subject">Envoyer</button>
      <button class="hortense-btn-snd" @click="replyMessage" v-else>Envoyer</button>
    </div>
  </div>
</template>

<script>
import fetcher from "packs/shared/api";
import toDate from 'packs/shared/formatDate.js';

export default {
  name: "MessageShowUser",
  props: {
    chat_room: Object,
    user: Object,
  },
  data() {
    return {
      listObjects: ['hortense', 'client'],
      listUsers: [3],
      chatMount: null,
      chatRoom: {
        object: this.chat_room.object,
        subject: "",
      },
      newMessage: {
        lodging_id : null,
        receiver_id: null,
        body: "",
      },
    };
  },
  methods: {
    async fetchMessage() {
      try {
        const response = await fetcher.get('/chat_rooms/' + this.chat_room.id);
        this.chatMount = response.data;
        this.newMessage.receiver_id = this.receiverId()
        console.log(this.receiverId())
        // this.newMessage.lodging_id = this.chatMount.messages[0].lodging_id
      } catch (e) {
        console.error(e);
      }
    },
    async sendMessage() {
      try{
        const res = await fetcher.patch('/chat_rooms/'+ this.chat_room.id, {chat_room: this.chatRoom})
        this.chatMount = res.data
        const resMessage = await fetcher.post('/chat_rooms/'+ this.chat_room.id + '/messages', {message: this.newMessage})
        this.chatMount.messages.push(resMessage.data)
        this.newMessage.body = ''
      }catch(e){
        console.error(e)
      }
    },
    async replyMessage() {
      try {
        const res = await fetcher.post('/chat_rooms/'+ this.chat_room.id +'/messages/', {message: this.newMessage})
        this.chatMount.messages.push(res.data)
        this.newMessage.body = ''
      } catch (e) {
        console.error(e);
      }
    },
    formatSender(message){
      if (this.isSender(message)) return 'Vous avez envoyé ce message'

      return `${message.sender.first_name} vous à envoyé ce message`
    },
    isSender(message){
      return (message.sender.id === this.user.id)
    },
    createdAt(timestamp) {
      return toDate.toDate(timestamp)
    },
    receiverId(){
      if (this.chatMount.messages.length) {
        console.log(this.chatMount.messages.find( m => m.receiver.id != this.user.id))
        return this.chatMount.messages.find( m => m.receiver.id != this.user.id).receiver.id
      }
    },
  },
  mounted() {
    this.fetchMessage();
  },
}
</script>

<style lang="scss" scoped>
#show-message-user{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  .sent{
    position: relative;
    left: 100%;
    transform: translate(-60%, 0px);
    .card-review{
      background-color: $light-green;
    }
  }
  .received{
    .card-review{
      background-color: #DDF8F8;
    }
  }
  .card-message{
    .card-review{
      // border: 1px solid grey;
      border-radius: 15px;
      padding: 2rem 2rem;
      width: 50%;
      margin-left: 5rem;
      transition: opacity 2s cubic-bezier(.165, .84, .44, 1);
      margin-bottom: 4rem;
      box-shadow: 0 1px 7px 0 rgba(0, 0, 0, .2), 0 6px 20px 0 rgba(0, 0, 0, .15);
      .review-autor{
        display: flex;
        justify-content: space-between;
      }
    }
  }
  .hortense-btn-snd{
    margin: 0 auto;
  }
  .card-reply{
    display: flex;
    justify-content: flex-end;
    margin-bottom: 3rem;
    padding: 1rem 2rem;
    textarea{
      border-radius: 5px;
      padding: 1rem 2rem;
      outline: 0;
    }
  }
  .object-of{
    width:400px;
    input{
      margin-bottom: 1rem;
    }
    .v-select{
      margin-bottom: 1rem;
    }
  }
}
</style>
