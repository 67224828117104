<template>
  <div :id='this.status + "-flash"' class="flash my-alert transition-15" role="alert" v-if='this.status'>
    <span class="mr-3">{{ this. text }}</span>
    <i class="fas fa-times" @click='$emit("destroy")'></i>
  </div>
</template>

<script>
  export default {
    name: 'flash',
    props: {
      text: String,
      status: String
    },
    updated() {
      setTimeout(() => this.$emit('destroy'), 5000);
    }
  }
</script>

<style lang='scss'>
  #success-flash {
    border: 2px solid #41b883;
  }

  #warning-flash {
    border: 2px solid #FFC65A;
  }

  #alert-flash {
    border: 2px solid #FD1015;
  }

  .flash {
    padding: 16px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    box-shadow: 0 0 8px rgba(0,0,0,0.2);
    border-radius: 4px;
    margin: 8px;
  }
  .transition-15 {
    transition: opacity 0.15s linear;
  }
  .transition-25 {
    transition: opacity 0.25s linear;
  }
  .my-alert {
    position: fixed;
    bottom: 16px;
    right: 16px;
    z-index: 1000;
  }
</style>
