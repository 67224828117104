<template>
  <div id="carddesti-phone">
    <div class="carddesti-header">
      <p class="subtitle-card raleway-bold-p mt-4">{{ destination.name }}</p>
      <p class="description-card raleway-reg-p13">{{ destination.content }}</p>
      <a :href="'/destination/'+ destination.slug" class="link-hortense">Voir les établissements</a>
    </div>
    <slither-slider :options="{numberOfSlides: 2,
                                  dots: false,
                                  controls: false, loop: true, autoplay: true}">
      <div v-for="(region, index) in destination.regions" :key='index'>
        <div class="col-grid-desti" v-if="region.photo">
          <img :src="region.photo" :alt="region.name">
          <a :href="'/destination/'+ region.slug" class="link-hortense">
            <p class="region-name raleway-reg-p15">{{ region.name }}</p>
          </a>
        </div>
        <div class="col-grid-desti" v-else>
          <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" :alt="region.name">
          <a :href="'/destination/'+ region.slug" class="link-hortense">
            <p class="region-name raleway-reg-p15">{{ region.name }}</p>
          </a>
        </div>
        <template slot="previous">
          <img src="../../images/fleche gauche.svg" alt="fleche gauche">
        </template>
        <template slot="next">
          <img src="../../images/fleche droite.svg" alt="fleche droite">
        </template>
      </div>
    </slither-slider>
  </div>
</template>

<script>

  export default {
    name: 'CardDestiPhone',
    props: [ 'destination' ],
    data() {
      return {

      }
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/desti-phone'>

</style>
