<template>
    <div id="search-bannerr" ref="searchBanner" class="mt-3">
      <div class="container-banner">
        <div class="search-location">
          <img src="../../images/Loupe.png" alt="search icon" class="icon" />
          <input
              class="raleway-reg-p15 search-bar-location"
              type="text"
              placeholder="Recherchez un hôtel, une destination"
              v-model="search"
          />
        </div>
        <span class="separator">|</span>
        <div class="search-duration">
          <img src="../../images/3.png" alt="calendar icon" class="icon" />
          <HotelDatePicker
              :startDate="minDate"
              :endDate="maxDate"
              :format="'DD/MM/YYYY'"
              :gridStyle="false"
              :hoveringTooltip="false"
              :i18n="cFR"
              ref="datePicker"
              @periodSelected="selectDates"
          ></HotelDatePicker>
        </div>
        <span class="separator">|</span>
        <div class="search-number">
          <img src="../../images/pers.png" alt="guest icon" class="icon" />
          <input
              class="raleway-reg-p15 search-bar-top text-center"
              @click="showGuestInfo = !showGuestInfo"
              :placeholder="formatAdults() + ' - ' + formatChilds()"
          />
          <Guestinfo
              v-if="showGuestInfo"
              @ageSelected="updateAges"
              @increaseRooms="updateRooms"
              @decreaseRooms="updateRooms"
              @increaseChilds="updateChilds"
              @decreaseChilds="updateChilds"
              @increaseAdults="updateAdults"
              @decreaseAdults="updateAdults"
              @closed="handleClose"
          />
        </div>
        <div class="search-location">
          <a class="around-btn link-hortense" @click="getLocation">
          <img src="../../images/Localisation.svg" alt="" />
          Autour de moi
        </a>
        </div>
        <template>
          <button class="hortense-btn-primary" style="height: 5.5vh; width: 10vw;" :disabled="disableGoButton" @click="performSearch">
            <div class="search-button">
              {{ searchButtonCaption }}
            </div>
          </button>
        </template>
      </div>
    </div>
  </template>
  
  <script>
  import Guestinfo from "components/btns/Guestinfo.vue";
  import { VueDatePicker } from "@mathieustan/vue-datepicker";
  import fetcher from '../../packs/shared/api.js';
  
  export default {
    name: "MainSearch",
    components: { Guestinfo, VueDatePicker },
    data() {
      return {
        lat: "",
        long: "",
        not_enable: false,
        valtemp: "",
        selectedDates: [],
        showGuestInfo: false,
        rooms: 1,
        childs: 0,
        adults: 1,
        childAges: [],
        color: "#CDDFD3",
        date: new Date(),
        currentDate: new Date(),
        display: true,
        isSearching: false,
        searchResults: null,
        cFR: {
          night: "Nuit",
          nights: "Nuits",
          week: "semaine",
          weeks: "semaines",
          "day-names": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
          "check-in": "Départ",
          "check-out": "Arrivée",
          "month-names": [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre",
          ],
          tooltip: {
            halfDayCheckIn: "Available CheckIn",
            halfDayCheckOut: "Available CheckOut",
            saturdayToSaturday: "Only Saturday to Saturday",
            sundayToSunday: "Only Sunday to Sunday",
            minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum.",
          },
        },
      };
    },
    watch: {
      searched() {
        this.isSearching = !this.searched;
      },
      expandCalendar(new_value, old_value) {
        if (this.expandCalendar) {
          window.scrollTo({ top: 0, behavior: "smooth" });
          setTimeout(() => {
            this.$refs.datePicker.showDatepicker();
          }, 100);
        }
        this.$emit("updateExpandCalendar");
      },
    },
    mounted() {
      const searchBanner = this.$refs.searchBanner;
      const offset = searchBanner.offsetTop; // Get the initial offset position of the search bar
  
      window.addEventListener('scroll', function () {
        if (window.pageYOffset > offset) {
          searchBanner.classList.add('fixed');
        } else {
          searchBanner.classList.remove('fixed');
        }
      });
    },
    methods: {
      getLocation(){
      navigator.geolocation.getCurrentPosition((position) => {
        window.open(`/reservation-hotel?geo=${position.coords.latitude},${position.coords.longitude}`, '_blank');
      });
    },
      handleClose() {
        this.showGuestInfo = false;
      },
      formatKoediaDate(date) {
            if (date) {
            const dateBits = date.split("/");
            return `${dateBits[2]}-${dateBits[1]}-${dateBits[0]}`;
            } else {
            return null;
            }
        },
      selectDates(event, start, end) {
        this.selectedDates = [this.formatDate(start), this.formatDate(end)];
      },
      formatDate(date) {
        const set = new Date(date);
        const options = { year: "numeric", month: "numeric", day: "numeric" };
        return set.toLocaleString("fr", options);
      },
      getLocation() {
        navigator.geolocation.getCurrentPosition(function (position) {
          let lat = position.coords.latitude;
          let long = position.coords.longitude;
          window.open(`/reservation-hotel?geo=${lat},${long}`, '_blank');
          // Turbolinks.visit(`/reservation-hotel?geo=${lat},${long}`);
        });
      },
      updateRooms(room) {
        this.rooms = room;
        this.$emit("numberOfRoom", this.rooms);
      },
      formatRooms() {
        let chambre = " Chambre";
        if (this.rooms > 1) {
          chambre += "s";
        }
        return this.rooms + chambre;
      },
      updateChilds(child) {
        this.childs = child;
        this.$emit("numberOfChild", this.childs);
      },
      formatChilds() {
        let enfant = " Enfant";
        if (this.childs > 1) {
          enfant += "s";
        }
        return this.childs + enfant;
      },
      updateAdults(adult) {
        this.adults = adult;
        this.$emit("numberOfAdult", this.adults);
      },
      updateAges(value) {
        this.childAges = [...value];
        this.$emit("ageOfChild", this.childAges);
      },
      formatAdults() {
        let adulte = " Adulte";
        if (this.adults > 1) {
          adulte += "s";
        }
        return this.adults + adulte;
      },
      formatDateSyntax(dateString) {
        let dateParts = dateString.split('/');
  
        let day = dateParts[0];
        let month = dateParts[1];
        let year = dateParts[2].slice(-2);
  
        return `${day}/${month}/${year}`;
      },
    performSearch() {
        this.$emit("selectDates", this.selectedDates);
        this.isSearching = true;

        const params = new URLSearchParams({
        search: this.search,
        childs: this.childs,
        adults: this.adults,
        start_date: this.selectedDates ? this.formatKoediaDate(this.selectedDates[0]) : null,
        end_date: this.selectedDates ? this.formatKoediaDate(this.selectedDates[1]) : null,
        });
        window.dataLayer = window.dataLayer || [];
      let bookingWindow = null;

      if (this.selectedDates && this.selectedDates[0]) {
        const dateInParts = this.selectedDates[0].split('/');
        const dateIn = new Date(`${dateInParts[2]}-${dateInParts[1]}-${dateInParts[0]}`);
        const today = new Date();
        const timeDifference = dateIn - today;
        bookingWindow = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      }

      window.dataLayer.push({
        event: 'search',
        ecommerce: {
          search_term: this.search,
          booking_window: bookingWindow,
          date_in: this.selectedDates ? this.formatKoediaDate(this.selectedDates[0]) : null,
          date_out: this.selectedDates ? this.formatKoediaDate(this.selectedDates[1]) : null,
          number_of_people: this.childs + this.adults,
          number_of_adult: this.adults,
          number_of_child: this.childs,
          results: 93,
          number_of_nights: 1
        }
      });

        window.location.href = `/reservation-hotel?${params.toString()}`;
    },
    },
    computed: {
      searchButtonCaption() {
        return this.isSearching ? "Recherche..." : "RECHERCHER";
      },
      disableSearchButton() {
        return this.isSearching;
      },
      disableGoButton() {
        return false;
      },
      minDate() {
        return new Date(
            this.currentDate.getFullYear(),
            this.currentDate.getMonth(),
            this.currentDate.getDate()
        );
      },
      maxDate() {
        return new Date(
            this.currentDate.getFullYear() + 1,
            this.currentDate.getMonth(),
            this.currentDate.getDate()
        );
      },
    },
  };
  </script>
  
  <style scoped>
  #search-bannerr {
    padding: 1rem;
    width: 95%;
    height: 9vh !important;
    margin: 0 auto;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    background: #fff 0% 0% no-repeat padding-box;
    box-shadow: 5px 3px 6px #00000029;
    opacity: 1;
    transition: all 0.3s ease-in-out; /* Smooth transition */
  }
  
  #search-bannerr.fixed {
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 1000;
    margin-top: 0px !important;
  }
  
  .container-banner {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap; /* Allow wrapping */
  }
  
  .search-location,
  .search-duration,
  .search-location,
  .search-number {
    display: flex;
    align-items: center;
    flex-shrink: 0;
  }
  
  .search-bar-location {
  background-color: white;
  border-width: 0px;
  width: 260px; /* Slightly reduce width for better spacing */
}

.search-bar-top {
  background-color: white;
  border-width: 0px;
  width: 150px; /* Adjust width for guest info input */
  text-align: center;
}
  
  .search-bar-location::placeholder,
  .search-bar-top::placeholder {
    color: black;
  }
  
  .search-bar-location:focus,
  .search-bar-top:focus {
    outline: none;
    box-shadow: none;
  }
  
  .icon {
    margin-right: 0.5rem;
  }
  
  .separator {
    color: grey;
    margin: 0 1rem;
  }
  
  .hortense-btn-primary {
    padding: 8px 16px;
    background-color: #3b6152;
    color: white;
    border: none;
    cursor: pointer;
    flex-shrink: 0;
  }
  
  .no-deco {
    text-decoration: none;
  }
  
  .no-deco.text-white {
    color: white;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .around-btn {
  cursor: pointer;
  width: 130px; /* Adjust button width */
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  font-size: 0.9rem !important;
  margin-right: 10px;
}

.around-btn img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
  </style>
  