<template>
  <div id="search-btn-phone">
    <div @click="$emit('show')">
      <button>
        <img src="../../images/loupe.svg" style="width:20xp;height:30px" alt="loope">
      </button>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'SearchBtnPhoneWhite',
    data() {
      return {
        showBanner: false
      }
    },
  }
</script>
