<template>
  <div id="exp-phone">
    <div class="phone-container">
      <div class="experience-header">
        <h2 class="merriweather-reg-26">{{ $t("Experience.header") }}</h2>
        <h2 class="merriweather-reg-40 mb-5"> {{ $t("Experience.title") }}</h2>
        <p class="raleway-reg-p15">{{ $t("Experience.content") }}</p>
      </div>
        <slither-slider :options="{ loop: true, autoplay: true, numberOfSlides: 1,dots: false, controls: false}">
          <template v-for="category in categoryNames">
            <CardexpPhone :category="category" :key="category"></CardexpPhone>
          </template>
        </slither-slider>
      <a class="hortense-btn-primary raleway-bold-p mt-5" :href="'/experiences'">{{ $t("Experience.viewbtn") }}</a>
    </div>
  </div>
</template>


<script>

  import CardexpPhone from 'components/cards-phone/CardexpPhone.vue';


  export default {
    name: 'exp-phone',
    components: { CardexpPhone },
    props: {
      experiencesByCategory: Object,
    },
    data() {
      return {
        categoriesMounted: this.experiencesByCategory,
        categoryNames:[]
      }
    },
    methods: {
      getKeys(){
        Object.entries(this.experiencesByCategory).forEach(([key, value]) => this.categoryNames.push(key));
      },
    },
    mounted(){
      this.getKeys();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/exp-phone'>

</style>
