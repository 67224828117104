<template>
  <div id="card-advantage">
    <div class="head-card">
      <p class="raleway-reg-p24">Vos avantages</p>
      <p class="raleway-reg-p15">Hortense a négocié pour vous des avantages et réductions avec des marques véritablement éthiques et green. Profitez de ces codes promos exclusifs dès maintenant.</p>
    </div>
    <div class="green-section mt-4" v-if='this.advantages > 0'>
      <template v-for="advantage in this.advantages">
        <div class="green-night mb-3">
          <p class="raleway-bold-p mb-0">NomMARQUE</p>
          <div class="middle text-center">
            <p class="raleway-reg-p15 mb-0">1 nuit offerte soit 150€</p>
            <p class="raleway-bold-p mb-0">NUMEROCODE</p>
          </div>
          <p class="raleway-reg-p15 mb-0">Validité : 23/02/2020</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'card-advantage',
    props: ["user"],
    data() {
      return {
        userStatut: '',
        userMount: '',
        advantages: 1,
      }
    },
    methods: {
      async setUserLevel(){
        const res = await fetcher.get('/dashboard/'+this.user.slug)
        this.userMount = res.data
        this.userStatut = this.userMount.privilege.statut
      },
    },
    mounted() {
      this.setUserLevel();
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>

</style>
