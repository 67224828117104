<template>
  <div id="search-btn">
    <div class="menu-burger" @click="$emit('top')">
      <button>
        <img src="../../images/Menu burger.svg" alt="">
      </button>
    </div>
    <div class="search-img" @click="$emit('show')">
      <button>
        <img src="../../images/loupe.svg" alt="">
      </button>
    </div>
  </div>
</template>

<script>
  export default{
    name: 'SearchBtn',
    data() {
      return {
        showBanner: false,
      }
    },
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/search-btn'>

</style>
