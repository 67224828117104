<template>
  <div :id="id">
    <div class="row row-region">
      <div class="col-lg-6 col-xs-12 col-region">
        <div class="header-region d-flex">
          <h2 class="merriweather-italic mr-4">{{index+1}}</h2>
          <h2 class="raleway-bold-p mb-5">{{ this.region.name }}</h2>
        </div>
        <p class="raleway-reg-p15 pr-5 mb-4"> {{ this.region.content }} </p>
        <!-- <a href="#" class="link-hortense">Voir le guide de voyage</a> -->
        <a :href="'/destination/' + this.region.slug" class="hortense-btn-primary btn-region mt-5">{{ this.formatlodgings() }}</a>
      </div>
      <div class="col-lg-6 col-xs-12 col-region-img" v-if="this.region.photo">
        <img :src="this.region.photo" class="gallery__img" :alt="this.region.name">
      </div>
      <div class="col-lg-6 col-xs-12 col-region-img" v-else>
        <img src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" class="gallery__img" :alt="this.region.name">
      </div>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';
  export default{
    name: 'card-region',
    props: ['id', 'region', 'index'],
    data() {
      return {

      }
    },
    methods: {
      formatlodgings() {
        return (this.lodgingsCount() > 0 ? this.lodgingsCount() : "") + (this.lodgingsCount() > 1 ? ' établissements' :  this.lodgingsCount() == 1 ?  ' établissement' : 'DÉCOUVRIR');
      },
      lodgingsCount(){
        return this.region.get_lodgings.length
      }
    },
    mounted(){
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/card-region'>

</style>
