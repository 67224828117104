<template>
  <div id="sliderEvergreen" class="d-flex">

     <div id="cardEvergreen">
        <div class="card-ever">
        <img :src="this.project.photo_key" alt="">
          <div class="sticker-program d-flex mt-4 pl-2">
            <div class="sticker-program-info d-flex">
              <p class="raleway-bold-h3 mb-0 pl-1 pr-2">{{ this.project.title }}</p>
              <p class="raleway-reg-p13 mb-0 pl-1 pr-2">{{ this.project.goal }}</p>
            </div>
          </div>
        </div>
      </div>

  </div>
</template>


<script>

  import CardEvergreen from 'components/cards/CardEvergreen.vue';

  export default {
    name: 'sliderEvergreen',
    components:{ CardEvergreen },
    props: [ 'project' ],


  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/evergreen-home'>

</style>
