<template>
  <div class="container mt-5">
    <h3 style="font: normal normal normal 26px/33px Merriweather;" >Les Chambres</h3>
    <div class="banner" style="width: 100%; max-width: 1110px; height: 271px; margin: auto; background-color: #f8f9fa;">
      <img :src="this.club.photo_club_banner" alt="Hotel Banner" style="width: 100%; height: 100%; object-fit: cover;">
    </div>

    <div class="container mt-4">
      <div class="row">
        <div class="col-md-4 col-12 mb-3" v-for="room in rooms" :key="room.id">
          <div class="card room-card" :style="{ backgroundImage: 'url(' + room.photo_key + ')' }">
            <div class="card-img-overlay d-flex flex-column justify-content-end">
              <p class="card-text">
                <strong>{{ room.name }}</strong><br>
                {{ room.area }}
                <br>
                {{ room.bathroom }}
                <br>
                {{ room.type }}
                <br>
                {{ room.divers }}
              </p>
              <div class="card-capacity">
                <img src="../../images/Pers.svg" alt="Capacity">
                {{ room.capacity }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotelRoom',
  props: ['club_rooms', 'club'],
  data() {
    return {
      rooms: this.club_rooms
    }
  }
}
</script>

<style scoped>
.room-card {
  background-size: cover;
  background-position: center;
  height: 148px;
}
.card-capacity {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
}
.room-card {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 148px;
}

.room-card::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.1); /* Change the rgba last value to adjust opacity */
  z-index: 1;
}

.card-img-overlay {
  position: relative;
  z-index: 2; /* Ensure text appears above the overlay */
}
@media (max-width: 768px) {
  .banner {
    height: auto; /* Adjust height for mobile devices */
  }
}
</style>
