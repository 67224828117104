<template>
 <div id="lodging-show">
   <div
       id="banner-lodging"
       :style="{backgroundImage: 'url('+ yoga.photo_second_banner +')'}"
   >
     <ModalYoga
         :lodging="this.yoga"
         :photoKeys="this.yoga.photos_keys"
         v-if="modalGallery"
         @close="modalGallery = false"
     ></ModalYoga>
     <div class="btn-galerie mb-3">
       <div class="galerie-icon df-jsb-aic">
         <img src="../../images/Galerie icone.svg" alt="galerie" />
         <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
       </div>
     </div>
     <div class="banner-show-container">
       <div class="col-9 offset-3 catch-hub-show">
         <p class="merriweather-italic30">
           {{ this.yoga.hub_description }}
         </p>
       </div>
     </div>
     <div class="btn-galerie-phone mb-3">
       <div class="galerie-icon df-jsb-aic">
         <img src="../../images/Galerie icone.svg" alt="galerie" />
         <p class="raleway-bold-p mb-0" @click="showModalGallery">Galerie</p>
       </div>
     </div>
   </div>
  <div class="nav-lodging">
    <div class="lodging-header">
      <div class="header">
        <h1 v-if="this.stay_name" class="lodging-show-raleway-reg-p26-b text-center">{{ this.stay_name }}</h1>
      </div>
    </div>
    <div id="headDestiii">
    <div class="list-desti-btns mt-5">
      <ul class="bar">
        <li  v-if="!isPhone" v-for="(schedule, i) in this.yoga_schedules" :key="schedule.id" class="menu-item raleway-reg-p16">
          <button :id="'button_' + schedule.id" class="dateBUtton"  v-if="i==0" style="font: normal normal 18px/36px Raleway; font-weight: bold !important;" @click="fetchDestinations(schedule.id)">{{schedule.yoga_date}}</button>
          <button :id="'button_' + schedule.id" class="dateBUtton" style="font: normal normal 18px/36px Raleway;" v-if="i!=0" @click="fetchDestinations(schedule.id)">{{schedule.yoga_date}}</button>
        </li>
        <li>
          <a
              @click="goToContact()"
             class="hortense-btn-primary df-jsb-aic"
          >CONTACTEZ NOUS</a
          >
        </li>
      </ul>
    </div>
  </div>
  </div>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <p style="color: #0a0a0a !important;" v-for="(schedule, i) in this.yoga_schedules" :key="schedule.id" class="raleway-reg-p16 text-center">
       <button :id="'button_' + schedule.id" v-if="i==0" style=" font: normal normal  18px/36px Raleway; font-weight: bold !important; color: #0a0a0a !important;" class="dateBUtton" @click="fetchDestinations(schedule.id)">{{schedule.yoga_date}}</button>
       <button :id="'button_' + schedule.id" v-if="i!=0" style="font: normal normal  18px/36px Raleway; color: #0a0a0a !important;" class="dateBUtton" @click="fetchDestinations(schedule.id)">{{schedule.yoga_date}}</button>
     </p>
   </div>
   <div class="nav-lodging" style="background-color: #F7F7F7">
       <div style="text-align: center; background-color: #F7F7F7" >
<!--           <p class="mab"  style="font:  normal normal normal 24px/42px Raleway;">{{yoga.accommodation}}</p>-->
         <p class="mab2" style="font:  normal normal bold 24px/42px Raleway;" v-if='yoga.yoga_place'>{{yoga.yoga_place.title}}</p>
         <p class="mab" style="font: normal normal normal 18px/36px Raleway;">{{yoga.location_detail}}</p>
         <p v-if="this.yoga_schedule && !is_urban" class="mab " style="font: normal normal 600 18px/36px Raleway;">{{ this.yoga_schedule.number_of_days }} jours / {{ this.yoga_schedule.number_of_nights }} nuits</p>
         <p v-if="this.yoga_schedule && is_urban" class="mab " style="font: normal normal 600 18px/36px Raleway;">One Day Retreat</p>
         <p v-if="this.current_date" class="mab" style="font:  italic normal normal 18px/36px Raleway;">{{this.current_date}}</p>
           <div>
             <img src="../../images/yoga_icon.png" alt="">
           </div>
         <p v-if="this.yoga_schedule" class="mab" style="font: italic normal normal 18px/36px Raleway;">{{this.yoga_schedule.yoga_type}}</p>
         <div v-if="this.yoga_schedule" style="display: flex; align-items: center; justify-content: center;">
           <p  class="mab2 " style="font: normal normal normal 18px/36px Raleway;">Niveau : </p><p style="font: italic normal 600 18px/36px Raleway"> {{ this.yoga_schedule.level }}</p>
         </div>
           <p v-if="this.yoga_schedule" class="mab2 " style="font:  normal normal bold 18px/36px Raleway;">À partir de {{ this.yoga_schedule.lowest_rate }} €</p>
       </div>
   </div>
   <YogaPlace :yoga_place="yoga_place" v-if="!isPhone" :yoga_services="yoga_services"></YogaPlace>
   <div v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3 pt-5 mt-5">
     <div
         class="accordion-header pt-5 mt-5"
         @click.prevent="isExpand2 = !isExpand2"
         :class="this.isExpand2 ? 'active' : ''"
     >
       <p class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">LE LIEU</p>
     </div>
     <div class="accordion-body" :class="this.isExpand2 ? 'active' : ''">
       <YogaPlacePhone :yoga_place="yoga_place" v-if="isPhone" :yoga_services="yoga_services"></YogaPlacePhone>
     </div>
   </div>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <div
         class="accordion-header"
         @click.prevent="isExpand3 = !isExpand3"
         :class="this.isExpand3 ? 'active' : ''"
     >
       <p v-if="!is_urban" class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">Informations sur le séjour</p>
       <p v-if="is_urban" class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">L'expérience Urban Retreat</p>
     </div>
     <div class="accordion-body" :class="this.isExpand3 ? 'active' : ''">
       <yogaStayPhone :yoga_stay="yoga_stay"></yogaStayPhone>
     </div>
   </div>
   <YogaStay v-if="!isPhone" :yoga_stay="yoga_stay" :is_urban="is_urban"></YogaStay>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <div
         class="accordion-header"
         @click.prevent="isExpand4 = !isExpand4"
         :class="this.isExpand4 ? 'active' : ''"
     >
       <p v-if="!is_urban" class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">LE PROGRAMME DU SÉJOUR</p>
       <p v-if="is_urban" class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">Le programme de la journée</p>
     </div>
     <div class="accordion-body" :class="this.isExpand4 ? 'active' : ''">
       <yogaProgramPhone v-if=" this.yoga_schedule.yoga_programs" :yoga_program=" this.yoga_schedule.yoga_programs" :yoga_schedule="this.yoga_schedule"></yogaProgramPhone>
     </div>
   </div>
   <YogaProgram v-if="!isPhone && yoga_schedule.yoga_programs" :is_urban="is_urban" :yoga_program="yoga_schedule.yoga_programs" :yoga_schedule="yoga_schedule"></YogaProgram>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <div
         class="accordion-header"
         @click.prevent="isExpand5 = !isExpand5"
         :class="this.isExpand5 ? 'active' : ''"
     >
       <p class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">LES INTERVENANTS</p>
     </div>
     <div class="accordion-body" :class="this.isExpand5 ? 'active' : ''">
       <YogaTeacher v-if="this.yoga_teacher" :yoga_teacher="this.yoga_teacher"></YogaTeacher>
     </div>
   </div>
   <YogaTeacher v-if="!isPhone && this.yoga_teacher" :yoga_teacher="this.yoga_teacher"></YogaTeacher>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <div
         class="accordion-header"
         @click.prevent="isExpand6 = !isExpand6"
         :class="this.isExpand6 ? 'active' : ''"
     >
       <p class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">LA LOCALISATION</p>
     </div>
     <div class="accordion-body" :class="this.isExpand6 ? 'active' : ''">
       <div class="pb-4" style="font: normal normal normal 15px/18px Raleway;" v-html="yoga.location_description"></div>
       <YogaMap :markers="this.markers"></YogaMap>
     </div>
   </div>
   <div v-if="!isPhone" class="container  justify-content-md-center mt-3">
     <h3 style="font: normal normal normal 26px/33px Merriweather;">La localisation</h3>
     <div class="pb-4" style="font: normal normal normal 15px/18px Raleway;" v-html="yoga.location_description"></div>
   </div>
   <YogaMap v-if="!isPhone" :markers="this.markers"></YogaMap>
   <div style="margin-top: 0 !important;" v-if="isPhone" id="description" class="lodging-discover col-lg-8 offset-lg-2 col-md-11 offset-md-1 p-3">
     <div
         class="accordion-header"
         @click.prevent="isExpand7 = !isExpand7"
         :class="this.isExpand7 ? 'active' : ''"
     >
       <p class="raleway-reg-p24" style="font: normal normal bold 15px/44px Raleway;">Votre demande de réservation</p>
     </div>
     <div class="accordion-body" :class="this.isExpand7 ? 'active' : ''">
       <YogaPayment :yoga_id="yoga.id" :yoga_rate="yoga_rate"></YogaPayment>
     </div>
   </div>
   <YogaPayment :yoga_id="yoga.id" v-if="!isPhone" :yoga_rate="yoga_rate"></YogaPayment>
 </div>

</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";

import HeadYoga from "./HeadYoga.vue";
import ScrollUp from "../../components/btns/ScrollUp.vue";
import fetcher from "packs/shared/api.js";
import Searchbtn from "../../components/btns/Searchbtn.vue";
import Searchbanner from "../../components/btns/Searchbanner.vue";
import YogaCard from "./YogaCard.vue";
import YogaStay from "./YogaStay.vue";
import ModalGallery from "../../components/modals/ModalGallery.vue";
import Modalshare from "../../components/modals/Modalshare.vue";
import YogaProgram from "./YogaProgram.vue";
import yogaStay from "./YogaStay.vue";
import YogaPlace from "./YogaPlace.vue";
import YogaTeacher from "./YogaTeacher.vue";
import YogaPayment from "./YogaPayment.vue";
import YogaMap from "../../components/YogaMap.vue";
import YogaPlacePhone from "./YogaPlacePhone.vue";
import yogaStayPhone from "./YogaStayPhone.vue";
import yogaProgramPhone from "./YogaProgramPhone.vue";
import ModalYoga from "../../components/modals/ModalYoga.vue";
export default {
  name: "Yogashow",
  components: {
    YogaMap,
    Modalshare, ModalGallery, HeadYoga, ScrollUp, Searchbtn, Searchbanner, YogaCard, YogaStay, YogaProgram, yogaStay, YogaPlace, YogaTeacher, YogaPayment, YogaPlacePhone, yogaStayPhone, yogaProgramPhone, ModalYoga},
  props: {
    yoga: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      stay_name: this.yoga.online_yoga_schedules[0].stay_name,
      modalGallery: false,
      isExpand: false,
      isExpand2: true,
      isExpand3: false,
      isExpand5: false,
      isExpand6: false,
      showBanner: false,
      isExpand4: false,
      isExpand7: true,
      selectedDestination: "France",
      destinations: null,
      isLoading: true,
      yoga_schedules: this.yoga.online_yoga_schedules,
      yoga_schedule: this.yoga.online_yoga_schedules[0],
      yoga_teacher: this.yoga.online_yoga_schedules[0],
      yoga_place: this.yoga.yoga_place,
      yoga_rate: this.yoga.online_yoga_schedules[0],
      yoga_stay: this.yoga.online_yoga_schedules[0],
      yoga_services: this.yoga.yoga_services,
      current_date: this.yoga.online_yoga_schedules[0].yoga_date,
      markers: [],
      fileNames: {
        "Caraïbes": "Caraibes",
        "Polynésie Française": "Polynesias",
        "Afrique": "Africas",
        "Océan Indien": "Indians",
        "France": "Francecard",
        "Europe": "Europe",
        "Americas": "Americas",
        "Asia": "Asia"
      },
      is_urban: this.yoga.yoga_city.includes('Urban Retreat')
    };
  },
  methods: {
    showModalGallery() {
      this.modalGallery = !this.modalGallery;
    },
    goToContact(){
      window.open("/pages/contact", "_blank");
    },

    formatDate(date){
      const set = new Date(date)
      const options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
      return set.toLocaleString('default', options)
    },
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    isSelectDestination(name) {
      return this.selectedDestination === name && this.destinations;
    },
    selectDestination(destination){
      this.$emit("destinationSelector", destination);
    },
    async fetchDestinations(id) {
      $(".dateBUtton").css("font-weight", "normal")
      event.currentTarget.style.fontWeight = "bold"
      try {
        const response = await fetcher.get(
            `/yoga_details?schedule=${id}`
        );
        this.yoga_schedule = response.data;
        this.$set(this.yoga_schedule, 'yoga_programs', response.data.programs);
        this.yoga_programs = response.data.programs;
        this.yoga_teacher = response.data;
        this.yoga_rate = response.data;
        this.current_date = response.data.yoga_date;
        this.yoga_stay = response.data;
        this.stay_name = response.data.stay_name;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchDestinationsTwo(id) {
      $(".dateBUtton").css("font-weight", "normal")

      // Set font weight to bold for the clicked button
      const clickedButton = document.getElementById('button_' + id);
      if (clickedButton) {
        clickedButton.style.fontWeight = 'bold';
      }
      try {
        const response = await fetcher.get(
            `/yoga_details?schedule=${id}`
        );
        this.yoga_schedule = response.data;
        this.$set(this.yoga_schedule, 'yoga_programs', response.data.programs);
        this.yoga_programs = response.data.programs;
        this.yoga_teacher = response.data;
        this.yoga_rate = response.data;
        this.current_date = response.data.yoga_date;
        this.yoga_stay = response.data;
        this.stay_name = response.data.stay_name;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
    markerMap() {
      const markers = [];
      markers.push([this.yoga.lat, this.yoga.long]);
      return markers;
    },
    displayMapfind() {
      this.markers = JSON.parse(JSON.stringify(this.markerMap()));
    },
    async hashEmail(email) {
      if(email && email !== '') {
        const encoder = new TextEncoder();
        const data = encoder.encode(email);
        const hash = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hash));
        return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      } else {
        return ""
      }
    },
  },
  async mounted() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    const dateParam = url.searchParams.get('date');

    if (dateParam !== null) {
      this.fetchDestinationsTwo(dateParam)
    }
    else{
      this.yoga_schedule.yoga_programs = this.yoga.online_yoga_schedules[0].programs;
    }
    this.markerMap();
    this.displayMapfind();
    const hashedEmail = await this.hashEmail(this.user && this.user.email ? this.user.email : '');
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'page_view',
      page: {
        page_type: 'product',
        page_category: 'nos experiences bien être',
      },
      user: {
        email: this.user ? hashedEmail : "",
      },
    });
    window.dataLayer.push({
      event: 'view_item',
      ecommerce: {
        items: [{
          item_name: this.stay_name,
          item_id: this.yoga.id,
          item_city: this.yoga.yoga_city,
          item_category: 'nos experiences bien être',
          item_variant: this.yoga_schedule.yoga_type,
          item_region: 'Occitanie'
        }]
      }
    });
  },
  computed: {
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    }
  }
};
</script>
<style lang="scss" scope="app/assets/stylesheets/components/lodging-show">
#headDestiii {
  .desti-header {
    padding: 0rem 11rem;
  }
  .row-desti-map {
    justify-content: space-around;
  }
  .bar {
    align-items: center;
  }
  .col-mapcontent {
    margin-top: 8rem;
    .btn-desti {
      width: 226px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .info-loca {
    display: flex;
    align-items: flex-start;

    img {
      width: 12px;
      margin-right: 10px;
    }
  }

  .list-desti-btns {
    margin-bottom: 5rem;
    ul {
      display: flex;
      justify-content: center;
    }

    li {
      list-style: none;
      padding: 15px 30px;
      margin-right: 1rem;
    }
  }
}



.padding_bottom {
  padding-bottom: 10vw;
}

.link-hortense {
  cursor: pointer;
}
.capacity {
  display: flex;
  align-items: center;
  margin-left: 1rem;
  margin-left: auto;
}
.center{
  background-position: center;
  background-size: cover;
}

@media only screen and (max-device-width: 480px) {
  .lodging-header-desktop {
    display: none !important;
  }
}
@media only screen and (min-device-width: 481px) {
  .container-lodging {
    //margin-top: 15vh !important;
  }
  .lodging-header-container {
    display: none;
  }
}
.lodging-made-trip {
  text-align: center;
  border-color: #cddfd3;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: 20px 20px;
  border-bottom-right-radius: 20px 20px;
  border-bottom-left-radius: 20px 20px;
  height: fit-content;
  width: 200px;
  margin-top: 250px;
  padding: 0.5rem 1rem 0.5rem 0.5rem;
  position: absolute;
  //left: 71%;
  max-width: 400px;
  width: 100%;

  @media (max-width: 480px) {
    left: 4%;
    margin-top: 1em;
    position: relative;
    max-width: 370px;
    width: 92%;
  }
  .image-container {
    display: flex;
    justify-content: start;
    align-items: start;
  }
  .image-container img {
    flex: 1;
    max-width: 100%;
    height: auto;
    width: auto;
    margin: 0;
    padding: 0;
  }

  .head {
    display: flex;

    img {
      width: 32px;
      height: 32px;
    }

    .content {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }

    .modal-arrow {
      .arrow {
        width: 14px;
        height: 14px;
      }
    }
  }
}
.lodging-show-raleway-reg-p26-b{
  font-family: $Merriweather;
  font-weight: 400;
  font-size: 26px;
}
.mab{
  margin-bottom: 0 !important;
  line-height: 20px;
}
.mab2{
  letter-spacing: 0px;
  line-height: 0px;
}
.mab3 {
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #headDestiii {
    .desti-header {
      padding: 0rem 2rem;
    }
    .row-desti-map {
      justify-content: space-around;
    }
    .bar {
      align-items: inherit;
    }
    .col-mapcontent {
      margin-top: 8rem;
      .btn-desti {
        width: 226px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .info-loca {
      display: flex;
      align-items: flex-start;

      img {
        width: 12px;
        margin-right: 10px;
      }
    }

    .list-desti-btns {
      margin-bottom: 5rem;

      ul {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 0;
      }

      li {
        list-style: none;
        padding: 1px 7px;
        margin-right: 0;
        margin-bottom: 11px;
        justify-content: center;
        align-items: center;
        display: flex;
      }
    }
  }
}
</style>