<template>
  <div id="label-phone">
    <div class="phone-container">
      <div class="label-img">
        <img src="../images/Label.svg" alt="Label">
      </div>
        <div class="label-header">
          <h2 class="merriweather-reg-26"> {{ $t("Label.title") }} </h2>
          <p class="raleway-reg-p16">{{ $t("Label.content") }}</p>
        </div>
        <a class="hortense-btn-primary btn-label raleway-bold-p mt-3" :href="'/label-ecoresponsable-green-host/'">{{ $t("Label.viewbtn") }}</a>

        <div class="green-img">
          <img src="../images/greenFull.svg" alt="green note">
        </div>
        <div class="green-header">
          <h2 class="merriweather-reg-26"> {{ $t("Green.title") }} </h2>
          <p class="raleway-reg-p16">{{ $t("Green.content") }}</p>
        </div>
        <a class="hortense-btn-primary btn-label raleway-bold-p mt-3" :href="'/green-note-engagement-environnemental/'">{{ $t("Label.viewbtn") }}</a>
    </div>
  </div>
</template>


<script>


  export default {
    name: 'label-phone',

  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/label-phone'>

</style>
