<template>
  <div id="card-project">
    <div class="col-4">
      <div class="img-bg-pro">
        <img :src="this.project.photo_key" alt="">
      </div>
      <div class="project-content">
        <h1 class="raleway-bold-p">{{ this.project.title}}</h1>
        <div class="loca d-flex">
          <img src="../../images/Pin.svg" alt="">
          <p class="raleway-reg-p15">{{ this.project.address }}</p>
        </div>
        <p class="raleway-reg-p15">Objectif : {{ this.project.goal }}</p>
        <p class="raleway-reg-p15"><strong>Budget</strong> {{ this.project.budget }} €</p>
        <p class="raleway-reg-p15"><strong>Travaux</strong> {{ this.project.format_start }} - {{ this.project.format_end }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'card-project',
    props: [ 'project' ]
  }
</script>
