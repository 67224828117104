<template>
  <div class="container-fluid">
    <div class="scrolling-wrapper row flex-row flex-nowrap mt-4 pb-1 pt-2">

      <div class="col-11 pb-1 "v-for="(item, index) in this.yoga_stay.stay_pics" :key="index">
        <div class="card border-0" >
          <img class="card-img-top" :src="item" alt="Card image cap">
          <div class="py-3">
            <p class="c-text">{{yoga_stay.stay_titles[index]}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="scrolling-wrapper row flex-row flex-nowrap mt-1 pb-1 pt-1">
      <div class="col-11 pb-1"v-for="(item, index) in this.yoga_stay.stay_pics_last">
        <div class="card border-0" >
          <img class="card-img-top" :src="item" alt="Card image cap">
          <div class="py-3">
            <p class="c-text">{{yoga_stay.stay_titles_last[index]}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myImage from '../../images/BelAmi-Chambre.png';
export default {
  data(){
    return{
      myImage:myImage,
    }
  },
  name: 'YogaStayPhone',
  props: ['yoga_stay'],
  methods: {
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    selectDestination(destination) {
      this.isLoading = true;
      this.selectedDestination = destination;
      this.fetchDestinations();
    },
    isSelectDestination(name) {
      return this.selectedDestination === name && this.destinations;
    },
    async fetchDestinations() {
      try {
        const response = await fetcher.get(
            `/destination?country=${this.selectedDestination}`
        );
        this.destinations = response.data;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    }
  }
}
</script>

<style scoped>
.scrolling-wrapper{
  overflow-x: auto;
}
.c-text{
  text-align: left !important;
  font: normal normal normal 14px/16px Raleway;
  letter-spacing: 0px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;

}

</style>