<template>
  <div id="indians">
    <div class="row row-desti-indians">
      <div class="col-lg-7 col-xs-10">
        <h2 class="text-center raleway-reg-p24 mb-4">Asie</h2>
        <p class="raleway-reg-p15 mb-4" v-if="destinations.length != 0">{{ destinations[0].content_destination }}</p>
        <div class="d-flex justify-content-center">
          <a :href="'/destination/Asie'" class="hortense-btn-primary btn-desti mb-4">{{ formatLodgings() }}</a>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-lg-6 col-xs-10 offset-xs-1 offset-lg-3 mt-5">
        <p class="merriweather-italic text-center">Participez au développement du tourisme durable en privilégiant des destinations respectueuses de l’environnement, des ressources naturelles et de l’économie locale.​
        </p>
      </div>
    </div>
    <div v-if="!isFetching">
      <template v-for="(region, index) in destinations[0].regions">
        <CardRegion class="card-region" :index="index" :region="region " :id="region.name"></CardRegion>
      </template>
    </div>
  </div>
</template>

<script>
import CardRegion from 'components/cards/CardRegion.vue';
import fetcher from 'packs/shared/api.js';

export default {
  name: 'asia',
  components: { CardRegion },
  data() {
    return {
      destinations: [],
      isFetching: true,
      lodgingsCount: 0
    }
  },
  methods: {
    async fetchDestinations() {
      try {
        const response = await fetcher.get('/destination?country=Asie');
        this.destinations = response.data;
        this.calculateLodgingCount();
        this.isFetching = false;
      } catch(e) {
        console.error(e);
      }
    },
    formatLodgings() {
      let établissement = ' établissement';
      if (this.lodgingsCount > 1) {
        établissement += 's';
      }
      const count = this.lodgingsCount || 0;
      return count > 1 ? count + établissement : 'DÉCOUVRIR';
    },
    calculateLodgingCount() {
      if (this.destinations.length != 0) {
        this.destinations.forEach((destination) => {
          const lodging = destination?.get_lodgings?.length || 0;
          this.lodgingsCount += lodging;
        });
      } else {
        this.lodgingsCount = 0;
      }
    },
    redirectToCard(name) {
      this.scrollToMe(name);
    },
    scrollToMe(name) {
      const elementFound = this.$children.find(e => e.$el.id === name);
      const topEl = elementFound.$el.offsetTop;
      window.scrollTo(0, topEl);
    },
  },
  created() {
    this.fetchDestinations();
  }
}
</script>

<style lang="scss" scope='app/assets/stylesheets/components/indians'>

</style>