<template>
      <div>
        <h5 v-if="title" style="line-height: 1.5 !important; font: normal normal 600 16px/15px Raleway;"> {{title}}</h5>
        <p v-if="description" style="line-height: 2.5; font: normal normal normal 15px/18px Raleway;" class="pb-0">{{description}}</p>
      </div>

</template>

<script>
export default {
  name:`MiniList`,
  props:{
    title: String,
    description: String
  }
}
</script>