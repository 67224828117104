<template>
  <div id="accommodations">
    <div class="card-container">
      <div class="row row-hubs">
        <div class="col-4">
          <div class="lodging-header">
            <h2 class="merriweather-reg-26">Nos</h2>
            <h2 class="merriweather-reg-40 mb-5">Nouveautés</h2>
          </div>
        </div>
        <div class="container-fluid py-2">
          <div class="d-flex flex-row flex-nowrap" id="scroller" style="overflow: scroll; overflow-y: hidden">
            <div class="card card-body border-0" v-if="lodgings"  v-for="lodging in lodgings" :key="lodging.id" style="min-height: auto; min-width: auto; margin-right:25px; max-width: 300px;">
              <CardProductHome data-aos="fade-up" data-aos-duration="900" :lodging="lodging" :user="isUserConnected()" @connection="$emit('connection')"></CardProductHome>
            </div>
          </div>
          <div class="flex-shrink-0" style="position: absolute; margin-left: 41%;">
            <button @click="scroll_left" class="btn-left btn-link p-2 toggle text-dark"><img style="width: 12px; height: 24px;" src="../../images/fleche gauche.svg" alt="" /></button>
          </div>
          <div class="flex-shrink-0" style="position: absolute; margin-left: 45%;">
            <button @click="scroll_right" class="btn-right btn-link toggle p-2 text-dark"><img style="width: 12px; height: 24px;" src="../../images/fleche droite.svg" alt="" /></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardProduct from '../../components/cards/CardProduct.vue';
import fetcher from 'packs/shared/api.js';
import CardProductHome from "./CardProductHome.vue";
import AOS from "aos";


export default {
  name: 'AccommodationCard',
  props: {
    lodgings: Array,
    user: Object,
    wishlist: Object,
  },
  components: {CardProductHome, CardProduct },
  data() {
    return {
      lodgingsList: this.lodgings,
      lodgingsFiltered: this.lodgings,
      SelectedOption: 'Hotel',
      lodgingsCurrentPage: 2, // starts at 2 as the first page is loaded by the parent component.
    };
  },
  watch: {
    SelectedOption() {
      // reset current page when switching type
      this.lodgingsCurrentPage = 2;
    },
  },
  methods: {
    autoScroll() {
      const scroller = document.getElementById('scroller');
      const step = 1; // Adjust step for speed
      let maxScrollLeft = scroller.scrollWidth - scroller.clientWidth;

      const frame = () => {
        if (scroller.scrollLeft >= maxScrollLeft) {
          scroller.scrollLeft = 0; // Reset scroll to start when it reaches the end
          maxScrollLeft = scroller.scrollWidth - scroller.clientWidth; // Recalculate in case of dynamic content
        } else {
          scroller.scrollLeft += step;
        }
        requestAnimationFrame(frame);
      }
      frame();
    },
    goToGroup(url){
      //Turbolinks.visit(url);
      window.location.href = url;
    },
    isUserConnected() {
      return this.user ? this.user : {};
    },
    async loadMoreLodgings(e) {
      const responseLodging = await fetcher.get(`/reservation-hotel?page=${this.lodgingsCurrentPage}&lodgings_count=3&type=${this.SelectedOption}`);
      this.lodgingsCurrentPage++;
      // this.SeeMoreLodging(e);
      this.lodgingsFiltered = this.lodgingsFiltered.concat(responseLodging.data);
    },
    async selectOption(e) {
      const activeEl = document.querySelector('.active');
      activeEl.classList.remove('active');
      e.target.parentNode.classList.add('active');
      this.SelectedOption = e.target.dataset.value;
      if (this.SelectedOption) {
        const responseLodging = await fetcher.get(`/reservation-hotel?page=1&lodgings_count=3&type=${this.SelectedOption}`);
        this.lodgingsFiltered = responseLodging.data;
      } else {
        this.lodgingsFiltered = [];
      }
    },
    formatSource(lodging) {
      if (lodging.photo_banner) {
        return lodging.photo_banner
      } else {
        return 'https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=';
      }
    },
    greenNotePicto(score){
      if (score === `${score}`){
        return require(`../../images/greennote ${score}.svg`)
      } else {
        return require("../../images/blanc.png")
      }
    },
    scroll_left() {
      let content = document.querySelector("#scroller");
      content.scrollLeft -= 220;
    },
    scroll_right() {
      let content = document.querySelector("#scroller");
      content.scrollLeft += 220;
    }
  },
  mounted() {
    AOS.init();
    this.autoScroll();
  }
};
</script>

<style>
#scroller {
  overflow: hidden;
  scrollbar-width: none;
}

#scroller::-webkit-scrollbar {
  display: none;
}
#cardProduct-big-two{
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 0 auto;
  .card-hub-info{
    width: 100%;
  }
  .hub-img{
    width: 106%;
    height: 211px;
    padding-right: 1rem;
  }
  .hub-header{
    margin-top: 1rem;
    justify-content: space-between;
  }
  .location-hub{
    display: none;
  }
  .catch-hub-phone{
    display: block;
  }
  .catch-hub{
    display: none;
  }
  .reviews-btn{
    display: flex;
    justify-content: space-between;
  }
  .hub-img-zoom{
    margin-top: auto;
    margin-bottom: auto;
    width: 300px;
    height: 224px;
    margin-right: 1rem;
    overflow: hidden;
    cursor: pointer;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .sticker-hub{
    position: absolute;
    background-color: #fff;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    align-items: center;

    img{
      width: 19px;
      height: 28px;
    }
  }
  .sticker-info{
    flex-direction: column;
  }

  .standing{
    img{
      width: 15px;
    }
  }
  .hub-reviews{
    img{
      width: 23px;
      height: 27px;
    }
    .review-info{
      flex-direction: column;
    }
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  #cardProduct-big{
    display: flex;
    flex-direction: column;
    width: auto;
    margin: 0 auto;
    .card-hub-info{
      width: 100%;
    }
    .hub-img{
      width: 100%;
      height: 211px;
      padding-right: 1rem;
    }
    .hub-header{
      margin-top: 1rem;
      justify-content: space-between;
    }
    .location-hub{
      display: none;
    }
    .catch-hub-phone{
      display: block;
    }
    .catch-hub{
      display: none;
    }
    .reviews-btn{
      display: flex;
      justify-content: space-between;
    }
  }
}
</style>