<template>
  <div id="card-comment">
    <div class="card-comment">
      <p class="raleway-reg-p15">« {{resa.comments[0].body}} »</p>
      <div class="comment-info d-flex justify-content-between">
        <div class="name-note d-flex">
          <p class="raleway-reg-p13">{{this.resa.user.name}}</p>
          <p class="raleway-reg-p13 ml-5"><strong>Note :{{this.resa.rate_average}}/</strong>10</p>
        </div>
        <div class="date">
          <p class="raleway-reg-p13">{{this.resa.comment_date}}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name: 'CardComment',
    props: [ 'resa' ],
  }
</script>
