<template>
  <div id="messages-list-user">
    <BannerProfile :user="this.user"></BannerProfile>
    <div class="container-profil">
      <Menuprofil :user="this.user"></Menuprofil>
      <a href="javascript:history.go(-1)" class="no-deco mb-5"> < Retour </a>
    <!-- <h1>Vos messages de : <br>{{messages[0].object}}</h1> -->
        <MessageTable :messagesType='chat_rooms[0].object' v-if='chat_rooms.length'></MessageTable>
    </div>
  </div>
</template>

<script>

  import MessageTable from 'pages/inbox/MessageTable.vue';
  import BannerProfile from 'components/shared/BannerProfile.vue';
  import Menuprofil from 'components/shared/Menuprofil.vue';

  export default {
    name: 'MessagesListUser',
    components: { BannerProfile, Menuprofil, MessageTable},
    props: {
      chat_rooms: Array,
      user: Object,
    }
  }
</script>
<style lang="scss" scoped>
  #messages-list-user{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
