<template>
  <div id="switch-trip">
    <div class="buisness-travel df-jsb-aic mt2">
      <h2 class="raleway-semi-p13 mb-0">Kids-friendly</h2>
      <input type="checkbox" id="Kids-friendly" v-model="selected" :value="66" /><label for="Kids-friendly">Kids-friendly</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'switch-kids',
  data() {
    return {
      selected: [],
    };
  },
  watch: {
    selected(val) {
      // easier to handle only arrays
      this.$emit('checked', val, 'eq_services');
    },
  },
};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
