<template>
  <div id="weekend">
    <div class="card-container">
      <div class="row row-hubs">
        <div class="col-4">
          <div class="weekend-header">
            <h2 class="merriweather-reg-26"> Nos </h2>
            <h2 class="merriweather-reg-40 mb-5">Week-ends</h2>
            <p class="raleway-reg-p16">Quelques jours loin de tout, pour profiter entre amis, en famille ou à deux. Découvrez nos idées de week-ends pour vous offrir une parenthèse de détente et de bien-être dans un cadre exceptionnel.</p>
          </div>
            <a class="hortense-btn-primary raleway-bold-p mt-5" :href="'/weekends'">{{ $t("Weekend.viewbtn") }}</a>
        </div>
        <div class="col-8 pt-2">
          <div class="row row-weekend">
            <div v-for='weekend,index in weekendsMounted' class="col-6 col-weekend">
              <CardWeekend :weekend="weekend" v-if='index === 0' data-aos="fade-up" data-aos-duration="900"></CardWeekend>
              <CardWeekend :weekend="weekend" v-else-if='seeMore(index)' data-aos="fade-up" data-aos-duration="900"></CardWeekend>
            </div>
          </div>
          <div class="pagination-hub d-flex mt-3">
            <a href="#" @click='SeeMoreWeekend' class="link-hortense"> voir plus <img src="../images/flechebas-simple.svg" alt="fleche bas"></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import AOS from 'aos';
  import CardWeekend from 'components/cards/CardWeekend.vue';
  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'weekend',
    components: { CardWeekend },
    props: {
      weekends: Array
    },
    data() {
      return {
         weekendsMounted: this.weekends,
         numberOfVisibleWeekend: 4,
      }
    },
    methods: {
      seeMore(index) {
        return index < this.numberOfVisibleWeekend;
      },
      SeeMoreWeekend(e) {
        e.preventDefault();
        this.numberOfVisibleWeekend += 3;
      },
      SeeLessWeekend(e) {
        e.preventDefault();
        this.numberOfVisibleWeekend -= 3;
      },
    },
    mounted() {
      AOS.init();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/weekend'>

</style>
