<template>
  <div id="destinations">
    <div class="container-index">
      <headYoga :is_urban="this.is_urban" @destinationSelector="selectDestination" :yoga_cities="this.yoga_cities"></headYoga>
      <div class="container">
        <ClubExpBar></ClubExpBar>
        <a href="/experience-club-hortense"><p class="pb-4 mt-5 raleway-reg-p15 text-center">Découvrez l'expérience Club Hortense</p></a>
        <p class="pb-4 raleway-reg-p15 text-center" v-if="yogas.length == 0">Hortense est à la recherche d'expériences Club à vous proposer.</p>
      </div>
      <div class="mt-3" v-for="yoga, index in this.yogas" :key="yoga.id">
        <YogaCard v-if="index % 2 == 0" :yoga="yoga"></YogaCard>
        <YogaCardAlter v-if="index % 2 != 0" :yoga="yoga"></YogaCardAlter>
      </div>
    </div>
  </div>
</template>

<script>
import HeadYoga from "./HeadYoga.vue";
import ScrollUp from "../../components/btns/ScrollUp.vue";
import fetcher from "packs/shared/api.js";
import Searchbtn from "../../components/btns/Searchbtn.vue";
import Searchbanner from "../../components/btns/Searchbanner.vue";
import YogaCard from "./YogaCard.vue";
import YogaCardAlter from "./YogaCardAlter.vue";
import ClubExpBar from "./ClubExpBar.vue";

export default {
  name: "Clubs",
  components: { HeadYoga, ScrollUp, Searchbtn, Searchbanner, YogaCard, YogaCardAlter , ClubExpBar },
  props: {
    yoga_cities: {
      type: Array,
      required: true
    },
    yogas: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      is_urban: false,
      showBanner: false,
      selectedDestination: "France",
      destinations: null,
      isLoading: true,
      fileNames: {
        "Caraïbes": "Caraibes",
        "Polynésie Française": "Polynesias",
        "Afrique": "Africas",
        "Océan Indien": "Indians",
        "France": "Francecard",
        "Europe": "Europe",
        "Americas": "Americas",
        "Asia": "Asia"
      },
    };
  },
  methods: {
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    selectDestination(destination) {
      this.isLoading = true;
      this.selectedDestination = destination;
      this.fetchDestinations();
      if(destination == 21 ) {
        this.is_urban = true
      } else {
        this.is_urban = false
      }
    },
    isSelectDestination(name) {
      return this.selectedDestination === name && this.destinations;
    },
    async fetchDestinations() {
      try {
        const response = await fetcher.get(
            `/club_cities?city=${this.selectedDestination}`
        );
        this.yogas = response.data;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.is_urban=false;
  }
};
</script>
<style
    lang="scss"
    scope="app/assets/stylesheets/components/destinations"
>
a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: black;
  text-decoration: underline;
}
</style>
