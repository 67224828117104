<template>
  <div id="map-test">
    <mapbox
        access-token="pk.eyJ1IjoiaG9ydGVuc2VncmVlbiIsImEiOiJja3N1Nm9iNGEwNjUyMm9xY2RlMDlwYWsxIn0.AMGi9L_80RYWk4sB5JYU5w"
        :map-options="{
        style: 'mapbox://styles/mapbox/streets-v10',
        zoom: 15,
        center: [this.markers[0][0], this.markers[0][1]],
      }"
        @map-init="displayMarkers"
    />
    <div class="closing">
      <button class="close-btn-modal" @click="$emit('close')"></button>
    </div>
    <Cardmap v-if="selectedMarker" :lodging="selectedLodging" :destination="selectedLodging.destination" @unselect="selectCardMap" :idMarker="selectedMarker" />
  </div>
</template>

<script>
import Mapbox from 'mapbox-gl-vue';
import Cardmap from '../components/cards/Cardmap.vue';


export default {
  name: 'map-test',
  props: ['markers', 'lodgings', 'selectCardMap'],
  components: { Mapbox, Cardmap },
  data() {
    return {
      selectedMarker: null,
      selectedLodging: null,
    };
  },
  methods: {
    displayOneMarker(map, mk) {
      const marker = new mapboxgl.Marker()
          .setLngLat(mk)
          .addTo(map);
    },
    displayMarkers(map) {
      this.fitMapToMarkers(map, this.markers);
      this.markers.forEach((marker, index) => {
        let el = document.createElement('div');
        el.id = marker[2];
        const priceLabel = document.createElement('div');
        priceLabel.textContent = `${marker[3]}€`;
        priceLabel.style.backgroundColor = '#d4a017';
        priceLabel.style.borderRadius = '5px';
        priceLabel.style.padding = '5px 10px';
        priceLabel.style.textAlign = 'center';
        priceLabel.style.fontSize = '14px';
        priceLabel.style.fontWeight = 'bold';
        priceLabel.style.color = 'white';
        priceLabel.style.position = 'absolute';
        priceLabel.style.top = '-25px';
        priceLabel.style.left = '-50px';

        el.appendChild(priceLabel);
        el.addEventListener('click', (e) => {
          this.selectMarker(el.id);
          document.querySelectorAll('.marker').forEach(mk => {
            if (mk.id != el.id) {
              mk.style.display === 'none' ? mk.style.display = 'block' : mk.style.display = 'none';
            }
            else {
              map.flyTo({
                center: [marker[0], marker[1]],
                zoom: 20
              })
            }
          })
        })
        el.className = 'marker';
        new mapboxgl.Marker(el)
            .setLngLat([marker[0], marker[1]])
            .addTo(map);
      })
      map.addControl(new mapboxgl.GeolocateControl({
            positionOptions: {
              enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true
          })
      );
    },
    fitMapToMarkers(map, markers) {
      const bounds = new mapboxgl.LngLatBounds();
      markers.forEach(marker => bounds.extend([marker[0], marker[1]]));
      map.fitBounds(bounds, { padding: 70, maxZoom: 15, duration: 0 });
    },
    selectMarker(markerId) {
      this.selectedMarker = markerId;
      this.selectedLodging = this.lodgings.find(lodging => lodging.id === (parseInt(markerId)));
    },
    unselectCardMap() {
      this.selectedMarker = null;
      this.selectedLodging = null;
    }
  }
}
</script>

<style scoped lang="scss">
.marker {
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

#card-map {
  position: absolute;
  top: 5%;
  left: 10%;
  width: 60%;
  z-index: 1000;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #card-map {
    position: absolute;
    top: 5%;
    width: 80% !important;
    z-index: 1000;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
}
</style>