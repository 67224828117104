<template>
  <div id="collections" v-if="this.weekendMounted">
    <Searchbtn
      @show="showBanner = !showBanner"
      @top="redirectToTop"
    ></Searchbtn>
    <Searchbanner v-if="showBanner" @close="showBanner = false"></Searchbanner>
    <div class="container-index">
      <div class="index-text">
        <!--<h1 class="merriweather-reg-40 mb-5">Nos week-ends</h1>-->
        <h1 class="merriweather-reg-40 mb-5">Nos week-ends</h1>
        <p class="raleway-reg-p15 text-center">
          Vous ne savez pas encore où partir en week-end ? Découvrez notre
          sélection de week-ends en amoureux, de week-ends en famille et entre
          amis. Prenez le temps de décrocher du quotidien pour vous retrouver et
          recharger les batteries. Pas besoin de partir à l’autre bout du monde
          pour se changer les idées : parfois, les meilleures expériences sont à
          deux pas de la maison.
        </p>
      </div>
      <div class="weekend-card-index">
        <div class="row row-weekend">
          <div
            v-for="weekend in weekendMounted"
            class="col-lg-6 col-xs-8 col-weekend"
          >
            <Cardweekendall
              :weekend="weekend"
              data-aos="fade-up"
              data-aos-duration="900"
            ></Cardweekendall>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AOS from "aos";
import Cardweekendall from "components/cards/Cardweekendall.vue";
import fetcher from "packs/shared/api.js";
import Searchbtn from "components/btns/Searchbtn.vue";
import Searchbanner from "components/btns/Searchbanner.vue";

export default {
  name: "weekend-all",
  props: ["weekends"],
  components: { Cardweekendall, Searchbtn, Searchbanner },
  data() {
    return {
      showBanner: false,
      weekendMounted: this.weekends,
    };
  },
  methods: {
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    // async fetchdatas() {
    //   try {
    //     const responseWeekend = await fetcher.get("/weekends/");
    //     this.weekendMounted = await responseWeekend.data;
    //   } catch (e) {
    //     console.error(e);
    //   }
    // },
  },
  mounted() {
    AOS.init();
    // this.fetchdatas();
  },
};
</script>
<style
    lang="scss"
    scope="app/assets/stylesheets/components/collection-gallery"
></style>