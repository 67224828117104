<template>
  <div id="cardEverPhone">
    <div class="card-ever">
      <img src="https://images.unsplash.com/photo-1488330890490-c291ecf62571?ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8Z3JlZW58ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="">
      <div class="sticker-program d-flex mt-4 pl-2">
        <div class="sticker-program-info d-flex">
          <p class="raleway-bold-h3 mb-0 pl-1 pr-2"> Projet Loren</p>
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2">Lorem ipsum dolor sit amet, consectetur.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'CardEverPhone',

  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/ever-phone'>

</style>
