<template>
  <div id="contact-form">
    <!-- modify this form HTML and place wherever you want your form -->
    <div class="container-index">
      <div class="header-inspi text-center mb-5">
        <h1 class="merriweather-reg-40">Contactez-nous</h1>
        <p class="raleway-reg-p15">Une question, une réclamation, besoin d’aide ? Pour toute information complémentaire, envoyez-nous un message.</p>
      </div>
      <div class="form-contact">
        <form id="my-form" action="https://formspree.io/f/xayvjvey" method="POST">
          <div class="line">
            <label class="raleway-reg-p15">Votre prénom :</label>
            <input type="text" name="NOM" class="form-control raleway-reg-p13" placeholder="Camille" id="name" data-validation-required-message="Veuillez entrer votre NOM svp">
            <p class="help-block text-danger"></p>
          </div>
          <div class="line">
            <label class="raleway-reg-p15">Votre nom :</label>
            <input type="text" name="NOM" class="form-control raleway-reg-p13" placeholder="Dupont" id="name" data-validation-required-message="Veuillez entrer votre NOM svp">
            <p class="help-block text-danger"></p>
          </div>
          <div class="line">
            <label class="raleway-reg-p15">Votre e-mail :</label>
            <input type="email" name="Email" class="form-control raleway-reg-p13" placeholder="camille@dupont.com" id="email" data-validation-required-message="Veuillez entrer votre EMAIL svp">
            <p class="help-block text-danger"></p>
          </div>
          <div class="line">
            <label class="raleway-reg-p15">Objet :</label>
            <v-select class="select-item raleway-bold-p13 text-center" placeholder="Choisir un objet" v-model="objectSelected" :options="objectChoices"></v-select>
          </div>
          <div class="line">
            <label class="raleway-reg-p15">Message :</label>
            <textarea type="text" name="Message" class="form-control raleway-reg-p13" placeholder="Message*" id="message" data-validation-required-message="Veuillez entrer un MESSAGE svp"></textarea>
            <p class="help-block text-danger"></p>
          </div>
          <button class="hortense-btn-primary" id="my-form-button">Envoyer</button>
          <p id="my-form-status"></p>
        </form>
      </div>
    </div>
    <a href="/" class="hortense-btn-primary">Retour à la page d’accueil</a>
    <!-- Place this script at the end of the body tag -->
  </div>
</template>


<script>

  export default {
    name: 'contact-form',

    data() {
      return{
        objectChoices: [
          "Question sur mon séjour",
          "Question sur mon compte client",
          "Question sur le programme Evergreen",
          "Réclamation",
          "Autre"
        ],
        objectSelected: null,
      }
    },
    methods: {
      submit() {
        const form = document.getElementById("my-form");
          async function handleSubmit(event) {
            event.preventDefault();
            const status = document.getElementById("my-form-status");
            const data = new FormData(event.target);
            fetch(event.target.action, {
              method: form.method,
              body: data,
              headers: {
                  'Accept': 'application/json'
              }
            }).then(response => {
              status.innerHTML = "Votre message a bien été envoyé, nous vous en remercions. Nous vous recontacterons dans un délai de 24h ouvrées.";
              form.reset()
            }).catch(error => {
              status.innerHTML = "Oops! There was a problem submitting your form"
            });
          }
        form.addEventListener("submit", handleSubmit)
      }
    },
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/contact-form'>

</style>
