<template>
  <div id="cgv">
    <div class="container-index">
      <h1 class="merriweather-reg-40 text-center mb-5">Conditions Générales d’Utilisation</h1>
      <pre class="raleway-reg-p15">
<strong>PREAMBULE</strong>

Les présentes conditions générales d’Utilisation (ci-après « CGU ») s’appliquent à l’utilisation du site internet détenu par la société HORTENSE, Société par actions simplifiée unipersonnelle au capital de 15.000 euros enregistrée au RCS de Créteil sous le numéro 892 531 476, dont le siège social est sis 112 avenue de Paris – 94300 Vincennes (ci-après « la Société ») accessible à l’adresse www.hortense.green ainsi qu’à l’ensemble des services qui y sont proposés par la Société.

Tout Utilisateur qui utilise les Services offerts sur la plateforme s’engage à les respecter.

Les Services offerts sur le site www.hortense.green sont offerts exclusivement aux consommateurs.

Hortense met à disposition des internautes une plateforme de réservation d’hébergements écoresponsables à l’adresse www.hortense.green.

Le Site met à la disposition de l’Utilisateur une plateforme recensant des adresses et autres informations utiles à la Réservation d’un Hébergement.

Par le biais du Site, les Utilisateurs peuvent consulter, rechercher ou comparer les produits et services proposés et effectuer une Réservation.

Hortense n’intervient qu’en qualité d’intermédiaire lors de la Réservation, celle-ci formant un contrat entre le Fournisseur et le Voyageur. Le Service ne consiste qu’en une prestation d’intermédiation entre un Voyageur et un Fournisseur par la mise à disposition du Site.

L'utilisation du Site est gratuite pour les Utilisateurs, sous réserve des éventuels frais de communication des opérateurs de téléphonie mobile et des fournisseurs d’accès à Internet.

Les présentes conditions générales d’utilisation ont pour objet de définir les modalités et conditions d’utilisation des Services et de Réservations sur le Site et de définir les droits et obligations des parties.

<strong>ARTICLE 1 – DEFINITIONS</strong>

Chaque terme débutant ci-après par une majuscule a le sens qui lui est donné dans sa définition.

CGU : désignent les présentes conditions générales d’utilisation.

Service Connexe : Tout produit ou service réservé par un Voyageur en complément d’une Nuitée (exemple : petit-déjeuner, transfert, prestation de spa, location de bicyclettes, frais de départ tardif/arrivée anticipée, frais pour le séjour de personnes supplémentaires, frais de service, lits d’appoint, billets de théâtre…).

Compte : Espace mis à disposition de l'Utilisateur inscrit sur le Site, lui permettant d’accéder, après s'être identifié par ses identifiants de connexion, à son Espace personnel, à effectuer des Réservations et à accéder aux fonctionnalités réservées aux titulaires de Comptes.

Consommateur : Conformément à la définition de l’article préliminaire du Code de la consommation, toute personne physique qui agit à des fins qui n’entrent pas dans le cadre de son activité commerciale, industrielle, artisanale ou libérale.

Contenu : Tout article, définition, document, guide, fiche pratique, information, texte, graphique, image, musique, logiciel, contenu audio ou vidéo mis à disposition, accessible ou téléchargeable sur le Site.

Donnée Personnelle : toute information se rapportant à une personne physique identifiée ou identifiable qui peut être identifiée, directement ou indirectement, notamment par référence à un identifiant, tel qu'un nom, un numéro d'identification, des données de localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres à son identité physique, physiologique, génétique, psychique, économique, culturelle ou sociale ou qui y sont rattachées et/ou rattachables.

Evergreen : Programme de soutien et d’accompagnement financier de projets du tourisme durable mis en place par Hortense.

Fournisseur : Partenaire de Hortense proposant un Hébergement et/ou tourte Réservation Connexe sur la Plateforme.

Green Note : Score attribué à un Hébergement par Hortense conformément à une grille de notation évaluant l’écoresponsabilité dudit Hébergement.

Hébergement : Forme de logement temporaire proposé par le Partenaire aux Utilisateurs par le biais du Site.

Hortensia : Programme de fidélisation offert par Hortense pour récompenser la fidélité des Voyageurs qui réservent fréquemment par le biais du Site.

Nuitée : Séjour d'une personne pendant une nuit dans un hôtel ou dans tout autre lieu d'hébergement.

Service : Toute prestation de Services de mise en relation avec un Fournisseur proposée sur le Site.

Service Exclusif : Prestations qu’un Fournisseur peut proposer à titre gratuit à un Voyageur inscrit au programme de fidélité Hortensia.

Site Internet, Site ou Plateforme : Site internet sécurisé de la société Hortense accessible à l’adresse www.hortense.green et permettant d’avoir accès aux Services dans les conditions et modalités prévues aux présentes Conditions Générales d’Utilisation.

Réservation : Réservation, l'achat, le paiement ou la réservation d'une Nuitée et/ou d’une Réservation Connexe.

Utilisateur : Toute personne physique ou morale, majeure et capable, naviguant sur le Site.

Voyageur : Utilisateur personne physique Consommateur, qui effectue une Réservation sur le Site.

<strong>ARTICLE 2 – Objet des CGU</strong>

Les présentes CGU ont pour objet de définir les conditions et les modalités dans lesquelles les Utilisateurs peuvent accéder au Site et à l’utiliser.

L’utilisation du Service est subordonnée au respect des CGU, lesquelles en constituent une condition essentielle. Toute personne qui accède au Site s’engage en conséquence à respecter, sans réserve, les présentes CGU, qui sont notifiées aux Utilisateurs pour acceptation préalablement à leur utilisation de l’Application ou du Site.

La Société est libre de modifier, à tout moment et sans préavis, les présentes CGU, afin notamment de prendre en compte toute évolution légale, jurisprudentielle, éditoriale et/ou technique. La version qui prévaut est celle qui est accessible en ligne.

Ces CGU pouvant faire l’objet de modifications ultérieures, la version applicable à l’achat du Voyageur est celle en vigueur sur le site internet à la date de passation de la Réservation. Les modifications ne sont opposables aux utilisateurs du Site qu’à compter de leur mise en ligne et ne peuvent s’appliquer aux transactions conclues antérieurement.

La Société, agissant de bonne foi, se réserve le droit d’annuler, de modifier, de remplacer les CGU ou les Services ou de transférer la propriété du Site. Un Utilisateur ne pourra en aucun cas former une demande de dommages-intérêts contre la Société à la suite de telles modifications.

<strong>ARTICLE 3 - Champ d’application</strong>
 
Les présentes CGU s’appliquent, sans restriction ni réserve à l’ensemble des opérations conclues par les Voyageurs, désirant effectuer des Réservations sur le Site. Elles constituent, avec le bon de Réservation, les documents contractuels opposables aux parties, à l’exclusion de tous autres documents, prospectus, catalogues qui n’ont qu’une valeur indicative.

Elles précisent notamment, et sans que cela ne soit limitatif, les conditions de Réservation, de paiement et de gestion de la Réservation.

Les caractéristiques principales des Hébergements et des Services Connexes et notamment adresse de l’Hébergement, description de l’Hébergement, équipements, Services connexes proposés, disponibilités, détail des tarifs (taxes, prélèvement, frais applicables), conditions d’annulation et de non-présentation, type d’hôte (marchand / non-marchand).

Le Voyageur est tenu d’en prendre connaissance avant toute passation de Réservation.

Le choix et l’achat d’une Nuitée et de Services Connexes est de la seule responsabilité du Voyageur.

Les présentes CGU s’appliquent aux relations entre le Voyageur et la Société à l’exclusion de toute autres conditions générales ou particulières, et notamment celles du Fournisseur.

Ces CGU sont accessibles à tout moment sur le Site et prévaudront, le cas échéant, sur toute autre version ou tout autre document contradictoire.

Le Voyageur déclare avoir pris connaissance des présentes CGU et les avoir acceptées sans restriction ni réserve en cochant la case prévue à cet effet avant la mise en œuvre de la procédure de Réservation en ligne.

Sauf preuve contraire, les données enregistrées dans le système informatique de la Société constituent la preuve de l’ensemble des transactions conclues avec le Voyageur.

Le Voyageur reconnaît agir en qualité de Consommateur – et non de Professionnel – et avoir la capacité requise pour contracter et effectuer les Réservations proposées sur le Site, notamment être âgé de 16 (seize) ans minimum.

<strong>ARTICLE 4 – Réservations</strong>

Le Site permet de mettre en relation des Fournisseurs et des Utilisateurs aux fins de Réservation de Nuitées et de Services Connexes au sein de leurs Hébergements selon les modalités décrites dans les présentes CGU.

Les Nuitées et Services Connexes sont proposés à la vente sur le Site par les Fournisseurs, sous réserve de disponibilité.

Elles sont présentées dans le catalogue publié sur le Site et font l’objet d’un descriptif établi par le Fournisseur mentionnant leurs caractéristiques essentielles au sens de l’article L. 111-1 du code de la consommation. Les descriptions des Hébergements et Services Connexes n’engagent que le Fournisseur qui les a établies.

<strong>ARTICLE 5 – Recours aux Services – Création du Compte</strong>
 
<strong>5.1. Création et utilisation du Compte</strong>

Afin de pouvoir bénéficier de l’ensemble des services mis à disposition par le biais du Site, l'Utilisateur doit procéder à une inscription unique gratuite. A défaut d’inscription, l’Utilisateur pourra bénéficier d’une version simplifiée du Service et du Site.

L'inscription se fait par l'utilisation du formulaire d'inscription fourni par la Société sur le Site.

En s’inscrivant sur le Site, l'Utilisateur confirme sa connaissance et son acceptation sans réserve des termes et du contenu de présentes CGU.

Lors de l’inscription sur le Site, chaque Utilisateur doit fournir des Données personnelles afin de permettre un accès individualisé et sécurisé à l’interface et aux Services.

Ces données à caractère personnel sont renseignées par le biais du formulaire d'inscription et comportent le prénom, le nom, la date de naissance, l’adresse mail. Chaque champ doit être renseigné par une indication conforme à la réalité. L'inscription n’est possible que par l’utilisation du nom civil de l'Utilisateur ; l'utilisation de noms de fantaisie ou de pseudonymes n’est pas possible.

Les personnes de moins de 16 ans ne sont pas autorisées à utiliser le Service proposé par la Société, sauf si autorisation du responsable légal.

En s’inscrivant sur le Site et en ouvrant un compte, les Utilisateurs reconnaissent avoir lu la politique de protection des données accessible au lien suivant www.hortense.green/politique-confidentialité/.

Si le processus d'inscription est interrompu, par exemple, en raison de champs incomplets ou de l'absence de consentement aux présentes CGU, la Société supprimera le Compte correspondant dans un délai de sept jours.

La Société se réserve le droit de refuser unilatéralement et sans justification l’inscription d’un Utilisateur. Le cas échéant, l’ensemble des Données personnelles transmises sera supprimé dans les meilleurs délais.

Les Utilisateurs doivent protéger l’accès à leur Compte de toute utilisation non autorisée et frauduleuse. Ils s’engagent à informer le Fournisseur immédiatement dans l’hypothèse d’une telle utilisation illicite ou de risque de fraude.

Pour préserver la sécurité des données transmises, les Utilisateurs doivent protéger avec diligence leurs données de connexion et n’accorder l’accès à leur Compte qu’à des tiers de confiance. Dans une telle hypothèse, la Société ne pourra être tenue responsable de la perte ou de l’utilisation frauduleuse des données.

En cas de changement de sa situation, l’Utilisateur s'engage à en informer la Société. La Société ne saurait être tenue responsable par l’Utilisateur au cas où il n'aurait pas été avisé d'un changement de situation ou d’informations erronées le concernant.

La Société se réserve le droit d'engager des poursuites et de demander des dommages et intérêts à l'encontre de toute personne ayant essayé de tricher, tromper, ou utiliser frauduleusement le Service fourni par le biais du Site, ou générer des avantages ou bénéfices de façon frauduleuse ou déloyale, ou plus généralement d’avoir saisi des informations dans le but d’usurper une identité.

<strong>5.2. Suspension et fermeture du Compte</strong>

Afin d'assurer le respect de ses obligations par l’Utilisateur, la Société se réserve la possibilité d’appliquer l’échelle de sanctions suivantes selon la violation dont s’est rendu coupable l’Utilisateur :
- avertissement ;
- suppression de contenu ;
- désactivation temporaire du Compte utilisateur ;
- annulation (désactivation irrévocable du compte).

Le type de sanction est fonction du but, de l'impact et du type de violation de l'Utilisateur.

Dans le cas où un Compte d'utilisateur est annulé, l'Utilisateur concerné n’est pas autorisé à s’inscrire à nouveau et ne pourra solliciter aucune indemnisation, résultant notamment de l’impossibilité de passer des Réservations et d’accéder à son espace personnel.

La Société se réserve le droit, sans indemnité et sans préavis de suspendre l’accès à tout ou partie du Compte jusqu’à ce que la cause de la suspension ait disparu, voire de supprimer le Compte au regard de la gravité du manquement.

L’Utilisateur reconnaît que la Société ne peut être tenue pour responsable à son encontre ou à l'encontre de tiers des conséquences de la fermeture ou de la suspension du Compte.

L’Utilisateur peut à tout moment renoncer à utiliser les services et demander une fermeture de son Compte via le Site. Dans tous les cas, l’Utilisateur reste responsable de toute utilisation de son Compte antérieure à la fermeture de ce Compte

<strong>ARTICLE 6 – Réservations</strong>

<strong>6.1. Réservations de Nuitées et de Services Connexes</strong>

Une adresse email est nécessaire pour passer la Réservation.

Toute Réservation passée sur le Site internet constitue la formation d’un contrat conclu à distance entre le Voyageur et le Fournisseur. La Société demeure tiers au contrat de Réservation, à l’obligation de paiement et à la fourniture du service de paiement, confié à un tiers de confiance respectant les exigences, les critères de conformité et les processus de validation établis dans les normes de sécurité informatique des données de l'industrie des cartes de paiement (« PCI-DSS », ou Payment Card Industry Data Security Standard). Le Service de Réservation se limite à la transmission des détails de la Réservation au Fournisseur concerné et à l’envoi au Voyageur d’un courriel de confirmation au nom et pour le compte de l’établissement.

Lorsque d’une Réservation est effectuée par un Voyageur sur le Site, Hortense transmet la Réservation au Fournisseur concerné. Le Fournisseur dispose d’un délai de 24 heures pour accepter ou refuser la Réservation. Toutefois et selon le Fournisseur, la Réservation peut être à validation instantanée.

Le Voyageur sera informé par courriel de l’évolution de sa Réservation.

Lors de sa Réservation, le Voyageur doit compléter des détails indispensables à la Réservation :
- date d’arrivée ;
- nombre de nuits ;
- type de chambre (y compris les préférences fumeurs/non-fumeurs, si applicable), le tarif de la chambre, le nom, le pays de résidence du Voyageur et les coordonnées de sa carte bancaire (qui forment ensemble les « Informations Voyageur ») et toute autre demande particulière effectuée par le Voyageur.

Hortense n’est pas responsable de l’exactitude et de l’exhaustivité des informations (y compris des coordonnées de la carte bancaire) et des dates fournies par les Voyageurs, ni des obligations de paiement des Voyageurs liées à leur Réservation en ligne.

Les Hébergements et Services Connexes sont disponibles tant qu’ils sont visibles et réservables sur le site, dans la limite des Nuitées disponibles. La Société se réserve, au nom et pour le compte du Fournisseur, la possibilité d’annuler le contrat conclu avec le Voyageur sans que sa responsabilité ne puisse être engagée dans les hypothèses suivantes :

- les coordonnées de facturation sont incorrectes ou ne peuvent être vérifiées ;
- la Réservation est identifiée comme susceptible de constituer une fraude notamment, et sans que cela ne soit exhaustif, quant aux conditions d’âge et à la qualité du Voyageur ;
- le paiement n’est pas reçu dans les délais indiqués ;
- les prix affichés comportaient une erreur matérielle ;
- des erreurs figuraient dans la description de l’Hébergement et/ou des Services Connexes.
- La confirmation de Réservation, qui comprend les éléments essentiels de celle-ci et notamment la description le prix des Nuitées et Services Connexes réservés, est adressée par courriel dans un délai de 24 heures. A défaut de confirmation, il est conseillé de contacter le service Client de la Société joignable par courriel à l’adresse contact@hortense.green ou directement sur le Site à la rubrique « Nous écrire ».


Il est expressément convenu que les données stockées sur des ordinateurs ou des supports électroniques et en particulier enregistrées dans les systèmes informatiques d’Hortense et/ou des Fournisseurs concernés constituent une preuve concernant les Réservations effectuées et seront donc acceptables aux mêmes conditions et avec la même valeur de preuve qu'un document papier écrit.

La Société se réserve le droit d’effectuer un contrôle concernant l’adresse ou la solvabilité du Voyageur afin de s’assurer de l’absence de fraude.

<strong>6.2. Modification et/ou annulation des réservations d’hébergements</strong>

En application de l’article L.121-21-8° du Code de la consommation, les Services proposées sur le Site ne sont pas soumis à l'application du droit de rétractation prévu aux articles L.121-21et suivants du Code de la consommation en matière de vente à distance.

En conséquence, les Réservations effectuées sur le Site sont exclusivement soumises aux conditions d’annulation et de modification prévues dans les présentes CGU et/ou dans les conditions spécifiques des Fournisseurs.

Les modifications ou annulations de Réservation doivent obligatoirement être effectuées en ligne par le Voyageur, soit par le biais de son Compte, soit en s’identifiant à l’aide des références de réservation figurant dans l’email de confirmation.

Les modifications sont possibles dans les limites et selon les modalités définies par le Fournisseur concerné.

En cas d’annulation d’une Réservation ou d’une absence de présentation, le Fournisseur pourra appliquer des frais de non-présentation ou d’annulation, qui sont présentés en amont lors de la procédure de Réservation et confirmés dans le mail de confirmation adressé par la Société au Voyageur.

En cas d’arrivée tardive ou de non-présentation la première nuit de la Réservation (arrivée repoussée au lendemain), le Voyageur en informera le Fournisseur et l’Hébergement concerné au plus tard le jour de la date d’arrivée telle que prévue dans la confirmation de la Réservation pour éviter l’annulation de la Réservation dans sa totalité.

A défaut, la Réservation dans son ensemble pourra être annulée et le Voyageur ne pourra être remboursé que selon les dispositions des règles et restrictions du Fournisseur Concerné.

Dans l’hypothèse où avant le départ, le respect d'un des éléments essentiels du contrat est rendu impossible par suite d'un évènement extérieur qui s'impose à la Société et/ou le Fournisseur concerné, Hortense en avertira le plus rapidement possible le Voyageur qui pourra résoudre sans frais le contrat ou accepter la modification proposée.

Dans l’hypothèse où un Fournisseur ne serait pas en mesure d'honorer la Réservation, Hortense ne pourra être tenue pour responsable de tout coût encouru suite au relogement, étant précisé que le relogement éventuel sera à la charge et de la seule gestion du Fournisseur.

<strong>ARTICLE 7 – Tarifs</strong>
 
Les Nuitées et Services Connexes sont fournis aux tarifs en vigueur figurant sur le Site lors de l’enregistrement de la Réservation par le Fournisseur.

Les prix sont exprimés en Euros Toutes Taxes Comprises. Ils ne comprennent pas les taxes de séjour, qui sont facturées en supplément, dans les conditions indiquées sur le Site.

Ces tarifs sont fermes et non révisables pendant leur période de validité, telle qu’indiqué sur le Site, la Société et/ou le Fournisseur se réservant le droit, hors cette période de validité, de modifier les prix à tout moment.

Une facture est établie par la Société au nom et pour le compte du Fournisseur en cas de paiement en ligne et une facture est en sus toujours remise au Voyageur, à sa demande, lors de son séjour au sein de l’Hébergement et/ou d’utilisation des Services Connexes.

<strong>ARTICLE 8 – Conditions de paiement</strong>

Selon les options proposées par le Fournisseur, le Voyageur a la possibilité de payer en ligne au moment de la Réservation ou de payer sur place, lors de son séjour au sein de l’Hébergement. Pour certaines Réservations soumises à des conditions particulières, le Fournisseur peut exiger un paiement en ligne. Le cas échéant, la Société et le Fournisseur Concerné se réserve le droit d'annuler une réservation si le paiement associé à la Réservation n'est pas reçu dans les délais impartis.

En cas d’option pour le paiement en ligne, la Réservation des Nuitées et des Services Connexes sera acquittée dans le devis sélectionné par le Voyageur, au moment de la validation de la commande.

La Société et les Fournisseurs utilisent le service de paiement sécurisé fourni par la société Stripe et la société Paypal. Les données confidentielles (le numéro de carte bancaire à 16 chiffres ainsi que la date d'expiration, le code CVV) sont directement transmises cryptées sur le serveur de la banque, la Société n’a pas accès à ces données. Le Client est engagé par sa Réservation dès qu’il clique sur « Payer ».

En cas de paiement sur place, le Fournisseur facturera les Nuitées et les Services Connexes lors du séjour du Voyageur au sein de l’Hébergement. Une garantie de la Réservation pourra être demandée lors de la validation de la Réservation. Elle se fonde sur les coordonnées de la carte bancaire fournies par le Voyageur ou la personne ayant effectué la réservation.

<strong>ARTICLE 9 – Résultats de recherche</strong>

Hortense met à la disposition des Utilisateurs un classement par défaut des Hébergements et Fournisseurs établi par l’utilisation d’algorithmes complexes et dynamiques utilisant notamment les indices suivants :
- Prix proposé par le Fournisseur par rapport au prix moyen observé pour des Hébergements et Services Connexes comparables ;
- Satisfaction des voyageurs ;
- Green Note.


Des critères et filtres de recherche peuvent être précisés par le Voyageur sur la page de résultats de la recherche afin d’affiner les résultats de la recherche selon ses préférences.

Apparaissent en tête des résultats les offres les plus pertinentes et compétitives en fonction de différents facteurs, comme le prix proposé par l'établissement par rapport à ses prix moyens, la Green Note, les notes des voyageurs, le nombre d'avis voyageurs, le nombre de réservations jusqu'à ce jour et la localisation (par rapport à la destination recherchée).

<strong>ARTICLE 10 - Programme de fidélité Hortensia</strong>

<strong>10.1. Fonctionnement du programme Hortensia</strong>

Le programme de fidélité Hortensia proposé par Hortense est gratuit pour tout Voyageur qui demande à y souscrire. Il est accessible à toute personne de plus de 18 ans ayant ouvert un Compte sur le Site avec une adresse e-mail valide et s’étant inscrite. Le Programme n’est pas ouvert aux entreprises, aux associations ou à tout autre groupe.

Le programme Hortensia permet de bénéficier de nombreux Services Exclusifs négociés avec les Fournisseurs et notamment, sous réserve de disponibilité, selon les Hébergements, Fournisseurs et statut :
- Surclassement de type de chambre
- Check-in avancé
- Check-out tardif
- Petit déjeuner offert
- Accès gratuit au spa
- Accès gratuit à la thalasso
- Un massage offert
- Un dîner offert
- Un dîner bistronomique offert
- Un dîner gastronomique offert
- Remise sur la restauration
- Remise sur la carte des boissons
- Cadeau de bienvenue
- Green fee offert
- Dégustation de vins
- 1 nuit offerte si 7 nuits consécutives réservées
- 1 nuit offerte si 14 nuits consécutives réservées

Cette liste est non exhaustive. Chaque Réservation effectuée sur le Site permet au Voyageur de se voir attribuer des points de fidélité Green Points. Une Nuitée réservée permet l’obtention de 50 Green Points, à l’exception des Nuitées offertes ou réservées grâce à un bon Green Night.

Les points sont échangeables contre des nuits éligibles offertes, les Green Nights, qui peuvent être intégrées à une Réservation directement depuis le Compte du Voyageur. En cas d’utilisation d’un bon Green Night, le Voyageur devra s’acquitter des taxes de séjour et tout autre coût ou frais connexe associé au séjour.

Dès obtention de 2 500 points par un Voyageur, la Société lui octroie un bon Green Night (valeur 150 euros TTC). Ce bon d’achat est utilisable sous 12 mois. Si le Voyageur n’utilise pas son bon Green Night avant la date limite, sa cagnotte sera réduite de 500 points (valeur du bon).

Si le prix de la Nuitée réservée grâce au bon Green Night est inférieur à la valeur du bon, la différence n’est pas remboursable.

Si le prix de la Nuitée réservée grâce au bon Green Night est supérieur à la valeur du bon, le Voyageur doit s’acquitter de la différence.

Le Programme Hortensia comporte 4 niveaux :
- Green Trotter = 500 points soit 10 nuits réservées
- Green Trotter Plus = 1 000 points soit 20 nuits réservées
- Green Trotter Premium = 1 500 points soit 30 nuits réservées
- Green Trotter Infinite = 2 500 points soit 50 nuits réservées

Le statut Trotter Infinite donne droit au Voyageur concerné aux avantages supplémentaires suivants, outre la possibilité d’échanger des points contre des bons Green Nights dans les conditions ci-avant décrites :
- Un bon Green Night d’une valeur de 150 euros TTC sous forme de carte cadeau
- Un bon Green Night d’une valeur de 150 euros TTC toutes les tranches de 500 points

Les Voyageurs peuvent à tout moment consulter leur solde de points sur leur Compte.

<strong>10.2. Programme Evergreen</strong>

Hortense soutient financièrement le développement de projets du tourisme durable en reversant une partie de sa commission aux porteurs de projets sélectionnés.

Hortense propose aux Voyageurs de compenser symboliquement l’émission de CO² de leur voyage en participant au financement des projets éco-responsables portés pa r ses Hébergements partenaires.

En versant la somme compensatoire de son choix, le Voyageur obtient des points de fidélité, des cadeaux et des avantages sur son séjour :

- 15 € reversés = 50 points de fidélité
- 150 € reversés = 500 points de fidélité = 1 nuit offerte sous la forme d’un bon Green Night d’une valeur de 150 euros TTC

<strong>10.3. Parrainage</strong>

En cas de parrainage d’un proche non encore client de la Société, c’est-à-dire n’ayant jamais effectué de Réservation sur le Site, un Voyageur disposant d’un Compte peut obtenir des Green Points lors de la première Réservation de son filleul.

Le filleul ne pourra bénéficier que d’un seul parrainage pour une souscription. Lors de la souscription, pour être filleul le Voyageur devra renseigner le code parrainage qui lui aura été communiqué par le parrain.

Le programme de parrainage sera valable après vérification et validation de la Réservation du filleul.

Le parrain et le filleul bénéficieront chacun d’une prime de parrainage de 10 Green Points automatiquement affecté à leur programme de fidélité.

La liste de filleuls et les avantages parrainage sont accessibles par connexion sur le Compte personnel du Voyageur.


<strong>ARTICLE 11 – Politique de traitement des avis clients et de leur publication en ligne</strong>

<strong>11.1. Conditions et modalités de dépôt d’un avis et d’une note</strong>

A la suite d’une Réservation honorée par le Voyageur, celui-ci est invité à donner son avis en rédigeant un commentaire et à noter l’Hébergement et/ou les Services Connexes en exprimant sa satisfaction sur une échelle de 1 à 10.

Les éléments à prendre en compte pour l’évaluation sont : qualité de l’Hébergement et/ou du Service Connexe, prix adéquat de l’Hébergement et/ou du Service Connexe, adéquation de l’Hébergement et/ou du Service Connexe au public visé, satisfaction générale et recommandation du Fournisseur. Un champ libre permet également au Voyageur de déposer un avis qualitatif.

Les avis sont tous sollicités à la suite d’une Réservation et le séjour dans l’Hébergement réservé. Aucune contrepartie n'est offerte aux Voyageurs pour la collecte de leur avis ni par la Société ni par les Fournisseurs.

Chaque avis déposé doit pouvoir être relié à un Voyageur par la Société.

Pour laisser un avis, le Voyageur doit être une personne physique, ne pas être en situation de conflit d'intérêt et avoir séjourné personnellement au sein de l’Hébergement évalué ou avoir effectué lui-même le Service Connexe concernée par l’évaluation.

Dans un souci d'authenticité de son avis, il doit également pouvoir être identifié par les coordonnées qu’il a communiqué pour la création de son compte ou sa Réservation. Le dépositaire d'avis accepte la possibilité d'être recontacté à des fins d'analyse ou de traitement le cas échéant de son mécontentement par téléphone ou par courriel.

Les commentaires sont publiés généralement en moins de 48 heures après la réception de l’avis, sans que ce délai constitue un engagement de résultat de la part de la Société. La date mentionnée sur l’avis publié est la date de publication de l’avis. Les avis sont anonymisés et seuls le prénom et l’initiale du nom du Voyageur sont visibles par les Utilisateurs du Site.

<strong>11.2. Conditions et modalités de réponse d’un Fournisseur</strong>

Le Fournisseur dont un Hébergement ou un Service Connexe a été notée et/ou appréciée dispose de la possibilité de répondre au Voyageur.

Dès dépôt d’un avis relatif à un Hébergement ou un Service Connexe qu’il offre aux Voyageurs, le Fournisseur reçoit une notification et dispose d’un délai de 72 heures à compter de cette notification pour répondre à l’avis.

En cas de réponse, une notification est adressée par courriel au Voyageur concerné qui peut ajuster sa note et/ou son avis.

<strong>11.3. Modération</strong>

Les commentaires et avis sont modérés a posteriori par le Site afin que ces contributions ne contreviennent pas aux lois et usages en vigueur, qu'elles restent décentes, courtoises et respectueuses de chacun et qu'elles ne s'écartent pas des sujets dont le Site a vocation à traiter, ou ne détournent pas sa finalité.

Le rôle des modérateurs est d'écarter les commentaires attentatoires au respect des personnes ou nuisant à la qualité des échanges à la suite d’un signalement par un Utilisateur.

Les Voyageurs et les Fournisseurs acceptent de n'envoyer aucun contenu :
- qu'ils savent faux, imprécis ou fallacieux ;
- qui enfreint tout droit d'auteur, brevet, marque de commerce, secret commercial ou autres droits de propriété, droits de publicité ou portant atteinte à la confidentialité de tout tiers ;
- qui enfreint toute loi ou ordonnance, ou encore tout décret ou règlement (notamment, mais sans s'y limiter, ceux qui régissent la protection de la vie privée, la protection des consommateurs, la concurrence déloyale, la discrimination ou la publicité mensongère) ;
- qui est ou peut raisonnablement être considéré comme étant diffamatoire, incitant à la haine, partial ou insultant sur le plan racial ou religieux, menaçant de manière illégale ou représentant un harcèlement illicite de tout individu, partenariat ou toute entreprise ;
- pour lequel ils ont été payés ou ont reçu une rétribution par tout tiers ;
- qui comporte des renseignements faisant référence à d'autres sites Web, adresses, adresses email, coordonnées de contact ou numéros de téléphone ;
- qui contient des virus, vers ou autres programmes ou fichiers informatiques potentiellement nocifs ;
- qui permettrait de s’introduire dans un système informatique, ou d’altérer le contenu ou commettre l’une quelconque des infractions réprimées par les articles 323-1 et 323-7 du Code pénal (« hacking »), ou de procéder à l’envoi en nombre de messages non sollicités via le Service, pratique réprimée par l’article 226-18 du Code pénal ;
- qui désactiverait ou perturberait de quelque façon que ce soit toute fonction du Site.

La Société, en accord avec la loi, se réserve la possibilité de ne pas afficher et/ou de supprimer tout commentaire qu'elle jugerait contraire aux principes de modération ci-dessus ou dont un tiers, estimant une ou plusieurs de ces contributions préjudiciables, demanderait le retrait.

La Société, en sa qualité de simple modérateur, ne peut garantir la véracité, la probité ou l'honnêteté des contributions, qui peuvent, le cas échéant, engager la responsabilité civile ou pénale de leur auteur.

Dans l'hypothèse où un commentaire ou un avis serait écarté par les modérateurs, la note attribuée ne serait pas prise en compte pour le calcul de la note moyenne de l’Hébergement ou du Service Connexe.

<strong>11.4. Affichage des avis</strong>

L'avis collecté est destiné à être affiché de manière individuelle et anonyme sur les pages de chacun des Hébergements notés.

L'auteur d'un avis ne peut le modifier une fois qu'il l'a validé, sauf en cas de réponse par le Fournisseur. L’auteur d’un avis peut demander à la Société de le supprimer de son site Internet.

Un Fournisseur peut demander la suppression d’un avis de façon motivée notamment dans le cas où il estime que l’avis est inapproprié. La société demeure toutefois libre de juger du bien-fondé de la demande.

La demande de suppression d’un avis s’effectue en envoyant un courriel à contact@hortense.green en précisant le ou les avis concerné(s) et les motivations de la demande.

La société ne fixe pas de limite à la durée d’affichage des avis.

<strong>11.5. Notes</strong>

Toutes les notes sont prises en compte, sans exception, dans le calcul de la satisfaction des Hébergements. Les notes moyennes sont recalculées à chaque fois qu’un nouvel avis est pris en compte. Pour chacun des cas ou types d’Hébergement, chaque note sur cinq est calculée sur la base d'une moyenne arithmétique sur l’ensemble des avis publiés.

<strong>ARTICLE 12 – Informatiques et Libertés</strong>
 
Lors de votre Réservation, les données nominatives collectées feront l'objet d'un traitement informatique. Vous êtes invité à vous reporter à notre politique de protection des données du Site pour obtenir toutes les informations relatives à ce point www.hortense.green/politique-confidentialité/.

<strong>ARTICLE 13 – Propriété intellectuelle</strong>

Le Site et chacun des éléments qui le composent, notamment, mais non limitativement, les logiciels, les structures, infrastructures, bases de données et contenus de toute nature (textes, images, visuels, logos, marques…) exploités par la Société sont protégés par tous droits de propriété intellectuelle en vigueur, ce que l’Utilisateur reconnait.

L'utilisation du Site ne confère aux Utilisateurs aucun droit de propriété intellectuelle sur quelque élément que ce soit dudit Site, qu'il s'agisse des outils logiciels et techniques mis à votre disposition par la Société ou encore des éléments protégés (textes, images, vidéos, marques, dessins et modèles, bases de données…) qui restent la propriété de leurs propriétaires respectifs.

L’accès au Service est concédé en licence d’utilisation gratuite. Les licences accordées par les CGU ne donnent aucun droit sur le Contenu du Site, programmes informatiques, logiciels, et produits associés aux Services fournis par la Société, ni sur les logos associés et d’autres noms, logos, icônes et marques identifiant les produits et Services de la Société qui ne doivent pas être utilisés sans la permission écrite préalable de la Société.

A ce titre, toute reproduction ou représentation, intégrale ou partielle, du Site et des Services qu’il propose ou de chacun des éléments qui le compose, sans l’autorisation de la Société, est interdite.

De même, l’Utilisateur s’interdit, sauf autorisation écrite et préalable de la Société, d’utiliser, de reproduire, d’adapter, de modifier, de créer des œuvres dérivées, de distribuer, de concéder une licence, de vendre, de transférer, de présenter publiquement, de transmettre, de diffuser ou d’exploiter de toute autre manière le Site, les Services qu’il propose et l’intégralité de ses éléments appartenant à la Société.

Conformément aux dispositions applicables du Code de la propriété intellectuelle, seule l'utilisation du Site pour un usage privé est autorisée, à l'exclusion de toute autre utilisation qui serait constitutive d'actes de contrefaçon sanctionnés en tant que tels. Toute autre utilisation pourra donner lieu à des poursuites.

<strong>ARTICLE 14 - Accessibilité du Site</strong>

La Société s'engage à faire ses meilleurs efforts pour assurer l'accessibilité aux Services et le bon fonctionnement du Site.

A ce titre, la Société s’efforce de permettre l’accès aux Services 24 heures sur 24, 7 jours sur 7, sauf en cas de force majeure tel que défini par l’article 1218 du code civil et la jurisprudence applicable ou d’un événement hors de son contrôle, et sous réserve des éventuelles pannes et interventions de maintenance nécessaires au bon fonctionnement du Site.

La Société ne peut garantir une disponibilité du Site et/ou des Services, une fiabilité des transmissions et des performances en termes de temps de réponse ou de qualité.

La responsabilité de la Société ne saurait être engagée en cas d’impossibilité d’accès aux Services.

Par ailleurs, la Société peut être amenée à interrompre l’accès aux Services ou à une partie des Services, à tout moment sans préavis et sans droit à indemnités. L’Utilisateur reconnaît et accepte que la Société ne soit pas responsable des interruptions, et des conséquences qui peuvent en découler pour l’Utilisateur ou tout tiers.

<strong>ARTICLE 15 – Obligations de l’Utilisateur</strong>

Les Utilisateurs s’engagent à faire une utilisation du Site dans le respect des conditions des présentes CGU.

Plus particulièrement, les Utilisateurs s’interdisent toute utilisation des Services qui serait contraire à toute législation applicable. A cet égard, les Utilisateurs s’interdisent de publier du Contenu injurieux, diffamant, dénigrant, calomnieux, raciste, xénophobe, contraire à la morale et aux bonnes mœurs, contrefaisant, portant atteinte à l’ordre public ou aux droits de tiers, susceptible de porter atteinte aux droits, à la réputation et à l’image de la Société et plus généralement, dont la teneur enfreindrait la loi et/ou les règlements, notamment d’ordre pénal.

Les Utilisateurs reconnaissent et acceptent qu’ils assument l’entière responsabilité de toute publication. La responsabilité de la Société ne pourra en aucun cas être engagée à ce titre.

Chaque Utilisateur s’engage également :
- à vérifier l’exactitude des informations transmises ;
- à s’interdire de procéder à une quelconque altération, reproduction, correction, arrangement, modification ou diffusion du Service ;
- à vérifier la conformité de ses données à la législation et aux droits d’éventuels tiers, à en obtenir seul toutes les autorisations nécessaires, et garantit la Société contre tout recours d’un tiers.

Les Utilisateurs s’engagent expressément à :
- respecter les lois et règlements en vigueur et ne porte pas atteinte à l’ordre public, aux bonnes mœurs ou aux droits de tiers et n’enfreigne aucune disposition législative ou réglementaire ;
- ne pas mettre à disposition du public tout contenu dont ils ne détiendraient pas les droits et qui violerait ainsi des dispositions relatives au droit d’auteur ou au droit de la propriété intellectuelle ;
- Ne pas utiliser le Site et les Services de manière frauduleuse (ex : un faux compte ou une fausse identité) et/ou pour nuire aux intérêts de la Société ou de tiers ;
- Ne pas céder ou transférer d’une quelconque manière son Compte à un tiers ;
- Fournir à la Société toutes les informations nécessaires au fonctionnement du Site et des Services ;
- Informer la Société en cas de complication de toute nature concernant le Site ou les Services.

L’Utilisateur reconnait qu’est interdit :
- Tout comportement de nature à interrompre, suspendre, ralentir ou empêcher la continuité du Service ;
- Toute intrusion ou tentative d’intrusions dans les systèmes de la Société, tout détournement des ressources système du Site, des Services et du Compte ;
- Toute utilisation du Site à des fins illicites ou illégales telles que notamment : spamming, intrusion ou tentative d’intrusion à partir du Site (à titre non exhaustif : scan de ports, sniffing, spoofing…).
- Toute action de nature à imposer une charge disproportionnée sur les infrastructures numériques de la Société ;
- Toute atteinte aux mesures de sécurité et d’authentification, tout acte de nature à porter atteinte aux droits et intérêts financiers, commerciaux ou moraux de la Société et des autres utilisateurs du Site et des Services ;
- Tout manquement aux CGU ;
- Toute copie et/ou détournement de l’infrastructure numérique du Site ;
- Et plus généralement toute pratique détournant le Site et les Services à des fins autres que celles pour lesquelles ils ont été conçus.

L’Utilisateur est également seul responsable de toutes informations transmises et de leur mise à jour. En conséquence, la Société ne saurait être tenue pour responsable du contenu des informations transmises, diffusées ou collectées, de leur exploitation et de leur mise à jour, ainsi que de tous fichiers et ce, à quelque titre que ce soit. A ce titre, l’Utilisateur s’engage à conserver des informations conformes et à les rectifier si besoin par le biais de son espace personnel.

Les Utilisateurs sont seuls responsables de leur utilisation du Site. La responsabilité de la Société ne saurait être engagée du fait d’un défaut de fonctionnement du Site consécutif à une mauvaise utilisation de celui-ci par l’Utilisateur. Par ailleurs, les Utilisateurs garantissent la Société contre toute action émanant de tiers ou d’autres Utilisateurs qui revendiqueraient une violation de leurs droits.

En utilisant le Site, l'Utilisateur accepte, dans les limites légales et réglementaires applicables, d'indemniser la Société ou toute filiale qui subirait un préjudice du fait du manquement de l’Utilisateur de toutes les réclamations soulevées par des tiers à la suite d'une violation de leurs droits par l'Utilisateur en relation avec le contenu téléchargé ou généré par cet Utilisateur.

L'Utilisateur supporterait le cas échéant les frais de toute procédure judiciaire, dans laquelle la Société ou l’une de ses filiales serait impliquée dans le cadre de ces revendications, y compris les dépens et les honoraires d'avocat et ce, sans préjudice des dommages et intérêt que pourrait solliciter la Société ou la filiale considérée en réparation des préjudices qu’elle a personnellement subis.

Dans le cas où une demande est présentée par un tiers, l'Utilisateur doit mettre à la disposition de la Société ou de la filiale concernée, dans les meilleurs délais, l’ensemble des informations utiles à sa défense et à l’appréhension de la prétention du tiers.

<strong>ARTICLE 16 – Droit applicable</strong>
 
Les présentes Conditions Générales de Vente et les opérations qui en découlent sont régies par le droit français, sous réserve des dispositions impératives du pays de résidence du Voyageur.

Les présentes Conditions Générales de Vente sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige.

<strong>ARTICLE 17 – Litiges et médiation</strong>
 
Tous les litiges auxquels les opérations d’achat et de vente conclues en application des présentes conditions générales de vente pourraient donner lieu, concernant tant leur validité, leur interprétation, leur exécution, leur résiliation, leurs conséquences et leurs suites et qui n’auraient pu être résolues entre la Société et le Voyageur seront soumis aux tribunaux compétents dans les conditions de droit commun.

Conformément aux dispositions du Code de la consommation concernant le règlement amiable des litiges, la Société adhère au Service du Médiateur du Tourisme et voyage (MTV).

Le Voyageur est informé qu’il peut en tout état de cause recourir à une médiation conventionnelle, notamment auprès de la Commission de la médiation de la consommation (article L 534-7 du Code de la consommation) ou auprès des instances de médiation sectorielles existantes, et dont les références figurent sur le Site ou à tout mode alternatif de règlement des différends (conciliation, par exemple) en cas de contestation.

<strong>ARTICLE 18 – Information précontractuelle – Acceptation du Voyageur</strong>
 
Le Voyageur reconnait avoir eu communication, préalablement à la passation de sa Réservation, d’une manière lisible et compréhensible, des présentes Conditions Générales de Vente et de toutes les informations et renseignements visés aux articles L. 111-1 à L. 111-7 du Code de la consommation, et en particulier :
- les caractéristiques essentielles de l’Hébergement et des Services Connexes, compte tenu du support de communication utilisé et du Fournisseur concerné ;
- le prix des Nuitées et des Services Connexes et des frais annexes ;
- les informations relatives à l’identité du Fournisseur, à ses coordonnées postales, téléphoniques et électroniques, et à ses activités, si elles ne ressortent pas du contexte ;
- les fonctionnalités du contenu numérique et, le cas échéant, à son interopérabilité ;
- la possibilité de recourir à une médiation conventionnelle en cas de litige ;
- les informations relatives au droit de rétractation (absence d’application).

Le fait pour une personne physique, de Réservation sur le Site emporte adhésion et acceptation pleine et entière des présentes CGU, ce qui est expressément reconnu par le Voyageur, qui renonce, notamment, à se prévaloir de tout document contradictoire, qui serait inopposable à la Société.

<strong>ARTICLE 19 – Responsabilité</strong>
 
La responsabilité de la Société ne peut pas être engagée en cas d’inexécution ou de mauvaise exécution due soit au fait du Voyageur soit au fait insurmontable et imprévisible d’un tiers au contrat, soit à la force majeure, soit à des circonstances exceptionnelles et inévitables au sens de l’article L. 211-2 du code du tourisme.

Malgré le soin apporté à l’élaboration et à la mise à jour du contenu du Site, des informations inexactes ou incomplètes sont susceptibles d’y apparaître. Celles-ci ne sont pas susceptibles d’engager la responsabilité de la Société.

La Société ne saurait être tenue responsable des dommages résultant de l’utilisation ou de l’impossibilité d’utilisation du Site y compris les virus informatiques.

Malgré l’utilisation d’un logiciel de sécurité crypté, la Société décline toute responsabilité en cas de dommages résultant de l’utilisation de communication électronique et ne peut garantie la sécurité des informations et des paiements transmis via internet ou par courriel.

La responsabilité de la Société ne saurait être engagée en cas de dommages subis par un tiers résultant de l’utilisation du Site.

La Société s’engage à fournir les Services avec diligence, étant précisé qu’elle n’est soumise qu’à une obligation de moyens, à l’exclusion de toute obligation de résultat, ce que les Utilisateurs reconnaissent et acceptent expressément.

La Société intervient en qualité́ d’intermédiaire. Sa responsabilité́ se limite à la fourniture des outils et moyens techniques permettant aux Fournisseurs et aux Utilisateurs d’entrer en relation aux fins de Réservation à travers le Site. La Société agit en son nom propre et ne passe aucun acte juridique au nom et pour le compte des Fournisseurs ou des Utilisateurs, qui contractent directement entre eux.

La Société n’est pas partie aux contrats conclus entre les Fournisseurs et les Utilisateurs et ne saurait en aucun cas voir sa responsabilité́ engagée au titre des difficultés pouvant intervenir lors de la conclusion ou de l’exécution de ces contrats, ni être partie à quelques litiges éventuels que ce soit entre un Fournisseur et un Utilisateur.

<strong>ARTICLE 20 – Stipulations finales</strong>
 
Si une stipulation des CGU venait à être déclarée illégale ou inapplicable, l’applicabilité du reste des CGU n’en serait pas affectée, les autres clauses demeurant en vigueur.
Toute renonciation ou retard de la Société à réclamer l’exécution par le Voyageur des stipulations des présentes CGU ne vaut pas renonciation aux droits dont il est titulaire et n’autorise pas le Voyageur à s’affranchir du respect des CGU.

Les présentes CGU constituent l’intégralité de l’accord conclu entre les parties et remplacent tous les accords précédents et actuels conclus entre la Société et le Voyageur. Toute renonciation à une disposition des CGU ne sera effective qu’après signature écrite d’un représentant de la Société.
</pre>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'cgu',
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/static-pages'>
</style>
