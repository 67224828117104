<template>
  <div id="chatroom">
    <RoomDropdown :room-id='1'></RoomDropdown>
    <RoomDropdown :room-id='2'></RoomDropdown>
  </div>
</template>

<script>
  import RoomDropdown from 'components/chatroom/RoomDropdown.vue';

  export default {
    name: 'ChatRoom',
    components: { RoomDropdown }
  }
</script>

<style lang="scss" scoped>
  #chatroom {
    width: 100%;
    height: 100%;
    background-color: white;
    position: absolute;
    top: 0;
    z-index: 999;
  }
</style>
