<template>
  <div id="whois-phone">
    <div class="whoishome-bg">
      <div class="card-whoishome">
        <div class="whoishome-header">
          <h2 class="merriweather-reg-24 pr-2"> Qui est </h2>
          <h1 class="merriweather-reg-30"> Hortense ? </h1>
        </div>
        <p class="raleway-reg-p15">Profondément passionnée et impliquée, Hortense redéfinit les liens entre les différents acteurs du tourisme durable. Voyageurs, hôteliers, agences de voyage en ligne : tous ensemble, voyageons dans le bon sens.</p>
        <a :href="'/qui-est-hortense-voyage-ecoresponsable/'" class="hortense-btn-primary">Découvrir Hortense</a>
      </div>
    </div>
  </div>
</template>


<script>




  export default {
    name: 'whois-phone',
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/whois-phone'>

</style>
