<template>
  <div id="multiselectbudget">
    <div class="dropdown dropdown-filter" @click="showDropdown">
      <div class="overselect"></div>
      <div class="c-form-input">
        <h2 class="raleway-semi-p13">Nombre de voyageurs</h2>
        <img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="show" />
        <img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
      </div>
    </div>
    <div class="multiselect mt-3" v-if="show">
      <div class="adult choice-number row" style="margin-left: 20px">
        <div class="col-4">
          <p class="raleway-reg-p15 mb-0" style="font-size: 13px">Adultes</p>
        </div>
        <div class="col-2" @click="decreaseAdults()">
          <img style="width: 19px;height: 28px;" src="../../images/Moins.svg" alt="moins" />
        </div>
        <div class="col-2">
          <p class="raleway-reg-p15 mb-0">{{ adults }}</p>
        </div>
        <div class="col-2" @click="increaseAdults()">
          <img src="../../images/Plus.svg" style="width: 19px;height: 28px;" alt="moins" />
        </div>
      </div>
      <div class="child choice-number row" style="margin-left: 20px">
        <div class="col-4">
          <p class="raleway-reg-p15 mb-0" style="font-size: 13px">Enfants</p>
        </div>
        <div class="col-2" @click="decreaseChilds()">
          <img style="width: 19px;height: 28px;" src="../../images/Moins.svg" alt="moins" />
        </div>
        <div class="col-2">
          <p class="raleway-reg-p15 mb-0">{{ childs }}</p>
        </div>
        <div class="col-2" @click="increaseChilds()">
          <img style="width: 19px;height: 28px;" src="../../images/Plus.svg" alt="moins" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'roomspace',
  components: { },
  props: ['options'],
  data() {
    return {
      childs: 0,
      adults: 2,
      show: false,
      selected: [],
    };
  },
  methods: {
    showDropdown() {
      this.show = !this.show;
    },
    decreaseChilds() {
      if (this.childs > 0) {
        this.childs -= 1;
        this.$emit('changedChilds', this.childs);
        this.$emit("decreaseChilds", this.childs);
      }
    },
    increaseChilds() {
      if (this.childs < 9) {
        this.childs += 1;
        this.$emit('changedChilds', this.childs);
        this.$emit("increaseChilds", this.childs);
      }
    },
    decreaseAdults() {
      if (this.adults > 0) {
        this.adults -= 1;
        this.$emit('changedAdults', this.adults);
        this.$emit("decreaseAdults", this.adults);
      }
    },
    increaseAdults() {
      if (this.adults < 9) {
        this.adults += 1;
        this.$emit('changedAdults', this.adults);
        this.$emit("increaseAdults", this.adults);
      }
    },
  },
};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
