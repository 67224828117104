<template>
  <div id="card-exclusive">
    <div class="exclusive-container mt-1">
      <div class="exclusive-card mb-4">
          <img v-if="exclusive.photo_key" :src="exclusive.photo_key" :alt="exclusive.key_word">
          <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" :alt="exclusive.key_word">
      </div>
      <div class="exclusive-card">
          <h3 class="category-exclusive merriweather-italic mt-4">{{ exclusive.key_word }}</h3>
          <p class="subtitle-card raleway-bold-p">{{ exclusive.title }}</p>
          <p class="description-card raleway-reg-p13">{{ exclusive.subtitle }}</p>
          <p class="description-card raleway-reg-p13">{{ exclusive.description_hp }}</p>
          <a :href="'/offres-exclusives/' + exclusive.slug" class="link-hortense">Découvrir la sélection</a>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'cardExclusive',
    props: ['exclusive'],
    data() {
      return{
        myExclusive: this.exclusive
      }
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/exclusive'>
</style>
<style lang="scss" scoped>

   .exclusive-container {
        gap: 1.5rem;
        margin-top: 6rem;
        width: 100%;
        //height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .exclusive-card {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            //height: 70vw;
            //height: 100%;
            img{
              height: 19vw;
              width: 14vw
            }
        }
    }
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  .exclusive-container {
        gap: 1.5rem;
        margin-top: 6rem;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        .exclusive-card {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
        }
    }
  
}
</style>
