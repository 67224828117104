<template>
  <div id="modal-chief-intro">
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-container">
              <div class="closing">
                <button class="close-btn-modal" @click="$emit('close')"></button>
              </div>
              <div class="modal-content-pros">
                <div class="box-pros">
                  <!-- <p class="raleway-reg-p15">HELLO</p> -->
                  <p class="raleway-reg-p15">{{ this.description.chief_intro }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
  </div>
</template>

<script>
    import fetcher from 'packs/shared/api.js';

  export default{
    name: 'modal-chief-intro',
    props:[ 'description' ],
  }
</script>

<style lang="scss" scoped>
#modal-chief-intro{
  .modal-mask {
    position:fixed;
    overflow:scroll;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: table;
    transition: opacity 0.3s ease;
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-container {
    width: 721px;
    height: fit-content;
    margin: 51px auto;
    padding: 1rem 1rem;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    .heading-modal{
      padding: 0rem 7rem;
    }
  }
  .modal-content-pros{
    padding: 1rem 3rem;
    overflow-y: scroll;
  }
  .closing{
    display: flex;
    justify-content: flex-end;
  }
  button.close-btn-modal{
    background-image: url('../../images/Croix fermer.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 7px;
    height: 7px;
    border: 0;
    background-color: transparent;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  #modal-chief-intro{
    .modal-container {
      width: auto;
      height: fit-content;
      margin: 51px auto;
      padding: 1rem 1rem;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }
    .closing{
      display: flex;
      justify-content: center;
    }
  }
}

</style>
