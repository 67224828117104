<template>
  <div id="cardProduct-big" @click="$emit('selectedCard', dataMarker)" v-if="lodgingMount">
    <div class="catch-hub-phone" v-if="lodging.lodging_description && lodging.lodging_description.catch_sentence">
      <p class="merriweather-italic">{{ lodging.lodging_description.catch_sentence }}</p>
    </div>
    <div class="catch-hub-phone" v-else>
      <p class="merriweather-italic">Un havre aussi éco-responsable que luxueux</p>
    </div>
    <div class="hub-img-zoom" @click="goToLodging()" :style="{ backgroundImage: 'url(' + formatSource() + ')' }">
      <div class="sticker-hub d-flex mt-4 pl-2" style="position: none;">
        <img :src="greenNotePicto(lodging.score.toFixed())" :alt="lodging.score.toFixed()" v-if="lodging.score && !upHere" />
        <div class="sticker-info d-flex" v-if="lodging.score" @mouseover="upHere = true" @mouseleave="upHere = false">
          <div v-if="!isPhone" style="position:absolute; width: 519px;height: 396px;background: #FFFFFF 0% 0% no-repeat padding-box;border: 1px solid #A5D3AE;border-radius: 0px 53px 53px 0px;opacity: 1;" v-show="upHere">
            <div class="row pl-2">
              <div class="pl-3 ">
            <img style="width: 14px;height: 21px;" :src="greenNotePicto(lodging.score.toFixed())" :alt="lodging.score.toFixed()" v-if="lodging.score" />
              </div>
            <p class="raleway-reg-p13 mb-0 pl-1 pr-2">
              <b>{{ lodging.score.toFixed() }}</b
              >/5
            </p>
            </div>
            <div class="pl-4">
            <div class="mt-2">
            <p style="text-align: left;font: normal normal bold 13px/15px Raleway;letter-spacing: 0px;color: #000000;text-transform: uppercase;opacity: 1;">
              LA GREEN NOTE
              </p>
            </div>
            <div class="mt-3 pr-2">
              <p style="font: normal normal bold 13px/15px Raleway;">
                Pour être intégré à la sélection, un hébergement doit obtenir une note globale d’au moins 5 sur 10.</p>
            </div>
            <div class="mt-2 pr-2">
            <p style="text-align: left;font: normal normal normal 12px/14px Raleway;letter-spacing: 0px;color: #000000;opacity: 1;">
              La Green Note, développée par Hortense, est un outil de scoring qui vise à évaluer le niveau d’engagement environnemental des hébergements. Elle se base sur 10 critères d’éco-responsabilité pour attribuer une note sur 10 à chaque critère, ainsi qu’une note globale à l’hébergement, allant de 1 à 10.<br></br> Pour être intégré à la sélection, un hébergement doit obtenir une note globale d’au moins 5 sur 10. Cela signifie que l’établissement doit démontrer un engagement significatif dans des pratiques éco-responsables et répondre aux critères établis par la Green Note.<br></br> Les 10 critères d’éco-responsabilité pris en compte dans l’évaluation incluent des aspects tels que la gestion des déchets, l’efficacité énergétique, l’utilisation de sources d’énergie renouvelables, la conservation de l’eau, la promotion du transport durable, l’utilisation de matériaux écologiques, la sensibilisation à l’environnement, la formation du personnel aux éco-gestes etc.<br></br> En utilisant la Green Note, Hortense vise à encourager les hébergements à adopter des pratiques plus respectueuses de l’environnement et à aider les voyageurs à faire des choix plus durables en matière d’hébergement.</p>
            </div>
          </div>
          </div>
          <div v-if="isPhone" style="position:fixed;background: #FFFFFF 0% 0% no-repeat padding-box;border: 1px solid #A5D3AE;border-radius: 0px 53px 53px 0px;opacity: 1;" v-show="upHere">
            <div class="row pl-2">
              <div class="pl-3 ">
                <img style="width: 14px;height: 21px;" :src="greenNotePicto(lodging.score.toFixed())" :alt="lodging.score.toFixed()" v-if="lodging.score" />
              </div>
              <p class="raleway-reg-p13 mb-0 pl-1 pr-2">
                <b>{{ lodging.score.toFixed() }}</b
                >/10
              </p>
            </div>
            <div class="pl-4">
              <div class="mt-2">
                <p style="text-align: left;font: normal normal bold 13px/15px Raleway;letter-spacing: 0px;color: #000000;text-transform: uppercase;opacity: 1;">
                  LA GREEN NOTE
                </p>
              </div>
              <div class="mt-3 pr-2">
                <p style="font: normal normal bold 13px/15px Raleway;">
                  Pour être intégré à la sélection, un hébergement doit obtenir une note globale d’au moins 5 sur 10.</p>
              </div>
              <div class="mt-2 pr-2">
                <p style="text-align: left;font: normal normal normal 12px/14px Raleway;letter-spacing: 0px;color: #000000;opacity: 1;">
                  La Green Note, développée par Hortense, est un outil de scoring qui vise à évaluer le niveau d’engagement environnemental des hébergements. Elle se base sur 10 critères d’éco-responsabilité pour attribuer une note sur 10 à chaque critère, ainsi qu’une note globale à l’hébergement, allant de 1 à 10.<br></br> Pour être intégré à la sélection, un hébergement doit obtenir une note globale d’au moins 5 sur 10. Cela signifie que l’établissement doit démontrer un engagement significatif dans des pratiques éco-responsables et répondre aux critères établis par la Green Note.<br></br> Les 10 critères d’éco-responsabilité pris en compte dans l’évaluation incluent des aspects tels que la gestion des déchets, l’efficacité énergétique, l’utilisation de sources d’énergie renouvelables, la conservation de l’eau, la promotion du transport durable, l’utilisation de matériaux écologiques, la sensibilisation à l’environnement, la formation du personnel aux éco-gestes etc.<br></br> En utilisant la Green Note, Hortense vise à encourager les hébergements à adopter des pratiques plus respectueuses de l’environnement et à aider les voyageurs à faire des choix plus durables en matière d’hébergement.</p>
              </div>
            </div>
          </div>
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2">
            <b>{{ lodging.score.toFixed() }}</b
            >/10
          </p>
          <p class="raleway-reg-p13 mb-0 pl-1 pr-2">Green note</p>
        </div>
      </div>
    </div>
    <div class="card-hub-info">
      <div class="hub-header d-flex">
        <h1 class="raleway-bold-h3">{{ lodging.name }}</h1>
<!--        <div v-if="lodging.lodging_description.is_club">-->
<!--          <img style="height: 50px; width: 50px" src="../../images/club_logo.png" alt="Club LOgo" />-->
<!--        </div>-->
        <div class="like-icon">
          <img src="../../images/wishlistOn.svg" alt="Coeur plein" v-if="isIncludeInWishlist()" @click="toggleLike()" />
          <img src="../../images/wishlist.svg" alt="Coeur vide" v-else @click="toggleLike()" />
        </div>
      </div>
      <Mapfind v-if="showMapfind" :markers="markers" @close="closeMap"></Mapfind>
      <div v-if="lodging.standing && parseInt(lodging.standing) > 0" class="standing">
        <img src="../../images/Etoiles.svg" alt="Etoile remplie" v-if="lodging.standing" v-for="i in parseInt(lodging.standing)" :key="i" />
       <img v-if="lodging.standing && parseInt(lodging.standing) > 0" src="../../images/blanc.png" alt="white" />
      </div>
      <p class="description-hub raleway-reg-p13" v-if="lodging.country">{{ formatStyle(lodging.country.name) }}, {{ lodging.region.name }}</p>
      <div class="location-hub">
        <img src="../../images/Localisation.svg" alt="Localisation" style="margin-right: 5px" />
        <a class="link-hortense" @click="displayMapfind">Voir sur la carte</a>
      </div>
      <div @click="goToLodging()" class="catch-hub" v-if="lodging.lodging_description && lodging.lodging_description.catch_sentence">
        <p class="merriweather-italic">{{ lodging.lodging_description.catch_sentence }}</p>
      </div>
      <div @click="goToLodging()" class="catch-hub" v-else>
        <p class="merriweather-italic">Un havre aussi éco-responsable que luxueux</p>
      </div>
      <div @click="goToLodging()" id="green-experience-section">
        <div class="header" v-if="lodging.lodging_description.green_experience ">
          <span class="nights">+ DE 3 NUITS = GREEN EXPÉRIENCE</span>
          <span class="visit">{{ lodging.lodging_description.green_experience }}</span>
        </div>
        <div class="discount-details" v-if="lodging.lodging_description.green_experience_two">
          <img src="../../images/discount.png" class="discount-image" />
          <div class="details">
            <div class="details-1">
              <span class="discount">{{lodging.lodging_description.green_experience_two}}</span>
<!--              <span class="separator">|</span>-->
<!--              <span class="discount">-10% SUR LA CARTE DES SOINS</span>-->
            </div>
<!--            <div class="details-1">-->
<!--              <span class="check-in">CHECK-IN AVANCÉ</span>-->
<!--              <span class="separator">|</span>-->
<!--              <span class="bottle">BOUTEILLE DE MUSCAT PÉTILLANT CORSE</span>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <p class="raleway-reg-p13">A partir de <strong>{{ this.lodging.lowest_price}}€/nuit</strong></p>
      <div  class="reviews-btn">
        <div class="hub-reviews d-flex">
          <img class="mt-2 mr-2" src="../../images/avis.svg" alt="avis" />
          <div class="review-info d-flex">
            <div class="score-hub d-flex" v-if="ratedLodgings">
              <p class="raleway-reg-p13 mb-0 pl-1 pr-2">
                <b>{{ this.lodging.lodging_description.rating_value || average }}</b
                >/5
              </p>
              <a :href="'/reservation-hotel/' + lodging.slug + '/avis'" class="link-hortense"> {{ this.lodging.lodging_description.review_count || ratedLodgings.length }} avis</a>
            </div>
            <p class="raleway-reg-p13">Note des voyageurs</p>
          </div>
        </div>
        <a v-if="exclusive" @click="openInNewWindowTwo(exclusive.slug, lodging.slug)" :href="'/offres-exclusives/' + this.exclusive.slug + '/' + this.lodging.slug" class="xs-btn-primary text-center" target="_blank" rel="noopener noreferrer">Voir les disponibilités</a>
        <a v-else @click="openInNewWindow(lodging.slug)" class="xs-btn-primary text-center">Voir les disponibilités</a>
      </div>
    </div>
  </div>
</template>

<script>
import fetcher from 'packs/shared/api.js';
import Cardmap from 'components/cards/Cardmap.vue';
import Maptest from 'components/Maptest.vue';
import Mapfind from 'components/Mapfind.vue';

export default {
  name: 'CardProductBig',
  props: ['lodging', 'dataMarker', 'user', 'exclusive', 'wishlistLodgings', 'showMapCard', 'to', 'from'],
  components: { Maptest, Cardmap, Mapfind },
  data() {
    return {
      lodgingMount: this.lodging,
      //wishlistLodgings: null,
      showMap: false,
      showMapfind: this.showMapCard,
      markers: [],
      ratedLodgings: [],
      averageList: [],
      average: '-',
      upHere: false
    };
  },
  methods: {
    goToLodging(){
      window.open('/reservation-hotel/' + this.lodging.slug, '_blank');
    },
    greenNotePicto(score) {
      if (score === `${score}`) {
        return require(`../../images/greennote ${score}.svg`);
      } else {
        return require('../../images/blanc.png');
      }
    },
    async toggleLike() {
      try {
        if (Object.entries(this.user).length !== 0) {
          const response = await fetcher.patch('/wishlists/' + this.user.id, { lodging_id: this.lodging.id });
          this.wishlistLodgings = response.data.lodgings;
          window.dataLayer.push({
            event : 'add_to_wishlist',
            ecommerce: {
              items: [{
                item_name: this.lodging.name,
                item_id: this.lodging.id,
                item_city: this.lodging.city ? this.lodging.city.name : 'Pruniers-en-Sologne',
                item_region: this.lodging && this.lodging.region ? this.lodging.region.name : 'Occitanie',
                item_category: 'lodging',
              }]
            }
          });
        } else {
          this.$root.$emit('openModal');
          window.scrollTo({
            top: 0,
            behaviour: 'smooth',
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    displayMap() {
      this.showMap = !this.showMap;
      this.markers = this.markerMap();
    },
    displayMapfind() {
      this.closeMap();
      this.$emit('hideBigMap');
      this.showMapfind = !this.showMapfind;
      this.markers = this.markerMap();
    },
    closeMap() {
      this.showMapfind = false;
      this.$emit('hideSmallMap');
    },
    markerMap() {
      return [this.lodging.longitude, this.lodging.latitude];
    },
    formatSource() {
      if (this.lodging.photo_banner) {
        return this.lodging.photo_banner
      } else {
        return 'https://media.gettyimages.com/photos/bedroom-in-new-luxury-home-picture-id1194686012?k=6&m=1194686012&s=612x612&w=0&h=IX6Bjhwa5M5NtCtlKll-FFi_hn5lxrPwiUImH3fsZZ0=';
      }
    },
    openInNewWindow(slug) {
      window.open('/reservation-hotel/' + slug, '_blank');
    },
    openInNewWindowTwo(slug_one, slug_two) {
      window.open('/offres-exclusives/' + slug_one + '/' + slug_two, '_blank');
    },
    formatStyle(value) {
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    isIncludeInWishlist() {
      if (this.wishlistLodgings) {
        for (const lodging of this.wishlistLodgings) {
          if (lodging.id === this.lodging.id) {
            return true;
          }
        }
      }
      return false;
    },
    ratedLodging() {
      if (this.lodging.reservations && this.lodging.reservations.length !== 0) {
        this.ratedLodgings = this.lodging.reservations.filter((el) => el.rated === true);
      }
      return this.ratedLodgings;
    },
    averageRate() {
      this.ratedLodgings.forEach((el) => {
        this.averageList.push(el.rate_average);
      });
      return this.averageList;
    },
    averageLodging() {
      if (this.averageList.length > 0) {
        this.average = (this.averageList.reduce((a, b) => a + b, 0) / this.averageList.length).toFixed(1);
      } else {
        this.average = '-';
      }
    },
    async fetchWishlist() {
      //console.log('************', this.user);
      try {
        if (this.user) {
          const response = await fetcher.get('/wishlists.json');
          this.wishlistLodgings = response.data.lodgings;
        }
      } catch (e) {
        console.error(e);
      }
    },
    async fetchLodgings() {
      try {
        // const res = await fetcher.get('/lodgings/' + this.lodging.slug);
        // this.lodgingMount = res.data;
        this.ratedLodging();
        this.averageRate();
        this.averageLodging();
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.fetchLodgings();
    //this.fetchWishlist();
  },
  computed: {
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    },
    defineWishlistLodgings() {
      return (this.wishlistLodgings = this.wishlist.lodgings);
    },
  },
};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/card-product"></style>