<template>
	<div id="multiselectlodgingstyle">
		<div class="dropdown dropdown-filter" @click="showDropdown">
			<div class="overselect"></div>
			<div class="c-form-input">
				<h2 class="raleway-semi-p13">Style d’établissement</h2>
				<img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="show" />
				<img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
			</div>
		</div>
		<div class="multiselect" v-if="show && stylesMount">
			<ul>
				<div class="row row-selectoptions mt-3">
					<div class="col-8" v-for="(style, index) in stylesMount" :key="index">
						<li>
							<input type="checkbox" :id="style.name" :value="style.id" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" :for="style.name">{{ formatStyle(style.name) }}</label>
						</li>
					</div>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'multiselectlodgingstyle',
		props: ['options', 'stylesMount'],
		data() {
			return {
				show: false,
				selected: [],
			};
		},
		methods: {
			formatStyle(value) {
				return value.charAt(0).toUpperCase() + value.slice(1);
			},
			showDropdown() {
				this.show = !this.show;
			},
		},
		watch: {
			selected(val) {
				this.$emit('checked', val, 'styles');
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
