<template>
  <div id="preloader">
    <div id="loader">
      <img src="../images/Logo.svg" alt="" class="logo-preloader">
    </div>
  </div>
</template>
<script>
  export default{
    name: "preloader"
  }
</script>

<style lang="scss" scoped>
  #preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 1000;

    .trapezoids {
    position: relative;
    top: 23%;

    @media (min-width: 769px) {
      .trapezoid-blue {
        margin: 0 auto;
        width: 0;
        height: 70px;
        border-top: 18px solid transparent;
        // border-right: 345px solid #3B6152;
        border-left: 0px solid transparent;
        border-bottom: 0px solid transparent;

        h2 {
          position: relative;
          top: -7px;
          left: 21px;
          white-space: nowrap;
          font-size: 40px;
          font-weight: bold;
        }
      }
    }

    @media (max-width: 768px) {
      .trapezoid-blue {
        margin: 0 auto;
        width: 0;
        height: 60px;
        border-top: 18px solid transparent;
        // border-right: 291px solid #3B6152;
        border-left: 0px solid transparent;
        border-bottom: 0px solid transparent;

        h2 {
          position: relative;
          top: -8px;
          left: 17px;
          white-space: nowrap;
          font-size: 34px;
          font-weight: bold;
        }
      }
    }

    @media (min-width: 769px) {
      .trapezoid-orange {
        margin: 0 auto;
        position: relative;
        top: -20px;
        left: 70px;
        width: 0;
        height: 52px;
        border-top: 14px solid transparent;
        // border-right: 283px solid black;
        border-left: 0px solid transparent;
        border-bottom: 0px solid transparent;

        h1 {
          position: relative;
          top: -6px;
          left: 5px;
          white-space: nowrap;
          font-size: 40px;
          font-weight: bold;
        }
      }
    }

    @media (max-width: 768px) {
      .trapezoid-orange {
        margin: 0 auto;
        position: relative;
        top: -16px;
        left: 0px;
        width: 0;
        height: 48px;
        border-top: 14px solid transparent;
        // border-right: 243px solid black;
        border-left: 0px solid transparent;
        border-bottom: 0px solid transparent;

        h1 {
          position: relative;
          top: -4px;
          left: 5px;
          white-space: nowrap;
          font-size: 34px;
          font-weight: bold;
        }
      }
    }
  }
  }

  #loader {
    display: block;
    position: relative;
    left: 50%;
    top: 38%;
    width: 150px;
    height: 150px;
    margin: -75px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    // border-top-color: black;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;

    img.logo-preloader {
      width: 150px;
      position: relative;
      top: 22px;
      left: 26px;
      transform: rotate(0deg);
    }
  }

  #loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    // border-top-color: #3B6152;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;

    img.logo-preloader {
      -webkit-animation: spin 0s;
      animation: spin 0s;
    }
  }

  #loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    // border-top-color: black;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;

    img.logo-preloader {
      -webkit-animation: spin 0s;
      animation: spin 0s;
    }
  }

  @-webkit-keyframes spin {
      0%   {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }

  @keyframes spin {
      0%   {
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);
          transform: rotate(0deg);
      }
      100% {
          -webkit-transform: rotate(360deg);
          -ms-transform: rotate(360deg);
          transform: rotate(360deg);
      }
  }

  .loaded #loader-wrapper .loader-section.section-left {
    transform: translateX(-100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  }

  .loaded #loader-wrapper .loader-section.section-right {
    transform: translateX(100%);
    transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
  }

  .loaded #preloader {
    opacity: 0;
    transition: all 0.3s ease-out;
    z-index: 0;
  }

  .loaded #loader-wrapper {
    visibility: hidden;
    transform: translateY(-100%);
    transition: all 0.3s 1s ease-out;
  }

</style>
