<template>
	<div id="slider-budget">
		<vue-slider class="raleway-reg-p15" v-model="budget" v-bind="options" />
	</div>
</template>

<script>
	export default {
		name: 'slider-budget',
		data() {
			return {
				options: {
					min: 0,
					max: 2000,
					dotSize: 0,
					processStyle: {
						background: 'black',
					},
					tooltip: 'always',
					tooltipPlacement: 'top',
					// tooltipFormatter: (value) => `${value}€`,
					tooltipStyle: {
						background: 'transparent',
						border: 'transparent',
						color: 'black',
					},
				},
				budget: [0, 2000],
			};
		},
		watch: {
			budget(val) {
				this.$emit('changedBudget', val);
			},
		},
	};
</script>
<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
