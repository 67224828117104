<template>
  <div id="testimony-phone">
    <div class="row row-testimony">
      <div class="col-8 offset-2  text-center col-testimony">
        <p class="raleway-reg-p15 mb-3">«  ipsum dolor sit amet, consectetur adipiscing elit. Nullam justo est, condimentum ut placerat a, rhoncus ut ante. Donec placerat leo arcu, sit"</p>
          <div class="lodging-info-testimony mb-2">
            <p class="raleway-bold-p mb-2">Hôtel Les Aiguillettes</p>
            <div class="standing mb-2">
              <img src="../../images/Stars.svg" alt="Etoile">
            </div>
            <p class="description-hub raleway-reg-p13">Les Maldives, Asie du Sud</p>
          </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default{
    name: 'TestimonyPhone',
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/fid-phone'>

</style>
