<template>
    <div id="guest-info" class="guest-info-dropdown">
      <div class="container-guest">
        <div class="adult choice-number row">
          <div class="col-4">
            <p class="raleway-reg-p15 mb-0">Adultes</p>
          </div>
          <div class="col-3" @click="decreaseAdults()">
            <img src="../../images/Moins.svg" alt="moins" />
          </div>
          <div class="col-2">
            <p class="raleway-bold-p mb-0">{{ adults }}</p>
          </div>
          <div class="col-2" @click="increaseAdults()">
            <img src="../../images/Plus.svg" alt="moins" />
          </div>
        </div>
  
        <div class="child choice-number row">
          <div class="col-4">
            <p class="raleway-reg-p15 mb-0">Enfants</p>
          </div>
          <div class="col-3" @click="decreaseChilds()">
            <img src="../../images/Moins.svg" alt="moins" />
          </div>
          <div class="col-2">
            <p class="raleway-bold-p mb-0">{{ childs }}</p>
          </div>
          <div class="col-2" @click="increaseChilds()">
            <img src="../../images/Plus.svg" alt="moins" />
          </div>
        </div>
  
        <div class="dropdown-age" v-if="childs">
          <p class="raleway-reg-p15">
            Quel est l’âge {{ formatName(childs) }} voyageant avec vous ?
          </p>
          <template v-for="child in childs">
            <v-select
              class="select-item"
              placeholder="age"
              @input="selectAges"
              :options="agesChoices"
            ></v-select>
          </template>
        </div>
        <div class="closing-cross">
          <button class="" @click="close()">
            <img src="../../images/Croix fermer.svg" alt="fermer" />
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "MobileGuestInfo",
    data() {
      return {
        rooms: 1,
        childs: 0,
        adults: 2,
        ageSelected: [],
        agesChoices: [
          "1 an",
          "2 ans",
          "3 ans",
          "4 ans",
          "5 ans",
          "6 ans",
          "7 ans",
          "8 ans",
          "9 ans",
          "10 ans",
          "11 ans",
          "12 ans",
          "13 ans",
          "14 ans",
          "15 ans",
          "16 ans",
          "17 ans",
        ],
      };
    },
  
    methods: {
      close() {
        this.$emit("closed");
      },
      selectAges(value) {
        this.ageSelected.push(value);
        this.$emit("ageSelected", this.ageSelected);
      },
      formatName(childs) {
        childs.length > 1 ? "des enfants" : "de l'enfant";
      },
      decreaseRooms() {
        if (this.rooms > 0) {
          this.rooms -= 1;
          this.$emit("decreaseRooms", this.rooms);
        }
      },
      increaseRooms() {
        if (this.rooms < 9) {
          this.rooms += 1;
          this.$emit("increaseRooms", this.rooms);
        }
      },
      decreaseChilds() {
        if (this.childs > 0) {
          this.childs -= 1;
          this.$emit("decreaseChilds", this.childs);
        }
      },
      increaseChilds() {
        if (this.childs < 9) {
          this.childs += 1;
          this.$emit("increaseChilds", this.childs);
        }
      },
      decreaseAdults() {
        if (this.adults > 0) {
          this.adults -= 1;
          this.$emit("decreaseAdults", this.adults);
        }
      },
      increaseAdults() {
        if (this.adults < 9) {
          this.adults += 1;
          this.$emit("increaseAdults", this.adults);
        }
      },
    },
    mounted() {
    document.body.classList.add("scroll-lock");
  },

  destroyed() {
    document.body.classList.remove("scroll-lock");
  }
  };
  </script>
  
  <style scoped>
#guest-info {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    z-index: 1000;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 40px;
    overflow-x: hidden;
    box-sizing: border-box;
}

.closing-cross {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    text-align: right !important;
}

.closing-cross img {
    width: 24px;
    height: 24px;
}

.container-guest {
    display: flex;
    flex-direction: column;
    width: 90%; /* Set a width to keep content from stretching across the screen */
    max-width: 400px;
    box-sizing: border-box;
}

.choice-number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 15px 0;
    border-bottom: 1px solid #e0e0e0;
}

.choice-number p {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
}

.choice-number img {
    width: 24px;
    height: 24px;
    cursor: pointer;
}
.scroll-lock {
  overflow: hidden;
  touch-action: none;
}


  </style>
  