<template>
  <div id="w-phone">
    <div class="phone-container">
      <div class="exclusive-header">
        <h2 class="merriweather-reg-26">Nos</h2>
        <h2 class="merriweather-reg-40 mb-5">Offres exclusives</h2>
        <p class="raleway-reg-p15">{{ $t("Exclusive.content") }}</p>
      </div>
        <slither-slider :options="{loop: true, autoplay: true, numberOfSlides: 1,dots: false, controls: false}">
          <template v-for="exclusive in this.exclusivesMounted">
            <div id="cardExclusivephone">
              <div class="col-lg-6 col-xs-10 col-weekend card-exclusive">
                <img v-if="exclusive.photo_key" :src="exclusive.photo_key" :alt="exclusive.key_word">
                <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" :alt="exclusive.key_word">
              </div>
              <div class="col-lg-6 col-xs-10 col-weekend card-exclusive">
                <h3 class="category-exclusive merriweather-italic mt-4">{{ exclusive.title }}</h3>
                <p class="subtitle-card raleway-bold-p">{{ exclusive.subtitle }}</p>
                <a :href="'/offres-exclusives/' + exclusive.slug" class="link-hortense">Découvrir l'offre</a>
              </div>
            </div>
          </template>
        </slither-slider>
      <a class="hortense-btn-primary raleway-bold-p mt-2" href="/offres-exclusives">{{ $t("Exclusive.viewbtn") }}</a>
    </div>
  </div>
</template>

<script>

  import CardexclusivePhone from 'components/cards-phone/CardexclusivePhone.vue';
  import fetcher from 'packs/shared/api.js';

  export default{
    name: 'exclusive-phone',
    components: { CardexclusivePhone},
    props:['exclusives'],
    data() {
      return {
        exclusivesMounted: this.exclusives,
      }
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/w-phone'>

</style>
