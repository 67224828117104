<template>
  <div id="headDesti">
    <div class="desti-header text-center ">
      <h1 class="merriweather-reg-40">Nos destinations</h1>
      <!-- <p class="raleway-reg-p15">{{ $t("Destination.text") }}</p> -->
    </div>

    <div class="list-desti-btns mt-5">
      <ul class="bar">
        <li class="raleway-bold-p desti-btn" @click="selectDestination('France')">
          <button class="raleway-bold-p">France</button>
        </li>
        <li class="raleway-bold-p desti-btn" @click="selectDestination('Caraïbes')">
          <button class="raleway-bold-p">Caraibes</button>
        </li>
        <li class="raleway-bold-p desti-btn" @click="selectDestination('Afrique')">
         <button class="raleway-bold-p">Afrique</button>
        </li>
        <li class="raleway-bold-p desti-btn" @click="selectDestination('Polynésie Française')">
          <button class="raleway-bold-p">Polynésie Française</button>
        </li>
        <li class="raleway-bold-p desti-btn" @click="selectDestination('Océan Indien')">
          <button class="raleway-bold-p">Océan Indien</button>
        </li>
        <li class="raleway-bold-p desti-btn" @click="selectDestination('Europe')">
          <button class="raleway-bold-p">Europe</button>
        </li>
        <li class="raleway-bold-p desti-btn" @click="selectDestination('Asia')">
          <button class="raleway-bold-p">Asie</button>
        </li>
        <li class="raleway-bold-p desti-btn" @click="selectDestination('Americas')">
          <button class="raleway-bold-p">AMÉRIQUES</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>


  export default {
    name: 'headDesti',
    methods: {
      selectDestination(destination){
        this.$emit("destinationSelector", destination);
        $(".desti-btn").css("background-color", "white")
        event.currentTarget.style.backgroundColor = '#cddfd3'
      }
    },
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/destinations'>


</style>
