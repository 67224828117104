<template>
  <div v-if="lodgings" id="experience-show">
    <div class="container-index">
      <div class="desti-header text-center ">
        <h1 class="merriweather-reg-40  mt-4">Nos hébergements</h1>
        <h3 class="merriweather-italic24">Les plus beaux hébergements éco-responsables</h3>
        <p class="raleway-reg-p15 mt-2">Amoureuse de la nature et avant tout dénicheuse de voyages, Hortense vous propose une sélection des plus beaux hébergements éco-responsables. Laissez-vous emporter par le meilleur du tourisme haut de gamme : services exceptionnels, expériences uniques et locales… Préparez-vous à passer un séjour inoubliable !</p>
        <p class="raleway-reg-p15">{{ this.formatLodgings() }}</p>
      </div>
      <div class="btn-filters">
        <div class="green-note-filter mr-3 df-jsb-aic" @click="sortByNote">
          <p class="raleway-reg-p13 mb-0 mr-3">Green note</p>
          <img src="../../images/fleches dropdown.svg" alt="fleches">
        </div>
        <div v-if="this.lodgingsCounter !== 0" class="map-redirection" @click="displayMap">
          <p class="raleway-reg-p13 mb-0">Carte</p>
        </div>
      </div>
      <div class="filters-phone">
        <p class="raleway-reg-p15 mb-0" @click="showFilters"  v-if="showFilt">Masquer les filtres</p>
        <p class="raleway-reg-p15 mb-0" @click="showFilters" v-else>Appliquer les filtres</p>
      </div>
      <div class="row row-page">
        <div class="col-md-3 col-xs-0 col-filter">
          <Filterlodgings @checked='filterLodgings' @switch="filteringLodgings" @changeBudget='filterLodgingsByBudget'></Filterlodgings>
        </div>
        <div class="col-md-9 col-xs-10">
          <div class='lodgings'>
            <Maptest v-if="showMap" :markers="this.markers"  :lodgings="this.filteredLodgings" @markerSelected="selectCardMap" :selectCardMap="selectCardMap" :selectCard="selectCard" @close="showMap = false"></Maptest>
            <div v-if='!selectedMarker'>
              <CardProductbig v-for='(lodging, index) in this.filteredLodgings' :key='index' :lodging="lodging" :destination='lodging.destination' v-if='index === 0' :data-marker='index' :data-lodging='lodging.id'></CardProductbig>
              <CardProductbig :lodging="lodging" :destination='lodging.destination' v-else style='margin-top: 20px' :data-marker='index' :data-lodging='lodging.id'></CardProductbig>
            </div>
            <div v-else>
              <Cardmap :lodging='this.selectedLodging' :destination='this.selectedLodging.destination' @unselect='selectCard' :idMarker='selectedCard'></Cardmap>
            </div>
          </div>
          <div v-if=" lodgingsCounter === 0" class="no-lodging">
            <p class="raleway-reg-p15 text-center">Aucun hébergement disponible.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import fetcher from 'packs/shared/api';
  import CardProductbig from 'components/cards/CardProductbig.vue';
  import Filterlodgings from 'pages/destinations/Filterlodgings.vue';
  import Cardmap from 'components/cards/Cardmap.vue'
  import Maptest from 'components/Maptest.vue';

  export default {
    name: 'ExperienceShow',
    props: {
      experience: Object,
      lodgings: Array,
    },
    components: {CardProductbig, Filterlodgings, Cardmap, Maptest},
    data() {
      return {
        lodgingsCounter: 0,
        filteredLodgings: [],
        showMap: false,
        markers: [],
        showFilt: false,
        selectedMarker: false,
        selectedLodging: null,
        selectedCard: null,
        current: 1,
        asc: true,
      };
    },
    methods: {
      showFilters(){
        const filter = document.querySelector('#filter-lodging')
        if(filter.style.display === 'block') {
          this.showFilt = false
          filter.style.display = 'none';
        } else {
          filter.style.display ='block'
          this.showFilt = true
        }
      },
      onPageChange (page) {
        this.current = page
      },
      sortByNote(){
        this.filteredLodgings.sort((a, b) => {
          if ( this.asc ) {
            return a.score - b.score
          } else {
            return b.score - a.score
          }
        })
        this.asc = !this.asc
      },
      filteringLodgings(value = '', key = '') {
        if (value[0] === '' || value[0] === undefined) {
          this.filteredLodgings = this.lodgings
        } else {
          this.filteredLodgings = this.filteredLodgings.filter( lodging => {
            return lodging.lodging_profile.animal === true
          })
        }
        this.lodgingsCounter = this.filteredLodgings.length
      },
      selectCardMap(attr){
        this.selectCard(attr);
        if(this.showMap){
          setTimeout(() => {this.showMap = false}, 10);
          setTimeout(() => {this.showMap = true}, 10);
        }
      },
      filterLodgings(value = '', key = '') {
        if (value[0] === '' || value[0] === undefined) {
          this.filteredLodgings = this.lodgings
        } else {
          this.filteredLodgings = this.lodgings.filter(el => {
            return (value.includes(el[key]?.toString()) || ( Array.isArray(el[key]) && this.includesIn(value, el[key]?.map(e => e.name))))
          })
        }
        this.lodgingsCounter = this.filteredLodgings.length
      },
      formatLodgings() {
        let hebergement = ' hébergement';
        if (this.lodgingsCounter > 1) {
          hebergement += 's';
        }
        return  this.lodgingsCounter + hebergement;
      },
      selectCard(attr) {
        const cards = document.querySelectorAll('#cardProduct-big')
        const selectedCard = cards[attr[0]]
        this.selectedCard = attr[0]
        this.selectedMarker = !this.selectedMarker;
        cards.forEach(el => {
          if (el?.dataset?.marker !== selectedCard?.dataset?.marker) {
            el.style.display === 'none' ? el.style.display = 'flex' : el.style.display = 'none'
          }
        })
        this.selectedLodging = this.lodgings.filter(lod => {return lod.id === parseInt(selectedCard?.dataset?.lodging)})[0]
      },
      displayMap() {
        this.showMap = !this.showMap;
        this.markers = this.markerMap();
      },
      lodgingCount() {
        this.lodgingsCounter += this.lodgings.length;
      },
      includesIn(array1, array2) {
        for (const val of array1) {
          if (array2.includes(val)) {
            return true
          }
        }
        return false
      },
      markerMap() {
        const markers = [];
        this.filteredLodgings.map(el => {
          markers.push([el.longitude, el.latitude,el.id,  el.lowest_price])
        })
        return markers;
      },
      debug () {
        console.log(this.markers)
      }
    },
    mounted() {
      this.filteredLodgings = this.lodgings;
      this.lodgingCount();
    }
  }
</script>

<style scoped lang="scss">
  #experience-show {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;

    .filters-phone{
      display:none;
    }
  }
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    #experience-show{
      .filters-phone{
        display:block;
        position: fixed;
        bottom: 0;
        z-index: 5;
        color: white;
        background-color: rgba(0,0,0,0.5);
        padding: 4px 5px;
      }
      #filter-lodging{
        display: none;
      }
    }
  }
</style>
