<template>
  <div id="wishlist-user">
    <BannerProfile :user="this.user"></BannerProfile>
    <div class="container-profil">
      <Menuprofil :user="this.user"></Menuprofil>

      <!-- Lodgings Section -->
      <div v-if="type === 'lodging' && wishList.lodgings && wishList.lodgings.length" class="wishlist">
        <div class="col-lg-5 col-xs-8">
          <h3 class="merriweather-reg-26">Mes</h3>
          <h2 class="merriweather-reg-40 mb-5">Hébergements favoris</h2>
        </div>
        <div class="card-wish" v-for='lodging in wishList.lodgings' :key="lodging.id">
          <CardWishlist :lodging="lodging" @toggle="toggleLike(lodging.id)"></CardWishlist>
        </div>
      </div>

      <!-- Experiences Section -->
      <div v-if="type === 'experience' && wishList.experiences.length" class="wishlist">
        <div class="col-lg-5 col-xs-8 mt-5">
          <h3 class="merriweather-reg-26">Mes</h3>
          <h2 class="merriweather-reg-40 mb-5">Expériences favorites</h2>
        </div>
        <div class="card-wish" v-for='experience in wishList.experiences' :key="experience.id">
          <CardWishlistExp :experience="experience" @toggle="toggleLikeExp(experience.id)"></CardWishlistExp>
        </div>
      </div>

      <!-- No Items Available -->
      <div v-if="(type === 'lodging' && !wishList.lodgings.length) || (type === 'experience' && !wishList.experiences.length)" class="empty-resa">
        <div class="no-wish">
          <p class="raleway-reg-p15 mb-5 text-center">
            Vous n’avez aucune {{ type === 'lodging' ? 'hébergement' : 'expérience' }} dans vos favoris pour le moment.
          </p>
          <a :href="type === 'lodging' ? '/reservation-hotel' : '/reservation-experience'" target="_blank" class="hortense-btn-primary">
            Trouver une {{ type === 'lodging' ? 'hébergement' : 'expérience' }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardWishlist from 'pages/dashboard/CardWishlist.vue';
import CardWishlistExp from 'pages/dashboard/CardWishlistExp.vue';
import BannerProfile from 'components/shared/BannerProfile.vue';
import Menuprofil from 'components/shared/Menuprofil.vue';
import fetcher from 'packs/shared/api.js';

export default {
  name: 'wishlist-user',
  components: { BannerProfile, Menuprofil, CardWishlist, CardWishlistExp },
  props: ["user"],
  data() {
    return {
      wishList: {
        lodgings: [],
        experiences: [],
      },
      type: this.getQueryParam('type') || 'lodging',
    };
  },
  mounted() {
    this.fetchWishlist();
  },
  methods: {
    getQueryParam(param) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param);
    },
    async toggleLike(id) {
      try {
        const response = await fetcher.patch('/wishlists/' + this.user.id, { lodging_id: id });
        this.wishList = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async toggleLikeExp(id) {
      try {
        const response = await fetcher.patch('/wishlists/' + this.user.id, { experience_id: id });
        this.wishList = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchWishlist() {
      try {
        if (this.user) {
          const response = await fetcher.get('/wishlists.json');
          console.log('response', response.data)
          this.wishList = response.data;
        }
      } catch (e) {
        console.error(e);
      }
    }
  }
};
</script>
<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>
.wishlist {
  margin-bottom: 30px;
}
.empty-resa .no-wish {
  text-align: center;
  margin-top: 20px;
}
</style>
