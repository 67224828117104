<template>
  <div id="comments-dashboard">
    <BannerProfile :user="this.user"></BannerProfile>
    <div class="container-profil">
      <Menuprofil :user="this.user"></Menuprofil>
      <div class="row row-info-user">
        <div class="col-lg-4 col-xs-7 col-header">
          <h2 class="merriweather-reg-26">Mes</h2>
          <h1 class="merriweather-reg-40">Commentaires</h1>
        </div>

        <div class="col-lg-8 col-xs-12 col-cards">
          <div v-if="noComment.length > 0">
            <Cardaddcomment v-for="resa in noComment.slice().reverse()" :user='user' :resa="resa"></Cardaddcomment>
          </div>
          <div v-if="commented.length > 0">
            <Cardcomment v-for="resa in commented.slice().reverse()" :user='user' :resa="resa"></Cardcomment>
          </div>
        </div>
        <div class="no-comment" v-if="noComment || commented">
          <p class="raleway-reg"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';
  import Flash from 'alert/Flash.vue';
  import BannerProfile from 'components/shared/BannerProfile.vue';
  import Menuprofil from 'components/shared/Menuprofil.vue';
  import Cardcomment from 'pages/dashboard/Cardcomment.vue';
  import Cardaddcomment from 'pages/dashboard/Cardaddcomment.vue';

  export default{
    name: 'comments-dashboard',
    components: { BannerProfile, Menuprofil, Flash, Cardcomment, Cardaddcomment },
    props: [ "user" ],
    data() {
      return {
        status: null,
        text: null,
        lodging: null,
        reservationsList: [],
        pasts: null,
        noComment: [],
        commented:[],
      }
    },
    methods: {
      async fetchPastResa() {
        try {
          const response = await fetcher.get("/reservations.json")
          this.reservationsList = response.data
          await this.dateNow();
          await this.pastReservations();
          await this.resaCommented();
          await this.resaNotCommented();
        } catch(e) {
          console.error(e)
        }
      },
      resaNotCommented(){
        this.noComment = this.pasts.filter(past => past.ratings.length === 0)
          return this.noComment
      },
      resaCommented(){
        this.commented = this.pasts.filter(past => past.ratings.length > 0)
          return this.commented
      },
      dateNow(splinter){
        const set = new Date();
        let getDate = set.getDate().toString();
        if (getDate.length == 1){ //example if 1 change to 01
         getDate = "0"+getDate;
        }
        let getMonth = (set.getMonth()+1).toString();
        if (getMonth.length == 1){
         getMonth = "0"+getMonth;
        }
        let getYear = set.getFullYear().toString();
        const dateNow = getYear +splinter+ getMonth +splinter+ getDate ; //today
        return dateNow;
      },
      pastReservations() {
        /*this.pasts = this.reservationsList.filter((reservation) => {
          return reservation.checkin === this.dateNow('-') && reservation.duration < 0 || reservation.check_out < this.dateNow('-')
        })*/
        this.pasts = this.reservationsList;
        console.log('this.reservationsList');
        console.log(this.reservationsList);
        console.log('this.pasts');
        console.log(this.pasts);
        return this.pasts
      },
    },
    mounted() {
      this.fetchPastResa();
    },
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/my-resa'>
</style>