<template>
  <div id="cardColl">
    <div class="card-coll">
      <img v-if="collection.photo_key" 
        :src="collection.photo_key" 
        class="img-collection" 
        :alt="collection.name"
      >
      <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" class="img-collection" :alt="collection.name">
      <div class="card-content">
        <h3 class="mt-4 mb-4">{{ collection.name }}</h3>
        <h2 class="mb-2">{{ collection.title }}</h2>
        <p class="mb-2">{{ collection.description_collection_page }}</p>
        <a :href="'/collections/' + collection.slug" class="mb-3">Découvrir la collection</a>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'cardCollection',
    props: ['collection'],
    data() {
      return{
        imgHeight: '',
        imgWidth: '',
        naturalWidth: 0,
        naturalHeight: 0,
      }
    },
    computed: {
      isPhone() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
          return true;
        }else{
          return false;
        }
      },
      isPortrait() {
        return this.naturalWidth < this.naturalHeight;
      },
      isLandscape() {
        return this.naturalWidth > this.naturalHeight;
      },
    },
    methods:{
      checkImageOrientation() {
        if(!this.isPhone && this.$refs.image){
          this.naturalWidth = this.$refs.image.naturalWidth;
          this.naturalHeight = this.$refs.image.naturalHeight;
        }
      },
      defineHeight(){
        if ((this.collection.id+1)%3 === 0){
          return this.imgHeight = '600px' 
        }else{
          return this.imgHeight = '286px' 
        }
      },
      defineWidth(){
        if ((this.collection.id+1)%3 === 0){
          return this.imgWidth = '408px'
        }else{
          return this.imgWidth = '568px'
        }
      },
      changeHeight() {
        const img = document.getElementsByClassName('img-collection');
          img[0].style.width = this.imgWidth
          img[0].style.height = this.imgHeight
      },
      changeSize(){
        setTimeout(function() {
          const images = document.getElementsByClassName('img-collection');
          for (let i = 0; i < images.length; i++) {
            console.log('WIDTH');
            console.log(images[i].width);
            console.log('HEIGHT');
            console.log(images[i].height);
            if(images[i]){
              if(images[i].width < images[i].height){
                  images[i].style.width = '85%';
                  images[i].style.height = '650px';
              }
            }
          }
        }, 4000);
      }
    },
    mounted(){
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ){
        console.log('hey')
      }else{
        //this.defineHeight();
        //this.defineWidth();
        //this.changeHeight();
        this.changeSize();
      }
    }
  }
</script>

<style lang="scss" scoped>
  #cardColl{
    margin-bottom: 2rem;
    width:100%;
    .img-collection {
      // height:600px;
      width: 100%;
      object-fit: cover;
    }
    .img-collection-portrait {
      height: 700px;
      width: 90%;
      object-fit: cover;
    }
    .card-content{
      padding-top: 1.5rem;
      padding-bottom: 2rem;
      h3{
        font-family: $Merriweather;
        font-style: italic;
        font-size: 20px;
        color: #000000;
      }
      h2{
        font-family: $Raleway;
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        text-transform: uppercase;
      }
      p{
        font-family: $Raleway;
        font-weight: 400;
        font-size: 15px;
        color: #000000;
      }
      a{
        text-decoration: underline;
        font-family: $Raleway;
        font-weight: 500;
        font-size: 15px;
        color: #000000;
      }
    }
  }
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    #cardColl{
      margin-bottom: 2rem;
      width:100%;
      .img-collection {
        height:250px;
        width: 100%;
      }
    }
  }
</style>
