<template>
  <div id="modal-login-partner" v-else>
    <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-content">
              <div class="closing">
                <button class="close-btn-modal" @click="$emit('close')"></button>
              </div>
              <div class="modal-content-form">
                <div class="header-modal">
                  <h2 class="merriweather-reg-26">Vous faites partie de nos hôtes ?</h2>
                  <p class="raleway-reg-p15">Connectez-vous pour accéder à votre espace partenaire : </p>
                </div>
                <div class="form-signup raleway-reg-p15">
                  <p style="color: red;" v-if='errorMessage'>invalid email or password</p>
                  <div class="form-item">
                    <label for="name">Email</label>
                    <input v-model="userInfo.email" type="text">
                  </div>
                  <div class="form-item">
                    <label for="name">Mot de passe</label>
                    <input v-model="userInfo.password" type="password">
                  </div>
                  <button @click="UserBasicInfo" class="hortense-btn-primary">Me connecter</button>
                </div>
                <div class="become d-flex">
                  <p class="raleway-reg-p15 mr-3">Vous souhaitez devenir hôte partenaire ?</p>
                  <a @click="showModalBecome = true" class="link-hortense">Contactez-nous.</a>
                </div>
                <div class="knew-more">
                  <p class="raleway-reg-p15">En savoir + sur Hortense :</p>
                  <ul>
                    <li>Découvrez la <a href="/green-note-engagement-environnemental/" class="link-hortense">Green Note</a>, un outil développé par Hortense qui permet de mesurer le niveau d’engagement éco-responsable des hébergements partenaires.</li>
                    <li>Découvrez <a href="/programme-evergreen-demarche-environnementale/" class="link-hortense">le Programme Evergreen</a> : Hortense accompagne ses partenaires dans leur démarche environnementale via une aide opérationnelle et financière. </li>
                    <li>Découvrez <a href="/label-ecoresponsable-green-host/" class="link-hortense">le label Green Host</a> qui met en avant les hébergements de charme qui répondent aux critères de respect de l’environnement Hortense.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
  </div>
</template>

<script>
    import fetcher from 'packs/shared/api.js';

  export default{
    name: 'modal-login-partner',
    components:{ },
    data() {
      return {
        userInfo: {
          email: '',
          password: '',
          role: 'partner'
        },
        errorMessage: false,
        showModalBecome: false,
      }
    },
    methods: {
      async UserBasicInfo(){
        try {
          const response = await fetcher.post('/users/sign_in', { user: this.userInfo })
          this.$emit('close', response.data);
          this.errorMessage = false
          location.reload();
        } catch (e) {
          this.errorMessage = true
        }
      },
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/modal-signup'>

</style>
