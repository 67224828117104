<template>
  <div id="lodging-show-phone">
    <div
      id="experiences-lodging"
      class="experiences-lodging"
      data-aos="fade-up"
      data-aos-duration="900"
    >
      <div class="container-lodging phone">

        <!-- <slither-slider :options="{ numberOfSlides: 2, dots: false, controls: false }"> -->
        <div class="lodging-category-container">
          <h1 class="lodging-category active" @click="selectOption">
            <p class="raleway-reg-p16" data-value="culturelles">culturelles</p>
          </h1>
          <h1 class="lodging-category" @click="selectOption">
            <p class="raleway-reg-p16" data-value="au naturel">au naturel</p>
          </h1>
          <h1 class="lodging-category" @click="selectOption">
            <p class="raleway-reg-p16" data-value="au bord de l'eau">
              au bord de l'eau
            </p>
          </h1>
          <h1 class="lodging-category" @click="selectOption">
            <p class="raleway-reg-p16" data-value="sportives">sportives</p>
          </h1>
          <h1 class="lodging-category" @click="selectOption">
            <p class="raleway-reg-p16" data-value="immersives">immersives</p>
          </h1>
          <h1 class="lodging-category" @click="selectOption">
            <p class="raleway-reg-p16" data-value="culinaires">culinaires</p>
          </h1>
          <h1 class="lodging-category" @click="selectOption">
            <p class="raleway-reg-p16" data-value="diy">DIY</p>
          </h1>
          <h1 class="lodging-category" @click="selectOption">
            <p class="raleway-reg-p16" data-value="oenologiques">oenologiques</p>
          </h1>
        </div>
        <!-- </slither-slider> -->

        <template v-if="this.experiencesFiltered.length">
          <!-- <slither-slider :options="{ numberOfSlides: 1, dots: false, controls: false }"> -->
          <div class="card-lodging-container">
            <div v-for="(experience, index) in this.experiencesFiltered" :key="index">
              <Cardexplodging :experience="experience" :user="isUserConnected()" @connection="$emit('connection')"></Cardexplodging>
            </div>
          </div>
          <!-- </slither-slider> -->
        </template>
        <template v-else>
          <!-- <slither-slider :options="{ numberOfSlides: 1, dots: false, controls: false }">
						<div> -->
          <p class="raleway-reg-p15">
            Hortense est à la recherche d'expériences à vous proposer près de
            cet hébergement
          </p>
          <!-- </div>
					</slither-slider> -->
        </template>

        <a class="link-hortense" :href="'/experiences'"
          >Découvrir toutes les expériences</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import Cardexplodging from "components/cards/Cardexplodging.vue";
	import Maplodgingshow from '../pages/lodgings/Maplodgingshow.vue';

export default {
  name: "experience-lodging-show",
  components: { Cardexplodging, Maplodgingshow },
  props: {
    experiences: Array,
    user: Object,
  },
  data() {
    return {
      SelectedOption: null,
      experiencesList: this.experiences,
      experiencesFiltered: this.experiences,
    };
  },
  methods: {
    isUserConnected() {
      return this.user ? this.user : {};
    },
    selectOption(e) {
      const activeEl = document.querySelector(".active");
      activeEl.classList.remove("active");
      e.target.parentNode.classList.add("active");
      e.preventDefault();
      this.SelectedOption = e.target.dataset.value;
      if (this.SelectedOption) {
        this.experiencesFiltered = this.experiencesList.filter(
          (el) => el.category_name === this.SelectedOption
        );
      } else {
        this.experiencesFiltered = this.experiencesList.filter(
          (el) => el.category_name === "culturelles"
        );
      }
    },
  },
};
</script>
<style lang="scss">
#lodging-show-phone {
  margin-top: 1rem;
  width: 100%;

  .container-lodging {
    //width: 82%;
    //margin-top: 9vh;
  }
  h1 {
    text-decoration: none;
    color: black;
    p {
      opacity: 0.4;
      text-transform: uppercase;
      width: max-content;
    }
    &:hover {
      p {
        opacity: 1;
      }
    }
    &.active {
      margin-bottom: 2rem;
      border-bottom: 3px solid #3b6152;
      width: fit-content;

      p {
        opacity: 1;
        font-family: $Raleway;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 0.75rem;
      }
    }
  }
  #experiences-lodging {
    padding-left: 15px;

    .info-exp {
      padding-left: 0rem;

				& > div {
					padding: .75rem 0;
				}
			}
		}
	}
	.show-cart-btn {
    text-decoration: underline;
    padding: 10px 0px;
    font-size: 80%;
	}
</style>
