<template>
  <div id="otp-verification">
    <div class="container-otp">
      <div class="otp-header">
        <h2 class="merriweather-reg-26">Verify Your OTP</h2>
      </div>
      <div class="modal-content-form">
        <div class="form-item">
          <label for="otp">Enter the OTP sent to your email*</label>
          <input v-model="otp" type="text" maxlength="6" placeholder="6-digit OTP">
        </div>
      </div>
      <p class="otp-alert">{{ text }}</p>

      <button @click="verifyOtp" class="hortense-btn-primary">Verify OTP</button>
    </div>
  </div>
</template>

<script>
import fetcher from '../../packs/shared/api.js'; // Adjust the path as necessary

export default {
  name: "otp-verification",
  data() {
    return {
      otp: '',
      text: ''
    }
  },
  methods: {
    async verifyOtp() {
      try {
        if (this.otp.length !== 6) {
          this.text = 'OTP must be 6 digits long';
          return;
        }

        const response = await fetcher.post(`/verify-otp`, {
          'otp_attempt': this.otp
        });

        if (response.status === 404) {
          this.text = "The OTP has expired or is invalid";
        } else if (response.status === 200) {
          window.location.href = "/admin";
        }
        console.log(response);
      } catch (e) {
        console.log(e);
        this.text = 'An error occurred. Please try again.';
      }
    }
  }
}
</script>

<style scoped>
#otp-verification {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.container-otp {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20vh;
}
.otp-header {
  text-align: center;
}
.modal-content-form {
  padding: 2rem 7rem;
  margin-right: auto;
  margin-left: auto;
}
.form-item {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
}
.hortense-btn-primary {
  margin: 2rem 7rem;
}
.otp-alert {
  text-align: center;
  color: red;
}

.form-item input {
  width: 350px;
  height: 60px;
  padding: 2px 23px;
}
</style>
