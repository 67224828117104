<template>
  <div id="c-phone">
    <div class="phone-container">
      <div class="collection-header">
        <h2 class="merriweather-reg-26"> Nos </h2>
        <h2 class="merriweather-reg-40 mb-5">Collections</h2>
        <p class="raleway-reg-p16">{{ $t("Collection.content") }}</p>
      </div>
      <slither-slider :options="{loop: true, autoplay: true, numberOfSlides: 1,dots: false, controls: false}">
        <template v-for="collection in this.collectionsMounted">
          <CardcoPhone :collection="collection"></CardcoPhone>
        </template>
      </slither-slider>
      <a class="hortense-btn-primary raleway-bold-p mt-5" :href="'/collections'">voir toutes les collections</a>
    </div>
  </div>
</template>


<script>

  import CardcoPhone from 'components/cards-phone/CardcoPhone.vue';


  export default {
    name: 'CPhone',
    components:{ CardcoPhone },
    props:['collections'],
    data() {
      return {
        collectionsMounted: this.collections
      }
    },
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/c-phone'>

</style>
