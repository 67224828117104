<template>
  <div id="cardWeekend">
    <div class="card-weekend">
      <img v-if="weekend.photo_key" :src="weekend.photo_key" :alt="weekend.key_word">
      <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" :alt="weekend.key_word">
      <h3 class="category-weekend merriweather-italic mt-4">{{ weekend.key_word }}</h3>
      <p class="subtitle-card raleway-bold-p">{{ weekend.title }}</p>
      <p class="description-card raleway-reg-p13">{{ weekend.subtitle }}</p>
      <p class="description-card raleway-reg-p13">{{ weekend.description_hp }}</p>
      <a :href="'/weekends/week-end-' + weekend.slug" class="link-hortense">Découvrir la sélection</a>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'cardWeekend',
    props: ['weekend'],
    data() {
      return{
        myWeekend: this.weekend
      }
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/weekend'>

</style>
