<template>
  <div id="modal-share">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">
            <div class="closing">
              <button class="close-btn-modal" @click="$emit('close')"></button>
            </div>
            <div class="modal-content-form">
              <share-it :targets="this.targetShare" :height="600" :width="600" dense :url="this.origin+'/'+ this.for+'s'+'/'+this.for.slug" />
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  export default{
    name: 'modal-share',
    props: {
      for: Object,
    },
    data() {
      return {
        currentUrl: "",
        origin: window.location.origin,
        targetShare: [],
      }
    },
    methods: {
      shareDevices() {
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ) {
          return this.targetShare = ['twitter', 'facebook', 'linkedin', 'whatsapp']
        } else {
          return this.targetShare = ['twitter', 'facebook', 'linkedin']
        }
      },
    },
    mounted(){
      this.shareDevices();
    },
    created() {
      this.currentUrl = window.location.href;
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/modal-share'>

</style>
