<template>
  <div id="modal-destroy">
    <div class="destroy-modal">
      <div class="close-btn-modal">
         <h1 class="raleway-bold-h3" @click="$emit('close')">X</h1>
      </div>
      <p class="raleway-reg-p15">Êtes-vous sûr de vouloir supprimer votre compte ?</p>
      <a href="/users/" data-method="delete" class="hortense-btn-primary">Oui supprimer mon compte</a>
      <button class="hortense-btn-snd" @click="$emit('close')">Non c’est une erreur</button>
    </div>
  </div>
</template>

<script>

    import fetcher from 'packs/shared/api.js';

  export default{
    name: 'modal-destroy',
    props: ["user"],
    data(){
      return {

      }
    },

    methods: {
      async destroyAccount(){
        try{
          const response = await fetcher.delete('users/'+ this.user.id)
          console.log(response)
        } catch(e) {
          console.errors(e)
        }
      },
    }
  }
</script>
