<template>
  <div id="card-phone-home">
    <div class="collection-card pr-4">
      <img v-if="collection.photo_key" :src="collection.photo_key" class="gallery__card" :alt="collection.name">
                <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" class="gallery__card" :alt="collection.name">
      <div class="card-content">
        <h3 class="mb-2 mt-2">{{ collection.name }}</h3>
        <h2 class="mb-2">{{ collection.title }}</h2>
        <p class="mb-1">{{ collection.subtitle }}</p>
        <a :href="'/collections/' + collection.slug" class="mb-3">Découvrir la collection</a>
      </div>
    </div>
  </div>
</template>
<script>
  export default{
    name: 'CardPhoneHome',
    props: ['collection'],
  }
</script>
