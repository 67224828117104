<template>
  <div id="reservation-user-info" v-if="userInfo">
    <Modalsignup v-if="showModal" :showModalLogin="showModalLogin" @close="setUser"></Modalsignup>
    <!-- <div class="banner-resa"> -->
    <!-- todo
    </div> -->
    <div class="container-profil">
      <div v-if="loyaltyUser" class="row row-how-p mb-3" style="border-top: solid 1px black;border-bottom: solid 1px black">
        <div class="col-lg-5 mt-4" :class="{ 'col-5': !isPhone }">
          <div>
            <h1 class="raleway-reg-p24-u">Le programme de fidélité Hortensia</h1>
            <p class="raleway-reg-p15">Grâce à cette réservation, vous gagnez {{ pointsDiff }} points. Plus que {{ userRemainingPoints }} points pour arriver au statut {{ userRemainingStatus }}. </p>
          </div>
        </div>
        <div :class="{ 'col-1 col-lg-1 mt-4 p-1': !isPhone,'col-3 col-lg-1 mt-4': isPhone }">
          <div class="fid-item-resa">
            <h1 v-if="loyaltyUser" :class="{ 'raleway-reg-p24': !isPhone,'raleway-reg-p15': isPhone }">{{loyaltyUser.loyalty_points}}</h1>
            <p class="raleway-reg-p10">Points acquis</p>
          </div>
        </div>
        <div class="mt-5 equal">
          <p class="raleway-bold-p33">+</p>
        </div>
        <div :class="{ 'col-1 col-lg-1 mt-4 p-1': !isPhone,'col-3 col-lg-1 mt-4': isPhone }">
          <div class="fid-item-resa">
            <h1 :class="{ 'raleway-reg-p24': !isPhone,'raleway-reg-p15': isPhone }">{{pointsDiff}}</h1>
            <p class="raleway-reg-p10">Nouveaux points</p>
          </div>
        </div>
        <div class="mt-5 equal">
          <p class="raleway-bold-p33">=</p>
        </div>
        <div :class="{ 'col-1 col-lg-1 mt-4 p-1': !isPhone,'col-3 col-lg-1 mt-4': isPhone }">
          <div class="fid-item-resa">
            <h1 :class="{ 'raleway-reg-p24': !isPhone,'raleway-reg-p15': isPhone }">{{loyaltyUser.loyalty_points + pointsDiff}}</h1>
            <p class="raleway-reg-p10">Points</p>
          </div>
        </div>
        <!--<div class="mt-5 equal">
          <p class="raleway-bold-p33">=</p>
        </div>
        <div class="col-1 col-lg-1 mt-4 p-1">
          <div class="fid-item-resa">
            <h1 class="raleway-reg-p24">{{ numberLoyaltyNights }}</h1>
            <p class="raleway-reg-p10">Nuits offertes Disponibles</p>
          </div>
        </div>-->
      </div>
      <div class="row row-one-info">
        <div class="col-lg-6 col-info-form">
          <div class="header" v-if="informationsCompleted && !this.user">
            <p class="raleway-reg-p24 mb-4">1. Informations</p>
            <div class="">
              <p class="raleway-reg-p15 mb-0">
                {{ userInfo.last_name }}, {{ userInfo.first_name }}
              </p>
              <p class="raleway-reg-p15 mb-0">{{ userInfo.country }}, {{ userInfo.city }}</p>
              <p class="raleway-reg-p15 mb-0">{{ userInfo.email }}</p>
              <p class="raleway-reg-p15">{{ userInfo.phone }}</p>
            </div>
            <a class="link-hortense" @click="close()" v-if="!this.user"
            >Modifier</a
            >
          </div>
          <div class="header" v-if="this.user">
            <p class="raleway-reg-p24 mb-4">1. Informations</p>
            <div class="">
              <p class="raleway-reg-p15 mb-0">
                {{ userInfo.last_name }}, {{ userInfo.first_name }}
              </p>
              <p class="raleway-reg-p15 mb-0">{{ userInfo.country }}, {{ userInfo.city }}</p>
              <p class="raleway-reg-p15 mb-0">{{ userInfo.email }}</p>
              <p class="raleway-reg-p15">{{ userInfo.phone }}</p>
            </div>
            <div class="write-to-lodging" v-if="informationsCompleted && this.user">
              <p class="raleway-semi-p13">Envoyer un message à l’établissement</p>
              <textarea
                  v-model="messageBody"
                  cols="45"
                  rows="6"
                  placeholder="Écrire à l'établissement"
              ></textarea>
              <button class="hortense-btn-primary" @click="changeStep(1)">
                Valider ces informations
              </button>
            </div>
          </div>
          <div class="form-user-info" v-if="!informationsCompleted">
            <div v-if="!this.user">
              <p class="raleway-reg-p24 mb-4">1. Informations</p>
              <p class="raleway-reg-p15">
                Vous avez déjà réservé avec nous auparavant? Veuillez Vous
                <span class="link-hortense" @click="connectModal">connecter</span>.
              </p>
              <p class="raleway-reg-p15">
                Si cela n’est pas le cas, vous pouvez
                <span class="link-hortense" @click="signupModal">créer un compte ici</span>.
              </p>
              <p class="raleway-reg-p15">
                La création d’un compte n’est pas obligatoire pour finaliser votre réservation
              </p>
            </div>
            <div class="line-form">
              <label for="name"
              >Nom *
                <input v-model="userInfo.last_name" type="text" />
              </label>
              <label for="name"
              >Prénom *
                <input v-model="userInfo.first_name" type="text" />
              </label>
            </div>
            <div class="line-form">
              <label for="name"
              >Ville
                <input v-model="userInfo.city" type="text" />
              </label>
              <label for="name"
              >Pays
                <input v-model="userInfo.country" type="text" />
              </label>
            </div>
            <div class="line-form">
              <label for="name"
              >Email *
                <input v-model="userInfo.email" type="text" />
              </label>
              <label for="name"
              >Téléphone
                <input v-model="userInfo.phone" type="text" />
              </label>
            </div>
<!--            <div class="reservation-other">-->
<!--              <p class="raleway-semi-p13 mb-0">Je réserve pour quelqu’un d’autre</p>-->
<!--              <input-->
<!--                  type="checkbox"-->
<!--                  id="other_guest"-->
<!--                  value="other_guest"-->
<!--                  v-model="selectedOther"-->
<!--              /><label for="other_guest"></label>-->
<!--            </div>-->
            <p>* informations obligatoires</p>
            <p class="raleway-reg-p15">La création d’un compte n’est pas obligatoire pour finaliser votre réservation.
              <strong>Veuillez cliquer sur "Valider ces informations" avant de valider la réservation.</strong>
            </p>
            <p class="raleway-reg-p15">
              <strong>Si vous disposez déjà d'un compte client, veillez à être connecté à votre espace client avant de valider la réservation.</strong>
            </p>
            <button class="hortense-btn-primary" @click="changeStep(1)">
              Valider ces informations
            </button>
          </div>
          <div class="step-from-1" id="step-1">
            <div class="mb-4">
              <p class="raleway-reg-p24 mb-0">2. Soutenir le tourisme durable</p>
            </div>
            <div class="tourism-section">
              <div class="card-evergreen">
                <div class="head">
                  <img
                      src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/planete2.png"
                      alt="planete"
                  />
                  <div class="content">
                    <p class="raleway-bold-p mb-0">Programme evergreen</p>
                    <p class="raleway-reg-p13 mb-0">
                      Hortense soutient les projets de tourisme durable en reversant 1% du montant
                      de votre réservation aux hôteliers partenaires. Vous ne payez rien en plus,
                      Hortense reverse simplement un pourcentage de sa commission !
                    </p>
                  </div>
                  <div>
                    <button class="modal-arrow" @click="showEvergreens = !showEvergreens">
                      <img class="arrow" src="../../images/flechebas-simple.svg" alt="fermer" />
                    </button>
                  </div>
                </div>
                <div class="cards" v-if="this.evergreens && this.showEvergreens === true">
                  <slither-slider
                      class="slither-slider"
                      :options="{
                      numberOfSlides: 2,
                      autoplay: false,
                      controls: true,
                      extras: 2,
                      loop: true,
                      dots: false,
                      touch: true,
                    }"
                  >
                    <template v-for="evergreen in this.evergreens">
                      <div id="cardEvergreen">
                        <div class="card-ever">
                          <img :src="evergreen.photo_key" alt="" />
                          <div class="sticker-program d-flex mt-4 pl-2">
                            <div class="sticker-program-info d-flex">
                              <p class="raleway-bold-h3 mb-0 pl-1 pr-2">
                                {{ evergreen.title }}
                              </p>
                              <p class="raleway-reg-p13 mb-0 pl-1 pr-2">
                                {{ evergreen.goal }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template slot="previous">
                      <div class="previous">
                        <img src="../../images/fleche gauche.svg" alt="fleche gauche" />
                        <p class="raleway-reg-p13 m-0">PLUS</p>
                      </div>
                    </template>
                    <template slot="next">
                      <img src="../../images/fleche droite.svg" alt="fleche droite" />
                    </template>
                  </slither-slider>
                </div>
              </div>
              <div class="card-carbon-offset">
                <div class="head">
                  <p class="raleway-bold-p mb-0">Emission de CO² pour votre voyage</p>
                  <p class="raleway-reg-p15">
                    Avec le programme de fidélité Hortensia, soutenir le développement durable vous
                    rapporte des avantages.
                    <strong>1 nuit réservée avec Hortense = 50 points cumulés.</strong>
                    Dès que vous aurez cumulé 2500 points, Hortense vous offre 1 nuit tous les 500
                    points !
                  </p>
                </div>
                <div class="row row-transport">
                  <div class="col-6 col-md-3">
                    <div class="card-transport green">
                      <p class="raleway-reg-p13">Nuits d’hôtel</p>
                      <img src="../../images/hotel.svg" alt="hotel" />
                      <p class="raleway-semi-p13 align-center">
                        {{ compensationByNightsAndTravelers.compensation_carbon_offset.toFixed(2) }}
                        Kg de CO²<br />
                        {{ compensationByNightsAndTravelers.compensation_amount.toFixed(2) }}€
                      </p>
                    </div>
                  </div>
                  <div class="col-6 col-md-3" v-if="this.distanceBetween !== undefined">
                    <div
                        class="card-transport"
                        :class="{
                        green: selectedTransportEngin === 'train',
                        grey: selectedTransportEngin != 'train',
                      }"
                        @click="chooseTransportEngine('compensationByTrain', 'train')"
                    >
                      <p class="raleway-reg-p13">Train</p>
                      <img src="../../images/train.svg" alt="train" />
                      <p class="raleway-reg-p13 align-center">
                        {{ compensationByTrain.compensation_carbon_offset.toFixed(2) }}
                        Kg de CO²<br />
                        {{ compensationByTrain.compensation_amount.toFixed(2) }}€
                      </p>
                    </div>
                  </div>
                  <div class="col-6 col-md-3" v-if="this.distanceBetween !== undefined">
                    <div
                        class="card-transport"
                        :class="{
                        green: selectedTransportEngin === 'plane',
                        grey: selectedTransportEngin != 'plane',
                      }"
                        @click="chooseTransportEngine('compensationByPlane', 'plane')"
                    >
                      <p class="raleway-reg-p13">Avion</p>
                      <img src="../../images/avion.svg" alt="avion" />
                      <p class="raleway-semi-p13 align-center">
                        {{ compensationByPlane.compensation_carbon_offset.toFixed(2) }}
                        Kg de CO²<br />
                        {{ compensationByPlane.compensation_amount.toFixed(2) }}€
                      </p>
                    </div>
                  </div>
                  <div class="col-6 col-md-3" v-if="this.distanceBetween !== undefined">
                    <div
                        class="card-transport"
                        :class="{
                        green: selectedTransportEngin === 'car',
                        grey: selectedTransportEngin != 'car',
                      }"
                        @click="chooseTransportEngine('compensationByCar', 'car')"
                    >
                      <p class="raleway-reg-p13">Voiture</p>
                      <img src="../../images/voiture.svg" alt="voiture" />
                      <p class="raleway-reg-p13 align-center">
                        {{ compensationByCar.compensation_carbon_offset.toFixed(2) }}
                        Kg de CO²<br />
                        {{ compensationByCar.compensation_amount.toFixed(2) }}€
                      </p>
                    </div>
                  </div>
                </div>
                <div class="carbon-offset-total">
                  <p class="raleway-bold">=</p>
                  <p class="raleway-bold">
                    {{ totalCompensationCarbonOffset.toFixed(2) }} Kg de CO²
                  </p>
                  <div>
                    <p class="raleway-bold mb-0">Total d’émission de carbone</p>
                    <p class="raleway-reg-p13">
                      soit {{ totalCompensationAmount.toFixed(2) }}€ pour compenser cette émission
                    </p>
                  </div>
                </div>
                <p class="raleway-reg-p15">
                  En versant une somme compensatoire pour soutenir des projets de développement
                  durable, vous gagnez des points de fidélité, des cadeaux et des avantages sur
                  votre séjour. Surclassement, accès au spa, repas gastronomique…
                </p>
                <!--<div id="info-icon">
                  <img src="../../images/Info icone.svg" alt="info" />
                </div>-->
                <div id="info">
                  <p class="raleway-reg-p13">
                    nuit offerte dans l’un des hébergements du programme evergreen dans la limite de
                    2 nuits offertes.
                  </p>
                </div>
                <div class="row row-how-p" style="display:none">
                  <div class="col-5 col-lg-3 mt-4">
                    <div class="fid-item">
                      <h1 class="raleway-reg-p24">150€</h1>
                      <!-- <p class="raleway-reg-p15">Points</p> -->
                    </div>
                  </div>
                  <div class="col-2 col-lg-1 equal">
                    <p class="raleway-bold-p33">=</p>
                  </div>
                  <div class="col-5 col-lg-3 mt-4">
                    <div class="fid-item">
                      <h1 class="raleway-reg-p24">500</h1>
                      <p class="raleway-reg-p15">Points</p>
                    </div>
                  </div>
                  <div class="col-3 col-lg-1 equal">
                    <p class="raleway-bold-p33">=</p>
                  </div>
                  <div class="col-7 col-lg-3 mt-4">
                    <div class="fid-item">
                      <h1 class="raleway-reg-p24">1</h1>
                      <p class="raleway-reg-p15">Nuit offerte</p>
                    </div>
                  </div>
                </div>
                <p class="raleway-semi-p15" style="display:none">
                  Versez tout de suite 150€ en somme compensatoire et gagnez 500 points fidélité
                  soit 1 nuit offerte dans l’un de nos hébergements du programme Evergreen.
                </p>
                <div class="input-fid d-flex">
                  <input
                      class="col-5"
                      type="number"
                      placeholder="30€"
                      @input="fromPriceToPoints"
                      :value="compensationToPay"
                  />
                  <div class="col-2 equal">
                    <p class="raleway-bold-p33">=</p>
                  </div>
                  <input
                      class="col-5"
                      type="number"
                      placeholder="10 points de fidélité"
                      @input="fromPointsToPrice"
                      :value="compensationPointToEarn"
                  />
                </div>
                <div class="compensation mt-5">
                  <p class="raleway-bold mr-2 mb-0">
                    Total de vos points fidélité après compensation
                  </p>
                  <div class="score">
                    <p class="raleway-bold-p20 mb-0" style="color: grey">
                      {{ compensationPointToEarn }}
                    </p>
                    <p class="raleway-reg-p15 ml-2 mb-0">points de fidélité</p>
                  </div>
                </div>
                <p class="raleway-reg-p13 mb-5">
                  Points de fidélité gagnés avec votre voyage et votre compensation carbone
                </p>
                <button class="hortense-btn-primary" @click="changeStep(3)">Valider</button>
              </div>
            </div>
          </div>
          <div v-if="hasTravelGuideImage" class="step-from-2">
            <div class="mb-4">
              <p class="raleway-reg-p24 mb-0">3. Guide De Voyage</p>
            </div>
            <div class="description-main-section">
              <div class="description-section">
                <div class="travel-guide-description">
                  <p class="raleway-reg-p15">Enrichissez votre séjour avec notre quide de<br>voyage exclusif pour seulement 12€ de plus !</p>
                </div>
                <div class="travel-guide-description">
                  <p class="raleway-reg-p15">Ce guide soigneusement élaboré regorge de<br> trésors cachés et d'expériences uniques qui vous<br>
                  permettront de vivre pleinement votre sejour.<br> Découvrez les meilleurs restaurants locaux, les<br>
                  sites touristiques incontournables, les activités<br> passionnantes, les lieux hors des sentiers
                  battus<br> et bien plus encore !</p>
                </div>
                <div class="travel-guide-description">
                  <p class="raleway-reg-p15">Hortense sengage pour promouvoir un tourisme<br> durable et authentique. Découvrez plus de 250<br>
                  lieux et expériences pour voyager de manière<br> écoresponsable et explorer des sites uniques à<br>
                  proximité de votre lieu de séjour.</p>
                </div>
                <div class="travel-guide-check-section form-item raleway-reg-p13">
                  <input
                      type="checkbox"
                      id="guide-accept"
                      value="guide-accept"
                      @change="handleGuideAcceptanceChange"
                  />
                  <label for="guide-accept">J'ACHÈTE LE GUIDE DE VOYAGE</label>
                </div>
              </div>
              <div class="image-section">
                <img
                    :src="travelGuideImageUrl"
                    alt="guide img"
                    class="travel-guide-img"
                >
                <div class="travel-guide-image-text">
                  <p>Explorez. Découvrez. Donnez du</br> sens à votre voyage avec Hortense.</p>
                </div>
              </div>
            </div>
            <div class="info-cancelation mb-3">
              <div class="raleway-reg-p13">
                <img src="../../images/info.svg" alt="info" />
                <p>
                  Veuillez noter que ce guide sera disponible en version papier dans votre chambre le jour de votre
                  arrivée. Avant votre séjour, vous pouvez consulter la version en ligne de ce guide
                  <a :href="travelGuideLink" class="link-hortense" target="_blank" rel="noopener noreferrer">ici.</a>.
                </p>
              </div>
            </div>
          </div>
          <div class="step-from-3" id="step-3">
            <div @click="changeStep(4)" class="mb-4">
              <p class="raleway-reg-p24 mb-0">{{ guideSectionNumber }} Carte de paiement</p>
            </div>
            <div class="section-payment">
              <div class="info-payment">
                <img src="../../images/info.svg" alt="info" />
                <div>
                  <p class="raleway-reg-p13 mb-0">
                    Le paiement de votre voyage sera dû directement sur place à votre arrivée. Vous
                    ne payez ici que le soutien au développement durable que vous avez consenti
                    donner que vous avez consenti et le guide de voyage que vous avez acheté.
                  </p>
                  <p class="raleway-reg-p13 mb-0 mt-1">
                    Une autorisation temporaire est effectuée afin de vérifier la validité de votre
                    carte bancaire. Afin de garantir la réservation, vos coordonnées de carte
                    bancaire sont enregistrées sur Stripe (système de paiement sécurisé).
                  </p>
                </div>
              </div>
              <div class="link-container">
                <a class="link-item link-pages">{{ $t("payment") }}</a>
                <ul class="list-link">
                  <li class="link-item picto">
                    <img
                        src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/3DSecure.png"
                        alt="3DSecure"
                    />
                  </li>
                  <li class="link-item picto">
                    <img src="../../images/Visa.svg" alt="Visa" />
                  </li>
                  <li class="link-item picto">
                    <img src="../../images/Mastercard-1.svg" alt="MasterCard" />
                  </li>
                  <li class="link-item amex">
                    <img src="../../images/American express.png" alt="AmericaExpress" />
                  </li>
                  <li class="link-item paypal">
                    <img
                        src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/PayPal+.png"
                        alt="Paypal"
                    />
                  </li>
                </ul>
              </div>
              <div class="card-stripe mb-3">
                <div class="credit-card">
                  <p class="raleway-bold-p mb-0">Carte de crédit</p>
                </div>
                <div class="field">
                  <input type="text" placeholder="Nom/Prénom" v-model="cardDetails.holder" />
                </div>
                <div class="field">
                  <div id="card-number" class="input empty"></div>
                </div>
                <div class="field">
                  <div id="card-expiry" class="input empty"></div>
                </div>
                <div class="field">
                  <div id="card-cvc" class="input empty"></div>
                </div>
                <div id="card-errors" role="alert" ref="errorsCard"></div>
              </div>
              <p class="raleway-reg-p13">
                En réservant cette chambre, vous acceptez la
                <a class="link-hortense" href="/politique-confidentialit%C3%A9"
                >politique de confidentialité</a
                >, les
                <a class="link-hortense" href="/faq-hortense">conditions d’annulation</a>
                et les
                <a class="link-hortense" href="/conditions-generales-utilisation"
                >conditions d’utilisation</a
                >.
              </p>
              <div class="form-item raleway-reg-p13 newsletter">
                <input
                    type="checkbox"
                    id="newsletter"
                    value="newsletter"
                    v-model="checkedMarketing"
                />
                <label for="newsletter"
                >J’accepte de recevoir des informations marketing ou offres promotionnelles de la
                  part d’Hortense.</label
                >
              </div>
              <div class="form-item raleway-reg-p13 test">
                <input
                    type="checkbox"
                    id="test"
                    value="test"
                    v-model="checkedSales"
                />
                <label for="test"
                >J’accepte de recevoir des offres commerciales de la part des partenaires
                  d’Hortense.</label
                >
              </div>
              <!--<div class="form-item raleway-reg-p13 privilege">
                <input
                  type="checkbox"
                  id="privilege"
                  value="privilege"
                  v-model="checkedSales"
                />
                <label for="privilege"
                  >J’accepte de recevoir des offres commerciales de la part des partenaires
                  d’Hortense.</label
                >
              </div>-->
              <button
                  class="hortense-btn-primary"
                  :disabled="isProcessingPayment"
                  @click.prevent="handlePayment"
              >
                {{ paymentButtonCaption }}
              </button>
            </div>
          </div>
          <p class="raleway-reg-p13">
            Le montant de votre séjour est à régler en amont ou sur place. En cas de règlement anticipé, l'établissement vous contactera directement. Besoin d’aide ? écrivez-nous
            <a :href="'/pages/contact/'" class="link-hortense">ici.</a>
          </p>
        </div>
        <div class="col-lg-5">
          <div class="card-resa-sum">
            <p class="raleway-reg-p24 text-center mb-4 mt-2">Votre réservation</p>
            <div class="card-img">
              <!-- <img
                  v-if="!Array.isArray(this.item.photos_keys)"
                  :src="this.item.photos_keys"
                  :alt="this.item.name"
              /> -->
              <img
                  v-if="this.item.photo_banner"
                  :src="this.item.photo_banner"
                  :alt="this.item.name"
              />
              <img
                  v-else
                  src="https://images.unsplash.com/flagged/photo-1556438758-8d49568ce18e?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1053&q=80"
                  :alt="this.item.name"
              />
            </div>
            <div class="row row-resa-detail">
              <div class="col-7 px-0">
                <p class="raleway-bold-p13 mb-0">
                  {{ `${lodging.name}` }}
                </p>
                <p v-if="item.koedia_room_name" class="raleway-reg-p13 mb-0">
                  {{ `${item.name}` }}
                </p>
                <p class="raleway-reg-p13 mb-0">
                  Du {{ this.formatDate(from) }} au {{ this.formatDate(to) }}
                </p>
                <p class="raleway-reg-p13 mb-0">
                  {{ Math.ceil((this.toDate(to) - this.toDate(from)) / (1000 * 60 * 60 * 24)) }} nuits
                </p>
                <p class="raleway-semi-p13">
                  {{ this.formatAdults() }} - {{ this.formatChilds() }}
                </p>
                <p class="raleway-semi-p13 mb-0">{{ item.name }}</p>
                <p v-if="this.accomodationCode" class="raleway-reg-p13">{{ Object.values(selectedOption.board)[0] }}</p>
              </div>
              <div class="col-5">
                <a class="link-hortense" @click="close()">Modifier</a>
                <p class="raleway-reg-p13">{{ pricePerNightHt }}€ par nuit</p>
              </div>
            </div>
            <div v-if="loyaltyUser" class="col col-radio raleway-reg-p13">
              <div v-for="(option, index) in loyaltyUser.loyalty_first_token" :key="index">
                <input type="checkbox" :id="'first' + index.toString()" v-model="loyalty_token" :value="'first-' + index.toString()">
                <label :for="'first' + index.toString()">Crédit voyage d’une valeur de 50€</label>
                <br>
              </div>
              <div v-for="(option, index) in loyaltyUser.loyalty_second_token" :key="index">
                <input type="checkbox" :id="'second' + index.toString()" v-model="loyalty_token" :value="'second-' + index.toString()">
                <label :for="'second' + index.toString()">1 nuit offerte d'une valeur de 100€</label>
                <br>
              </div>
              <div v-for="(option, index) in loyaltyUser.loyalty_third_token">
                <input type="checkbox" :id="'third' + index.toString()" v-model="loyalty_token" :value="'third-' + index.toString()">
                <label :for="'third' + index.toString()">1 nuit offerte d'une valeur de 150€</label>
                <br>
              </div>
              <div v-for="(option, index) in loyaltyUser.loyalty_fourth_token">
                <input type="checkbox" :id="'fourth' + index.toString()" v-model="loyalty_token" :value="'fourth-' + index.toString()">
                <label :for="'fourth' + index.toString()">1 nuit offerte d'une valeur de 200€</label>
                <br>
              </div>
              <div v-for="(option, index) in loyaltyUser.loyalty_fifth_token">
                <input type="checkbox" :id="'fifth' + index.toString()" v-model="loyalty_token" :value="'fifth-' + index.toString()">
                <label :for="'fifth' + index.toString()">1 nuit offerte d'une valeur de 250€</label>
                <br>
              </div>
              <div v-for="(option, index) in loyaltyUser.loyalty_sixth_token">
                <input type="checkbox" :id="'sixth' + index.toString()" v-model="loyalty_token" :value="'sixth-' + index.toString()">
                <label :for="'sixth' + index.toString()">1 nuit offerte d'une valeur de 300€</label>
                <br>
              </div>
            </div>
            <div class="row row-price-detail">
              <div class="col-6 px-0">
                <p class="raleway-reg-p13 mb-0">Total HT</p>
                <p v-if="!accomodationCode" class="raleway-reg-p13 mb-0">Taxe de séjour</p>
                <p v-if="lodging.is_entire" class="raleway-reg-p13">TVA</p>
<!--                <p v-if="!accomodationCode && lodging.is_entire" class="raleway-reg-p13">Frais de ménage</p>-->
                <p v-if="this.code_promo" class="raleway-reg-p13">Code exclusif</p>
                <p class="raleway-bold-p mb-0">TOTAL TTC</p>
              </div>
              <div class="col-6 px-0 align-right">
                <div v-if="accomodationCode">
                  <p class="raleway-reg-p13 mb-0">{{ totalHt }}€</p>
                  <!--<p class="raleway-reg-p13 mb-0">{{ touristTax }}€</p>-->
                  <p v-if="lodging.is_entire" class="raleway-reg-p13">{{ tva }}€</p>
                  <p v-if="this.code_promo" class="raleway-reg-p13">{{ this.code_promo }}</p>
                  <p v-if="loyalty_token.length === 0" class="raleway-bold-p">{{ totalTtc }}€</p>
                  <p v-else class="raleway-bold-p">{{ totalTtcLoyalty }}€</p>
                </div>
                <div v-else>
                  <p class="raleway-reg-p13 mb-0">{{ totalHtNotKoedia }}€</p>
                  <p class="raleway-reg-p13 mb-0">{{ totalTaxNotKoedia }}€</p>
                  <p class="raleway-reg-p13">{{ totalTvaNotKoedia }}€</p>
<!--                  <p v-if="lodging.is_entire" class="raleway-reg-p13">{{ this.houseHold || "0.00€" }}</p>-->
                  <p v-if="loyalty_token.length === 0" class="raleway-bold-p">{{ totalTtcNotKoedia }}€</p>
                  <p v-else class="raleway-bold-p">{{ totalTtcLoyaltyNotKoedia }}€</p>
                </div>
              </div>
              <p class="raleway-reg-p13 pt-2">
                Dû en arrivant à l'établissement. Une taxe de séjour sera potentiellement à régler sur place.
              </p>
            </div>

            <div class="row">
              <div v-if="exclusive" id="description" class="exclusive-options-resa ol-lg-6 col-xs-10 col-weekend cardColl p-2 mb-3 ml-3 mr-3">
                <p class="raleway-bold-p">Découvrez notre offre exclusive</p>
                <!--<p class="raleway-reg-p13">{{ this.exclusive.description_exclusive_page }}</p>-->
                <ul>
                  <li v-if="this.exclusive.exclusive_offer_1">{{ this.exclusive.exclusive_offer_1 }}</li>
                  <li v-if="this.exclusive.exclusive_offer_2">{{ this.exclusive.exclusive_offer_2 }}</li>
                  <li v-if="this.exclusive.exclusive_offer_3">{{ this.exclusive.exclusive_offer_3 }}</li>
                  <li v-if="this.exclusive.exclusive_offer_4">{{ this.exclusive.exclusive_offer_4 }}</li>
                  <li v-if="this.exclusive.exclusive_offer_5">{{ this.exclusive.exclusive_offer_5 }}</li>
                </ul>
              </div>
              <div v-if="exclusive" class="exclusive-hiw-resa card-content ol-lg-6 col-xs-10 col-weekend cardColl p-2 mb-3 ml-3 mr-3">
                <p class="raleway-bold-p">COMMENT ÇA MARCHE ?</p>
                <p class="raleway-reg-p13 mb-2">{{ this.exclusive.how_it_works }}</p>
              </div>
            </div>
            <!-- <div>{{ selectedOption }}</div> -->
            <!-- KOEDIA -->
            <div class="info-cancelation mb-3" v-if="!gettingRateDetails">
              <div class="raleway-semi-p13 mb-2">Frais d'annulation</div>
              <div class="raleway-reg-p13">
                <img src="../../images/info.svg" alt="info" />
                <ul v-if="!isRefundable">
                  <li>Tarif non remboursable.</li>
                  <li>En cas d'annulation, le paiement intégral de la réservation sera dû.</li>
                </ul>
                <ul v-else>
                  <li v-if="cancelationPolicy">{{ cancelationPolicy }}</li>
                  <li>
                    En cas d'annulation, après cette date, le paiement intégral de la réservation
                    sera dû.
                  </li>
                </ul>
              </div>
            </div>

              <!-- NOT KOEDIA -->
              <div v-if="selectedPriceOptionFee" class="info-cancelation mb-3">
                <div class="raleway-semi-p13 mb-2">Frais d'annulation</div>
                <div class="raleway-reg-p13">
                  <img src="../../images/info.svg" alt="info" />
                  <p>{{ selectedPriceOptionFee }}</p>
                </div>
              </div>
            <div v-if="this.lodging.is_club" class="promo form-item raleway-reg-p13">
              <input
                  type="checkbox"
                  id="guide-acceptt"
                  value="guide-acceptt"
                  style="align-self: flex-start;"
              />
              <label for="guide-acceptt" style="align-self: flex-start;">
                Je souhaite bénéficier du service de conciergerie du <a href="/experience-club-hortense">Club Hortense</a>
              </label>
            </div>

            <div class="promo">
              <label for="code_promo" class="raleway-bold-p">Code exclusif - Code d’activation carte cadeau</label>
              <input v-model="code_promo" type="text" style="width: 25%" :class="{'border-green': loyaltyCodeValidity === 'true' && loyaltyCodeValidity !== 'null', 'border-red': loyaltyCodeValidity === 'false' && loyaltyCodeValidity !== 'null'}"/>
              <!--<button class="hortense-btn-primary">VALIDER</button>-->
              <button :disabled="this.isButtonDisabled" style="display:inline;cursor:pointer; padding: 4px 10px !important;" id="code_button" class="hortense-btn-primary" @click="checkLoyaltyCode">VALIDER</button>

              <p v-if="loyaltyCodeValidity === 'false' && loyaltyCodeValidity !== 'null'" class="raleway-reg-p13" style="color: red">L'offre n'est plus valide</p>
              <p v-if="loyaltyCode && loyaltyCodeValidity !== 'null'" class="raleway-reg-p13">
                <span v-if="loyaltyCode.code_type === 2">Vous bénéficierez de {{ loyaltyCode.code_type_description }} services exclusifs au cours de votre séjour.</span>
                <span v-if="loyaltyCode.code_type === 1">Vous bénéficiez désormais du statut {{ loyaltyCode.code_type_description }}</span>
                </p>
                  <p v-if="loyaltyCodeValidity === 'null'" class="raleway-reg-p13" style="color: red">*Votre code exclusif est expiré</p>
                  <p v-if="loyaltyCodeValidity === 'notvalid'" class="raleway-reg-p13" style="color: red">Les conditions de l’offre ne sont pas respectées</p>
            </div>


            <hr class="custom-line">
            <div class="row row-price-detail">
            <div class="p-1 __container  pt-2">
              <h2 class="raleway-bold-p raleway-semi-p13">VOS AVANTAGES</h2>
              <p class="raleway-reg-p13 mb-4" v-if="!user">Pour bénéficier de vos avantages, vous devez avoir un compte
                client et être connecté à celui-ci au moment de la confirmation
                de votre réservation.</p>
              <div class="d-flex align-items-start mb-3 gap-3">

                <div class="travel-guide-check-section mt-1 form-item raleway-reg-p13">
                  <input
                      type="checkbox"
                      id="guide-accept"
                      value="guide-accept"
                      v-model="isLoyaltySectionChecked"
                  />
                  <label for="guide-accept " class="__form_input"></label>
                </div>

                <div>
                  <h2 class="_sub_title raleway-reg-p15 p-1 mb-3" style="background: #cfe5dc !important; color: green !important;">LE PROGRAMME DE FIDÉLITÉ HORTENSIA</h2>
                  <div v-if="user">
<!--                    <p class="raleway-reg-p13 mb-1">GREEN TROTTER PREMIUM </p>-->
                    <div class="__user_points bg-white __btn_text mb-4" >
                      <p>MES POINTS : {{ user.loyalty_points }}</p>
                    </div>
                  </div>
                  <div class="d-flex align-items-center flex-wrap _button_container" v-if="user && this.lodging && this.lodging.green_trotters && this.lodging.green_trotters.length">
                    <template v-if="user.loyalty_points >= 500" v-for="gift in this.lodging.green_trotters[0].gifts">
                      <button class="__payment_btn" >{{ gift.name }}</button>
                    </template>
                    <template v-if="user.loyalty_points >= 1000" v-for="gift in this.lodging.green_trotters[1].gifts">
                      <button class="__payment_btn" >{{ gift.name }}</button>
                    </template>
                    <template v-if="user.loyalty_points >= 1500" v-for="gift in this.lodging.green_trotters[2].gifts">
                      <button class="__payment_btn" >{{ gift.name }}</button>
                    </template>
                    <template v-if="user.loyalty_points >= 2500" v-for="gift in this.lodging.green_trotters[3].gifts">
                      <button class="__payment_btn" >{{ gift.name }}</button>
                    </template>
                  </div>
                  <div v-if="!user" class="d-flex align-items-center flex-wrap _button_container">
                    <p class="raleway-reg-p13">Les services exclusifs dépendent de votre statut dans le cadre notre programme de fidélité. Merci de vous connecter à votre espace client pour connaître votre statut et les services auxquels vous avez droit durant votre séjour.</p>
                  </div>
                  <div v-if="user && user.loyalty_points < 1000" class="d-flex align-items-center flex-wrap _button_container">
                    <p class="raleway-reg-p13">Encore quelques réservations pour atteindre le premier niveau de notre programme de fidélité et profiter de services exclusifs pendant votre séjour.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="light_container" v-if="this.numberOfNights && this.numberOfNights >= 3">
              <div class="d-flex align-items-start p-1 mb-5 gap-3">
                <div class="travel-guide-check-section form-item mt-1 raleway-reg-p13">
                  <input
                      type="checkbox"
                      id="guide-accept"
                      value="guide-accept"
                      v-model="isGreenExperienceChecked"
                  />
                  <label for="guide-accept " class="__form_input"></label>
                </div>

                <div>
                  <h2 class="_sub_title raleway-reg-p15 p-1 mb-3">GREEN EXPERIENCE</h2>
<!--                  <p class="raleway-reg-p13 payment-para">{{ this.lodging.lodging_description.green_experience }}</p>-->
                  <div class="d-flex align-items-center flex-lg-nowrap flex-wrap _button_container">
                    <button v-if="this.lodging.lodging_description.green_experience " class="__payment_btn">{{ this.lodging.lodging_description.green_experience }}</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-start p-1 mb-5 gap-3">
              <div class="travel-guide-check-section form-item mt-1 raleway-reg-p13">
                <input
                    type="checkbox"
                    id="guide-accept"
                    value="guide-accept"
                />
                <label for="guide-accept " class="__form_input"></label>
              </div>
              <div>
                <h2 class="_sub_title_right raleway-reg-p15 p-1">-10% SUR VOTRE 1ER SÉJOUR</h2>
                <div class="mt-2" v-if="!user">
                  <p class="raleway-reg-p13" >Pour bénéficier des -10 %, créez un compte client
                    et vous recevrez un e-mail avec un code exclusif.
                    Si vous avez déjà un compte, le code est disponible
                    dans votre espace client.</p>
<!--                  <div class="d-flex align-items-center flex-lg-nowrap flex-wrap _button_container">-->
<!--                    <button v-if="this.lodging.lodging_description.green_experience" class="__payment_btn">{{this.lodging.lodging_description.green_experience}}</button>-->
<!--                  </div>-->
                </div>
                <div v-else>
                  <p class="raleway-reg-p13" >Offre valable peu importe la durée de votre sejour.</p>
                </div>
              </div>
            </div>
            <div class=" align-items-start p-3 gap-3" v-if="!user">
              <div>
                <button class="hortense-btn-primary ml-4" id="show-modal" @click="showModal = true">
                  JE ME CONNECTE
                </button>
              </div>
              <div class="d-flex align-items-start pt-2 pl-3 ml-4 mb-5 gap-3 ">
                <p class="raleway-reg-p15">
                  <span class="link-hortense ____link" @click="showModal = true">Je crée mon compte</span>.
                </p>
              </div>
            </div>
          </div>




            <div v-if="!lodging.is_entire" class="raleway-reg-p13 message-container">
                <p v-if="!item.koedia_room_name" class="text-center text-muted bottom-static-text">
                Cet hébergement ne propose pas la réservation instantanée. Une demande de réservation doit être faite. La disponibilité vous sera confirmée sous 24h.</p>
            </div>
          </div>
          <div v-else class="raleway-reg-p13 message-container text-center">
            <p class="raleway-reg-p13 message-container text-center">
              Vous allez recevoir une confirmation par e-mail. Aucune inscription n'est nécessaire.
              Aucun frais de réservation.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import fetcher from "packs/shared/api.js";
import Searchbanner from "../../components/btns/Searchbanner.vue";
import Searchbtn from "../../components/btns/Searchbtn.vue";
import ReservationCard from "../../components/cards/ReservationCard.vue";
import ModalEvergreen from "./ModalEvergreen.vue";
import Modalsignup from "../../components/modals/Modalsignup.vue";
import ModalLoginAdmin from "../../components/modals/ModalLoginAdmin.vue";

export default {
  name: "reservation-user-info",
  components: {
    ModalLoginAdmin,
    Modalsignup,
  },
  props: {
    exclusive: Object,
    houseHold: String,
    totalTaxNotKoedia: String,
    totalHtNotKoedia: String,
    totalTtcNotKoedia: String,
    totalTvaNotKoedia: String,
    item: Object,
    lodging: Object,
    from: Date,
    to: Date,
    tax: Object,
    total: Number,
    totalHt: Number,
    pricePerNightHt: Number,
    totalTtc: Number,
    tva: Number,
    user: Object,
    childs: Number,
    adults: Number,
    numberOfNights: Number,
    koediaRoomId: String,
    koediaSessionId: String,
    childrenAges: Array,
    koediaFrom: String,
    koediaTo: String,
    selectedOption: Object,
    accomodationCode: Object,
    selectedPriceOptionFee: String
  },
  data() {
    return {
      discountAmount: 0,
      isButtonDisabled: false,
      guideChecked: false,
      showModal: false,
      showModalLogin: false,
      loyaltyCode: null,
      loyaltyCodeValidity: String,
      loyalty_token: [],
      loyaltyUser: null,
      status: {
        getRateDetails: null,
        createToken: null,
        createCustomer: null,
        addCard: null,
        paymentIntent: null,
        confirmCardPayment: null,
        reqAccomBook: null,
        createReservation: null,
      },
      cardDetails: {
        lastFourDigits: null,
        type: null,
        expiryMonth: null,
        expiryYear: null,
        holder: null,
      },
      showModallogin: false,
      showSignupModal: false,
      rateDetails: null,
      paymentIntentId: null,
      reservation: null,
      clientSecret: null,
      displayError: this.$refs.errorsCard,
      card: null,
      stripeKey: document.querySelector("meta[name='stripe-key']").getAttribute("content"),
      stripe: null,
      userInfo: {
        last_name: null,
        first_name: null,
        email: null,
        phone: null,
        city: null,
        country: null,
      },
      code_promo: "",
      chatMount: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      cb: null,
      bookingRef: null,
      koediaBookingRef: null,
      date: null,
      cvc: null,
      cardToken: null,
      gettingRateDetails: true,
      isProcessingPayment: false,
      messageBody: null,
      checkedMarketing: null,
      checkedSales: null,
      selectedOther: [],
      evergreens: null,
      showEvergreens: false,
      showTourism: false,
      showPayment: false,
      distanceBetween: 0,
      userLatitude: 0,
      userLongitude: 0,
      compensationToPay: 0,
      compensationPointToEarn: 0,
      informationsCompleted: false,
      step: 1,
      selectedTransportEngin: "plane",
      lodgingMount: null,
      compensationByNightsAndTravelers: {
        compensation_amount: 130,
        compensation_carbon_offset: 22,
      },
      compensationByPlane: {
        compensation_amount: 100,
        compensation_carbon_offset: 22,
      },
      compensationByTrain: {
        compensation_amount: 55,
        compensation_carbon_offset: 22,
      },
      compensationByCar: {
        compensation_amount: 55,
        compensation_carbon_offset: 22,
      },
      totalCompensationCarbonOffset: 0,
      totalCompensationAmount: 0,
      isLoyaltySectionChecked: false,
      isGreenExperienceChecked: false,
      isDiscountSectionChecked: false,
    };
  },
  watch: {
    cardToken() {
      this.cardDetails.lastFourDigits = this.cardToken.card.last4;
      this.cardDetails.type = this.cardToken.card.brand;
      this.cardDetails.expiryMonth = this.cardToken.card.exp_month;
      this.cardDetails.expiryYear = this.cardToken.card.exp_year;
    },
  },
  created() {
    if (this.user) {
      if (this.numberOfNights > 3) {
        this.isGreenExperienceChecked = true;
      }
      if (this.user.loyalty_points >= 500) {
        this.isLoyaltySectionChecked = true;
      }
    }

  },
  methods: {
    /*taxTotal() {
      if (this.childs === 0) {
        return this.tax.tourist_tax * this.numberOfNights * this.adults;
      } else if (this.adults === 0) {
        return this.tax.tourist_tax * this.numberOfNights * this.childs;
      } else {
        //return this.tax.tourist_tax * this.numberOfNights * this.adults * this.childs;
        return this.tax.tourist_tax * this.numberOfNights * this.adults * this.childs;
      }
    },*/
    handleGuideAcceptanceChange(event) {
      if(event.target.checked) {
        this.compensationToPay += 12
        this.guideChecked = true
      } else {
        this.compensationToPay -= 12
        this.guideChecked = false
      }
    },
    async hashEmail(email) {
      if(email && email !== '') {
        const encoder = new TextEncoder();
        const data = encoder.encode(email);
        const hash = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hash));
        return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      } else {
        return ""
      }
    },
     async checkLoyaltyCode(){
       var myButton = document.getElementById("code_button");
        try {
              const today = new Date();
              today.setDate(today.getDate() + 1);
              const response = await fetcher.get('/user_codes/' +  this.code_promo);
              const codeData = response.data;
              this.loyaltyCode = codeData;
          if (codeData && codeData.activation_code) {
            if(this.accomodationCode){
              this.totalTtc = this.totalTtc - parseInt(codeData.amount);
              // myButton.disabled = true;
              this.isButtonDisabled = true;
              this.discountAmount = parseInt(codeData.amount)
            } else {
              this.totalTtcNotKoedia = this.totalTtcNotKoedia - parseInt(codeData.amount);
              // myButton.disabled = true;
              this.discountAmount = parseInt(codeData.amount)
              this.isButtonDisabled = true;
            }
          }
              else if(codeData.code_status === true && today <= new Date(codeData.expiry_date)
                  && today >= new Date(codeData.validity_date)
                  && !codeData.code_utilization_date)
                  {
                    if (codeData.code_name == 'BIENVENUE') {

                      this.totalTtc  = this.totalTtc  - ((this.totalTtc  * 10) / 100);
                      this.discountAmount = (this.totalTtc * 10) / 100
                      this.isButtonDisabled = true;
                    }
                    else if(codeData.code_type_trigger <= Math.ceil((this.toDate(this.to) - this.toDate(this.from)) / (1000 * 60 * 60 * 24)))
                    {
                      this.loyaltyCodeValidity = 'true';
                      if(codeData.code_type === 0){
                        if(this.accomodationCode){
                          this.totalTtc = this.totalTtc - 50*parseInt(codeData.code_type_description);
                          // myButton.disabled = true;
                          this.discountAmount = 50*parseInt(codeData.code_type_description)
                          this.isButtonDisabled = true;

              } else {
                this.totalTtcNotKoedia = this.totalTtcNotKoedia - 50*parseInt(codeData.code_type_description);
                // myButton.disabled = true;
                          this.discountAmount = 50*parseInt(codeData.code_type_description)
                this.isButtonDisabled = true;

              }
            }
          } else {
            this.loyaltyCodeValidity = 'notvalid';
          }
        } else {
          this.loyaltyCodeValidity = 'false';
        }
      } catch (e) {
        this.loyaltyCodeValidity = 'null';
        console.error(e);
      }
    },
    setUser(user) {
      this.user = user;
      this.showModal = false;
    },
    async useLoyaltyCode(){
      try {

        console.log('code_promo');
        console.log(this.code_promo);
        console.log('user_id');
        console.log(this.user);
        const res = await fetcher.patch('/user_codes/1', {collection: {
            code_name: this.code_promo,
            user_id: this.user.id,
            code_utilization_date: new Date().toISOString().slice(0, 10) }});
      } catch (e) {
        console.error(e);
      }
    },
    touristTax(){
      return (
          parseFloat(this.tax.tourist_tax) *
          parseInt(this.numberOfNights) *
          parseInt(this.adults)
      ).toFixed(2);
    },
    async sendMessage() {
      try {
        console.log(this.user);
        const res = await fetcher.post("/chat_rooms", {
          receiver_id: this.lodging.user_id,
          object: "client",
        });
        this.chatMount = res.data;
        console.log(res.data);
        const resMessage = await fetcher.post("/chat_rooms/" + this.chat_room.id + "/messages", {
          message: this.messageBody,
        });
        this.chatMount.messages.push(resMessage.data);
        this.newMessage.body = "";
      } catch (e) {
        console.error(e);
      }
    },
    close() {
      this.$emit("closed");
      window.location.href = this.lodging.is_entire ? '/reservation-hotel/' + this.lodging.slug : '/reservation-hotel/' + this.lodging.slug + '/reservation'
    },
    initStripe() {
      this.stripe = Stripe(this.stripeKey);
      const elements = this.stripe.elements();

      const elementStyles = {
        base: {
          color: "#32325D",
          fontWeight: 500,
          fontSize: "16px",
          fontSmoothing: "antialiased",

          "::placeholder": {
            color: "#848484",
          },
          ":-webkit-autofill": {
            color: "#e39f48",
          },
        },
        invalid: {
          color: "#E25950",

          "::placeholder": {
            color: "#FFCCA5",
          },
        },
      };

      const elementClasses = {
        focus: "focused",
        empty: "empty",
        invalid: "invalid",
      };

      this.cardNumber = elements.create("cardNumber", {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardNumber.mount("#card-number");

      this.cardExpiry = elements.create("cardExpiry", {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardExpiry.mount("#card-expiry");

      this.cardCvc = elements.create("cardCvc", {
        style: elementStyles,
        classes: elementClasses,
      });
      this.cardCvc.mount("#card-cvc");
      this.displayError = this.$refs.errorsCard;
    },
    async ReqAccomBook() {
      try {
        const res = await fetcher.post(
            `/lodgings/${this.lodging.slug}/request_accommodation_booking`,
            {
              koedia_session_id: this.koediaSessionId,
              koedia_room_id: this.selectedOption.room_id,
              start: this.koediaFrom,
              end: this.koediaTo,
              first_name: this.userInfo.first_name,
              last_name: this.userInfo.last_name,
              adults: this.adults,
              children: this.childs,
              children_ages: this.childrenAges,
              email: this.userInfo.email
              // card_details: this.cardDetails,
            }
        );
        if (res.data.error) {
          this.status.reqAccomBook = false;
          console.error(res.data.error);
        } else {
          this.status.reqAccomBook = true;
          this.koediaBookingRef =
              res.data.booking_request.book_accommodation_response.accom_book_desc.book_item[
                  "@bookingref"
                  ];

          console.log("REF BOOK");
          console.log(this.koediaBookingRef);
          this.bookingRef =
              res.data.booking_request.book_accommodation_response.accom_book_desc.book_item[
                  "@customerbookingref"
                  ];
        }
      } catch (e) {
        this.status.reqAccomBook = false;
        console.error(e);
      }
    },
    async createReservation() {
      try {
        console.log("user: this.userInfo");
        console.log(this.userInfo)

        console.log("user: this.user");
        console.log(this.user)
        let exclusiveOffer = "";
        if(this.exclusive){
          exclusiveOffer = this.exclusive.title
        }
        console.log('this.from');
        console.log(this.from);
        console.log('this.to');
        console.log(this.to);
        const res = await fetcher.post("/reservation-hotel/" + this.lodging.id + "/reservations", {
          lodging_id: this.lodging.id,
          reservation: {
            room_code: this.item.code,
            checkin: this.from,
            check_out: this.to,
            total: (this.accomodationCode && this.loyalty_token?.length === 0) ?  this.totalTtc : (this.accomodationCode && this.loyalty_token?.length != 0) ? this.totalTtcLoyalty  : this.loyalty_token.length === 0 ? this.totalTtcNotKoedia : this.totalTtcLoyaltyNotKoedia,
            duration: this.numberOfNights,
            payment_id: this.paymentIntentId,
            client_secret: this.clientSecret,
            booking_ref: this.bookingRef,
            koedia_booking_ref: this.koediaBookingRef,
            promo_code: this.code_promo,
            tva: this.tax?.tourist_tax,
            accommodation_code: this.accomodationCode,
            offer: exclusiveOffer,
            room_name: this.item.name,
            loyalty_token: this.tokenToResa,
            adults: this.adults,
            childs: this.childs
          },
          user: this.userInfo,
        });
        if(this.code_promo.length > 0 && this.loyaltyCodeValidity === 'true'){
          this.useLoyaltyCode();
        }
        console.log('this.userInfo')
        console.log(this.userInfo)
        this.reservation = res.data;
        this.status.createReservation = true;
        let bookingWindow = null;

        if (this.from) {
          const dateIn = new Date(`${this.from}`);
          const today = new Date();
          const timeDifference = dateIn - today;
          bookingWindow = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
        }
        const lodgingTypeMapping = {
          Hotel: "hôtels",
          Villa: "maisons et villas",
          Guesthouse: "chambres d'hôtes",
          Lodge: "lodges et lieux insolites",
          Appartment: "appartements",
          Gite: "maisons et villas",
          Guestroom: "chambres d'hôtes",
          Other: "lodges et lieux insolites"
        };
        let items = [{
          item_name: this.lodging.name,
          item_id: this.lodging.id,
          price: parseFloat((this.accomodationCode && this.loyalty_token?.length === 0) ?  this.totalTtc : (this.accomodationCode && this.loyalty_token?.length != 0) ? this.totalTtcLoyalty  : this.loyalty_token.length === 0 ? this.totalTtcNotKoedia : this.totalTtcLoyaltyNotKoedia),
          item_city: this.lodging.city ? this.lodging.city.name : 'Pruniers-en-Sologne',
          item_region: this.lodging && this.lodging.region ? this.lodging.region.name : 'Occitanie',
          item_category: 'Nos hébergements',
          item_variant: lodgingTypeMapping[this.lodging.type] || "hôtels",
          item_category2: 'Nos hébergements',
          quantity: 1
        }];
        if (this.guideChecked) {
          items.push({
            item_name: 'Guide de voyage',
            item_id: this.lodging.id,
            item_category: 'Guide de voyage',
            price: 12,
            quantity: 1
          });
        }
        const guideValue = this.guideChecked ? 12.0 : 0.0
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: {
            currency: 'EUR',
            transaction_id: '1188726393',
            discount: this.discountAmount,
            coupon : this.loyaltyCodeValidity === 'true' ? this.code_promo : null,
            value: parseFloat( (this.accomodationCode && this.loyalty_token?.length === 0) ?  this.totalTtc : (this.accomodationCode && this.loyalty_token?.length != 0) ? this.totalTtcLoyalty  : this.loyalty_token.length === 0 ? this.totalTtcNotKoedia : this.totalTtcLoyaltyNotKoedia) + guideValue,
            number_of_nights: this.numberOfNights || 1,
            date_in: this.formatDateSyntax(this.toDate(this.from)),
            date_out: this.formatDateSyntax(this.toDate(this.to)),
            number_of_people: parseInt(this.adults || 0) + parseInt(this.childs || 0),
            number_of_adult: this.adults || 0,
            number_of_child: this.childs || 0,
            booking_window: bookingWindow || 2,
            quantity: 1,
            method: 'Visa',
            items: items,
          },
        });
        Turbolinks.visit("/reservation-hotel/" + this.lodging.slug + "/reservations/confirmation");
      } catch (e) {
        this.status.createReservation = false;
        console.log("Failed createReservation");
        console.error(e);
      }
    },
    getRateDetails() {
      if(this.accomodationCode){
        console.log('this.koediaFr', this.koediaFrom)
          console.log('this.koediaTo', this.koediaTo)
        this.gettingRateDetails = true;
        fetcher
            .post(`/lodgings/${this.lodging.slug}/get_rate_details`, {
              koedia_session_id: this.koediaSessionId,
              koedia_room_id: this.selectedOption.room_id,
              start: this.koediaFrom,
              end: this.koediaTo,
              children: this.childs,
              children_ages: this.childrenAges,
            })
            .then((res) => {
              this.rateDetails = res.data.get_rate_details_response.res_get_rate_details;
              this.gettingRateDetails = false;
              this.status.getRateDetails = true;
            })
            .catch((e) => {
              this.status.getRateDetails = false;
              console.log("Failed getRateDetails");
              console.log(e);
            });
      }
    },
    connectModal() {
      if (!this.user) {
        this.$root.$emit("openModalLogin");
        window.scrollTo({
          top: 0,
          behaviour: "smooth",
        });
      }
    },
    signupModal() {
      if (!this.user) {
        this.$root.$emit("openModalSignup");
        window.scrollTo({
          top: 0,
          behaviour: "smooth",
        });
      }
    },
    Modal() {
      if (!this.user) {
        this.$root.$emit("openModal");
        window.scrollTo({
          top: 0,
          behaviour: "smooth",
        });
      }
    },
    async fetchLoyaltyUser() {
      try {
        const res = await fetcher.get(`/loyalty_status/${this.user.id}`);
        this.loyaltyUser = res.data;
        console.log('loyaltyUser');
        console.log(this.loyaltyUser);
      } catch (e) {
        console.error(e);
      }
    },
    async fetchUser() {
      try {
        const res = await fetcher.get(`/dashboard/${this.user.id}`);
        this.userInfo = res.data;
      } catch (e) {
        console.error(e);
      }
    },
    async updateLoyaltyPoints(type) {
      try {
        let new_points = 0;
        // if true using loyalty code
        // false without using loyalty code
        if(type && this.loyaltyCodeValidity === 'true'){
          new_points = this.loyaltyUser.loyalty_points + this.pointsDiff - 500;
        } else {
          new_points = this.loyaltyUser.loyalty_points + this.pointsDiff;
        }
        console.log('Points');
        console.log(new_points);
        const res = await fetcher.patch(`/dashboard/${this.user.id}`, {user: { loyalty_points: new_points }});
      } catch (e) {
        console.error(e);
      }
    },
    async updateUserStatusByCode(status) {
      try {
        const res = await fetcher.patch(`/dashboard/${this.user.id}`, {user: { loyalty_status: status }});
      } catch (e) {
        console.error(e);
      }
    },
    async updateUserTokenByCode(token) {
      try {
        let res = null;
        if(token === 1){
          res = await fetcher.patch(`/dashboard/${this.user.id}`, {user: { loyalty_first_token: token + this.userInfo.loyalty_first_token }});
        }else if(token === 2){
          res = await fetcher.patch(`/dashboard/${this.user.id}`, {user: { loyalty_second_token: token + this.userInfo.loyalty_second_token }});
        }else if(token === 3){
          res = await fetcher.patch(`/dashboard/${this.user.id}`, {user: { loyalty_third_token: token + this.userInfo.loyalty_third_token }});
        } else if(token === 4){
          res = await fetcher.patch(`/dashboard/${this.user.id}`, {user: { loyalty_fourth_token: token + this.userInfo.loyalty_fourth_token }});
        } else if(token === 5){
          res = await fetcher.patch(`/dashboard/${this.user.id}`, {user: { loyalty_fifth_token: token + this.userInfo.loyalty_fifth_token }});
        } else if(token === 6){
          res = await fetcher.patch(`/dashboard/${this.user.id}`, {user: { loyalty_sixth_token: token + this.userInfo.loyalty_sixth_token }});
        }
      } catch (e) {
        console.error(e);
      }
    },
    async addNewSripeCard() {
      try {
        const response = await fetcher.post("/add_card", { card_token: this.cardToken.id });
        if (response.data.error) {
          this.displayError.textContent = response.data.error;
          this.status.addCard = false;
        } else {
          this.status.addCard = true;
        }
      } catch (e) {
        this.status.addCard = false;
        console.log("Failed addNewSripeCard");
        console.error(e);
      }
    },
    async initCreateCardToken() {
      try {
        const response = await this.stripe.createToken(this.cardNumber);
        if (response.error) {
          this.status.createToken = false;
          console.log("Failed createToken");
          console.log(response.error);
        } else {
          this.status.createToken = true;
          this.cardToken = response.token;
        }
      } catch (e) {
        this.status.createToken = false;
        console.log("Failed initCreateCardToken");
        console.log(e);
      }
    },
    async initCreateStripeCustomer() {
      try {
        const createCustomerResponse = await fetcher.post("/create_customer", {
          card_token: this.cardToken,
          user: this.userInfo,
        });
        this.status.createCustomer = true;
        this.userInfo.stripe_customer_id = createCustomerResponse.data.stripe_customer_id;
      } catch (e) {
        this.status.createCustomer = false;
        console.log("Failed initCreateStripeCustomer");
        console.log(e);
      }
    },
    async initPaymentIntent() {
      try {
        const response = await fetcher.post("/payment_intents", {
          lodging_id: this.lodging.slug,
          room_id: this.item.is_entire ? null : this.item.id,
          stripe_customer_id: this.userInfo.stripe_customer_id,
          reservation: {
            checkin: this.from,
            check_out: this.to,
            amount_carbon_offset: this.compensationToPay,
          },
          user: this.userInfo,
        });
        this.status.paymentIntent = true;
        this.paymentIntentId = response.data.payment_intent_id;
        this.clientSecret = response.data.client_secret;
      } catch (e) {
        this.status.paymentIntent = false;
        console.log("Failed initPaymentIntent");
        console.log(e);
      }
    },
    async initConfirmCardPayment() {
      try {
        const response = await this.stripe.confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.cardNumber,
          },
        });
        if (response.error) {
          console.log("Failed confirmCardPayment");
          console.log(response.error);
          this.status.confirmCardPayment = false;
          this.displayError.textContent = response.error.message;
        } else {
          this.status.confirmCardPayment = true;
        }
      } catch (e) {
        this.status.confirmCardPayment = false;
        console.log("Failed initConfirmCardPayment");
        console.log(e);
      }
    },
    async handlePayment() {
      // FROM HERE
      this.displayError.textContent = "";
      this.isProcessingPayment = true;

      await this.initCreateCardToken();

      if (!this.hasStatusError()) {
        if (!this.userInfo.stripe_customer_id) {
          await this.initCreateStripeCustomer();
        } else {
          await this.addNewSripeCard();
        }
      }

      if (!this.hasStatusError() && this.compensationToPay > 0) {
        await this.initPaymentIntent();
        await this.initConfirmCardPayment();
      }

      if (!this.hasStatusError()) {
        if(this.accomodationCode){
          await this.ReqAccomBook();
        }
      }
      // TO HERE
      setTimeout(() => {
        if (!this.hasStatusError()) {
          this.createReservation();
        }}, 1000);
      // Update loyalty user points
      if(this.loyalty_token.length === 0){
        if(this.loyaltyUser){
          this.updateLoyaltyPoints(false);
        }
      }else{
        if(this.loyaltyUser){
          this.updateLoyaltyPoints(true);
        }
        if(this.loyaltyCode.code_type === 1 && this.loyaltyCode.code_type_trigger <= this.numberOfNights){
          console.log('IN STATUS BY CODE');
          this.updateUserStatusByCode(this.loyaltyCode.code_type_description);
        } else if(this.loyaltyCode.code_type === 0 && this.loyaltyCode.code_type_trigger <= this.numberOfNights){
          console.log('IN TOKEN BY CODE');
          this.updateUserTokenByCode(this.loyaltyCode.code_type_description);
        }
      }
      this.isProcessingPayment = false;
    },
    hasStatusError() {
      const error = Object.values(this.status).find((el) => el === false);
      return error === false ? true : false;
    },
    formatChilds() {
      let enfant = " Enfant";
      if (this.childs > 1) {
        enfant += "s";
      }
      return this.childs + enfant;
    },
    formatAdults() {
      let adulte = " Adulte";
      if (this.adults > 1) {
        adulte += "s";
      }
      return this.adults + adulte;
    },
    async fetchEvergreen() {
      try {
        const responseEvergreen = await fetcher.get(
            "/programme-evergreen-demarche-environnementale/"
        );
        this.evergreens = await responseEvergreen.data;
      } catch (e) {
        console.error(e);
      }
    },
    askForPosition() {
      navigator.geolocation.getCurrentPosition(
          (el) => {
            this.userLatitude = el.coords.latitude;
            this.userLongitude = el.coords.longitude;
          },
          (_e) => {
            try {
              const response = fetcher.get("/carbon_offsets/distance", {
                params: { id: this.lodging.id, city: this.userInfo.city },
              });
              this.distanceBetween = response.data;
            } catch (e) {
              console.error(e);
            }
          }
      );
    },
    fromPointsToPrice(e) {
      this.compensationPointToEarn = parseInt(e.target.value, 10);
      this.compensationToPay = ((this.compensationPointToEarn * 15) / 50).toFixed(2);
      this.compensationPointToEarn =
          parseInt(e.target.value, 10) > 1000 ? 1000 : parseInt(e.target.value, 10);
    },
    fromPriceToPoints(e) {
      this.compensationToPay = parseInt(e.target.value, 10);
      this.compensationPointToEarn = ((this.compensationToPay * 50) / 15).toFixed();
      this.compensationPointToEarn =
          parseInt(e.target.value, 10) > 1000 ? 1000 : parseInt(e.target.value, 10);
    },
    chooseTransportEngine(compensation, id) {
      this.selectedTransportEngin = id;
      const choosed = this[compensation];

      this.totalCompensationCarbonOffset =
          choosed.compensation_carbon_offset +
          this.compensationByNightsAndTravelers.compensation_carbon_offset;
      this.totalCompensationAmount =
          choosed.compensation_amount + this.compensationByNightsAndTravelers.compensation_amount;
    },
    numberOfKm() {
      const radlat1 = (Math.PI * this.lodging.latitude) / 180;
      const radlat2 = (Math.PI * this.userLatitude) / 180;
      const theta = this.lodging.longitude - this.userLongitude;
      const radtheta = (Math.PI * theta) / 180;
      this.distanceBetween =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      this.distanceBetween = Math.acos(this.distanceBetween);
      this.distanceBetween = (this.distanceBetween * 180) / Math.PI;
      this.distanceBetween = this.distanceBetween * 60 * 1.1515;
      this.distanceBetween = this.distanceBetween * 1.609344;
    },
    formatDate(date) {
      const set = new Date(date);
      const options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return set.toLocaleString("fr-FR", options);
    },
    toDate(date){
      return new Date(date);
    },
    formatDateSyntax(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
      return `${day}/${month}/${year}`;
    },
    formatDateSyntaxtwoo(date) {
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
      const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
      return `${day}-${month}-${year}`;
    },
    changeStep(step) {
      switch (step) {
        case 1:
          let bookingWindow = null;
          if (this.from) {
            const dateIn = new Date(`${this.from}`);
            const today = new Date();
            const timeDifference = dateIn - today;
            bookingWindow = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
          }
          const lodgingTypeMapping = {
            Hotel: "hôtels",
            Villa: "maisons et villas",
            Guesthouse: "chambres d'hôtes",
            Lodge: "lodges et lieux insolites",
            Appartment: "appartements",
            Gite: "maisons et villas",
            Guestroom: "chambres d'hôtes",
            Other: "lodges et lieux insolites"
          };
          this.scrollToSection('step-1');
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'add_personal_info',
            ecommerce: {
              currency: 'EUR',
              value: parseFloat((this.accomodationCode && this.loyalty_token?.length === 0) ?  this.totalTtc : (this.accomodationCode && this.loyalty_token?.length != 0) ? this.totalTtcLoyalty  : this.loyalty_token.length === 0 ? this.totalTtcNotKoedia : this.totalTtcLoyaltyNotKoedia),
              number_of_nights: this.numberOfNights || 1,
              date_in: this.formatDateSyntax(this.toDate(this.from)),
              date_out: this.formatDateSyntax(this.toDate(this.to)),
              number_of_people: parseInt(this.adults || 0) + parseInt(this.childs || 0),
              number_of_adult: this.adults || 0,
              number_of_child: this.childs || 0,
              booking_window: bookingWindow || 2,
              items: [{
                item_name: this.lodging.name,
                item_id: this.lodging.id,
                price: parseFloat((this.accomodationCode && this.loyalty_token?.length === 0) ?  this.totalTtc : (this.accomodationCode && this.loyalty_token?.length != 0) ? this.totalTtcLoyalty  : this.loyalty_token.length === 0 ? this.totalTtcNotKoedia : this.totalTtcLoyaltyNotKoedia),
                item_city: this.lodging.city ? this.lodging.city.name : 'Pruniers-en-Sologne' ,
                item_region: this.lodging && this.lodging.region ? this.lodging.region.name : 'Occitanie',
                item_category: 'Nos hébergements',
                item_variant: lodgingTypeMapping[this.lodging.type] || "hôtels",
              }]
            },
          });
          window.dataLayer.push({
            event: 'page_view',
            page: {
              page_type: 'personal_info',
              page_category: 'reservation',
            },
            user: {
              email: "",
            },
          });
          this.informationsCompleted = true;
          if (this.messageBody !== null) {
            this.sendMessage();
          }
          if (this.distanceBetween === 0) {
            this.numberOfKm();
          }
          this.fetchEmissionByNightsAndTravelers();
          this.step = 2;
          break;
        case 2:
          if (this.distanceBetween === 0) {
            this.numberOfKm();
          }
          this.fetchEmissionByNightsAndTravelers();
          this.step = step;
          break;
        case 3:
          this.step = step;
          this.scrollToSection('step-3');
          break;
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);

      if (section) {
        const headerOffset = 100; // Adjust this value based on your header height
        const elementPosition = section.getBoundingClientRect().top;
        const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    },
    async fetchEmissionByNightsAndTravelers() {
      try {
        const response = await fetcher.get("/carbon_offsets/all_info", {
          params: {
            number_of_nights: this.numberOfNights,
            number_of_travelers: this.adults + this.childs,
            number_of_km: this.distanceBetween,
            national: "true",
          },
        });
        this.compensationByNightsAndTravelers = response.data.hotel;
        this.compensationByPlane = response.data.plane;
        this.compensationByTrain = response.data.train;
        this.compensationByCar = response.data.car;
        this.totalCompensationCarbonOffset =
            this.compensationByNightsAndTravelers.compensation_carbon_offset;
        this.totalCompensationAmount = this.compensationByNightsAndTravelers.compensation_amount;
      } catch (e) {
        console.error(e);
      }
    },
    async fetchLodging() {
      try {
        const response = await fetcher.get("/lodgings/" + this.lodging.slug);
        this.lodgingMount = response.data;
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    hasTravelGuideImage() {
      return this.lodging?.lodging_description?.travel_guide_image && this.lodging?.lodging_description?.travel_guide_image !== 'null';
    },
    travelGuideImageUrl() {
      return this.hasTravelGuideImage ? this.lodging.lodging_description.travel_guide_image : '';
    },
    travelGuideLink() {
      const guideLink = this.lodging?.lodging_description?.travel_guide_link;
      return guideLink && guideLink !== 'null' ? guideLink : '#';
    },
    isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
    },
    tokenToResa(){
      if(this.loyalty_token.length > 0){
        const token = this.loyalty_token[0];
        if(token === 'first')
          return 50;
        else if(token === 'second')
          return 100;
        else if(token === 'third')
          return 150;
        else if(token === 'fourth')
          return 200;
        else if(token === 'fifth')
          return 250;
        else if(token === 'sixth')
          return 300;
      }
    },
    totalTtcLoyaltyNotKoedia(){
      let sum = 0;
      let sumArr = [];

      console.log('IN NOT KOEDIA');
      console.log(this.loyalty_token);

      sumArr = this.loyalty_token.map(element => {
        if (element.includes('first')) {
          return 50;
        } else if (element.includes('second')) {
          return 100;
        } else if (element.includes('third')){
          return 150;
        } else if (element.includes('fourth')){
          return 200;
        } else if (element.includes('fifth')) {
          return 250;
        } else if (element.includes('sixth')) {
          return 300;
        }
      });

      for (let i = 0; i < sumArr.length; i++) {
        sum += sumArr[i];
      }
      return (this.totalTtcNotKoedia - sum).toFixed(2);
    },
    totalTtcLoyalty(){
      let sum = 0;
      let sumArr = [];


      console.log('IN KOEDIA');
      console.log(this.loyalty_token);

      sumArr = this.loyalty_token.map(element => {
        if (element.includes('first')) {
          return 50;
        } else if (element.includes('second')) {
          return 100;
        } else if (element.includes('third')){
          return 150;
        } else if (element.includes('fourth')){
          return 200;
        } else if (element.includes('fifth')) {
          return 250;
        } else if (element.includes('sixth')) {
          return 300;
        }
      });

      console.log('SOMME ARRAY');
      console.log(sumArr);

      for (let i = 0; i < sumArr.length; i++) {
        sum += sumArr[i];
      }
      console.log('SOMME');
      console.log(sum);
      return (this.totalTtc - sum).toFixed(2);;
    },
    userRemainingPoints(){
      if(this.loyaltyUser.loyalty_points < 500){
        return 500 - this.loyaltyUser.loyalty_points;
      }
      else if(this.loyaltyUser.loyalty_points >= 500 && this.loyaltyUser.loyalty_points < 1000){
        return 1000 - this.loyaltyUser.loyalty_points;
      }
      else if(this.loyaltyUser.loyalty_points >= 1000 && this.loyaltyUser.loyalty_points < 1500){
        return 1500 - this.loyaltyUser.loyalty_points;
      }
      else if(this.loyaltyUser.loyalty_points < 2500) {
        return 2500 - this.loyaltyUser.loyalty_points;
      }
    },
    userRemainingStatus(){
      if(this.loyaltyUser.loyalty_points < 500){
        return "Green Trotter";
      }
      else if(this.loyaltyUser.loyalty_points >= 500 && this.loyaltyUser.loyalty_points < 1000){
        return "Trotter Plus";
      }
      else if(this.loyaltyUser.loyalty_points >= 1000 && this.loyaltyUser.loyalty_points < 1500){
        return "Trotter Premium";
      }
      else if(this.loyaltyUser.loyalty_points < 2500) {
        return "Trotter Infinite";
      }
    },
    /*numberLoyaltyNights(){
        let loyalty_points = 0;
        if(this.loyaltyUser){
          loyalty_points = this.loyaltyUser.loyalty_points;
        }
        return Math.floor((loyalty_points + this.pointsDiff) / 100);
    },*/
    pointsDiff(){
      console.log('this.to');
      console.log(this.to);
      console.log('this.from');
      console.log(this.from);
      return 50 * Math.ceil((this.toDate(this.to) - this.toDate(this.from)) / (1000 * 60 * 60 * 24));
    },
    cancelationPolicy() {
      if (this.isRefundable) {
        console.log('TEST');
        console.log(this.rateDetails.cancellation_fees_policy);
        console.log('type of fees_policy');
        console.log('It is of type:', typeof this.rateDetails.cancellation_fees_policy);

        if(Array.isArray(this.rateDetails.cancellation_fees_policy)){
          if(!this.rateDetails.cancellation_fees_policy[0]){
            return null;
          }else{
            const refundableAmount = parseFloat(
                this.rateDetails.cancellation_fees_policy[0].amount["@rack"]
            );
            const refundablePercent = parseInt((refundableAmount / this.totalTtc) * 100);
            const refundableToDay = this.rateDetails.cancellation_fees_policy[0].to_day;
            let refundableToTime = this.rateDetails.cancellation_fees_policy[0].to_time;

            if (refundableToTime === "0") {
              refundableToTime = "minuit";
            } else {
              refundableToTime = `${refundableToTime} heures`;
            }

            return `Remboursable à hauteur de ${refundablePercent}% jusqu'à ${refundableToDay} jours avant l'arrivée (${refundableToTime})`;
          }
        }else{
          if(!this.rateDetails.cancellation_fees_policy){
            return null;
          }else{
            const refundableAmount = parseFloat(
                this.rateDetails.cancellation_fees_policy.amount["@rack"]
            );
            const refundablePercent = parseInt((refundableAmount / this.totalTtc) * 100);
            const refundableToDay = this.rateDetails.cancellation_fees_policy.to_day;
            let refundableToTime = this.rateDetails.cancellation_fees_policy.to_time;

            if (refundableToTime === "0") {
              refundableToTime = "minuit";
            } else {
              refundableToTime = `${refundableToTime} heures`;
            }

            return `Remboursable à hauteur de ${refundablePercent}% jusqu'à ${refundableToDay} jours avant l'arrivée (${refundableToTime})`;
          }
        }
      }
    },
    isRefundable() {
      if (this.rateDetails) {
        if(this.rateDetails.rate_information){
          return this.rateDetails.rate_information.refundable;
        }
      }
    },
    paymentButtonCaption() {
      if (this.hasStatusError()) {
        return "Erreur de validation, veuillez réessayer";
      } else if (this.isProcessingPayment) {
        return "Paiement en cours...";
      } else {
        return `Valider mon voyage et payer ${this.compensationToPay}€`;
      }
    },
    priceWithTax() {
      return (this.total + this.tax.tourist_tax + this.tva).toFixed(2);
    },
    totalForStripe() {
      const num = this.priceWithTax.toString().replace(".", "");
      return parseInt(num);
    },
    guideSectionNumber() {
      if (this.lodging) {
        const guideImageLink = this.lodging.lodging_description.travel_guide_image;
        return guideImageLink && guideImageLink !== "null" ? "4." : "3.";
      } else {
        return "3.";
      }
    },
  },
  async mounted() {
    // this.fetchLodging();
    console.log('this.lodging', this.lodging)
    this.$root.$on("openModalLogin", (_) => {
      this.showModalLogin = true;
      this.showModal = true;
    });

    this.$root.$on("openModalSignup", (_) => {
      this.showModalLogin = false;
      this.showModal = true;
    });

    if (window.location.search === "?signin" && !this.user) {
      this.showModal = true;
    }

    if (window.location.search === "?admin_login" && !this.user) {
      this.showAdminModal = true;
    }
    this.fetchEvergreen();
    this.askForPosition();
    this.initStripe();
    this.fetchUser();
    this.fetchLoyaltyUser();
    this.getRateDetails();
    let bookingWindow = null;

    if (this.from) {
      const dateIn = new Date(`${this.from}`);
      const today = new Date();
      const timeDifference = dateIn - today;
      bookingWindow = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    }
    const lodgingTypeMapping = {
      Hotel: "hôtels",
      Villa: "maisons et villas",
      Guesthouse: "chambres d'hôtes",
      Lodge: "lodges et lieux insolites",
      Appartment: "appartements",
      Gite: "maisons et villas",
      Guestroom: "chambres d'hôtes",
      Other: "lodges et lieux insolites"
    };
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event : 'begin_checkout',
      ecommerce : {
        currency : 'EUR',
        value: parseFloat((this.accomodationCode && this.loyalty_token?.length === 0) ?  this.totalTtc : (this.accomodationCode && this.loyalty_token?.length != 0) ? this.totalTtcLoyalty  : this.loyalty_token.length === 0 ? this.totalTtcNotKoedia : this.totalTtcLoyaltyNotKoedia),
        number_of_nights: Math.ceil((this.toDate(this.to) - this.toDate(this.from)) / (1000 * 60 * 60 * 24)) || 1,
        date_in: this.formatDateSyntax(this.toDate(this.from)),
        date_out: this.formatDateSyntax(this.toDate(this.to)),
        number_of_people: parseInt(this.adults || 0) + parseInt(this.childs || 0),
        number_of_adult: this.adults || 0,
        number_of_child: this.childs || 0,
        booking_window: bookingWindow || 2,
        items: [{
          item_name: this.lodging.name,
          item_id: this.lodging.id,
          price:  parseFloat((this.accomodationCode && this.loyalty_token?.length === 0) ?  this.totalTtc : (this.accomodationCode && this.loyalty_token?.length != 0) ? this.totalTtcLoyalty  : this.loyalty_token.length === 0 ? this.totalTtcNotKoedia : this.totalTtcLoyaltyNotKoedia),
          item_city: this.lodging.city ? this.lodging.city.name : 'Pruniers-en-Sologne' ,
          item_category: 'Nos hébergements',
          item_variant: lodgingTypeMapping[this.lodging.type] || "hôtels",
          item_region: this.lodging && this.lodging.region ? this.lodging.region.name : 'Occitanie'
        }]
      }
    });
    const hashedEmail = await this.hashEmail(this.userInfo.email  || '');

    window.dataLayer.push({
      event: 'page_view',
      page: {
        page_type: 'start_checkout',
        page_category: 'reservation',
      },
      user: {
        email: hashedEmail || '',
      },
    });
  },
};
</script>
<style lang="scss" scoped>
.custom-line {
  border: none;
  border-top: 2px solid black;
  margin: 20px 0;
  width: 100%;
}

.link-container {
  display: flex;
  justify-content: center;
  padding-left: 0;

  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
    margin: 2rem 0;
  }

  .link-pages {
    margin-top: 5rem;
    margin-bottom: 3rem;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      white-space: nowrap;
      margin: 0.5rem 0.5rem 0 0;
    }
  }

  .list-link {
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      padding-left: 0;
    }
  }

  a,
  p {
    text-decoration: none;
    font-family: $Raleway;
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    margin-right: 3rem;
  }

  .picto {
    margin-top: 5rem;
    margin-bottom: 3rem;
    margin-right: 1rem;
    width: 2em;
    height: 1em;

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
      margin: 0.5rem;
    }
  }
  .paypal {
    img {
      margin-top: 5.2rem;
      margin-bottom: 3rem;
      margin-right: 1rem;
      width: 73px;
      height: 20px;

      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        margin: 0.5rem;
      }
    }
  }
  .amex {
    img {
      margin-top: 4.7rem;
      margin-bottom: 3rem;
      margin-right: 1rem;
      width: 2em;
      height: 2.1em;

      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        margin: 0.5rem;
      }
    }
  }
}
.credit-card {
  margin-bottom: 2rem;
  width: 218px;
  height: 60px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #000000;
  border-radius: 30px;
}
#modal-signup{
  .modal-mask {
    position:fixed;
    overflow:scroll;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: table;
    transition: opacity 0.3s ease;
  }
  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }
  .modal-content {
    width: 721px;
    height: fit-content;
    margin: 51px auto;
    padding: 1rem 1rem;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }
  .modal-content-form{
    padding: 3rem 7rem;
    overflow-y: scroll;
    height: 58vh;
  }
  .closing{
    display: flex;
    justify-content: flex-end;
  }

  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  .login{
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;
  }

  .form-signup{
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-item{
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      input{
        width: 350px;
        height: 60px;
        padding: 2px 23px;
      }
    }
  }

  .form-check{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    padding-left: 4rem;

    .form-item{
      display: flex;
      align-items: baseline;
      margin-bottom: 2rem;
      input{
        margin-right: 1rem;
      }
    }
  }
  .hortense-btn-primary{
    width: 349px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
  }

  .social-connection{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-connection-btn{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 3rem;
    .social-btn{
      display: flex;
      justify-content: center;
      width: 26%;
      padding: 1rem 1rem;
      box-shadow: 1px 2px 4px grey;
      border-radius: 4px;
      a{
        font-family: $Roboto;
        text-decoration: none;
        font-size: 16px;
        color:grey;
      }
      img{
        margin-right: 1rem;
        width: 24px;
      }
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  #modal-signup{
    .modal-content {
      width: 100%;
      height: fit-content;
      margin: 0;
      padding: 1rem 1rem;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }
    .closing{
      display: flex;
      justify-content: center;
    }
    .modal-content-form{
      padding: 3rem 0;
      overflow-y: scroll;
      overflow-x: hidden;
      height: 58vh;
    }
    .form-check{
      display: flex;
      flex-direction: column;
      align-items: center;
      align-items: flex-start;
      padding-left: 0;

      .form-item{
        display: flex;
        align-items: baseline;
        margin-bottom: 2rem;
        input{
          margin-right: 1rem;
        }
      }
    }
    .social-connection-btn{
      .social-btn{
        img{
          margin-right: 0.5rem;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
//  fin media modal sign up

#modal-login{
  .modal-mask {
    position:fixed;
    overflow:scroll;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  .modal-content {
    width: 721px;
    height: fit-content;
    margin: 20px auto;
    padding: 1rem 1rem;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .modal-content-form{
    padding: 3rem 7rem;
    overflow-y: scroll;
    height: 58vh;
  }
  .closing{
    display: flex;
    justify-content: flex-end;
  }


  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  .login{
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;
  }

  .form-signup{
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-item{
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
    }
    input{
      width: 350px;
      height: 60px;
      padding: 2px 23px;
    }
  }

  .form-check{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    padding-left: 4rem;

    .form-item{
      display: flex;
      align-items: baseline;
      margin-bottom: 2rem;
      input{
        margin-right: 1rem;
      }
    }
  }
  .hortense-btn-primary{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
  }
  .social-connection{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-connection-btn{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 3rem;
    .social-btn{
      display: flex;
      justify-content: center;
      width: 26%;
      padding: 1rem 1rem;
      box-shadow: 1px 2px 4px grey;
      border-radius: 4px;
      a{
        font-family: $Roboto;
        text-decoration: none;
        font-size: 16px;
        color:grey;
      }
      img{
        margin-right: 1rem;
        width: 24px;
      }
    }
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  #modal-subcribe .become {
    width: 60% !important;
  }
  #modal-login{
    .modal-mask {
      position:fixed;
      overflow:scroll;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      display: table;
      transition: opacity 0.3s ease;
    }
    .modal-content {
      width: 100%;
      height: fit-content;
      margin: 0;
      padding: 1rem 1rem;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }
    .closing{
      display: flex;
      justify-content: center;
    }
    .modal-content-form{
      padding: 3rem 0;
      overflow-y: scroll;
      height: 58vh;
    }
    .social-connection-btn{
      .social-btn{
        img{
          margin-right: 0.5rem;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}

// fin media

#modal-login-partner, #modal-become-host, #modal-subcribe{
  .modal-mask {
    position:fixed;
    overflow:scroll;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    height: 454px;
  }

  .modal-content {
    width: 721px;
    height: fit-content;
    margin: 20px auto;
    padding: 1rem 1rem;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .modal-content-form{
    padding: 3rem 6rem;
    overflow-y: scroll;
    height: 81vh;
  }
  .header-modal{
    margin-bottom: 4rem;
  }

  .become{
    margin-top: 3rem;
    justify-content: center;
    align-items: baseline;
  }

  .knew-more{
    margin-top: 3rem;
    li{
      font-family: $Raleway;
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 1rem;
    }
  }

  .closing{
    display: flex;
    justify-content: flex-end;
  }


  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  .login{
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;
  }

  .form-signup{
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-item{
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
      textarea{
        width: 350px;
        padding: 2px 23px;
        height: 160px;
      }
    }
    input{
      width: 350px;
      height: 60px;
      padding: 2px 23px;
    }
  }

  .form-check{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    padding-left: 4rem;

    .form-item{
      display: flex;
      align-items: baseline;
      margin-bottom: 2rem;
      input{
        margin-right: 1rem;
      }
    }
  }
  .hortense-btn-primary{
    width: 349px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
  }
}
#modal-subcribe{
  .modal-mask {
    position:fixed;
    overflow:scroll;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    transition: opacity 0.3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    height: 100vh;
  }

  .modal-container {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    margin: 0;
    padding: 0;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .modal-content-form{
    padding: 3rem 3rem 3rem 6rem;
    overflow-y: scroll;
    height: 100vh;
    width: 600px;
    background-color: white;
  }
  .header-modal{
    margin-bottom: 4rem;
  }

  .become{
    margin-top: 5rem;
    justify-content: center;
    align-items: baseline;
  }

  .knew-more{
    margin-top: 3rem;
    li{
      font-family: $Raleway;
      font-weight: 400;
      font-size: 15px;
      margin-bottom: 1rem;
    }
  }

  .closing{
    display: flex;
    justify-content: flex-end;
  }


  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  .login{
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;
  }

  .form-signup{
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .form-item{
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
    }
    input{
      width: 350px;
      height: 60px;
      padding: 2px 23px;
    }
  }

  .form-check{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    padding-left: 4rem;

    .form-item{
      display: flex;
      align-items: baseline;
      margin-bottom: 2rem;
      input{
        margin-right: 1rem;
      }
    }
  }
  .hortense-btn-primary{
    width: 349px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
  }
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  #modal-login-partner, #modal-become-host{
    .modal-content {
      width: 88%;
      height: fit-content;
      margin: 0;
      padding: 2rem 1rem;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }
    .closing{
      display: flex;
      justify-content: center;
    }
    .modal-content-form{
      padding: 3rem 2rem;
      overflow-y: scroll;
      height: 90vh;
    }
  }
  #modal-subcribe{
    .modal-content-form{
      padding: 0;
      overflow-y: scroll;
      width: 100%;
      background-color: white;
    }
    .closing{
      display: flex;
      justify-content: flex-start;
    }
  }
}


#password-new{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .container-index{
    align-items: center;
  }
}

#login{
  .content {
    margin-top: 100px;
    width: 721px;
    height: fit-content;
    margin: 20px auto;
    padding: 1rem 1rem;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
  }

  .content-form{
    padding: 3rem 7rem;
  }
  .closing{
    display: flex;
    justify-content: flex-end;
  }


  .modal-header h3 {
    margin-top: 0;
    color: #42b983;
  }

  .modal-body {
    margin: 20px 0;
  }

  .modal-default-button {
    float: right;
  }

  .login{
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;
  }

  .form-signup{
    display: flex;
    flex-direction: column;
    align-items: center;

    .form-item{
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
    }
    input{
      width: 350px;
      height: 60px;
      padding: 2px 23px;
    }
  }

  .form-check{
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: flex-start;
    padding-left: 4rem;

    .form-item{
      display: flex;
      align-items: baseline;
      margin-bottom: 2rem;
      input{
        margin-right: 1rem;
      }
    }
  }
  .hortense-btn-primary{
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
  }
  .social-connection{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .social-connection-btn{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 3rem;
    .social-btn{
      display: flex;
      justify-content: center;
      width: 26%;
      padding: 1rem 1rem;
      box-shadow: 1px 2px 4px grey;
      border-radius: 4px;
      a{
        font-family: $Roboto;
        text-decoration: none;
        font-size: 16px;
        color:grey;
      }
      img{
        margin-right: 1rem;
        width: 24px;
      }
    }
  }
}

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  #login{
    .content {
      width: 100%;
      height: fit-content;
      margin: 0;
      padding: 1rem 1rem;
      background-color: #fff;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
    }

    .content-form{
      padding: 3rem 0;
      overflow-y: scroll;
      height: 58vh;
    }
    .social-connection-btn{
      .social-btn{
        img{
          margin-right: 0.5rem;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}
.exclusive-options-resa {
  background-color:  #cddfd3;
  text-transform: none;
  h5{
    font-family: $Merriweather;
    font-weight: 400;
    font-style: italic;
    font-size: 20px;
    text-transform: none;
  }
  /*p {
    font-family: $Raleway;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    text-transform: none;
  }*/
  ul{
    list-style: none;
    list-style-image: url('../../images/Icotype_puce.svg');
    font-family: $Raleway;
    font-weight: 400;
    font-size: 13px;
  }
}

.exclusive-hiw-resa {
  border-color: #cddfd3;
  border-style: solid;
  border-width: 1px;
  h5{
    font-family: $Merriweather;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
  }
  /*p {
    font-family: $Raleway;
    font-weight: 400;
    font-size: 15px;
    color: #000000;
    text-transform: none;
  }*/
}
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .exclusive-options-resa {
    background-color:  #cddfd3;
    //border-radius: 20px;
    text-transform: none;
    //width: 80%;
    h5{
      font-family: $Merriweather;
      font-weight: 400;
      font-style: italic;
      font-size: 20px;
      text-transform: none;
    }
    /*p {
      font-family: $Raleway;
      font-weight: 400;
      font-size: 15px;
      color: #000000;
      text-transform: none;
    }*/
    ul{
      list-style: none;
      list-style-image: url('../../images/Icotype_puce.svg');
      font-family: $Raleway;
      font-weight: 400;
      font-size: 13px;
    }
  }

  .exclusive-hiw-resa {
    border-color: #cddfd3;
    border-style: solid;
    border-width: 1px;
    //border-top-right-radius: 20px 20px;
    //border-bottom-right-radius: 20px 20px;
    //border-bottom-left-radius: 20px 20px;
    /*p {
      font-family: $Raleway;
      font-weight: 400;
      font-size: 15px;
      color: #000000;
      text-transform: none;
    }*/
    h5{
      font-family: $Merriweather;
      font-weight: 600;
      font-size: 20px;
      text-transform: uppercase;
    }
  }
}
.section-payment button {
  width: 100%;
}

.card-stripe{
  .field{
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0.5rem 1rem;
  }
}

#card-errors {
  color: red;
}

.card-stripe {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card-stripe .field {
  display: flex;
  align-items: center;
  width: 350px;
  height: 60px;
  border: 1px solid grey;
  padding: 2rem 1rem;
  margin-bottom: 1rem;
}

.card-stripe .field.half-width {
  width: 47%;
}

.card-stripe .field.quarter-width {
  width: calc(25% - 10px);
}

.card-stripe .baseline {
  position: absolute;
  width: 100%;
  height: 1px;
  left: 0;
  bottom: 0;
  background-color: #cfd7df;
  transition: background-color 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.card-stripe .input {
  width: 100%;
  color: #32325d;
  background-color: transparent;
}

.card-stripe input {
  appearance: none;
  outline: none;
  border-style: none;
}

.border-green {
  border: 2px solid green;
}

.border-red {
  border: 2px solid red;
}
.description-main-section {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  .travel-guide-description {
    margin-bottom: 10px;
  }
  .image-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;

    .travel-guide-img {
      width: 200px;
      height: 230px;
    }
    .travel-guide-image-text {
      text-align: center;
      font-size: 14px;
      font-weight: lighter;
      font-style: italic;
      margin-top: 5px;
    }
  }
  .travel-guide-check-section {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border: 1px solid #2f4f4f;
    background-color: white;
    padding: 8px 5px 0px 0px;
    color: #2f4f4f;
    font-family: Arial, sans-serif;
    font-size: 12px;
    outline: none;
    width: 230px;
    margin-top: 40px;
    margin-bottom: 20px;

  }
}
.mobile-reserve{
    padding-left: 44%;
  }
@media (max-width: 768px) {
  .description-main-section {
    flex-direction: column;
  }

  .mobile-reserve{
    padding-left: 44%;
  }

  .image-section {
    order: -1;
    margin-bottom: 20px;
  }

  .description-section {
    width: 100%;
  }

  .travel-guide-description {
    width: 100%;
  }
}

.__container{
  width: 84vw;
  height: 100%;
}
.div-border{
  border: 1px solid black;
  border-top: none;
}

.__payment_btn{
  outline: none;
  border: 1px solid #435f53;
  color: #435f53;
  background: transparent;
  padding: 0 12px;
  font-size: 12px;
  text-transform: uppercase;
  min-width: max-content;
}
._button_container{
  gap: 20px;
}
.__title{
  font-size: 18px;
  font-weight: bold;
}
.__title_active{
  display: inline-block;
  background: #cfe5dc;
  color: green;
  font-weight: bold;
}
.light_container{
  background: #FFFFFF;
}
._sub_title_right {
  background: black;
  color: white;
  display: inline-block;
  font-weight: bold;
}
._sub_title {
  background: #435f53;
  color: white;
  display: inline-block;
  font-weight: bold;
}
.payment-para{
  width: 540px;
}
.__btn___secondary {
  background: #435f53;
  color: white;
  outline: none;
  border: none;
  padding: 10px 24px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
}
.__user_points{
  border: 1px solid black;
  width: 160px;
  padding: 10px;

  p{
    font-size: 14px;
    font-weight: 600;
    margin: 0;
  }
}
.__form_input:before{
  border-radius: 10px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .col-info-form {
    order:1;
    margin-top:3rem;
  }
  .mobile-reserve{
    padding-left: 0px !important;
  }
}
</style>
<style
 lang="scss"
scope="app/assets/stylesheets/components/exclusive-gallery"></style>
<style lang="scss" scope="app/assets/stylesheets/components/resanew-userinfo">
</style>