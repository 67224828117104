<template>
  <div id='message-table' class="profile-table">
    <table id="profiles" v-if='chatRooms.length'>
      <tr class="raleway-bold-h3">
        <th>Envoyé par</th>
        <th>Objet</th>
        <th>Reçu le</th>
        <th>En attente depuis</th>
      </tr>
      <template v-for='data in chatRooms'>
        <tr class="raleway-reg-p13" @click='redirectTo(data.id)'>
          <td>Hortense</td>
          <td>{{ data.subject }}</td>
          <td>{{ formatDate(data.created_at) }}</td>
          <td>{{ responseSince(data.created_at)}} minutes</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import toDate from 'packs/shared/formatDate.js';
import fetcher from 'packs/shared/api';

export default {
  name: 'MessageTable',
  props: {
    messagesType: String,
  },
  data() {
    return {
      chatRooms: [],
    };
  },
  methods: {
    formatDate(value) {
      return toDate.toDate(value);
    },
    responseSince(value) {
      const diffTime = Math.abs(Date.now() - Date.parse(value));
      const diffMinutes = Math.ceil(diffTime / (60 * 60 * 24));
      return diffMinutes;
    },
    async fetchMessages() {
      try {
        const response = await fetcher.get('/chat_rooms/list?q=' + this.messagesType);
        this.chatRooms = response.data;
      } catch (e) {
        console.error(e);
      }
    },
    redirectTo(id) {
      Turbolinks.visit('/chat_rooms/' + id);
    },
  },
  mounted() {
    this.fetchMessages();
  },
};
</script>
