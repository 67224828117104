<template>
  <div id="card-statut">
    <div class="head-card">
      <p class="raleway-reg-p24">Votre statut {{ userRealStatus }}</p>
      <p v-if="userRemainingPoints < 2500" class="raleway-reg-p15">Il vous manque {{ userRemainingPoints }} points pour arriver au statut {{ userRemainingStatus }} et bénéficier d’avantages exclusifs supplémentaires.</p>
    </div>
      <div class="statut-section">
        <div class="row row-statut">
          <div class="col-5 col-statut-point">
            <div class="points-select" v-if='this.userStatut === "Green Trotter"'>
              <img src="../../images/badge-guest.png" class="guest-badge" alt="">
              <p class="raleway-bold-p12 mb-0 text-center">Green Trotter</p>
              <p class="raleway-reg-p13 mb-0">500 points</p>
            </div>
            <div class="points" v-else>
              <img src="../../images/badge-guest.png" class="guest-badge" alt="">
              <p class="raleway-bold-p12 mb-0 text-center">Green Trotter</p>
              <p class="raleway-reg-p13 mb-0">500 points</p>
            </div>
          </div>
          <div class="col-5 col-statut-point">
            <div class="points-select" v-if='this.userStatut === "Green Trotter Plus"'>
              <img src="../../images/badge-member.png" class="member-badge" alt="">
              <p class="raleway-bold-p12 mb-0 text-center">Green Trotter Plus</p>
              <p class="raleway-reg-p13 mb-0">1000 points</p>
            </div>
            <div class="points" v-else>
              <img src="../../images/badge-member.png" class="member-badge" alt="">
              <p class="raleway-bold-p12 mb-0 text-center">Green Trotter Plus</p>
              <p class="raleway-reg-p13 mb-0">1000 points</p>
            </div>
          </div>
        </div>
        <div class="row row-statut">
          <div class="col-5 col-statut-point">
            <div class="points-select" v-if='this.userStatut === "Green Trotter Premium"'>
              <img src="../../images/badge-nomad@2x.png" class="nomad-badge" alt="">
              <p class="raleway-bold-p10 mb-0 text-center">Green Trotter Premium</p>
              <p class="raleway-reg-p13 mb-0">1500 points</p>
            </div>
            <div class="points" v-else>
              <img src="../../images/badge-member.png" class="member-badge" alt="">
              <p class="raleway-bold-p12 mb-0 text-center">Green Trotter Premium</p>
              <p class="raleway-reg-p13 mb-0">1500 points</p>
            </div>
          </div>
          <div class="col-5 col-statut-point">
            <div class="points-select" v-if='this.userStatut === "Green Trotter Infinite"'>
              <img src="../../images/badge-trotter@2x.png" class="trotter-badge" alt="">
              <p class="raleway-bold-p10 mb-0 text-center">Green Trotter Infinite</p>
              <p class="raleway-reg-p13 mb-0">2500 points</p>
            </div>
            <div class="points" v-else>
              <img src="../../images/badge-member.png" class="member-badge" alt="">
              <p class="raleway-bold-p12 mb-0 text-center">Green Trotter Infinite</p>
              <p class="raleway-reg-p13 mb-0">2500 points</p>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'card-statut',
    props: ["user"],
    data() {
      return {
        userStatut: '',
        userMount: '',
        userNextLevel: '',
        userNextStatus: '',
      }
    },
    computed: {
      userRealStatus(){
        let points = 0;
        if(this.userMount.loyalty_status === "Green Trotter"){
          points = 500;
        } else if(this.userMount.loyalty_status === "Green Trotter Plus"){
          points = 1000;
        } else if(this.userMount.loyalty_status === "Green Trotter Premium"){
          points = 1500;
        } else if(this.userMount.loyalty_status === "Green Trotter Infinite"){
          points = 2500;
        }
        if(this.userMount.loyalty_points <= points){
          return this.userMount.loyalty_status;
        } else {
          if(this.userMount.loyalty_points >= 500 && this.userMount.loyalty_points < 1000){
            return "Green Trotter Plus";
          } else if(this.userMount.loyalty_points >= 1000 && this.userMount.loyalty_points < 1500){
            return "Green Trotter Plus";
          } else if(this.userMount.loyalty_points >= 1500 && this.userMount.loyalty_points < 2500){
            return "Green Trotter Premium";
          } else if(this.userMount.loyalty_points >= 2500){
            return "Green Trotter Infinite";
          }
        }
      },
      userRemainingPoints(){
        if(this.userMount.loyalty_points < 500){
          return 500 - this.userMount.loyalty_points;
        }
        else if(this.userMount.loyalty_points >= 500 && this.userMount.loyalty_points < 1000){
          return 1000 - this.userMount.loyalty_points;
        }
        else if(this.userMount.loyalty_points >= 1000 && this.userMount.loyalty_points < 1500){
          return 1500 - this.userMount.loyalty_points;
        }
        else if(this.userMount.loyalty_points < 2500) {
          return 2500 - this.userMount.loyalty_points;
        }
      },
      userRemainingStatus(){
        if(this.userMount.loyalty_points < 500){
          return "Green Trotter";
        }
        else if(this.userMount.loyalty_points >= 500 && this.userMount.loyalty_points < 1000){
          return "Green Trotter Plus";
        }
        else if(this.userMount.loyalty_points >= 1000 && this.userMount.loyalty_points < 1500){
          return "Green Trotter Premium";
        }
        else if(this.userMount.loyalty_points < 2500) {
          return "Green Trotter Infinite";
        }
      }

    },
    methods: {
      async setUserLevel(){
        const res = await fetcher.get('/dashboard/'+this.user.slug)
        this.userMount = res.data
        this.userStatut = this.userMount.privilege.status
        this.userNextLevel = this.userMount.privilege.next_level
        this.userNextStatus = this.userMount.privilege.next_status
      },
    },
    mounted() {
      this.setUserLevel();
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>

</style>
