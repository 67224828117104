const toDate = (timestamp) => {
  return timestamp.split('T')[0]
};

const toDateTime = (timestamp, link = '') => {
  const date = toDate(timestamp);
  const time = timestamp.split('T')[1].slice(0,8)
  if (link) {
    link += ' ';
  }
  return  `${date} ${link}${time}`
};

export default { toDate, toDateTime };
