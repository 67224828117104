<template>
  <div id="destinations">
    <Searchbtn
      @show="showBanner = !showBanner"
      @top="redirectToTop"
      class="search-desti"
    ></Searchbtn>
    <Searchbanner v-if="showBanner" @close="showBanner = false"></Searchbanner>
    <div>
      <HeadDesti @destinationSelector="selectDestination"></HeadDesti>
      <component
        :is="fileNames[selectedDestination]"
        :destinations="destinations"
        v-if="!this.isLoading"
      ></component>
      <ScrollUp></ScrollUp>
    </div>
  </div>
</template>

<script>
import HeadDesti from "./HeadDesti.vue";
import Francecard from "./Francecard.vue";
import ScrollUp from "components/btns/ScrollUp.vue";
import fetcher from "packs/shared/api.js";
import Searchbtn from "components/btns/Searchbtn.vue";
import Searchbanner from "components/btns/Searchbanner.vue";

export default {
  name: "destinations",
  components: { HeadDesti, ScrollUp, Francecard, Searchbtn, Searchbanner },
  data() {
    return {
      showBanner: false,
      selectedDestination: "France",
      destinations: null,
      isLoading: true,
      fileNames: {
        "Caraïbes": "Caraibes",
        "Polynésie Française": "Polynesias",
        "Afrique": "Africas",
        "Océan Indien": "Indians",
        "France": "Francecard",
        "Europe": "Europe",
        "Americas": "Americas",
        "Asia": "Asia",
      },
    };
  },
  methods: {
    redirectToTop() {
      window.scrollTo(0, "#navbar");
    },
    selectDestination(destination) {
      this.isLoading = true;
      this.selectedDestination = destination;
      this.fetchDestinations();
    },
    isSelectDestination(name) {
      return this.selectedDestination === name && this.destinations;
    },
    async fetchDestinations() {
      try {
        const response = await fetcher.get(
          `/destination?country=${this.selectedDestination}`
        );
        this.destinations = response.data;
        this.isLoading = false;
      } catch (e) {
        console.error(e);
      }
    },
  },
  mounted() {
    this.fetchDestinations();
  },
  computed: {
    async optionComponent() {
      const response = await import(
        `components/destinations/${
          this.fileNames[this.selectedDestination]
        }.vue`
      );
      return response.default;
    },
  },
};
</script>
<style
  lang="scss"
  scope="app/assets/stylesheets/components/destinations"
></style>
