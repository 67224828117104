<template>
  <div id="c-exclusive-phone">
    <div class="phone-container">
      <div class="exclusive-header">
        <h2 class="merriweather-reg-26"> Nos </h2>
        <h2 class="merriweather-reg-40 mb-5">Offres exclusives</h2>
        <p class="raleway-reg-p16">{{ $t("Exclusive.content") }}</p>
      </div>
      <slither-slider :options="{loop: true, autoplay: true, numberOfSlides: 1,dots: false, controls: false}">
        <template v-for="exclusive in this.exclusivesMounted">
          <CardexclusivePhone :exclusive="exclusive"></CardexclusivePhone>
        </template>
      </slither-slider>
      <a class="hortense-btn-primary raleway-bold-p mt-5" href="/offres-exclusives">voir toutes nos offres</a>
    </div>
  </div>
</template>


<script>

  import CardexclusivePhone from 'components/cards-phone/CardexclusivePhone.vue';


  export default {
    name: 'CExclusivePhone',
    components:{ CardexclusivePhone },
    props:['exclusives'],
    data() {
      return {
        exclusivesMounted: this.exclusives
      }
    },
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/c-phone'>

</style>
