import { render, staticRenderFns } from "./Profildropdown.vue?vue&type=template&id=c6fddf50&scoped=true&"
import script from "./Profildropdown.vue?vue&type=script&lang=js&"
export * from "./Profildropdown.vue?vue&type=script&lang=js&"
import style0 from "./Profildropdown.vue?vue&type=style&index=0&id=c6fddf50&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6fddf50",
  null
  
)

export default component.exports