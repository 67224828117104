<template>
  <div id="cardCollection">
    <div class="card-collection">
      <img v-if="collection.photo_key" :src="collection.photo_key" class="img-collection" :alt="collection.name">
      <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" class="img-collection" :alt="collection.name">
      <div class="card-content">
        <h3 class="mb-3">{{ collection.name }}</h3>
        <h2 class="mb-3">{{ collection.title }}</h2>
        <p class="mb-3">{{ collection.subtitle }}</p>
        <a  :href="'/collections/' + collection.slug" class="mb-3">Découvrir la collection</a>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'cardCollection',
    props: ['collection'],
    data() {
      return{
        imgHeight: '',
      }
    },
    methods:{
      defineHeight(){
        if (this.collection.id%3 === 0){
          return this.imgHeight = '250px';
        }else{
          return this.imgHeight = '330px';
        }
      },
      changeHeight() {
        const img = document.getElementsByClassName('img-collection');
        if (img) {
          img[0].style.height = this.imgHeight
        }
      },
    },
    mounted(){
      this.defineHeight();
      this.changeHeight();
    }
  }
</script>

<style lang="scss" scoped>
  #cardCollection{
    margin-bottom: 2rem;
    width:100%;
    .img-collection {
      width: 100%;
      object-fit: cover;
    }
    .card-content{
      padding-top: 1.5rem;
      padding-bottom: 2rem;
      h3{
        font-family: $Merriweather;
        font-style: italic;
        font-size: 20px;
        color: #000000;
      }
      h2{
        font-family: $Raleway;
        font-weight: 700;
        font-size: 16px;
        color: #000000;
        text-transform: uppercase;
      }
      p{
        font-family: $Raleway;
        font-weight: 400;
        font-size: 15px;
        color: #000000;
      }
      a{
        text-decoration: underline;
        font-family: $Raleway;
        font-weight: 500;
        font-size: 15px;
        color: #000000;
      }
    }
  }
</style>
