<template>
  <div id="card-green-night">
    <div class="head-card">
      <p class="raleway-reg-p24">Vos crédits voyage</p>
      <p class="raleway-reg-p15">À partir du statut Green Trotter Infinite, vous bénéficiez d’un crédit voyage de 100 €, disponible dans la section “Vos codes exclusifs” et valable pendant un an.</p>
      <p class="raleway-reg-p15">Ensuite, vous recevez un crédit voyage de 100€ tous les 500 points accumulés à condition de disposer d’un minimum de 2 500 points.</p>
      <p class="raleway-reg-p15">Les crédits voyage sont valables pendant 12 mois. Si vous n'utilisez pas votre crédit de voyage avant la date d’expiration, vos points fidélité seront diminués de 500 points, correspondant à la valeur du crédit voyage.</p>
      <a :href="'/pages/cgu'" class="link-hortense">Conditions d’utilisation du programme de fidélité Hortensia</a>
    </div>
    <div class="green-section mt-4" v-if='this.greenNights'>
      <template v-for="greenNight in this.greenNights">
        <div class="green-night mb-3">
          <p class="raleway-bold-p mb-0">1 green night</p>
          <p class="raleway-reg-p15 mb-0">1 nuit offerte soit 150€</p>
          <p class="raleway-reg-p15 mb-0">Validité : {{ greenNight.expiration_date }}</p>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'card-green-night',
    props: ["user"],
    data() {
      return {
        userStatut: '',
        userMount: '',
        greenNights: '',
      }
    },
    methods: {
      async setUserLevel(){
        const res = await fetcher.get('/dashboard/'+this.user.slug)
        this.userMount = res.data
        this.userStatut = this.userMount.privilege.status
        this.greenNights = this.userMount.green_nights
      },
    },
    mounted() {
      this.setUserLevel();
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>

</style>
