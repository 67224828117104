<template>
  <div id="registration-user">
    <BannerProfile :user="this.user"></BannerProfile>
    <div class="container-profil">
      <Menuprofil :user="this.user"></Menuprofil>
      <div class="row row-info-user">
        <div class="col-lg-5 col-xs-7 col-header">
          <h2 class="merriweather-reg-26">Mes</h2>
          <h1 class="merriweather-reg-40">informations</h1>
        </div>
        <div class="col-lg-4 col-xs-7 col-form">
          <div class="gender">
            <input type="radio" id="M" value="M" v-model="userInfo.gender">
            <label class="mr-5" for="M">M</label>
            <input type="radio" id="Mme" value="F" v-model="userInfo.gender">
            <label for="F">Mme</label>
          </div>
          <div class="info-txt">
            <label for="name">Nom</label>
            <input v-model="userInfo.last_name" type="text">
            <label for="name">Prénom</label>
            <input v-model="userInfo.first_name" type="text">
            <label for="name">Date de naissance</label>

            <input v-model="userInfo.birthday" type="date" data-date-inline-picker="true">

            <!-- <input v-model="userInfo.birthday" type="text"> -->
            <!-- <input v-model="userInfo.birthday.split('T')[0]" type="text"> -->
            <label for="name">Email</label>
            <input v-model="userInfo.email" type="text">
            <label for="name">Téléphone</label>
            <input v-model="userInfo.phone" type="text">
          </div>
          <button @click="updateUserBasicInfo" class="hortense-btn-snd mt-3">Enregistrer</button>
        </div>
      </div>
      <!-- TODO -->
      <div class="row row-info-user">
        <div class="col-lg-5 col-xs-7 col-header">
          <h2 class="merriweather-reg-26">Mon</h2>
          <h1 class="merriweather-reg-40">Mot de passe</h1>
        </div>
        <div class="col-lg-4 col-xs-7 col-form">
          <div class="info-txt">
            <label for="ancient_password">Ancien mot de passe:</label>
            <input v-model="userInfo.password" type="password" autocomplete="new-password">
            <label for="new_password">Nouveau mot de passe:</label>
            <input v-model="new_password" type="password">
            <label for="password_confirmation">Confirmation</label>
            <input v-model="userInfo.password_confirmation" type="password">
          </div>
          <button @click="editUserpassword" class="hortense-btn-snd mt-3">Modifier</button>
        </div>
      </div>

      <div class="row row-address-user">
        <div class="col-lg-5 col-xs-7 col-header">
          <h2 class="merriweather-reg-26">Mon</h2>
          <h1 class="merriweather-reg-40">Adresse postale</h1>
        </div>
        <div class="col-lg-4 col-xs-7 col-form">
          <div class="info-txt mt-4">
            <label for="name">Adresse</label>
            <input v-model="userInfo.address" type="text">
            <label for="name">Code postal</label>
            <input v-model="zip_code" type="text">
            <label for="name">Ville</label>
            <input v-model="city" type="text">
            <label for="name">Pays</label>
            <input v-model="userInfo.country" type="text">
          </div>
          <button @click="updateUserAddress"class="hortense-btn-snd mt-3">Enregistrer</button>
        </div>
      </div>
      <div class="row row-cb-user">
        <div class="col-lg-5 col-xs-7 col-header">
          <h2 class="merriweather-reg-26">Mes</h2>
          <h1 class="merriweather-reg-40">Moyens de paiement</h1>
        </div>
        <div class="col-lg-4 col-xs-7 col-form">
          <div class="info-txt mt-4">
            <input v-model="cb" type="text" class="cb" placeholder="Numéro de carte">
              <input v-model="cvc" type="text" placeholder="MM/YY                                                                   CVC">
            <a href="#" class="link-hortense mb-4">Ajouter une carte</a>
          <Modaldestroy :user="this.user" v-if="showModalDestroy" @close='showModalDestroy = false'></Modaldestroy>
          </div>
          <button class="hortense-btn-snd mt-3 mb-5">Enregistrer</button>
          <div class="delete-acount text-center">
            <a @click="openModalDestroy" class="link-hortense mt-5">Supprimer mon compte</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import BannerProfile from 'components/shared/BannerProfile.vue';
  import Menuprofil from 'components/shared/Menuprofil.vue';
  import Modaldestroy from 'components/modals/Modaldestroy.vue';
  import fetcher from 'packs/shared/api.js';


  export default{
    name: 'registration-user',
    components: { BannerProfile, Menuprofil, Modaldestroy },
    props: {
      user: Object,
    },
    data() {
      return {
        userInfo: this.user,
        ancient_password: '',
        new_password: '',
        zip_code: '',
        city: '',
        showModalDestroy: false,
      }
    },
    methods: {
      openModalDestroy(){
        this.showModalDestroy = !this.showModalDestroy;
      },
      formatDate(date) {
        const set = new Date(date);
        let getDate = set.getDate().toString();
        if (getDate.length == 1){
         getDate = "0"+getDate;
        }
        let getMonth = (set.getMonth()+1).toString();
        if (getMonth.length == 1){
         getMonth = "0"+getMonth;
        }
        let getYear = set.getFullYear().toString();
        const dateNow = getYear +'-'+ getMonth +'-'+ getDate ; //today
        return dateNow;
      },
      async editUserpassword(){
        console.log(this.userInfo.password)
        // try {
        //   const response = await fetcher.patch('/users/password/', { user: {
        //                                                               password: this.userInfo.new_password,
        //                                                               password_confirmation: this.userInfo.password_confirmation
        //                                                             }
        //                                                           })
        // }catch(e){
        //   console.error(e)
        // }
      },
      async updateUserBasicInfo(){
        try {
          const response = await fetcher.patch('/dashboard/'+ this.user.id, {
                                                                              user: {
                                                                                first_name: this.userInfo.first_name,
                                                                                last_name: this.userInfo.last_name,
                                                                                gender: this.userInfo.gender,
                                                                                phone : this.userInfo.phone,
                                                                                birthday: this.formatDate(this.userInfo.birthday),
                                                                                email: this.userInfo.email
                                                                              }
                                                                            })
        } catch(e) {
          console.dir(e);
        }

      },
      async updateUserAddress() {
        try {
          const response = await fetcher.patch('/dashboard/'+ this.user.id, {
                                                                              user: {
                                                                                address: this.userInfo.address,
                                                                                country: this.userInfo.country
                                                                              }
                                                                            })
        } catch(e) {
          console.error(e);
        }
      },
    }
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/registration-user'>

</style>
