
<template>
  <div id="homeTablet">
    <Banner></Banner>
    <PropositionsPhone></PropositionsPhone>
    <LodginghomePhone @connection="openModal" :user='this.user' :lodgings="this.lodgings" v-if='lodgings'></LodginghomePhone>
    <ExclusiveHomePhone :exclusives="weekends" v-if='weekends.length > 0'></ExclusiveHomePhone>
    <WeekendPhone :weekends="this.weekends" v-if='weekends'></WeekendPhone>
    <ExpehomePhone :experiences='experiences' v-if='experiences'></ExpehomePhone>
    <GiftCardHome></GiftCardHome>
    <DestinationPhone :destinations="destinations" v-if='destinations'></DestinationPhone>
    <CollectionHomePhone :collections='collections' v-if='collections'></CollectionHomePhone>
    <TipsPhone></TipsPhone>
    <EvergreenPhoneHome></EvergreenPhoneHome>
    <LabelhomePhone></LabelhomePhone>
    <WhoisPhone></WhoisPhone>
    <FidPhone :user='this.user' @connection="openModal"></FidPhone>
    <ScrollUp></ScrollUp>
    <!-- <ReaPhone></ReaPhone> -->

  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';
  import Banner from 'components/shared/Banner.vue';
  import TipsPhone from 'components/TipsPhone.vue';
  import PropositionsPhone from 'components/PropositionsPhone.vue';
  import LodginghomePhone from 'components/LodginghomePhone.vue';
  import WeekendPhone from 'components/WeekendPhone.vue';
  import ExclusivePhone from 'components/ExclusivePhone.vue';
  import ExpehomePhone from 'components/ExpehomePhone.vue';
  import DestinationPhone from 'components/DestinationPhone.vue';
  import CollectionHomePhone from 'components/collection/CollectionHomePhone.vue';
  import EvergreenPhoneHome from 'components/EvergreenPhoneHome.vue';
  import LabelhomePhone from 'components/LabelhomePhone.vue';
  import WhoisPhone from 'components/WhoisPhone.vue';
  import FidPhone from 'components/FidPhone.vue';
  import ScrollUp from 'components/btns/ScrollUp.vue';
  import ExclusiveHomePhone from 'components/exclusive/ExclusiveHomePhone.vue';
  // import ReaPhone from 'components/ReaPhone.vue';

  export default {
    name: 'homeTablet',
    props: ['user'],
    components: { Banner, TipsPhone, LodginghomePhone, WeekendPhone, ExclusivePhone, ExclusiveHomePhone,  ExpehomePhone, DestinationPhone, CollectionHomePhone, EvergreenPhoneHome, LabelhomePhone, WhoisPhone, FidPhone, ScrollUp },
    data() {
      return {
        showBanner: false,
        lodgings: null,
        weekends: null,
        exclusives: null,
        experiences: null,
        destinations: null,
        collections: null,
        evergreens: null
      }
    },
    methods: {
      openModal() {
        this.$root.$emit('openModal')
      },
      redirectToTop() {
         window.scrollTo(0, '#navbar');
      },
      async fetchdatas() {
        try {
          const responseLodging = await fetcher.get('/reservation-hotel?page=1&lodgings_count=3');
          this.lodgings = responseLodging.data;
          const responseWeekend = await fetcher.get('/weekends');
          this.weekends = await responseWeekend.data;
          const responseExperience = await fetcher.get('/experiences');
          this.experiences = responseExperience.data;
          const responseDestination = await fetcher.get('/destinations');
          this.destinations = await responseDestination.data;
          const responseCollection = await fetcher.get('/collections');
          this.collections = responseCollection.data;
          const responseEvergreen = await fetcher.get('/evergreens');
          this.evergreens= await responseEvergreen.data;
          const responseExclusive = await fetcher.get('/offres-exclusives');
          this.exclusives = await responseExclusive.data;
        } catch(e) {
          console.error(e);
        }
      },
    },
    mounted() {
      this.fetchdatas();
    }
  }
</script>
