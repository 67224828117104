<template>
  <div id="review-list">
    <div class="container-index">
    <a href="javascript:history.go(-1)" class="no-deco mb-5">< Retour </a>
      <div class="index-text">
        <h1 class="merriweather-reg-40 mb-2">{{ this.lodging.name }}</h1>
        <h4 class="merriweather-reg-40 mt-2">{{this.lodging.lodging_description.rating_value}}/5</h4>
      </div>
      <div v-if="this.lodging && this.lodging.ratings">
        <template v-for="resa in this.lodging.ratings">
          <div>
            <div v-if="resa.authorized" class="card-review">
              <div class="review-autor">
                <h3 class="raleway-reg-p13 mb-4"> Avis de {{resa.user_name}} :</h3>
                <p class="raleway-bold">{{ resa.global }}/5</p>
              </div>
              <p style="font-weight: bold" class="merriweather-italic-13" >{{resa.title}}</p>
              <p class="merriweather-italic-13">{{resa.review}}</p>
              <div class="date-post">
                  <p class="raleway-reg-p13">Posté le {{ formatDate(resa.created_at.substring(0, 10))}}</p>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div v-else>
        <p class="raleway-reg-p15 text-center mt-5">Cet établissement n'a pas encore d'avis.</p>
      </div>
    </div>
  </div>
</template>
<script>

  import fetcher from 'packs/shared/api.js';

  export default{
    name: 'review-list',
    props: [ 'lodging'],
    data() {
      return{
        resaMount: null,
      }
    },
    methods: {
      async fetchResa() {
        try {
          const response = await fetcher.get('/reservation-hotel/'+this.lodging.id);
          this.resaMount = response.data;
          console.log('this.resaMount');
          console.log(this.resaMount);
        } catch(e) {
          console.error(e);
        }
      },
       formatDate(date){
        const set = new Date(date)
        const options = {weekday: "long", year: "numeric", month: "long", day: "numeric"};
        return set.toLocaleString('default', options)
      },
    },
  }
</script>
