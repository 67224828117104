<template>
  <div class="margin-table">
    <div :id="`room-dropdown-${this.roomId}`" class="room-dropdown" @click='displayDrop'>
      <div class="room-dropdown-name">
        Hotel Les aiguilletes
      </div>
      <div class="room-dropdown-subject">
        Demande de remboursement
      </div>
      <div class="room-dropdown-date">
        23/05/2021
      </div>
      <div class="room-dropdown-logo">
        <img src="../../images/flechebas-simple.svg" alt="dropdown arrow">
      </div>
    </div>
    <div id="in-message" v-if='this.showDrop'>

    </div>
  </div>
</template>

<script>
  export default {
    name: 'RoomDropdown',
    props: {
      roomId: Number,
    },
    data() {
      return {
        showDrop: false,
      }
    },
    methods: {
      displayDrop() {
        const arrow = this.$el.firstChild.lastChild.lastChild;
        const drop = this.$el.firstChild

        arrow.style.transform = arrow.style.transform === 'rotate(180deg)' ? '' : 'rotate(180deg)';

        this.showDrop = !this.showDrop;
      }
    },
    mounted() {

    }
  }
</script>

<style>
  .room-dropdown {
    width: 80%;
    height: 55px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    background: #F4F4F4;
  }
  #in-message {
    width: 80%;
    height: 50vh;
    background: #F4F4F4;
    margin: 0 auto;
  }
  .margin-table {
    margin-bottom: 5px;
  }
</style>
