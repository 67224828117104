<template>
  <div id="buisness">
    <div class="container-buisness-index">
      <h1 class="merriweather-reg-30 text-center header">Hortense for business</h1>
      <div id="banner-business">
          <div class="banner-show-container">
            <div class="">
              <p id="desire-p" :class="{ 'merriweather-italic24': !isPhone,  'merriweather-italic-15': isPhone}">
                Vous recherchez un lieu et des activités de <br/> 
                séminaire qui respectent l’environnement ? <br/> 
                Vous souhaitez donner une dimension RSE <br/>
                à votre prochain événement professionnel ?
              </p>
            </div>
          </div>
      </div>
      <p :class="{ 'mt-5 raleway-semi-p26 desktop-margin-left': !isPhone, 'mt-5 raleway-semi-p15 ml-5': isPhone }">Hortense For Business, c’est :</p>
      <div class="explanation" :class="{ 'desktop-margin-left': !isPhone }">
        <div class="title">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num1">&nbsp;</h1>
          </div>
          <h3 class="list-title mb-0" :class="{'raleway-bold-p15':!isPhone,'raleway-semi-p13':isPhone}"> UN INTERLOCUTEUR UNIQUE</h3>
        </div>
        <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Hortense sélectionne pour vous les meilleurs hébergements éco-responsables pour vos déplacements, vos séminaires ou vos événements d’entreprise. <br>Hortense vous conseille et négocie pour vous des tarifs attractifs auprès de ses hébergements partenaires.  </p>
      </div>
      
      <div class="explanation" :class="{ 'desktop-margin-left': !isPhone }">
        <div class="title">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num1">&nbsp;</h1>
          </div>
          <h3 class="list-title mb-0" :class="{'raleway-bold-p15':!isPhone,'raleway-semi-p13':isPhone}">DES LIEUX EXCEPTIONNELS</h3>
        </div>
        <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Hortense propose des hébergements de charme qui agissent pour réduire leur impact environnemental tout en offrant des expériences exceptionnelles.<br>Hortense vous propose des hébergements disposant d’espaces de travail adaptés et des espaces pour la détente et les moments festifs.</p>
      </div>
      
      <div id="banner-two">
          <div class="banner-show-container">
            <div class="">
              <p id="desire-p" :class="{ 'merriweather-italic24': !isPhone,'merriweather-italic-15': isPhone}">
                Entre excellence et critères éco-responsables,<br/> 
                trouvez l’hébergement idéal pour vos déplacements,<br/>
                vos séminaires ou vos événements d’entreprise.
              </p>
            </div>
          </div>
      </div>

       <div class="explanation" :class="{ 'desktop-margin-left': !isPhone }">
        <div class="title">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num1">&nbsp;</h1>
          </div>
          <h3 class="list-title mb-0" :class="{'raleway-bold-p15':!isPhone,'raleway-semi-p13':isPhone}">VOS SÉMINAIRES ET ÉVÉNEMENTS D’ENTREPRISE</h3>
        </div>
        <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Hortense vous proposera une sélection d’hébergements éco-responsables pour vos séminaires, vos formations et vos évènements professionnels.</p>
        <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Hortense vous recommandera des hébergements en fonction de vos besoins que vous cherchiez à organiser un comité de direction avec 10 personnes, une formation ou encore un séminaire avec plus de 100 personnes.</p>
      </div>
      <br/>
      <br/>
      <p class="text-center" :class="{'merriweather-italic-24':!isPhone,'merriweather-italic-16 ml-2':isPhone}">Une expérience porteuse de sens. Partagez vos valeurs avec vos collaborateurs.</p>

      <div class="explanation" :class="{ 'desktop-margin-left': !isPhone }">
      <div class="title">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num1">&nbsp;</h1>
          </div>
          <h3 class="list-title raleway-semi-p15">POURQUOI ORGANISER VOTRE ÉVÉNEMENT D’ENTREPRISE AVEC HORTENSE ?</h3>
        </div>
      </div>

      <div class="container">
          <div class="row">
              <div :class="{'col-4':!isPhone,'col-12':isPhone}">
                <p :class="{'raleway-medi-p15':!isPhone,'raleway-medi-p13':isPhone}">Notre promesse</p>
                <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Voyagez mieux avec Hortense : des choix de voyages responsables pour un avenir meilleur.</p>
                <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Organisez un événement original, en parfait alignement avec vos valeurs, pour marquer l’esprit de vos collaborateurs.</p>
              </div>
              <div :class="{'col-4':!isPhone,'col-12':isPhone}">
                <p :class="{'raleway-medi-p15':!isPhone,'raleway-medi-p13':isPhone}">Notre force</p>
                <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}"> Notre modèle de scoring interne permettant de vérifier l’engagement environnemental des hébergements touristiques.</p>
                <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Une entreprise innovante, dynamique et engagée qui vous organise des événements sur-mesure à l’image de votre entreprise.</p>
              </div>
              <div :class="{'col-4':!isPhone,'col-12':isPhone}">
                <p :class="{'raleway-medi-p15':!isPhone,'raleway-medi-p13':isPhone}">Notre engagement</p>
                <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Vous faire vivre une expérience mémorable dans un lieu d’exception et rendre votre événement responsable et vertueux.</p>
                <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Favorisez l’engagement et la cohésion de vos collaborateurs dans votre démarche RSE.</p>
              </div>
          </div>
      </div>
      <div id="banner-three">
          <div class="banner-show-container">
            <div class="">
              <p id="desire-p" :class="{ 'merriweather-italic24': !isPhone,'merriweather-italic-15': isPhone}">
                Hortense conçoit et produit des expériences <br/>
                uniques et sur-mesure avec un seul objectif <br/>
                en tête : rendre vos évènements mémorables <br/>
                et éco-responsables.
              </p>
            </div>
          </div>
      </div>
      <p style="margin-top: 10rem" class="text-center" :class="{'raleway-semi-p25':!isPhone,'raleway-semi-p15':isPhone}">Connaissez-vous le concept du Workation ?</p>
       <div id="banner-four">
          <div class="banner-show-container">
            <div class="">
              <p id="desire-p" :class="{ 'merriweather-italic24': !isPhone,'merriweather-italic-15': isPhone}">
                Hortense conçoit et produit des expériences <br/>
                uniques et sur-mesure avec un seul objectif <br/>
                en tête : rendre vos évènements mémorables <br/>
                et éco-responsables.
              </p>
            </div>
          </div>
      </div>

      <p :class="{ 'mt-5 raleway-semi-p26 desktop-margin-left': !isPhone, 'raleway-semi-p15 ml-5': isPhone }">Qu’est ce qu’une expérience Workation avec Hortense.green ?</p>
      <div class="explanation" :class="{ 'desktop-margin-left': !isPhone }">
        <div class="title">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num1">&nbsp;</h1>
          </div>
          <h3 class="list-title mb-0" :class="{'raleway-bold-p15':!isPhone,'raleway-semi-p13':isPhone}">UN LIEUX D’EXCEPTION</h3>
        </div>
        <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Nous sélectionnons des hébergements de charme.</p>
      </div>
      
      <div class="explanation" :class="{ 'desktop-margin-left': !isPhone }">
        <div class="title">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num1">&nbsp;</h1>
          </div>
          <h3 class="list-title mb-0" :class="{'raleway-bold-p15':!isPhone,'raleway-semi-p13':isPhone}">UN HÉBERGEMENT ÉCO-RESPONSABLE</h3>
        </div>
        <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Tous nos hôtes sont engagés dans une démarche de développement durable.</p>
      </div>

      <div class="explanation" :class="{ 'desktop-margin-left': !isPhone }">
        <div class="title">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num1">&nbsp;</h1>
          </div>
          <h3 class="list-title mb-0" :class="{'raleway-bold-p15':!isPhone,'raleway-semi-p13':isPhone}">UN ESPACE DE TRAVAIL ADAPTÉ</h3>
        </div>
        <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">Nous mettons à la disposition de vos salariés des espaces de travail ergonomiques, équipés de la technologie nécessaire pour leur permettre de rester connectés et productifs pendant leur séjour.</p>
      </div>

       <div class="explanation" :class="{ 'desktop-margin-left': !isPhone }">
        <div class="title">
          <div class="number-tip">
            <h1 class="raleway-reg-h1 num1">&nbsp;</h1>
          </div>
          <h3 class="list-title mb-0" :class="{'raleway-bold-p15':!isPhone,'raleway-semi-p13':isPhone}">UDES ACTIVITÉS</h3>
        </div>
        <p :class="{'raleway-reg-p15':!isPhone,'raleway-reg-p13':isPhone}">En plus de l’environnement de travail idéal, nous proposons des activités annexes (en dehors des horaires de bureau) qui favorisent la détente, l’inspiration et la créativité.</p>
      </div>
      <p class="mt-5 mb-5" :class="{'text-center raleway-semi-p15':!isPhone,'raleway-semi-p13 ml-4':isPhone}">Vous souhaitez faire vivre à vos collaborateurs une expérience de travail unique ?</p>
      <div class="container mt-2">
            <div class="row">
              <div class="col-12 text-center">
                <a :href="'/pages/contact/'" class="hortense-btn-primary" :class="{'business-button':!isPhone}">DÉCOUVREZ NOTRE OFFRE</a>
              </div>
            </div>
      </div>
      <div class="contact-us text-center">
        <h4 class="raleway-bold-p16">CONTACTEZ NOUS</h4>
        <p class="raleway-reg-p13">servicecorporate@hortense.green</p>
      </div>
      <br/><br/><br/>
      <p class="text-center" :class="{ 'merriweather-italic24': !isPhone,  'merriweather-italic-15': isPhone}">Ensemble, organisons votre évènement d’entreprise responsable, éthique et solidaire</p>
    <ScrollUp></ScrollUp>
    </div>
  </div>
</template>


<script>

  import ScrollUp from 'components/btns/ScrollUp.vue';

  export default {
    name: 'buisness',
    components: { ScrollUp },
    computed: {
      isPhone() {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
        return true;
      }else{
        return false;
      }
     }
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/static-pages'>
 .business-button {
    width: 20vw;
    display: inline;
}
.desktop-margin-left{
  margin-left: 10rem;
}
#buisness{
  .container-buisness-index {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 15vh;
    overflow: hidden;
   }
}
#banner-two {
  width: 100%;
  height: 290px;
  margin-top: 15vh;
  background-image: url("../images/hortense-for-business/man-holding-phone.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
   .banner-show-container {
      width: 100%;
      height: 250px;
      .catch-hub-show {
        color: white;
        margin-top: 4rem;
      }
      #desire-p {
          color: white;
          margin-left: 45vw;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        #desire-p {
          margin-left: 3vw;
        } 
      }
    }
    .btn-galerie {
      width: 100%;
      margin-left: 5rem;
      cursor: pointer;
      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.4;
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }
    .btn-galerie-phone {
      display: none;
    }
  }

#banner-three {
  width: 100%;
  height: 290px;
  margin-top: 15vh;
  background-image: url("../images/hortense-for-business/woman-holding-phone.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
   .banner-show-container {
      width: 100%;
      height: 250px;
      .catch-hub-show {
        color: white;
        margin-top: 4rem;
      }
      #desire-p {
          color: white;
          margin-left: 10vw;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        #desire-p {
          margin-left: 10vw;
        } 
      }
    }
    .btn-galerie {
      width: 100%;
      margin-left: 5rem;
      cursor: pointer;
      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.4;
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }
    .btn-galerie-phone {
      display: none;
    }
  }


#banner-four {
  width: 100%;
  height: 290px;
  margin-top: 15vh;
  background-image: url("../images/hortense-for-business/people-joking.png");
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-end;
   .banner-show-container {
      width: 100%;
      height: 250px;
      .catch-hub-show {
        color: white;
        margin-top: 4rem;
      }
      #desire-p {
          color: white;
          margin-left: 45vw;
      }
      @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        #desire-p {
          margin-left: 10vw;
        } 
      }
    }
    .btn-galerie {
      width: 100%;
      margin-left: 5rem;
      cursor: pointer;
      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.4;
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }
    .btn-galerie-phone {
      display: none;
    }
  }


#banner-business { 
    width: 100%;
    height: 694px;
    margin-top: 5vh;
    background-image: url("../images/hortense-for-business/villa-saint-ange-interieur.png");
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: flex-end;
    .banner-show-container {
      width: 100%;
      height: 650px;

      .catch-hub-show {
        color: white;
        margin-top: 4rem;
      }
      #desire-p {
          color: white;
          margin-left: 55vw;
          margin-top: 30vw;
      }
    }
    .btn-galerie {
      width: 100%;
      margin-left: 5rem;
      cursor: pointer;
      .galerie-icon {
        background-color: rgba(0, 0, 0, 0.4);
        // opacity: 0.4;
        border-radius: 60px;
        padding: 1rem 2rem;
        color: white;
        width: 152px;
        height: 48px;
      }
    }
    .btn-galerie-phone {
      display: none;
    }
  }



@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .wedding-container-index {
      width: 90%;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 15vh;
      overflow: hidden;
  }
  #banner-business {
        margin-top: 5vh;
        display: block;
        .btn-galerie-phone {
          display: block;
          //width: 100%;
          margin-left: 1rem;
          .galerie-icon {
            background-color: rgba(0, 0, 0, 0.4);
            // opacity: 0.4;
            border-radius: 60px;
            padding: 1rem 2rem;
            color: white;
            width: 152px;
            height: 48px;
          }
        }
        .btn-galerie {
          display: none;
        }
        .banner-show-container {
          width: 100%;
          height: 700px;
          display: flex;
          align-items: flex-end;
          .catch-hub-show {
            margin-left: 1rem;
            p {
              font-size: 26px;
            }
          }
          #desire-p {
            color: white;
            margin-left: 10vw;
            margin-bottom: 20%;
          }
        }
      }
}
</style>
