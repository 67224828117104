<template>
	<div id="multiselectlocations" class="mt2">
		<div class="dropdown dropdown-filter" @click="showDropdown">
			<div class="overselect"></div>
			<div class="c-form-input">
				<h2 class="raleway-semi-p13">Quartier</h2>
				<img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="show" />
				<img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
			</div>
		</div>
		<div class="multiselect" v-if="show">
			<ul>
				<div class="row row-selectoptions mt-3">
					<div class="col-10">
						<li>
							<input type="radio" id="Centre-ville" :value="0" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Centre-ville">Centre-ville</label>
						</li>
						<li>
							<input type="radio" id="Moins de 1km du centre" :value="1" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Moins de 1km du centre">Moins de 1km du centre</label>
						</li>
						<li>
							<input type="radio" id="Moins de 5km du centre" :value="5" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Moins de 5km du centre">Moins de 5km du centre</label>
						</li>
						<li>
							<input type="radio" id="Moins de 10km du centre" :value="10" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Moins de 10km du centre">Moins de 10km du centre</label>
						</li>
						<li>
							<input type="radio" id="Moins de 15km du centre" :value="15" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="Moins de 15km du centre">Moins de 15km du centre</label>
						</li>
					</div>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'multiselectlocations',
		props: ['options'],
		data() {
			return {
				show: false,
				selected: '',
			};
		},
		methods: {
			showDropdown() {
				this.show = !this.show;
			},
			select(event) {
				const inputs = document.getElementById('multiselectlocations').querySelectorAll('input');
				inputs.forEach((el) => {
					if (event.currentTarget != el) {
						el.checked = false;
					}
				});
				this.selected = event.currentTarget.value;
			},
		},
		watch: {
			selected(val) {
				this.$emit('checkedDistance', val);
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
