<template>
  <div id="privacy-policy">
    <div class="container-index">
      <h1 class="merriweather-reg-24 text-center mb-5">POLITIQUE DE CONFIDENTIALITÉ ET PROTECTION DES DONNÉES</h1>
      <pre class="raleway-reg-p15">
Nous accordons une grande importance à la protection de vos données à caractère personnel et mettons tout en œuvre pour les protéger. Vous trouverez ci-dessous notre engagement en matière de protection des données à caractère personnel ainsi que notre politique de protection des données.

<strong>NOTRE ENGAGEMENT EN MATIÈRE DE PROTECTION DES DONNÉES A CARACTÈRE PERSONNEL</strong>
• Vos données sont utilisées uniquement pour des finalités explicites, légitimes et déterminées en lien avec nos différentes offres et les prestations que vous aurez sélectionnées.
• Vos données personnelles de santé sont confidentielles. Elles ne sont destinées qu’à vous et à votre professionnel de santé en fonction de l’offre sélectionnée.
• Seules les données nécessaires à l’exécution de nos services sont collectées.
• Nous sommes respectueux de vos droits en tant qu’utilisateurs et clients, c’est pour cela que nous mettons tout en œuvre pour que vous puissiez les exercer.
• Nous sommes respectueux de votre vie privée et de vos choix, c’est pour cela que les communications que vous recevez de notre part peuvent faire l’objet d’une résiliation à tout moment.

SOMMAIRE
</pre>

<div class="summary-container">
  <p class="text">1.  IDENTITÉ DU RESPONSABLE DE TRAITEMENT</p>
  <p class="text">2.  VOS DROITS</p>
  <p class="text">3.  FINALITÉS DE LA COLLECTE DE VOS DONNÉES PERSONNELLES ET BASES LEGALES</p>
  <p class="text">3.1 Finalité de la collecte de vos données</p>
  <p class="text">3.2 Bases juridiques</p>
  <p class="text">4.  TRANSMISSION DES DONNEES</p>
  <p class="text">5.  SOUS-TRAITANTS</p>
  <p class="text">6.  TRANSFERT HORS EU</p>
  <p class="text">7.  DURÉE DE CONSERVATION DES DONNÉES</p>
  <p class="text">8.  RÈGLES APPLICABLES AUX PAIEMENTS PAR CARTE BANCAIRE ET À LA PROTECTION DES DONNÉES BANCAIRES</p>
  <p class="text">9.  LES MESURES DE SÉCURITÉ QUE NOUS METTONS EN PLACE POUR PROTÉGER VOS DONNÉES</p>
  <p class="text">10. SITES WEB TIERS ET RESEAUX SOCIAUX</p>
  <p class="text">11. POLITIQUE COOKIES & AUTRES TRACEURS</p>
  <p class="text">11.1. Qu’est-ce qu’un cookie ?</p>
  <p class="text">11.2. Quel type de cookies utilisons nous et pour quelle finalité ?</p>
  <p class="text">11.3. Réglage et blocage des cookies via notre gestionnaire de cookies</p>
  <p class="text">11.4. Réglage et blocage des cookies via vos paramètres de navigation</p>
  <p class="text">11.5. Paramétrage du système d’exploitation de votre smartphone</p>
  <p class="text">12. MODIFICATION DE LA PRESENTE POLITIQUE DE PROTECTION DES DONNEES</p>
</div>

<pre class="raleway-reg-p15">
<strong>1.  IDENTITÉ DU RESPONSABLE DE TRAITEMENT</strong>

Les données personnelles sont collectées par Hortense, Société par actions simplifiée unipersonnelle au capital de 15.000 euros enregistrée au RCS de Créteil sous le numéro 892 531 476, dont le siège social est sis 112 avenue de Paris – 94300 Vincennes.
Nous mettons à la disposition de nos clients et utilisateurs un site internet accessible depuis l’adresse www.hortense.green. Pour délivrer nos services nous collectons des données à caractère personnel sur les individus et les sociétés.

Dans les formulaires de collecte des données sur le site et l’application, les utilisateurs sont informés du caractère obligatoire ou non de la collecte de données. En cas de non-fourniture d’un champ de données obligatoire, nous ne pourrons malheureusement pas accomplir nos prestations et respecter nos engagements.

Nous sommes soucieux de la protection des données personnelles qui nous sont confiées. Nous nous engageons à assurer le meilleur niveau de protection de vos données personnelles en conformité avec le RGPD « Règlement Général sur la Protection des Données » Règlement (UE) 2016/679 du Parlement Européen et du Conseil du 27 avril 2016 et la Loi Informatique et Libertés loi n° 2018-493 du 20 juin 2018, promulguée le 21 juin 2018 qui a modifié la Loi Informatique et Libertés du 6 janvier 1978. Pour toute information sur la protection des données personnelles, vous pouvez également consulter le site de la Commission Nationale de l’Informatique et des Libertés www.cnil.fr.

<strong>2.  VOS DROITS</strong>
En application de la Loi Informatique et Liberté Loi 78-17 du 6 janvier 1978 modifiée et du Règlement Général sur la Protection des Données « RGPD », toute personne physique utilisant le service a la faculté d’exercer les droits suivants :
<strong>• Un droit d’accès</strong> : En tant que personne concernée, vous pouvez vous informer sur la nature des données personnelles stockées ou traitées vous concernant. Un accès à vos données personnelles vous sera fourni sur simple demande.
<strong>• Un droit de rectification</strong> : Si des informations personnelles sont inexactes ou incomplètes, vous pouvez demander à ce qu'elles soient modifiées.
<strong>• Un droit d’opposition</strong> : Vous avez le droit de vous opposer à tout moment au traitement de vos données personnelles lorsque Hortense traite vos données pour des motifs qui leur sont d’intérêt légitime ou pour des motifs de marketing direct.
<strong>• Un droit à l’oubli</strong> : L’Utilisateur peut obtenir de la Société, sous certaines conditions, l’effacement dans les meilleurs délais de certaines données personnelles le concernant. Ce droit à l’oubli ne peut être obtenu dans certains cas notamment pour des motifs d’intérêt public, à des fins d’archives ou pour respecter des obligations légales conditionnant le traitement des données personnelles par la société.
<strong>• Un droit à la limitation du traitement de vos données</strong> : L’Utilisateur peut obtenir de la Société que celle-ci limite le traitement de ses données personnelles et cela notamment lorsque l’Utilisateur s’oppose à ce traitement, conteste l’exactitude des données ou lorsqu’il pense que l’utilisation est illicite.
<strong>• Un droit à la portabilité de vos données à caractère personnel</strong> : Sous certaines conditions, l’Utilisateur peut demander à recevoir l’intégralité de ses données personnelles afin qu’il puisse les transférer vers un autre responsable de traitement sans que la Société ne puisse s’y opposer.
<strong>• Sort de vos données à votre décès</strong> : La Société respectera les directives données par tout utilisateur relatives à la conservation, à l'effacement et à la communication de ses données à caractère personnel après son décès. En l’absence de telles directives, la Société fera droit aux demandes des héritiers telles que limitativement énoncées par les dispositions applicables de la Loi Informatique et Libertés.

<strong>Comment exercer vos droits ?</strong>

Pour exercer vos droits, veuillez adresser votre courrier à Hortense, 112 avenue de Paris – 94300 Vincennes en indiquant votre nom, prénom, adresse mail et si possible votre numéro de commande, soit par courriel : contact@hortense.green.

Vos demandes doivent être accompagnées d’une copie prouvant votre identité. Nous avons un délai de 30 jours ouvrés à réception de votre demande pour vous répondre. Certaines demandes contraignantes peuvent prendre plus de temps, dans ce cas, le délai sera prolongé et vous en serez informés.

Si vous estimez que vos droits ne sont pas respectés, vous pouvez également déposer une réclamation auprès de la Commission Nationale de l’Informatique et Libertés (CNIL) 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07 ou via le site internet : www.cnil.fr.

<strong>3.  FINALITÉS DE LA COLLECTE DE VOS DONNÉES PERSONNELLES ET BASES LEGALES</strong>

En vertu du Règlement Général sur la Protection des Données et la Loi Informatique et Liberté, Hortense n’est autorisée à utiliser les données personnelles de ses utilisateurs et clients que si elle dispose d'une base juridique valable.

La communication de vos données personnelles est dans la plupart des cas délibérée. Lorsque vos informations nous sont indispensables pour le traitement de vos demandes, nous vous le signalons par une (*).

<strong>3.1 Finalité de la collecte de vos données</strong>

Afin de répondre à vos sollicitations et besoins, nous collectons à différentes étapes de l’achat, la souscription à une option, de la demande d’information vos données à caractère personnel.

<strong>3.2 Bases juridiques</strong>

Les bases juridiques qui nous permettent de collecter vos données sont les suivantes :
• L’exécution d'un contrat passé entre Hortense, ses utilisateurs et ses clients
• L’exécution d’une obligation légale
• Lorsque cela est dans l’intérêt légitime de Hortense
• Lorsque l’utilisateur a donné son consentement.

<table>
  <thead>
    <tr>
      <th><strong>Finalité</strong></th>
      <th><strong>Bases légales</strong></th>
    </tr>
  </thead>
  <tbody>
      <tr>
        <td>• Gestion du compte client et des réservations</td>
        <td>• Exécution du contrat passé entre Hortense et le client</td>
      </tr>
      <tr>
        <td>• Gestion des opérations de paiement et de crédit</td>
        <td>• Exécution du contrat passé entre Hortense et le client</td>
      </tr>
      <tr>
        <td>• Gestion de la relation client, du suivi des réservations, du service après-vente, des annulations et des remboursements</td>
        <td>• Intérêt légitime de Hortense afin d’améliorer les produits et le service</td>
      </tr>
      <tr>
        <td>• Recueil des avis clients sur les hébergements</td>
        <td>• Consentement de l’utilisateur</td>
      </tr>
      <tr>
        <td>• Gestion des options souscrites par l’utilisateurs</td>
        <td>• Intérêt légitime de Hortense</td>
      </tr>
      <tr>
        <td>• Lutte contre la fraude lors du paiement de la commande et gestion des impayés</td>
        <td>• Exécution du contrat passé entre Hortense et le client</td>
      </tr>
      <tr>
        <td>• Envoi d’offres commerciales ciblées par voie électronique et par notifications mobiles</td>
        <td>• Consentement du Client</td>
      </tr>
      <tr>
        <td>• Opérations d’analyses et de statistiques afin d’améliorer la connaissance des clients</td>
        <td>• Intérêt légitime de la Hortense</td>
      </tr>
      <tr>
        <td>• Personnalisation des sites et applications </td>
        <td>• Consentement du Client
• Intérêt légitime de la Hortense</td>
      </tr>
      <tr>
        <td>• Mesure de fréquentation des sites et applications mobiles</td>
        <td>• Consentement du Client</td>
      </tr>
      <tr>
        <td>• Mise à disposition d’outils de partage sur les réseaux sociaux </td>
        <td>• Intérêt légitime de la Hortense
• Consentement du Client</td>
      </tr>
  </tbody>
</table>

<strong>4.  TRANSMISSION DES DONNEES</strong>

Les données à caractère personnel des Utilisateurs sont collectées et traitées par :
• Les services : commercial, administratif, marketing, communication et Ressources Humaines de Hortense.
• Les établissements de financement et crédits
• Les autorités de police dans le cadre des réquisitions judiciaires concernant la lutte contre la fraude

<strong>5.  SOUS-TRAITANTS</strong>

Dans cadre de certaines prestations décrites plus bas, nous faisons appel à des sous-traitants :
• Le paiement sécurisé sur les sites et les applications mobile
• L’envoi de courriers postaux
• La personnalisation des contenus des sites et applications mobile
• La réalisation d’opérations de maintenance et de développement techniques du site internet, des applications interne et du système d’information.
• Le recueil des avis clients

<strong>6.  TRANSFERT HORS EU</strong>

Nous ne transférons pas vos données hors Union Européenne. En cas de changement de politique, cette rubrique fera l’objet d’une modification.

<strong>7.  DURÉE DE CONSERVATION DES DONNÉES</strong>

Selon le cas de figure, Hortense a défini des durées de conservation distinctes. Nous faisons en sorte que les durées de conservation soient pertinentes et respectent les délais légaux.
Pour établir la durée de conservation des données personnelles, la Société applique les critères suivants :
<strong>• Les données de prospect</strong> : les données sont conservées 3 ans. Le point de départ de la durée de conservation est le dernier échange que nous avons eue ou la dernière interactivité que vous avez eu avec l’un de nos programmes.
<strong>• Les données de client</strong> : les données sont conservées pour la durée de la relation contractuelle et pour une durée de trois ans après le dernier contact avec le client.
<strong>• Données des utilisateurs</strong> : les données sont conservées tout au long de l’existence du compte sur l’application. Une fois le compte supprimé par l’utilisateur, ce dernier est informé que ses données seront anonymisées et réutilisées.

L’utilisateur des programmes Hortense est informé qu’en cas de demande de suppression définitive de son compte, ses données seront anonymisées et servirons à Hortense pour des opérations ultérieures.
La Société est susceptible de conserver certaines données afin de remplir ses obligations légales ou réglementaires et afin de lui permettre d’exercer ses droits. A ce titre la société conserve les factures liées aux prestations dix ans.

<strong>8.  RÈGLES APPLICABLES AUX PAIEMENTS PAR CARTE BANCAIRE ET À LA PROTECTION DES DONNÉES BANCAIRES </strong>  

Afin d´assurer la sécurité de vos paiements, nous utilisons les services de prestataires externes. Ils garantissent un traitement sécurisé de toutes les données sensibles telles que les données bancaires et l’identité des utilisateurs, en conformité avec les directives et normes : DSP2, RGPD et PCI-DSS. 
Lorsque le paiement de votre commande s’effectue par carte bancaire, notre système de prise de commande se connecte en temps réel avec le système de nos prestataires qui collectent vos données et procèdent aux diverses vérifications pour éviter les abus et les fraudes. Les données sont stockées sur les serveurs de nos prestataires et ne sont à aucun moment transmises sur nos serveurs. Nos prestataires font la demande d’autorisation auprès de la banque et nous transmettent uniquement le numéro de transaction.
Afin de pouvoir débiter le compte lors de la facturation ou de le créditer, nos prestataires conservent les données bancaires associées au n° d’autorisation, le temps nécessaire à la réalisation de la transaction et au traitement des éventuelles réclamations.

Les données bancaires (numéro de carte, date d’expiration, nom du titulaire) ne sont mémorisées que si l’Utilisateur a donné son consentement préalable en cochant, lors de l’enregistrement de sa demande de contestation, la case proposée à cet effet lors de son inscription.

<strong>9.  LES MESURES DE SÉCURITÉ QUE NOUS METTONS EN PLACE POUR PROTÉGER VOS DONNÉES</strong>
En tant que responsable de traitement, Hortense prend toutes les précautions utiles pour préserver la sécurité et la confidentialité de vos données. Cela inclut la sécurité physique des bâtiments abritant nos systèmes et la sécurité du système informatique pour empêcher l'accès externe à vos données. L’accès à vos données est limité aux seules personnes ayant la nécessité d’en prendre connaissance.

<strong>10. SITES WEB TIERS ET RESEAUX SOCIAUX</strong>

Le site peut contenir des liens hypertexte donnant accès à d'autres sites web édités et gérés par des tiers et non par Hortense. Hortense ne pourra être tenu responsable directement ou indirectement dans le cas où lesdits sites tiers ne respecteraient pas les dispositions légales.

La création de liens hypertexte vers le site ne peut être faite qu'avec l'autorisation écrite et préalable de Hortense.

Nous attirons votre attention sur les réseaux sociaux que nous utilisons et avec lesquels vous pouvez être amenés à interagir pour nous soutenir et/ou partager l’une de nos publications. Lors de votre interaction, Hortense ne peut être tenu comme responsable en cas de problème. Sachez que lorsque vous utilisez ces liens, des informations à votre sujet peuvent être collectées ou partagées. Nous vous encourageons à consulter les politiques et paramètres de confidentialité des réseaux sociaux avec lesquels vous interagissez, afin de connaître les informations susceptibles d'être recueillies, utilisées ou partagées par ces sites.

<strong>11. POLITIQUE COOKIES & AUTRES TRACEURS</strong>

<strong>11.1. Qu’est-ce qu’un cookie ?</strong>
Les cookies sont de petits fichiers de données placés sur votre ordinateur ou votre appareil mobile par votre navigateur lorsque vous visitez un site Web. Habituellement, un cookie contient le nom du site Web qui l'utilise et une chaîne de texte ou un « identificateur unique » qui permet aux sites Web de reconnaître ce cookie lors de chaque visite subséquente tout au long de sa durée de vie.
Les cookies peuvent collecter et stocker un large éventail d'informations, tels que le type de navigateur ou le système d'exploitation utilisé, la langue ou d'autres paramètres du navigateur, ou encore vos interactions avec le site Web. Habituellement, les cookies ne sont pas utilisés pour collecter des données qui identifient un individu. Cependant, les informations recueillies avec des cookies peuvent être associées à une personne physique, si elles sont combinées avec des données personnelles identifiables telles que l'adresse e-mail d'une personne.

Le dépôt de cookies vous est signalé, lors de votre connexion, par un bandeau situé en bas de notre site. Votre consentement pour le dépôt de certains cookies est indispensable. Vous pouvez accepter ou refuser le dépôt de cookies sur votre ordinateur ou votre terminal mobile.

Si vous faites le choix de ne pas utiliser de cookies, vous pourriez être privé de certaines fonctionnalités présentes sur le site.

En général, nous utilisons deux types de cookies différents sur ce site :

Les cookies de session sont utilisés pour stocker des informations sur vos activités sur ce site pendant la durée de votre visite. Ils sont effacés lorsque vous fermez votre navigateur.

Les cookies persistants sont stockés dans l'un des sous-dossiers de votre navigateur pendant une ou plusieurs sessions. Ils expirent après une certaine période (définie dans le fichier) ou peuvent être supprimés manuellement.

Balises Web, scripts intégrés et autres technologies similaires : 

Nous et nos partenaires tiers pouvons également utiliser des technologies similaires sur ce site, tels que des balises Web (également appelées Pixel-Tags ou GIF) ou des scripts.

Les balises Web sont de petites images graphiques qui peuvent être intégrées dans des sites Web ou dans des courriels HTML qui ne sont généralement pas visibles par l'utilisateur. Elles permettent de suivre l'interaction de l'utilisateur avec le site ou nos newsletters. Par exemple, elles nous aident à comprendre si vous avez lu notre newsletter ou si vous avez cliqué sur les liens qui s'y trouvent, afin que nous puissions vous proposer des offres adaptées à vos centres d'intérêt.

Un script ou pixel intégré est un code conçu pour collecter des informations sur vos interactions avec ce site, tels que les liens sur lesquels vous cliquez. Le code est téléchargé temporairement sur votre appareil depuis notre serveur web ou celui d’un prestataire de services tiers. Il est actif uniquement lorsque vous êtes connecté au site Web, et est désactivé ou supprimé par la suite. Même si vous n’aurez pas la possibilité de rejeter ou de désactiver ces technologies spécifiquement, elles fonctionnent conjointement avec certains cookies. De ce fait, la désactivation des cookies empêchera le fonctionnement des technologies susmentionnées. 

Conformément à la Directive 2002/58/CE du 12 juillet 2002, nous recueillons votre consentement préalable au dépôt des cookies publicitaires, de mesure d’audience et de partage vers les réseaux sociaux.

<strong>11.2. Quel type de cookies utilisons nous et pour quelle finalité ?</strong>

Des cookies strictement nécessaires
Ces cookies sont nécessaires pour les opérations propres aux services qui sont fournis sur nos sites internet. Ils sont utilisés pour fournir les fonctionnalités basiques de nos sites internet, telles que se souvenir des informations qui ont été insérées dans un formulaire. Si vous empêchez l’installation de ces cookies, vous ne pourrez plus utiliser ces fonctionnalités et le site internet pourrait ne pas fonctionner de façon efficace.
Des cookies de performance
Ces cookies sont utilisés pour collecter des données anonymes à des fins statistiques. Ils nous permettent de mesurer l’audience du site internet et d’analyser la façon dont les visiteurs surfent sur le site internet (nombre de visiteurs sur le site internet, nombre de visites par page, temps passé sur chaque page, localisation des clics, mesures d’efficacité des publicités…). Ils sont également utilisés pour détecter des problèmes de navigation et toute autre difficulté. Ces cookies nous aident à améliorer notre site internet et votre navigation.
Des cookies de personnalisation ou de fonctionnalité
Ces cookies sont utilisés pour se souvenir de vos choix, de vos réglages et de vos préférences de contenu sur le site internet (comme votre langue, vos choix de personnalisation…) et vous offrir ainsi une expérience de navigation personnalisée en adaptant les contenus du site internet pour vous. Si vous refusez ces cookies nous ne pourrons plus vous offrir certaines fonctionnalités et certaines pages du site internet pourraient ne pas fonctionner correctement.

Spécificité des cookies de partage
Ces cookies sont spécifiquement liés à l’utilisation des boutons de partage d’une page du site sur les réseaux sociaux (Facebook, Twitter, LinkedIn, etc.). Les boutons de partage vous permettent de directement partager sur le réseau social concerné une page du site. En cliquant sur le bouton de partage sur le réseau social concerné, un ou plusieurs cookies sont alors déposés sur votre terminal (ordinateur, smartphone, tablette) par le réseau social. Nous n’avons ni accès ni contrôle sur ces cookies de tiers, qui peuvent être des cookies analytiques, de performance ou de ciblage.
Nous vous suggérons de consulter les sites internet de ces tiers pour plus d’informations au sujet de leurs cookies et pour savoir comment les gérer :
• Facebook : https://fr-fr.facebook.com/policies/cookies/
• Twitter : https://help.twitter.com/fr/rules-and-policies/twitter-cookies
• LinkedIn : https://www.linkedin.com/legal/cookie-policy?_l=fr_FR

<strong>11.3. Réglage et blocage des cookies via notre gestionnaire de cookies</strong>

La liste des cookies que nous utilisons est consultable via l’outil de gestion que nous avons mis en place. Vous avez la possibilité de les désactiver à tout moment. Néanmoins, nous attirons votre attention sur le fait que certains cookies sont indispensables au bon fonctionnement de notre site et qu’il est donc déconseillé de les désactiver.

<strong>11.4. Réglage et blocage des cookies via vos paramètres de navigation</strong>

Vous pouvez également contrôler les cookies grâce aux paramètres de votre navigateur. Si la plupart des navigateurs sont paramétrés par défaut et acceptent l’installation de cookies, vous avez la possibilité, si vous le souhaitez, de choisir d’accepter tous les cookies, ou de les rejeter systématiquement ou encore de choisir ceux que vous acceptez selon l’émetteur. Vous pouvez également paramétrer votre navigateur pour accepter ou refuser, au cas par cas, les cookies préalablement à leur installation. Vous pouvez également régulièrement supprimer les cookies de votre terminal via votre navigateur.

L'utilisation de cookies ou de technologies similaires par tout site Internet tiers, fournisseur de contenu publicitaire est soumise à leur propre politique de confidentialité en matière de cookies.

LA CNIL (Commission Nationale Informatique et Liberté) propose en téléchargement gratuit, un logiciel de gestion des cookies sur son site Internet : rendez-vous à l'adresse https://www.cnil.fr/vos-droits/vos-traces/les-cookies/ pour en savoir plus.

Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d’aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits en matière de cookies.

A titre d’exemple :
• Pour Internet Explorer™ : https://windows.microsoft.com/fr-FR/windows-vista/Block-or-allow-cookies
• Pour Safari™ : https://support.apple.com/fr-fr/guide/safari/sfri11471/mac
• Pour Chrome™: https://support.google.com/chrome/bin/answer.py?hl=fr&hlrm=en&answer=95647
• Pour Firefox™ :  https://support.mozilla.org/fr/kb/Activer%20et%20d%C3%A9sactiver%20les%20cookies

<strong>11.5. Paramétrage du système d’exploitation de votre smartphone</strong>

Vous avez la possibilité de contrôler le dépôt des cookies sur votre smartphone dans les règles du système d’exploitation.
Sur iOS : https://support.apple.com/fr-fr/HT201265
Sur Android : https://support.google.com/chrome/topic/3434352

Vous avez également la possibilité de vous opposer au dépôt des cookies en accédant au site internet : http://www.youronlinechoices.com/fr/controler-ses-cookies/

<strong>12. MODIFICATION DE LA PRESENTE POLITIQUE DE PROTECTION DES DONNEES</strong>

Hortense peut modifier la présente politique de protection des données à tout moment. Hortense informera les utilisateurs par tout moyen des modifications apportées à la présente politique.

Date de diffusion de cette politique : 01/12/2021

      </pre>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'privacy-policy',
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/static-pages'>
  #privacy-policy{
    table{
      th, td{
        border: 1px solid black;
        padding:1rem;
      }
    }
    .summary-container {
      max-width: 810px;
      margin-bottom: 2rem;

      .text { 
        overflow: hidden;
        position: relative;
        margin-bottom: 0px;
        font-size: 15px;
        color: #212529;
      }
      .text:after{
        content: " ............................................................................................................................................................................................................................................................... ";
        position: absolute;
        padding-left: 2px;
        font-size: 11px;
        bottom: 2px
      }
    }
  }
</style>
