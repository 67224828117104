<template>
	<div id="switch-trip">
		<div class="buisness-travel df-jsb-aic mt2">
			<h2 class="raleway-semi-p13 mb-0">Animaux</h2>
			<input type="checkbox" id="animal" v-model="selected" /><label for="animal">Animal</label>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'switch-trip',
		data() {
			return {
				selected: false,
			};
		},
		watch: {
			selected(val) {
				// easier to handle only arrays
				this.$emit('checked', val, 'animal');
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
