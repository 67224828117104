<template>
  <div id="headGiftCard">
    <div class="gift-card-header text-center ">
      <h2 class="merriweather-reg-40">LA CARTE CADEAU HORTENSE :<br/> Une invitation au voyage durable !</h2>
      <p class="raleway-reg-p15">Surprenez vos proches avec des expériences inoubliables dans des hébergements d'exception</p>
      <div class="btn text-center">
       <a class="hortense-btn-primary" @click="scrollToElement('form-user-info')">Offrir une carte cadeau</a>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'headGiftCard',
    methods: {
      scrollToElement(div) {
        const el = document.getElementsByClassName(div)[0];

        if (el) {
          el.scrollIntoView();
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.hortense-btn-primary{
  max-height: 25px;
  font-size: 11px;
}
  .link-list-container{
    .link-list{
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 3rem;
      list-style: none;

      .link-list-item{
        a{
          font-family: $Raleway;
          font-size: 14px;
          font-weight: 500;
          text-transform: uppercase;
          color:#000000;
          text-decoration: none;
        }
      }
    }
  }

  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    #headGiftCard{
      .gift-card-header{
        text-align: center;
        margin-top: 20px;

        h2{
          font-size: 19px;

        }
      }

    }
  }
</style>
