<template>
  <div class="container mt-5">
    <h3 style="font: normal normal normal 26px/33px Merriweather;" >Les Activités</h3>
    <p class="pb-4 raleway-reg-p15" style="font: normal normal normal 15px/18px Raleway;">Les Clubs Hortense sont des havres de paix où vous pouvez vous retrouver et profiter d'une multitude d'activités pour rendre chaque journée inoubliable. En famille, en couple ou entre amis, vous vous sentirez comme chez vous !</p>
    <div class="row">
      <div v-for="(item, index) in items" :key="index" class="col-md-4 d-flex mb-4">
        <div class="grpo">
          <img style="width: 106px;height: 90px;" :src="item.photo_key"  :alt="item.name" class="img-fluid">
        </div>
        <div class="yoga-description ml-1">
          <h3 style="font: normal normal 600 16px/15px Raleway;">{{ item.name }}</h3>
          <p style="ont: normal normal normal 15px/18px Raleway;">{{ item.price }}€</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['club_activities'],
  data() {
    return {
      items: this.club_activities
    }
  }
}
</script>

<style scoped>
.yoga-description {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.grpo {
  flex-grow: 0.1 !important;
}
</style>
