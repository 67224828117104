<template>
  <div id="desti-phone">
    <div class="phone-container">
      <div class="destination-header">
        <h2 class="merriweather-reg-26"> Nos </h2>
        <h2 class="merriweather-reg-40 mb-5">Destinations</h2>
        <p class="raleway-reg-p16">{{ $t("Destination.content") }}</p>
      </div>
        <template v-for='(destination, index) in destinationsMounted' v-if="index === 0">
          <CarddestiPhone :destination="destination"></CarddestiPhone>
        </template>
      <a class="hortense-btn-primary raleway-bold-p mt-5" :href="'/destination'">{{ $t("Destination.viewbtn") }}</a>
    </div>
  </div>
</template>


<script>

  import CarddestiPhone from 'components/cards-phone/CarddestiPhone.vue';


  export default {
    name: 'desti-phone',
    components: { CarddestiPhone },
    props: ['destinations'],
    data(){
      return{
        destinationsMounted: this.destinations,
      }
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/desti-phone'>

</style>
