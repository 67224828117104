<template>
  <div data-aos="zoom-out" data-aos-duration="1000">
    <div class="title-section">
      <h2 class="title-first merriweather-reg-26">L'expérience <span class="title-second merriweather-reg-40">Hortense</span></h2>
    </div>
    <div class="experience-hortense">
      <div class="cards-section">
        <div class="card-experience">
          <div class="card-upper-section card-1-color">
            <h3>Découvrez le programme de fidélité Hortensia</h3>
          </div>
          <div class="card-lower-section">
            <p>
              Voyagez comme un invité VIP avec Hortense ! Cumulez des points et profitez
              d’avantages exclusifs pour chaque réservation sur notre site dès que vous
              atteignez le statut Green Trotter.
            </p>
          </div>
        </div>
        <div class="card-experience card-middle">
          <div class="card-upper-section card-2-color">
            <h3>Obtenez -10% sur votre 1er séjour</h3>
          </div>
          <div class="card-lower-section">
            <p>
              Bénéficiez de 10 % de réduction sur votre premier séjour, quelle que soit
              sa durée ! Découvrez une expérience unique et vivez votre première escapade
              dans un hébergement de charme éco-responsable.
            </p>
          </div>
        </div>
        <div class="card-experience">
          <div class="card-upper-section card-3-color">
            <h3>Vivez bien plus qu'une simple nuit</h3>
          </div>
          <div class="card-lower-section">
            <p>
              Profitez des séjours clés en main, combinant hébergement de qualité,
              gastronomie raffinée, activités variées et services exclusifs, pour des
              escapades uniques et inoubliables.
            </p>
          </div>
        </div>
      </div>
      <div class="button-section">
        <button class="create-account-btn" @click="showModal = true">Je crée mon compte</button>
        <a class="login-link" @click="showModallogin = true">Je me connecte</a>
      </div>
    </div>
    <Modalsignup v-if="showModal" @close="setUser"></Modalsignup>
    <Modallogin v-if="showModallogin" @close="setUser"></Modallogin>
  </div>
</template>

<script>
import Modalsignup from './modals/Modalsignup.vue';
import Modallogin from './modals/Modallogin.vue';
import AOS from 'aos';

export default {
  name: 'ExperienceHortense',
  components: { Modalsignup, Modallogin },
  data() {
    return {
      showModal: false,
      showModallogin: false,
    }
  },
  methods: {
    setUser(user){
      this.user = user;
      this.showModal = false;
      this.showModallogin = false;
    },
  },
  mounted() {
    AOS.init();
  }
};
</script>
<style lang="scss" scope='app/assets/stylesheets/components/tips'>

</style>
<style scoped>
.experience-hortense {
  text-align: center;
  padding: 40px 20px;
  position: relative;
  color: #333;
}

.experience-hortense::before {
  content: "";
  position: absolute;
  top: 28%;
  left: 0;
  right: 0;
  bottom: 10%;
  background: url('../images/lodging-group/chambre-hotes-charme-hortense.jpeg') no-repeat center center;
  background-size: cover;
  z-index: -1;
}

.title-section {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  text-align: center;
  margin-top: 40px;
}

.bold-text{
  font: 40px / 33px Merriweather;
}

.title-section h2 {
  font-size: 2rem;
  margin: 0;
  color: #333;
}

.cards-section {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

/* Add horizontal scroll for mobile */
@media (max-width: 768px) {
  .cards-section {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
  }

  .card-experience {
    min-width: 280px;
    margin-right: 16px;
    scroll-snap-align: start;
  }
  .title-section {
    text-align: left;
  }
}

.card-experience {
  background-color: rgba(255, 255, 255);
  width: 320px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  position: relative;
  z-index: 1;
  margin-bottom: 1rem;
}

.card-middle {
  margin-top: -20px;
  z-index: 2;
}

.card-upper-section {
  padding: 20px;
}

.card-1-color {
  background-color: #cfe5dc;
}

.card-2-color {
  background-color: #000000;
  color: white;
}

.card-3-color {
  background-color: #3B6152;
  color: white;
}

.card-lower-section {
  padding: 20px;
  flex-grow: 1;
}

.card-experience h3 {
  margin: 0;
  font-size: 1.5rem;
}

.card-experience p {
  font-family: Raleway;
  line-height: 1.5;
  font-size: 17px;
}

.button-section {
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  height: 200px;
}

.create-account-btn {
  background-color: #3B6152;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-bottom: 10px;
}

.login-link {
  text-decoration: none;
  color: #000000;
  font-size: 1rem;
  border-bottom: 1px solid #000000;
  cursor: pointer;

}
</style>