<template>
  <div id="card-exp" v-if='this.catMount'>
    <div class="card-experience">
      <img v-if="this.catMount.photo_key" :src="this.catMount.photo_key" :alt="this.catMount.name">
      <img v-else src="https://hortense-vue.s3.eu-west-3.amazonaws.com/static/default.jpg" :alt="this.catMount.name">
      <p class="subtitle-card raleway-bold-p mt-4">{{ this.catMount.name }}</p>
      <p class="description-card raleway-reg-p13">{{ this.catMount.description }}</p>
      <a @click="redirectToExp" class="link-hortense">Découvrir nos expériences {{ this.catMount.name }}</a>
    </div>
  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';
  export default{
    name: 'CardExp',
    props: ['category'],
    data(){
      return{
        catMount: null,
      }
    },
    methods: {
      redirectToExp(){
        const regex = /s$/;
        Turbolinks.visit('/experiences?'+ this.category)
      },
      formatSlug(value) {
        return value.toLowerCase().replaceAll(' ', '-').replaceAll("'", "-")
      },
      async fetchdatas() {
        try {
          const res = await fetcher.get('/categories/'+ this.formatSlug(this.category));
          this.catMount = res.data;
        } catch(e) {
          console.error(e);
        }
      },
    },
    mounted() {
      this.fetchdatas();
    }
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/exp-phone'>

</style>
