<template>
  <div id="modal-gallery" v-if="this.images">
    <div class="modal-container">
      <div class="close-btn-photo">
        <h1 @click="$emit('close')">X</h1>
      </div>
      <div class="header">
        <div class="lodging-name">
        </div>
          <h1 class="merriweather-reg-24">{{ this.item.name }}</h1>
      </div>
      <div class="lodging-info-gallery">
        <p class="raleway-reg-p15 mb-2">{{ this.item.room_type }}</p>
      </div>
      <div class="images">
        <div class="box-images text-center">
          <img class="image-modal-gallery" v-for="(image, i) in this.images" :src="image.url" :key="i" @click="index = i" alt="Galery image">
        </div>
      </div>
      <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow>
    </div>
  </div>
</template>

<script>
  //import fetcher from 'packs/shared/api.js';
  import VueGallerySlideshow from 'vue-gallery-slideshow';

  export default{
    name: 'modal-gallery',
    props: {
      item: Object,
    },
    components: { VueGallerySlideshow },
    data() {
      return {
        room: this.item,
        images: this.item.photos_keys,
        index: null,
      }
    },
    methods: {
      // defineImageLodging() {
      //   const outsides = this.lodgingMount.outside_urls
      //   const commons = this.lodgingMount.common_part_urls
      //   this.images = outsides.concat(commons)
      // },
    },
    mounted() {
      // this.defineImageLodging();
    },
  }
</script>

