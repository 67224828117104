<template>
  <div id="multiselectfaq">
    <div class="dropdown dropdown-faq" @click="showDropdown">
      <div class="overselect"></div>
      <div class="c-form-input">
       <h2 class="raleway-semi-p13">{{category}}</h2>
       <img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="show">
        <img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else >
      </div>
    </div>
    <div class="multiselect" v-if="show" v-for=" g in groupedFaqs[category]">
      <ul>
        <li class="merriweather-italic question">{{ g.question }}</li>
        <pre class="raleway-reg-p15 answer ">{{ formatAnswer(g.answer)}}</pre>
      </ul>
    </div>
  </div>
</template>
<script>
  import fetcher from 'packs/shared/api.js';
export default{
  name: 'multiselectfaq',
  props: ['category', 'groupedFaqs[category]'],
  data() {
    return {
      show: false,
      faqsMounted: null,
      groupedFaqs: null,
      categoryFaqs: [],
    }
  },
   created() {
      this.fetchAllFaqs();
    },
  methods: {
    showDropdown() {
      this.show = !this.show
    },
    formatAnswer(string){
      return string.replace( /\W-/, "\n-")
    },
   async fetchAllFaqs() {
      try {
        const response = await fetcher.get('/pages/faqs.json');
        this.faqsMounted = response.data
        this.groupBy('category');
      } catch (e) {
        console.error(e)
      }
    },
    groupBy(key) {
      this.groupedFaqs = this.faqsMounted.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
      this.categoryFaqs = Object.keys(this.groupedFaqs)
    }
  },
}
</script>

<style lang="scss" scope='app/assets/stylesheets/components/faqs'>
  pre {
    white-space: pre-wrap;
  }
</style>

