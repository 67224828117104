<template>
	<div id="lodging-all">
		<div class="container-index">
			<div class="desti-header text-center">
        <h1 class="merriweather-reg-40 mt-4">Inspirations</h1>
        <h3 class="merriweather-italic24">
          Laissez-vous guider par les suggestions d'Hortense !
        </h3>
        <p class="raleway-reg-p15 mt-2">
          Authentique, intimiste, design, moderne... Découvrez notre sélection
          des plus beaux hébergements éco-responsables !
        </p>
				<p class="raleway-reg-p15">{{ this.formatLodgings() }}</p>
			</div>
      <div id="search-bannerr" ref="searchBar">
        <SearchLodging v-if="!isPhone" @updateFilteredLodgings="updateFilteredLodgings" @selectDates="updateDates" @close="false"></SearchLodging>
      </div>
			<div class="btn-filters mt-4">
				<div class="green-note-filter mr-3 df-jsb-aic" @click="sortByNote">
					<p class="raleway-reg-p13 mb-0 mr-3">Green note</p>
					<img src="../../images/fleches dropdown.svg" alt="fleches" />
				</div>
				<div
					v-if="this.lodgingsCounter !== 0"
					class="map-redirection"
					@click="displayMap"
				>
					<p class="raleway-reg-p13 mb-0">Carte</p>
				</div>
			</div>
			<div class="filters-phone">
				<p class="raleway-reg-p15 mb-0" @click="showFilters" v-if="showFilt">
					Masquer les filtres
				</p>
				<p class="raleway-reg-p15 mb-0" @click="showFilters" v-else>
					Appliquer les filtres
				</p>
			</div>
			<div class="row row-page">
				<div class="col-lg-3 col-xs-0 col-filter">
					<Filterlodgings
						:stylesMount="stylesMount"
						:servicesMount="servicesMount"
						:desiresMount="desiresMount"
						@checked="setData"
						@switch="setData"
						@changedBudget="setData"
					></Filterlodgings>
				</div>
				<div class="col-lg-9 col-xs-10">
					<div class="lodgings">
            <Maptest v-if="showMap" :markers="this.markers"  :lodgings="this.filteredLodgings" @markerSelected="selectCardMap" :selectCardMap="selectCardMap" :selectCard="selectCard" @close="showMap = false"></Maptest>
            <div v-if="!selectedMarker">
							<CardProductbig
								v-for="(lodging, index) in this.filteredLodgings"
								:key="index"
								:wishlistLodgings="wishlistLodgings" 
								:user="isUserConnected()"
								:lodging="lodging"
								:destination="lodging.destination"
								v-if="index === 0"
								:data-marker="index"
								:data-lodging="lodging.id"
								data-aos="fade-up"
								data-aos-duration="900"
								v-show="false"
							></CardProductbig>
							<CardProductbig
							    :wishlistLodgings="wishlistLodgings"
							    :lodging="lodging" 
								:user="isUserConnected()" 
								:destination="lodging.destination" 
								v-else 
								style="margin-top: 20px" :data-marker="index" 
								:data-lodging="lodging.id"
								v-show="false">
							</CardProductbig>
							
							<CardProductbig
								v-for="(lodging, index) in this.displayedLodgs"
								:key="index"
								:wishlistLodgings="wishlistLodgings" 
								:user="isUserConnected()"
								:lodging="lodging"
								:destination="lodging.destination"
								v-if="index === 0"
								:data-marker="index"
								:data-lodging="lodging.id"
								data-aos="fade-up"
								data-aos-duration="900"
								@hideBigMap="hideBigMap"
							></CardProductbig>
							<CardProductbig
							    :wishlistLodgings="wishlistLodgings" 
							    :lodging="lodging" 
								:user="isUserConnected()" 
								:destination="lodging.destination" 
								v-else 
								style="margin-top: 20px" :data-marker="index" 
								:data-lodging="lodging.id"
								@hideBigMap="hideBigMap">
							</CardProductbig>
						</div>
						<div v-else>
							<Cardmap
								:lodging="this.selectedLodging"
								:destination="this.selectedLodging.destination"
								@unselect="selectCard"
								:idMarker="selectedCard"
							></Cardmap>
						</div>
					</div>
					<nav v-if="lodgingsCounter !== 0">
								<ul class="pagination">
									<li class="page-item" @click="scrollToTop">
										<button
										    v-for="pageNumber in pages"
											:class="page === pageNumber ? 'active-item' : ''"
											type="button"
											class="page-link"
											@click="page = pageNumber"> 
											{{pageNumber}} 
										</button>
									</li>
								</ul>
					</nav>
					<div v-if="lodgingsCounter === 0" class="no-lodging text-center">
						<p class="raleway-reg-p15">Aucun hébergement disponible.</br>
						Hortense est à la recherche de lieux d’exception à vous proposer dans cette destination.</br></br>
						Si vous souhaitez que nous vous trouvions un hébergement de charme dans une destination spécifique</br>
						, <strong>n'hésitez pas à nous contacter et à nous fournir les détails de votre demande.</strong> Que ce soit un hôtel de charme en pleine nature, une</br>
						chambre d'hôtes à la campagne, une villa en bord de mer, un chalet en montagne ou un boutique hôtel en pleine ville,<br/>
						nous mettrons tout en œuvre pour trouver l'endroit parfait correspondant à vos attentes.</p></br>
						<a :href="'/voyage-sur-mesure/'" class="hortense-btn-primary" style="margin: 0px auto;display: inline">VOTRE VOYAGE SUR MESURE</a>					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import CardProductbig from "components/cards/CardProductbig.vue";
import Filterlodgings from "pages/destinations/Filterlodgings.vue";
import Cardmap from "components/cards/Cardmap.vue";
import Maptest from "components/Maptest.vue";
import fetcher from "packs/shared/api.js";
import AOS from "aos";
import SearchLodging from "../../components/btns/Searchlodging.vue";
export default {
	name: "inspirationsshow",
	components: {SearchLodging, CardProductbig, Filterlodgings, Maptest, Cardmap },
	props: ["lodgings", "user"],
	data() {
		return {
			wishlistLodgings: null,
			page: 1,
			perPage: 10,
			pages: [],	
			lodgingsList: null,
			filteredLodgings: [],
			showMap: false,
			markers: [],
			selectedMarker: false,
			selectedLodging: null,
			showFilt: false,
			selectedCard: null,
			current: 1,
      koediaFrom: null,
      koediaTo: null,
      from: null,
      to: null,
			asc: true,
			allValues: {
				// TODO static data could be fetched to be DRY
				score: [null, "4", "5", "6", "7", "8", "9", "10"],
				standing: [null, "0", "1", "2", "3", "4", "5"],
				type: [
					null,
					"Hotel",
					"Guestroom",
					"Gite",
					"Guesthouse",
					"Other",
					"Villa",
					"Appartment",
					"Lodge",
				],
				styles: [],
				services: [],
				desires: [],
				budget: [0, 2000],
				beddings: [
					null,
					"Lit double - Standard",
					"Lit double - King size",
					"King size",
					"king size",
					"Queen size",
					"Standard",
					"Lit double - Queen size",
					"queen-size",
					"Lit double",
					"Lit simple",
					"Lits jumeaux",
				],
				breakfast_include: false,
				half_pension: false,
				full_pension: false,
				all_inclusive: false,
				animal: false,
				business: false,
				freeCancel: false,
			},
			activeFilters: null,
			stylesMount: [],
			desiresMount: [],
			servicesMount: [],
		};
	},
	watch: {
		allValues: {
			deep: true,
			handler(val) {
				this.activeFilters = Object.assign({}, val);
			},
		},
		activeFilters: {
			deep: true,
			handler() {
				this.filter();
			},	
		},
		lodgings: {
			immediate: true,
			handler(val) {
				this.lodgingsList = val.map((el) => JSON.parse(el));
				this.filteredLodgings = this.lodgingsList;
				this.setPages();
			},
		},
		filteredLodgings() {
					this.setPages();
					console.log('pages');
					console.log(this.pages);		
		},
		desiresMount: {
			deep: true,
			handler(val) {
				if (!val.length) return;
				this.allValues.desires = val.map((el) => el.id);
			},
		},
		servicesMount: {
			deep: true,
			handler(val) {
				if (!val.length) return;
				this.allValues.services = val.map((el) => el.id);
			},
		},
		stylesMount: {
			deep: true,
			handler(val) {
				if (!val.length) return;
				this.allValues.styles = val.map((el) => el.id);
			},
		},
	},
	computed: {
		isPhone() {
			if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent)) {
				return true;
			}else{
				return false;
			}
		},
		displayedLodgs() {
			return this.paginate(this.filteredLodgings);
		},
		lodgingsCounter() {
			return this.filteredLodgings.length;
		},
	},
	beforeMount(){
			this.fetchWishlist();
	},
	methods: {
		hideBigMap() {
			   this.showMap = false;
		},
		async fetchWishlist() {
				try {
					if (this.user) {
						const response = await fetcher.get('/wishlists.json');
						this.wishlistLodgings = response.data.lodgings;
					}
				} catch (e) {
					console.error(e);
				}
		},
		scrollToTop() {
				window.scroll({
				top: 0,
				left: 0,
				behavior: 'smooth'
				});
		},
		setPages() {
			    this.page = 1;
			    this.pages = [];
				let numberOfPages = Math.ceil(this.filteredLodgings.length / this.perPage);
				for (let index = 1; index <= numberOfPages; index++) {
					this.pages.push(index);
				}
		   },
		   paginate(lodgs) {
			    let page = this.page;
			    let perPage = this.perPage;
			    let from = (page * perPage) - perPage;
			    let to = (page * perPage);
			    return  lodgs.slice(from, to);
		    },
		async fetchInfos() {
			try {
				let stylesRes = await fetcher.get("styles.json");
				this.stylesMount = stylesRes.data;

				let servicesRes = await fetcher.get("services.json");
				this.servicesMount = servicesRes.data;

				let desiresRes = await fetcher.get("desires.json");
				this.desiresMount = desiresRes.data;
			} catch (e) {
				console.log(e);
			}
		},
    updateFilteredLodgings(newLodgings) {
      console.log('newLodgings', newLodgings)
      this.filteredLodgings = newLodgings.data;
      this.lodgings = newLodgings.data;
      this.pagedNumber = newLodgings.headers['x-lodgings-count'];
    },
    updateDates(dates) {
      if(dates && dates.length > 0) {
        this.koediaFrom = this.formatDate(dates[0]);
        this.koediaTo = this.formatDate(dates[1]);
        this.from = this.koediaFrom;
        this.to = this.koediaTo;
      }
    },
		setData(val, name) {
			// as for animal switch for example
			if (typeof val === "boolean") {
				this.activeFilters[name] = val;
				return;
			}

			// all unchecked means we actually want all of them
			if (Array.isArray(val) && !val.length) {
				this.activeFilters[name] = [...this.allValues[name]];
			} else {
				this.activeFilters[name] = [...val];
			}
		},
		showFilters() {
			const filter = document.querySelector("#filter-lodging");
			if (filter.style.display === "block") {
				this.showFilt = false;
				filter.style.display = "none";
			} else {
				filter.style.display = "block";
				this.showFilt = true;
			}
		},
		isUserConnected() {
			return this.user ? this.user : null;
		},
		sortByNote() {
			this.filteredLodgings.sort((a, b) => {
				if (this.asc) {
					return a.score - b.score;
				} else {
					return b.score - a.score;
				}
			});
			this.asc = !this.asc;
		},
		filter() {
			this.filteredLodgings = this.lodgingsList;

			Object.entries(this.activeFilters).forEach(([filter, value]) => {
				// attributes with a unique value
				if (["score", "standing", "type"].includes(filter)) {
					this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
						return value.includes(lodging[filter]?.toString());
					});
				}

				// attributes with multiple possible values
				if (["styles", "services", "desires", "beddings"].includes(filter)) {
					this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
						return this.includesIn(value, lodging[filter + "_arr"]);
					});
				}

				// remove lodgings that don't offer a pricing for it
				//if (
				//	[
				//		"breakfast_include",
				//		"half_pension",
				//		"full_pension",
				//		"all_inclusive",
				//	].includes(filter)
				//) {
				//	this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
				//		if (value === false) return true;

				//		return lodging[filter + "_pricings"].some((pricing) => pricing > 0);
				//	});
				//}
				console.log('LODGINGS BEFORE');
			    console.log('filter');
				console.log(filter);
				console.log(this.filteredLodgings);

				// number range
        if (filter === 'budget') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            return lodging.lowest_price >= value[0] && lodging.lowest_price <= value[1];
          });
        }

        if (filter === 'adults') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            return lodging.accommodation_capacity == null || lodging.accommodation_capacity >= value || lodging.accommodation_capacity == 0;
          });
        }

				// toggle button, only filter when toggled
				if (filter == "animal") {
					this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
						if (value === false) return true;

						return lodging.lodging_profile.animal === true;
					});
				}

				// means only Leonie collection
				if (filter == "business") {
					this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
						if (value === false) return true;

						return lodging.collections_arr.includes("Léonie");
					});
				}

				// free when canceling price is either nil or 0
        if (filter == 'freeCancel') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            if (value === false) return true;

            return lodging.lodging_description.is_club
          });
        }

        if (filter === 'delice') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            if (value === false) return true;

            return lodging.lodging_profile.delice === true;
          });
        }

        if (filter === 'escapade') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            if (value === false) return true;

            return lodging.lodging_profile.escapade === true;
          });
        }

        if (filter === 'evasion') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            if (value === false) return true;

            return lodging.lodging_profile.evasion === true;
          });
        }

        if (filter === 'plaisirs') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            if (value === false) return true;

            return lodging.lodging_profile.plaisirs === true;
          });
        }

        if (filter === 'kids') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            return this.includesIn('Kids-friendly', lodging['services_arr']);
          });
        }

        if (filter === 'station') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            return this.includesIn('Gare à promixité', lodging['services_arr']);
          });
        }

        // means only Leonie collection
        if (filter === 'business') {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            if (value === false) return true;

            return lodging.collections_arr.includes('Léonie');
          });
        }

        if (['eq_services'].includes(filter)) {
          this.filteredLodgings = this.filteredLodgings.filter((lodging) => {
            return this.includesInValue(value[0], lodging['services_arr']);
          });
        }
			});
		},
		formatLodgings() {
			let hebergement = " hébergement";
			if (this.lodgingsCounter > 1) {
				hebergement += "s";
			}
			return this.lodgingsCounter + hebergement;
		},
		selectCard(attr) {
			const cards = document.querySelectorAll("#cardProduct-big");
			const selectedCard = cards[attr[0]];
			this.selectedCard = attr[0];
			this.selectedMarker = !this.selectedMarker;
			cards.forEach((el) => {
				if (el?.dataset?.marker !== selectedCard?.dataset?.marker) {
					el.style.display === "none"
						? (el.style.display = "flex")
						: (el.style.display = "none");
				}
			});
			this.selectedLodging = this.lodgingsList.filter((lod) => {
				return lod.id === parseInt(selectedCard?.dataset?.lodging);
			})[0];
		},
		selectCardMap(attr){
				this.selectCard(attr);
				if(this.showMap){
					setTimeout(() => {this.showMap = false}, 10);	
					setTimeout(() => {this.showMap = true}, 10);	
				}
		},
		displayMap() {
			this.showMap = !this.showMap;
			this.markers = this.markerMap();
		},
		includesIn(array1, array2) {
			// don't filter out this lodging when services, beddings, styles, or desires are not set
			if (!array2.length) return true;

			array1 = array1.map((e) => e?.toString());
			array2 = array2.map((e) => e?.toString());
			for (let val of array1) {
				if (array2.includes(val)) return true;
			}
			return false;
		},
		markerMap() {
			const markers = [];
			this.filteredLodgings.map((el) => {
				markers.push([el.longitude, el.latitude,el.id,  el.lowest_price]);
			});
			return markers;
		},
	},
	async created() {
		this.fetchInfos();
		AOS.init();
	},
};
</script>
<style lang="scss" scoped>
.page-item{
	border-color: black;
        button{
		display: inline-block; 
		color: black;
	}
}
.active-item{
	color: green;
	border-color: black;
	border-style: solid;
}
.cta{
  margin: 0px auto;
  display: inline
}

#search-bannerr.sticky {
  position: fixed;
  top: 40px;
  width: 80%;
  z-index: 1000;
  background: transparent;
}
</style>