<template>
  <div id="legals">
    <div class="container-index">
      <h1 class="merriweather-reg-40 text-center mb-5">Mentions légales</h1>
      <div class="container-profil">
        <pre class="raleway-reg-p15">
<strong>Edition du site internet</strong>

Le site www.hortense.green (ci-après « le Site ») est édité par la société HORTENSE, Société par actions simplifiée unipersonnelle au capital de 15.000 euros enregistrée au RCS de Créteil sous le numéro 892 531 476, dont le siège social est sis 112 avenue de Paris – 94300 Vincennes (ci-après « l'Editeur »).

<strong>Rédaction du site internet</strong>

Directeur de la publication : Madame Aurore Sterling
Contact : contact@hortense.green

<strong>Hébergeur du site internet</strong>

Le site www.hortense.green est hébergé par Heroku Inc dont le siège social est situé au 650, 7th Street, San Francisco, Californie, Etats-Unis (téléphone : +33 1 (877) 563-4311).

<strong>Collecte de données</strong>

Les informations personnelles pouvant être recueillies sur le Site sont principalement utilisées par l'Editeur pour la gestion des relations avec vous, et le cas échéant pour une fourniture personnalisée des Services. Elles sont enregistrées dans le fichier de clients de l'Editeur.

Conformément aux dispositions de la loi n° 78-17 du 6 janvier 1978 modifiée, relative à l'informatique, aux fichiers et aux libertés et au Règlement Général sur la Protection des Données, vous êtes informé(e) que l'Editeur procède à des traitements automatisés de vos données personnelles, notamment lors de votre connexion au Site ou dans le cadre des formulaires en ligne remplis par vos soins.

Vous disposez d'un droit d'accès, d'interrogation, de modification et de suppression des informations qui vous concernent, à exercer à tout moment auprès de l'Editeur soit directement sur le Site à la rubrique « Nous écrire », soit par courrier postal à l'adresse suivante : 112 avenue de Paris – 94300 Vincennes, soit par courriel contact@hortense.green.

Vous êtes invité à vous reporter à la politique de protection des données du Site pour obtenir toutes les informations relatives à ce point www.hortense.green/politique-confidentialité/.

<strong>Respect de la propriété intellectuelle</strong>

Toutes les marques, photographies, textes, commentaires, illustrations, images animées ou non, séquences vidéo, sons, ainsi que toutes les applications informatiques qui pourraient être utilisées pour faire fonctionner le Site et plus généralement tous les éléments reproduits ou utilisés sur le Site sont protégés par les lois en vigueur au titre de la propriété intellectuelle.

Ils sont la propriété pleine et entière de l'Editeur ou de ses partenaires, sauf mentions particulières.

Toute reproduction, représentation, utilisation ou adaptation, sous quelque forme que ce soit, de tout ou partie de ces éléments, y compris les applications informatiques, sans l'accord préalable et écrit de l'Editeur, sont strictement interdites. Le fait pour l'Editeur de ne pas engager de procédure dès la prise de connaissance de ces utilisations non autorisées ne vaut pas acceptation desdites utilisations et renonciation aux poursuites.

La reprise de tout ou partie de ce contenu nécessite l’autorisation préalable de l’Editeur ou du titulaire des droits sur ce contenu.

<strong>Liens hypertextes</strong>

Le Site peut contenir des liens hypertexte donnant accès à d'autres sites web édités et gérés par des tiers et non par l'Editeur. L'Editeur ne pourra être tenu responsable directement ou indirectement dans le cas où lesdits sites tiers ne respecteraient pas les dispositions légales.

La création de liens hypertexte vers le Site ne peut être faite qu'avec l'autorisation écrite et préalable de l'Editeur.

        </pre>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'legals',
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/static-pages'>
</style>
