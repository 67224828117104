<template>
  <div id="faq">
    <div class="container-index">
       <div class="index-text">
        <h1 class="merriweather-reg-40 mb-5">FAQ</h1>
      </div>
        <div class="img-whois-header">
          <img src="../../images/FAQ-1.jpeg" alt="">
        </div>
      <template v-for="category in categoryFaqs">
        <Multiselectfaq :category="category" :groupedFaqs[category]="groupedFaqs[category]"></Multiselectfaq>
      </template>
      <div class="img-whois-header">
        <img src="../../images/FAQ-2.jpg" alt="">
      </div>
    </div>
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js'
  import Multiselectfaq from 'components/faqs/Multiselectfaq.vue'

  export default{
    name: 'faq',
    components: { Multiselectfaq },
     data() {
      return {
       faqsMounted: null,
       groupedFaqs: null,
       categoryFaqs: [],
        show: false,
      }
    },
    created() {
      this.fetchAllFaqs();
    },
    methods: {
      showDropdown() {
        this.show = !this.show
      },
      async fetchAllFaqs() {
        try {
          const response = await fetcher.get('/pages/faqs.json');
          this.faqsMounted = response.data
          this.groupBy('category');
        } catch (e) {
          console.error(e)
        }
      },
      groupBy(key) {
        this.groupedFaqs = this.faqsMounted.reduce((rv, x) => {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
        this.categoryFaqs = Object.keys(this.groupedFaqs)
      }
    },
  }
</script>
<style lang="scss" scope='app/assets/stylesheets/components/faqs'>


</style>
