<template>
  <div id="card-promo-code">
    <div class="head-card">
      <p class="raleway-reg-p24">Vos codes exclusifs</p>
      <p class="raleway-reg-p15">Vous avez remporté un code promo suite à un concours Hortense sur nos réseaux sociaux ? Vos codes sont disponibles juste ici. N’oubliez pas de les utiliser avant la date de validité.</p>
    </div>
    <div class="green-section mt-4">
      <!-- <template v-for="promoCode in this.promoCodes"> -->
        <div class="green-night mb-3">
          <p class="raleway-bold-p mb-0">BIENVENUE</p>
          <p class="raleway-reg-p15 mb-0">- 10% sur votre premier séjour</p>
          <p class="raleway-reg-p15 mb-0">{{ this.validityDate }}</p>
        </div>
      <!-- </template> -->
    </div>
    <!-- <p class="raleway-reg-p15 mt-2">Vos points sont échangeables contre des nuits offertes : les Green Nights³. Vous pouvez réduire le montant total de votre séjour en utilisant vos points, directement depuis l’espace de réservation.</p> -->
  </div>
</template>

<script>

  import fetcher from 'packs/shared/api.js';

  export default {
    name: 'card-promo-code',
    props: ["user"],
    data() {
      return {
        userStatut: '',
        userMount: '',
        promoCodes: 1,
        validityDate: this.user && this.user.created_at 
          ? new Date(new Date(this.user.created_at).setFullYear(new Date(this.user.created_at).getFullYear() + 1)).toLocaleDateString('en-GB') 
          : 'no validity date'
      }
    },
    methods: {
      async setUserLevel(){
        const res = await fetcher.get('/dashboard/'+this.user.slug)
        this.userMount = res.data
        this.userStatut = this.userMount.privilege.statut
      },
    },
    mounted() {
      this.setUserLevel();
    },
  }
</script>

<style lang="scss" scope='app/assets/stylesheets/components/wishlist-user'>

</style>
