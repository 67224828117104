<template>
	<div id="multiselectnote">
		<div class="dropdown dropdown-filter" @click="showDropdown">
			<div class="overselect"></div>
			<div class="c-form-input">
				<h2 class="raleway-semi-p13">Green Note</h2>
				<img src="../../images/Fleche haut.svg" alt="fleche haut" v-if="show" />
				<img src="../../images/flechebas-simple.svg" alt="fleche bas" v-else />
			</div>
		</div>
		<div class="multiselect" v-if="show">
			<ul>
				<div class="row row-selectoptions mt-3">
					<div class="col-6">
						<li>
							<input type="checkbox" id="10" value="10" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="10">
								<img src="../../images/greennote 10.svg" alt="greennote10" class="mr-2" />
								<strong>10/</strong>10
							</label>
						</li>
						<li>
							<input type="checkbox" id="9" value="9" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="9">
								<img src="../../images/greennote 9.svg" alt="greennote9" class="mr-2" />
								<strong>9/</strong>10
							</label>
						</li>
						<li>
							<input type="checkbox" id="8" value="8" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="8">
								<img src="../../images/greennote 8.svg" alt="greennote8" class="mr-2" />
								<strong>8/</strong>10
							</label>
						</li>
						<li>
							<input type="checkbox" id="7" value="7" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="7">
								<img src="../../images/greennote 7.svg" alt="greennote7" class="mr-2" />
								<strong>7/</strong>10
							</label>
						</li>
					</div>
					<div class="col-6">
						<li>
							<input type="checkbox" id="6" value="6" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="6">
								<img src="../../images/greennote 6.svg" alt="greennote6" class="mr-2" />
								<strong>6/</strong>10
							</label>
						</li>
						<li>
							<input type="checkbox" id="5" value="5" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="5">
								<img src="../../images/greennote 5.svg" alt="greennote5" class="mr-2" />
								<strong>5/</strong>10
							</label>
						</li>
						<li>
							<input type="checkbox" id="4" value="4" v-model="selected" />
							<label class="raleway-reg-p13 ml-2" for="4">
								<img src="../../images/greennote 4.svg" alt="greennote4" class="mr-2" />
								<strong>4/</strong>10
							</label>
						</li>
					</div>
				</div>
			</ul>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'multiselectnote',
		emits: ['checked'],
		data() {
			return {
				show: false,
				selected: [],
			};
		},
		methods: {
			showDropdown() {
				this.show = !this.show;
			},
		},
		watch: {
			selected(val) {
				this.$emit('checked', val, 'score');
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
