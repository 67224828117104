<template>
  <div id="whoIshome">
    <div class="whoishome-bg" data-aos="fade-up" data-aos-duration="1000">
      <div class="card-whoishome">
        <div class="whoishome-header">
          <h2 class="merriweather-reg-26 pr-2"> Qui est </h2>
          <h1 class="merriweather-reg-48"> Hortense ? </h1>
        </div>
        <p class="raleway-reg-p15"> Profondément passionnée et impliquée, Hortense redéfinit les liens entre les différents acteurs du tourisme durable. Voyageurs, hôteliers, agences de voyage en ligne : tous ensemble, voyageons dans le bon sens.</p>
        <a :href="'/qui-est-hortense-voyage-ecoresponsable/'" class="hortense-btn-primary">Découvrir Hortense</a>
      </div>
    </div>
  </div>
</template>


<script>
  import AOS from 'aos';

  export default {
    name: 'whoIshome',
    mounted() {
      AOS.init();
    }
  }
</script>


<style lang="scss" scope='app/assets/stylesheets/components/whoishome'>

</style>
