<template>
  <div id="navbar-main">
    <div class="top-bar">
      <i class="fa-solid fa-gift" style="margin-right: 5px;"></i>
      <span>-10% sur votre 1er séjour</span>
    </div>
    <div id="navbar">
      <div class="navbar-vue">
        <div class="navbar-top">
          <div v-if="this.isActiveHortense" class="el-center--flex-column no-deco pointer active-dropdown" @click="showHortense">
            <ul class="list-items">
              <li class="item-title">{{ $t("Hortense.whois") }}</li>
              <li class="no-deco-black-cross">X</li>
              <li>
                <a @click='visitLink(1)' class="no-deco-black">{{ $t("Hortense.whois") }}</a>
              </li>
              <li>
                <a @click='visitLink(2)' class="no-deco-black">{{ $t("Hortense.greenhost") }}</a>
              </li>
              <li>
                <a @click='visitLink(3)' class="no-deco-black">{{ $t("Hortense.greennote") }}</a>
              </li>
              <li>
                <a @click='visitLink(4)' class="no-deco-black">{{ $t("Hortense.evergreen") }}</a>
              </li>
              <li>
                <a @click='visitLink(5)' class="no-deco-black">Le programme fidélité</a>
              </li>
            </ul>
          </div>
          <div v-else class="el-center--flex-column no-deco pointer" id="dropdown-hortense" @click="showHortense">
            {{ $t("Hortense.whois") }}
          </div>


          <a href="/" class="logo">
            <img src="../../images/Logo hortense.svg" alt="logo">
          </a>

          <div class="navbar-profile el-center--space-around">
            <div class="box-items el-center--space-evenly">
              <div v-if="this.user" class="div-user-dropdown" @click="showMenu = !showMenu">
                <Profildropdown :user="this.user" v-if="showMenu"></Profildropdown>
                <div class="el-center--flex-column no-deco-profil pointer" id="dropdown-profil">
                  <p class="mb-0 raleway-reg-p15">{{this.userInit(this.user.first_name)}}{{this.userInit(this.user.last_name)}}</p>
                </div>
              </div>
              <a v-else id="show-modal" @click="showModal = true">
                <i class="fa-solid fa-user"></i>
              </a>
              <!-- <hr class="item-separator"> -->
              <span class="item-separator">|</span>
              <a @click.prevent='redirectIfUser'>
                <i class="fa-solid fa-heart"></i>
              </a>
              <img v-if="this.userLevel === 'Green Trotter'" src="../../images/badge-guest.png" class="badge-level" alt="">
              <img v-if="this.userLevel === 'Green Trotter Plus'" src="../../images/badge-member.png" class="badge-level" alt="">
              <img v-if="this.userLevel === 'Green Trotter Premium'" src="../../images/badge-nomad.png" class="badge-level" alt="">
              <img v-if="this.userLevel === 'Green Trotter Infinite'" src="../../images/badge-trotter.png" class="badge-level" alt="">
            </div>

          </div>

        </div>
        <div class="navbar-bottom el-center">
          <ul class="el-center--space-evenly" style="width: 100%; list-style: none;">
            <li class="item-nav"><a :href="this.origin + '/reservation-hotel'" class="no-deco">{{ $t("selection") }}</a></li>
            <li class="item-nav"><a :href="this.origin + '/collections'" class="no-deco">{{ $t("collections") }}</a></li>
            <!-- <li class="item-nav"><a :href="this.origin + '/yogacation'" class="no-deco">{{ $t("yogacation") }}</a></li> -->
            <!-- <li class="item-nav"><a :href="this.origin + '/offres-exclusives'" class="no-deco">{{ $t("exclusive") }}</a></li> -->
            <li class="item-nav"><a :href="this.origin + '/destination'" class="no-deco">{{ $t("destinations") }}</a></li>
            <li class="item-nav"><a :href="this.origin + '/inspirations-voyage-ecoresponsable'" class="no-deco">Inspirations</a></li>
  <!--          <li class="item-nav"><a :href="this.origin + '/experiences'" class="no-deco">{{ $t("experiences") }}</a></li>-->
            <li class="item-nav"><a :href="this.origin + '/galerie-hotel-ecoresponsable'" class="no-deco">Galerie</a></li>
            <li class="item-nav"><a href="https://www.blog.hortense.green/" class="no-deco">{{ $t("newspaper") }}</a></li>
          </ul>
        </div>
        <ModalLoginAdmin v-if='showAdminModal' @closeAdmin='closeModalAdmin'></ModalLoginAdmin>
      </div>
      <Modalsignup v-if="showModal" @close="setUser"></Modalsignup>
    </div>
  </div>
</template>

<script>
  import fetcher from 'packs/shared/api.js';
  import Modalsignup from 'components/modals/Modalsignup.vue';
  import Profildropdown from 'components/menus/Profildropdown.vue';
  import ModalLoginAdmin from 'components/modals/ModalLoginAdmin';

  export default {
    name: "navbar",
    components: { Modalsignup, Profildropdown, ModalLoginAdmin },
    props: ["user"],
    data() {
      return {
        showMenu: false,
        isActiveHortense: false,
        isActiveCurrencies: false,
        showModal: false,
        showAdminModal: false,
        selectedCurrency: "FR/EUR",
        currencies: [{"currency": "FR/EUR", "lang": "fr"}, {"currency": "UK/GBP", "lang": "en"}, {'currency': "US/USD", "lang": "en"}],
        locale: 'fr',
        origin: window.location.origin.toString(),
        userLevel: null,
        userMount: '',
      }
    },
    watch: {
      locale(val) {
        this._i18n.locale = val
      }
    },
    mounted() {
      this.setUserLevel();
      this.$root.$on('openModal', _ => {
        this.showModal = true;
      });

      if (window.location.search === '?signin' && !this.user) {
        this.showModal = true;
      }

      if (window.location.search === '?admin_login' && !this.user) {
        this.showAdminModal = true;
      }
    },
    methods: {
      visitLink(n){
          switch (n) {
          case 1:
            window.location.href = '/qui-est-hortense-voyage-ecoresponsable/'
            break;
          case 2:
            window.location.href = '/label-ecoresponsable-green-host/'
            break;
          case 3:
            window.location.href = '/green-note-engagement-environnemental/'
            break;
          case 4:
            window.location.href = '/programme-evergreen-demarche-environnementale/'
            break;
          case 5:
            window.location.href = '/programme-fidélité-hortensia/'
            break;
        }
      },
      closeModalAdmin() {
        window.location.search = '';
        this.showAdminModal = false;
      },
      userInit(string){
        const names = string.split(','),
          initials = names[0].substring(0, 1).toUpperCase();
        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1).toUpperCase();
        }
        return initials;
      },
      setUser(user){
        this.user = user;
        this.showModal = false;
      },
      async setUserLevel(){
        try {
          if (this.user) {
            const res = await fetcher.get('/dashboard/'+this.user.slug)
            this.userMount = res.data
            if (this.userMount.privilege) {
              this.userLevel = this.userMount.privilege.statut
            }
          }
        } catch(e) {
          console.error(e.message)
        }
      },
      showHortense() {
        this.isActiveHortense = !this.isActiveHortense;
      },
      showCurrencies(e) {
        const el = e.currentTarget.lastElementChild;
        el.style.transform = el.style.transform ? '' : 'rotateZ(180deg)';
        this.isActiveCurrencies = !this.isActiveCurrencies;
      },
      currenciesLeft() {
        return this.currencies.filter(x => {return x.currency !== this.selectedCurrency })
      },
      selectCurrency(e) {
        this._i18n.locale = e.currentTarget.value;
        this.selectedCurrency = e.currentTarget.innerText;
        this.isActiveCurrencies = false;
        const el = document.querySelector('.arrow');
        el.style.transform = el.style.transform ? '' : 'rotateZ(180deg)';
      },
      redirectIfUser() {
        if (this.user) {
          Turbolinks.visit('/wishlists');
        }
        this.showModal = true;
      }
    },
  }
</script>

<style lang="scss" scoped>
  #show-modal {
    cursor: pointer;
  }
  #dropdown-hortense {
    width: 257px;
    display: flex;
    align-items: flex-start;
    font-weight: 600;
    padding-left: 3rem;
  }

  #dropdown-profil{
   width: 30px;
    height: 30px;
    p{
      color:black;
    }
  }


  .active-dropdown {
    width: 257px;
    height: 185px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    position: relative;
    left: -43px;
    top: 6px;
    z-index: 2;
    margin-left: 3rem;
    .list-items{
      list-style: none;
      padding: 2px;
      li{
        padding-bottom: 0.4rem;
      }
    }
    .item-title {
      font: normal normal 13px/36px $Raleway;
      letter-spacing: 0px;
      color: black;
      text-transform: uppercase;
      height: 30px;
    }
  }

  .pointer {
    cursor: pointer;
  }

  .no-deco {
    color: black;
    font-family: $Raleway;
    font-weight: 400;
    font-size: 13px;
    text-transform: uppercase;

    &:hover {
      text-decoration: none;
      color: #000000;
    }
  }
  .no-deco-black {
    color: black;
    font-family: $Raleway;
    font-weight: 400;
    font-size: 13px;
    text-transform: uppercase;
    color: black;
    &:hover {
      text-decoration: none;
      color: #000000;
      border-bottom: 5px solid #3B6152;
    }
  }

  .no-deco-black-cross {
    display: flex;
    justify-content: flex-end;
    color: black;
    font-family: $Raleway;
    font-weight: 400;
    font-size: 15px;
    text-transform: uppercase;
    color: black;
    &:hover {
      text-decoration: none;
      font-weight: 600;
    }
  }

  .profil-photo {
    width: 30px;
    height: 30px;
    border: 1px solid #A5D3AE;
    border-radius: 50%;
    background: url(https://images.unsplash.com/photo-1608504408764-f7a95454afd5?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=647&q=80);
    background-position: center;
    background-size: cover;
  }

  .item-nav {
    text-align: center;
    font: normal normal 13px/15px $Raleway;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
  }

  .navbar-bottom {
    width: 1043px;
    height: 15px;
  }

  .currencies-block {
    cursor: pointer;
  }

  .currencies {
    width: 57px;
    height: 15px;
    text-align: right;
    font: normal normal 13px/15px $Raleway;
    letter-spacing: 0px;
    color: #000000;
    text-transform: uppercase;
    opacity: 1;
    &-block {
      width: 72px;
      height: 15px;
    }
  }

  .arrow {
    display : inline-block;
    height : 0;
    width : 0;
    border-top : 9px solid black;
    border-right : 5px solid transparent;
    border-left : 5px solid transparent;
  }

  .el-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    &--flex-column {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    &--space-around {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    &--space-evenly {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
    }
    &--space-between {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  #navbar-main {
    display: flex;
    flex-direction: column;
    .top-bar {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;
      font-size: 15px;
      color: white;
      background: #1c1c1c;
      position: fixed;
      top: 0;
      z-index: 100;
    }
    #navbar {
      height: 154px;
      width: 100%;
      //background: transparent;
      // position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      // backdrop-filter:brightness(107%);
  
      .logo{
        img{
          width: 280px;
        }
      }
  
      .navbar-vue {
        width: 1280px;
        height: 106px;
        max-height: 106px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        //z-index: 10;
        position: sticky;

        .navbar-top {
          display: flex;
          justify-content: space-between;
          width: 100%;
          max-height: 52.54px;
          .navbar-profile {
            width: 192px;
            .box-items {
              width: 87px;
              height: 40px;
              border: solid 1px black;
              border-radius: 64px;
              i{
                color:black;
              }
              .item-separator{
                color: black;
                width: 10px;
                margin: 0;
              }
              .div-user-dropdown{
                width:2rem;
              }
            }
          }
        }
      }
    }
  }

  .badge-level{
    width: 20px;
    right: 152px;
  }

  .dropdown {
    width: 40px;
    &__header {
      cursor: pointer;
      line-height: 50px;
      // padding-left: 10px;
      // padding-right: 50px;
      position: relative;
      text-overflow: ellipsis;
      margin-top: 29px;
      margin-left: 1rem;
      margin-top: 29px;
      margin-left: 1rem;
      margin-right: 0px;
      &.is-active {
        + .dropdown__content {
          width: 257px;
          height: 185px;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          position: relative;
          left: -95px;
          top: 108px;
          z-index: 2;
          opacity: 1;
          visibility: visible;
          ul{
            height: inherit;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: flex-start;
            list-style: none !important;
          }
        }
      }
    }
    &__content {
      height: 0;
      opacity: 0;
      overflow: hidden;
      padding: 15px 10px;
      transition: opacity .3s;
      visibility: hidden;
    }
  }
</style>
