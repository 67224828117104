<!-- MainSearchMobile.vue -->
<template>
  <div id="search-bannerr-mobile" class="mt-4" ref="searchBannerMobile">
    <div class="container-banner-mobile">
      <div class="search-location">
        <img src="../../images/Loupe.png" alt="search icon" class="icon" />
        <input
          class="raleway-reg-p15 search-bar-location"
          type="text"
          placeholder="Recherchez un hôtel, une destination"
          v-model="search"
        />
      </div>
      <div class="search-details-mobile">
        <!-- Date Picker -->
        <div class="search-duration">
          <img src="../../images/3.png" alt="calendar icon" class="iconn" />
          <HotelDatePicker
            :startDate="minDate"
            :endDate="maxDate"
            :format="'DD/MM/YYYY'"
            :gridStyle="false"
            :hoveringTooltip="false"
            :i18n="cFR"
            ref="datePicker"
            @periodSelected="selectDates"
          ></HotelDatePicker>
        </div>

        <!-- Guest Info -->
        <div class="search-number" style="position: relative;"> <!-- Ensure this has position relative for dropdown to be positioned correctly -->
          <img src="../../images/pers.png" alt="guest icon" class="iconn" />
          <input
            class="raleway-reg-p15 search-bar-top text-center"
            @click="showGuestInfo = !showGuestInfo"
            :placeholder="formatAdults()"
          />
          <MobileGuestInfo
            v-if="showGuestInfo"
            @ageSelected="updateAges"
            @increaseRooms="updateRooms"
            @decreaseRooms="updateRooms"
            @increaseChilds="updateChilds"
            @decreaseChilds="updateChilds"
            @increaseAdults="updateAdults"
            @decreaseAdults="updateAdults"
            @closed="handleClose"
          />
        </div>
      </div>

      <button class="hortense-btn-primary" :disabled="disableGoButton" @click="performSearch">
        <div class="search-button">{{ searchButtonCaption }}</div>
      </button>
    </div>
  </div>
</template>
  
  <script>
  import MobileGuestInfo from "components/btns/MobileGuestInfo.vue";
  import { VueDatePicker } from "@mathieustan/vue-datepicker";
  
  export default {
    name: "MainSearchMobile",
    components: { MobileGuestInfo, VueDatePicker },
    data() {
      return {
        search: "",
        selectedDates: [],
        showGuestInfo: false,
        rooms: 1,
        childs: 0,
        adults: 2,
        childAges: [],
        currentDate: new Date(),
        cFR: {
          "day-names": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
          "month-names": [
            "Janvier",
            "Février",
            "Mars",
            "Avril",
            "Mai",
            "Juin",
            "Juillet",
            "Août",
            "Septembre",
            "Octobre",
            "Novembre",
            "Décembre",
          ],
          "check-in": "Départ",
          "check-out": "Arrivée",
        },
      };
    },
    methods: {
      handleClose() {
        this.showGuestInfo = false;
      },
      selectDates(event, start, end) {
        this.selectedDates = [this.formatDate(start), this.formatDate(end)];
      },
      formatDate(date) {
        const set = new Date(date);
        const options = { year: "numeric", month: "numeric", day: "numeric" };
        return set.toLocaleString("fr", options);
      },
      formatKoediaDate(date) {
            if (date) {
            const dateBits = date.split("/");
            return `${dateBits[2]}-${dateBits[1]}-${dateBits[0]}`;
            } else {
            return null;
            }
        },
      performSearch() {
        const trimmedSearch = this.search ? this.search.trim() : '';
        const params = new URLSearchParams({
          search: trimmedSearch,
          childs: this.childs,
          adults: this.adults,
          start_date: this.selectedDates ? this.formatKoediaDate(this.selectedDates[0]) : null,
          end_date: this.selectedDates ? this.formatKoediaDate(this.selectedDates[1]) : null,
        });
        window.location.href = `/reservation-hotel?${params.toString()}`;
      },
      updateRooms(room) {
      this.rooms = room;
      this.$emit("numberOfRoom", this.rooms);
    },
    formatRooms() {
      let chambre = " Chambre";
      if (this.rooms > 1) {
        chambre += "s";
      }
      return this.rooms + chambre;
    },
    updateChilds(child) {
      this.childs = child;
      this.$emit("numberOfChild", this.childs);
    },
    updateAdults(adult) {
      this.adults = adult;
      this.$emit("numberOfAdult", this.adults);
    },
    updateAges(value) {
      this.childAges = [...value];
      this.$emit("ageOfChild", this.childAges);
    },
    formatAdults() {
      let adulte = " Adulte";
      if (this.adults > 1) {
        adulte += "s";
      }
      return this.adults + adulte;
    },
      formatChilds() {
        let enfant = " Enfant";
        if (this.childs > 1) {
          enfant += "s";
        }
        return this.childs + enfant;
      },
    },
    computed: {
      searchButtonCaption() {
        return this.isSearching ? "Recherche..." : "RECHERCHER";
      },
      disableGoButton() {
        return false;
      },
      minDate() {
        return new Date(this.currentDate.getFullYear(), this.currentDate.getMonth(), this.currentDate.getDate());
      },
      maxDate() {
        return new Date(this.currentDate.getFullYear() + 1, this.currentDate.getMonth(), this.currentDate.getDate());
      },
    },
  };
  </script>
  
  <style scoped>
  .vhd__datepicker__clear-button{
    left:102% !important
  }
  .vhd__datepicker__clear-button svg {
    left: 150% !important;
}
  input[type="text"]:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

  #search-bannerr-mobile {
    padding: 1rem;
    width: 100%;
    margin: 0 auto;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #e0e0e0;
    background: #fff;
    box-shadow: 5px 3px 6px #00000029;
  }

  .search-duration::after {
  content: "";
  display: block;
  width: 3px;
  height: 70%;
  background-color: #dcd8d8;
  margin-left: 30px;
}
  
  .container-banner-mobile {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  
  .search-details-mobile {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .search-location {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .search-duration {
    display: flex;
    align-items: center;
    width: 80%;
  }

  .search-number {
    display: flex;
    align-items: center;
    width: 42%;
    margin-left: 4vw;
  }
  
  .hortense-btn-primary {
    margin-top: 1rem;
    padding: 8px 16px;
    background-color: #3b6152;
    color: white;
    border: none;
    cursor: pointer;
    width: 98% !important;
    height: 6vh !important;
  }
  
  .icon {
    margin-right: 0.5rem;
  }

  .iconn {
    margin-right: 0.1rem;
  }
  
  .search-bar-location,
  .search-bar-top {
    background-color: white;
    border-width: 0px !important;
    /* border-bottom: 1px solid black !important; */
    /* padding-bottom: 0.5rem; */
    width: 100%;
  }
  
  .search-bar-location::placeholder,
  .search-bar-top::placeholder {
    color: black;
  }
  </style>
  