<template>
	<div id="switch-trip">
		<div class="buisness-travel df-jsb-aic mt2">
			<h2 class="raleway-semi-p13 mb-0">Voyage d'affaires</h2>
			<input type="checkbox" id="voyage d’affaires" v-model="selected" /><label for="voyage d’affaires">Voyage d'affaires</label>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'switch-trip',
		data() {
			return {
				selected: false,
			};
		},
		watch: {
			selected(val) {
				this.$emit('checked', val, 'business');
			},
		},
	};
</script>

<style lang="scss" scope="app/assets/stylesheets/components/lodging-filter"></style>
