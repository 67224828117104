<template>
  <div id="search-banner" v-if="this.display">
    <div class="container-banner">
      <div class="search-location">
        <img src="../../images/Loupe.png" alt="search icon" class="icon" />
        <input
          class="raleway-reg-p15 search-bar-location"
          type="text"
          placeholder="Recherchez un hôtel, une destination"
          v-model="search"
        />
      </div>
      <div class="search-details-mobile">
        <!-- Date Picker -->
        <div class="search-duration">
          <img src="../../images/3.png" alt="calendar icon" class="iconn" />
          <HotelDatePicker
            :startDate="minDate"
            :endDate="maxDate"
            :format="'DD/MM/YYYY'"
            :gridStyle="false"
            :hoveringTooltip="false"
            :i18n="cFR"
            ref="datePicker"
            @periodSelected="selectDates"
          ></HotelDatePicker>
        </div>

        <!-- Guest Info -->
        <div class="search-number" style="position: relative;"> <!-- Ensure this has position relative for dropdown to be positioned correctly -->
          <img src="../../images/pers.png" alt="guest icon" class="iconn" />
          <input
            class="raleway-reg-p15 search-bar-top text-center"
            @click="showGuestInfo = !showGuestInfo"
            :placeholder="formatAdults()"
          />
          <MobileGuestInfo
            v-if="showGuestInfo"
            @ageSelected="updateAges"
            @increaseRooms="updateRooms"
            @decreaseRooms="updateRooms"
            @increaseChilds="updateChilds"
            @decreaseChilds="updateChilds"
            @increaseAdults="updateAdults"
            @decreaseAdults="updateAdults"
            @closed="handleClose"
          />
        </div>
      </div>

      <button class="hortense-btn-primary" :disabled="disableGoButton" @click="performSearch">
        <div class="search-button">{{ searchButtonCaption }}</div>
      </button>
    </div>
    <button class="close-button" @click="close">
      <img src="../../images/Croix fermer.svg" alt="fermer" />
    </button>
  </div>
</template>

<script>
import MobileGuestInfo from "components/btns/MobileGuestInfo.vue";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import fetcher from '../../packs/shared/api.js';

export default {
  name: "SearchBannerPhone",
  components: { MobileGuestInfo, VueDatePicker },
  props: {
    lodging: {
      type: Object,
      default: null,
    },
    searched: Boolean,
    expandCalendar: Boolean,
  },
  data() {
    return {
      lat: "",
      long: "",
      not_enable: false,
      valtemp: "",
      selectedDates: [],
      showGuestInfo: false,
      rooms: 1,
      childs: 0,
      adults: 2,
      childAges: [],
      color: "#CDDFD3",
      date: new Date(),
      currentDate: new Date(),
      display: true,
      isSearching: false,
      cFR: {
        night: "Nuit",
        nights: "Nuits",
        week: "semaine",
        weeks: "semaines",
        "day-names": ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
        "check-in": "Départ",
        "check-out": "Arrivée",
        "month-names": [
          "Janvier",
          "Février",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octobre",
          "Novembre",
          "Décembre",
        ],
        tooltip: {
          halfDayCheckIn: "Available CheckIn",
          halfDayCheckOut: "Available CheckOut",
          saturdayToSaturday: "Only Saturday to Saturday",
          sundayToSunday: "Only Sunday to Sunday",
          minimumRequiredPeriod: "%{minNightInPeriod} %{night} minimum.",
        },
      },
    };
  },
  watch: {
    searched() {
      this.isSearching = !this.searched;
    },
    expandCalendar(new_value, old_value) {
      if (this.expandCalendar) {
        window.scrollTo({ top: 0, behavior: "smooth" });
        setTimeout(() => {
          this.$refs.datePicker.showDatepicker();
        }, 100);
      }
      this.$emit('updateExpandCalendar')
    },
  },
  methods: {
    handleClose() {
      this.showGuestInfo = false;
    },
    formatKoediaDate(date) {
      if(date) {
        if (date.includes('-')) {
          return date;
        }
        const dateBits = date.split('/');
        return `${dateBits[2]}-${dateBits[1]}-${dateBits[0]}`; 
      } else {
        return null
      }
    },
    async performSearch() {
      this.$emit("selectDates", this.selectedDates);
      this.isSearching = true;
      try {
        const params = new URLSearchParams({
        search: this.search,
        childs: this.childs,
        adults: this.adults,
        start_date: this.selectedDates ? this.formatKoediaDate(this.selectedDates[0]) : null,
        end_date: this.selectedDates ? this.formatKoediaDate(this.selectedDates[1]) : null,
      }).toString();

      window.location.href = `/reservation-hotel?${params}`;
      } catch (e) {
        console.error(e);
        this.isSearching = false;
      } finally {
        this.isSearching = false;
      }
    },
    validate() {
      this.$emit("selectDates", this.selectedDates);
      this.$emit("adultsNum", this.adults);
      this.$emit("childsNum", this.childs);
      this.$emit("childsAge", this.childAges);
      this.$emit("formSubmit");
      this.isSearching = true;
      this.showGuestInfo = false;
    },
    selectDates(event, start, end) {
      // console.log(this.formatDate(start), this.formatDate(end))
      this.selectedDates = [this.formatDate(start), this.formatDate(end)];
      // console.log(this.selectDates);
    },
    formatDate(date) {
      const set = new Date(date);
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      //return set.toLocaleString("default", options);
      return set.toLocaleString("fr", options);
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition(function (position) {
        let lat = position.coords.latitude;
        let long = position.coords.longitude;
        window.open(`/reservation-hotel?geo=${lat},${long}`, '_blank');
        // Turbolinks.visit(`/reservation-hotel?geo=${lat},${long}`);
      });
    },
    updateRooms(room) {
      this.rooms = room;
      this.$emit("numberOfRoom", this.rooms);
    },
    formatRooms() {
      let chambre = " Chambre";
      if (this.rooms > 1) {
        chambre += "s";
      }
      return this.rooms + chambre;
    },
    updateChilds(child) {
      this.childs = child;
      this.$emit("numberOfChild", this.childs);
    },
    formatChilds() {
      let enfant = " Enfant";
      if (this.childs > 1) {
        enfant += "s";
      }
      return this.childs + enfant;
    },
    updateAdults(adult) {
      this.adults = adult;
      this.$emit("numberOfAdult", this.adults);
    },
    updateAges(value) {
      this.childAges = [...value];
      this.$emit("ageOfChild", this.childAges);
    },
    formatAdults() {
      let adulte = " Adulte";
      if (this.adults > 1) {
        adulte += "s";
      }
      return this.adults + adulte;
    },
    close() {
      if (this.lodging) {
        this.display = false;
      } else {
        this.$emit("close");
      }
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    const from = urlParams.get('from');
    const to = urlParams.get('to');
    const searchParam = urlParams.get('search');
    const adults = urlParams.get('adults');
    const childs = urlParams.get('childs');

    if (from && to) {
      this.selectedDates = [this.formatDate(from), this.formatDate(to)];
      this.$emit("selectDates", this.selectedDates);
    }

    if (searchParam) {
      this.search = searchParam;
    }

    if (adults) {
      this.adults = parseInt(adults);
    }

    if (childs) {
      this.childs = parseInt(childs);
    }
  },
  computed: {
    searchButtonCaption() {
      return this.isSearching ? "Recherche en cours..." : "Rechercher";
    },
    disableSearchButton() {
      return this.isSearching;
    },
    disableGoButton(){
      return this.getlocate;
    },
    minDate() {
      return new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        this.currentDate.getDate()
      );
    },
    maxDate() {
      return new Date(
        this.currentDate.getFullYear() + 1,
        this.currentDate.getMonth(),
        this.currentDate.getDate()
      );
    },
    search: {
      get () {
        if (this.lodging) {
          return this.lodging.name
        }
        else{
          return this.valtemp;
        }
      },
      set (newValue) {
        this.valtemp = newValue
      }
    },
  },
};
</script>
<style scoped>
#search-banner {
  padding: 1rem;
  width: 100%;
  margin: 0 auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #e0e0e0;
  background: #fff;
  box-shadow: 5px 3px 6px #00000029;
}

.container-banner {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
}

.search-details-mobile {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.search-location {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-duration {
  display: flex;
  align-items: center;
  width: 80%;
}

.search-number {
  display: flex;
  align-items: center;
  width: 42%;
  margin-left: 4vw;
}

.hortense-btn-primary {
  margin-top: 1rem;
  padding: 8px 16px;
  background-color: #3b6152;
  color: white;
  border: none;
  cursor: pointer;
  width: 100%;
  height: 6vh !important;
}

.icon {
  margin-right: 0.5rem;
}

.search-bar-location,
.search-bar-top {
  background-color: white;
  border-width: 0px !important;
  border-bottom: none !important;
  width: 100%;
}

.search-bar-location::placeholder,
.search-bar-top::placeholder {
  color: black;
}

.close-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  background: none;
  border: none;
  cursor: pointer;
}

.close-button img {
  width: 24px;
  height: 24px;
}
</style>
