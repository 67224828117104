<template>
  <div id="destinations">
    <div class="container-index">
      <div class="container">
        <p class="pb-3 raleway-reg-p15 text-center">Bonjour {{this.reservation.first_name}},</p>
        <p class="pb-2 raleway-reg-p15 text-center">Nous avons bien enregistré votre réservation.</p>
        <p class="pb-2 raleway-reg-p15 text-center">Nous espérons que vous vivrez une expérience</p>
        <p class="pb-2 raleway-reg-p15 text-center">Yogacation exceptionnelle.</p>
        <p class="pb-5 raleway-reg-p15 text-center">À bientôt ! L’équipe Hortense.</p>
        <div class="form-row justify-content-center">
          <button @click="goToYoga" class="hortense-btn-primary">RETOURNER SUR LA PAGE D’ACCUEIL</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeadYoga from "./HeadYoga.vue";
import ScrollUp from "../../components/btns/ScrollUp.vue";
import fetcher from "packs/shared/api.js";
import Searchbtn from "../../components/btns/Searchbtn.vue";
import Searchbanner from "../../components/btns/Searchbanner.vue";
import YogaCard from "./YogaCard.vue";
import YogaStay from "./YogaStay.vue";
import YogaCardAlter from "./YogaCardAlter.vue";

export default {
  name: "payment-success",
  components: { HeadYoga, ScrollUp, Searchbtn, Searchbanner, YogaCard, YogaStay, YogaCardAlter  },
  props:["reservation"],
  data() {
    return {
      showBanner: false,
      selectedDestination: "France",
      destinations: null,
      isLoading: true,
      fileNames: {
        "Caraïbes": "Caraibes",
        "Polynésie Française": "Polynesias",
        "Afrique": "Africas",
        "Océan Indien": "Indians",
        "France": "Francecard",
        "Europe": "Europe",
        "Americas": "Americas",
        "Asia": "Asia"
      },
    };
  },
  methods: {
    goToYoga() {
      window.location.href = "/experiences-bien-etre"
    },
    async hashEmail(email) {
      if(email && email !== '') {
        const encoder = new TextEncoder();
        const data = encoder.encode(email);
        const hash = await crypto.subtle.digest('SHA-256', data);
        const hashArray = Array.from(new Uint8Array(hash));
        return hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
      } else {
        return ""
      }
    }
  },
  async mounted()  {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'purchase',
      ecommerce: {
        currency: 'EUR',
        transaction_id: '66882458',
        discount: 0,
        coupon : this.reservation.promo || null,
        value: this.reservation.total,
        quantity: 1,
        method: 'Visa',
        items: [{
          item_name: 'Yoga & Introspection',
          item_id: this.reservation.yoga_rate_id,
          price: this.reservation.total,
          item_city: 'Près de Paris',
          item_region: 'Occitanie',
          item_category: 'Yoga Vinyasa, Yin Yoga, Pranayama',
          // item_variant: this.yoga_rate.yoga_type,
          quantity: 1
        }]
      },
    });
    const hashedEmail = await this.hashEmail(this.reservation.email  || '');

    window.dataLayer.push({
      event: 'page_view',
      page: {
        page_type: 'yogacation',
        page_category: 'confirm_reservation',
      },
      user: {
        email: hashedEmail || "",
      },
    });
  }
};
</script>
<style
    lang="scss"
    scope="app/assets/stylesheets/components/destinations"
></style>